import React, {useContext, useEffect, useState} from "react";
import {API_URL, PRIVATE_URL} from "../../common/struct/urlManager";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {COMPONENT_TYPES, INPUT_TYPES} from "../../patterns/form/formConstants";
import ElementForm from "../../patterns/form/elementForm";
import BackLink from "../../patterns/navigation/backLink";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {Loader} from "../../components/loader/loader";
import {getEmailTemplate} from "../../services/AdminService";
import {TEmailTemplate, TEmailTemplateType} from "../../interfaces/email";
import {AppContext} from "../../App";
import {OperationInformation, StoreContext} from "../../common/struct/store";

export default function EmailTemplateForm(): JSX.Element {
  const {t} = useTranslation();
  const {id} = useParams<string>();
  const [store] = useContext<StoreContext>(AppContext);

  const operations = store.global.operations.map((operation: OperationInformation) => ({id: operation.id, label: operation.name}));
  const templateTypes = Object.values(TEmailTemplateType).map(type => ({id: type, label: t("email_templates.type." + type.toLowerCase())}));

  const [template, setTemplate] = useState<TEmailTemplate|null>(null);
  const [loading, setLoading] = useState(true);
  const [submitForm, setSubmitForm] = useState<string>('');

  const elements = [
    {
      component: COMPONENT_TYPES.SELECT,
      type: INPUT_TYPES.DEFAULT,
      name: 'templateType',
      label: 'email_templates.columns.templateType',
      choices: templateTypes
    },
    {
      component: COMPONENT_TYPES.INPUT,
      type: INPUT_TYPES.NUMBER,
      name: 'mailjetId',
      label: 'email_templates.columns.mailjetId'
    },
    {
      component: COMPONENT_TYPES.SELECT,
      type: INPUT_TYPES.DEFAULT,
      name: 'operation',
      label: 'email_templates.columns.operation',
      choices: operations
    },
  ];

  const loadTemplate = (): Promise<void> => {
    if (id == null) {
      setLoading(false);
      return Promise.resolve();
    }
    return getEmailTemplate(id).then(template => {
      setTemplate(template);
      setLoading(false);
    });
  }

  useEffect(() => {
    loadTemplate()
  }, []);

  const submit = (e: any): void => {
    e.preventDefault();
    if (e.target.id === API_URL.EMAIL_TEMPLATES + "-form") {
      setSubmitForm(API_URL.EMAIL_TEMPLATES + "-form");
    }
  }

  const updateTemplateBeforeFetch = (template: any): any => {
    const templateType = template.templateType;
    const operation = template.operation;
    return {
      operation: operation ? (typeof operation === 'object') ? operation.id : 'bo/operations/' + operation : null,
      templateType: (typeof templateType === 'object') ? templateType.id : templateType,
      mailjetId: parseInt(template.mailjetId, 10)
    }
  }

  const updateTemplateBeforeShow = (template: any): any => {
    return {
      ...template,
      operation: template.operation ? {id: template.operation['@id'], label: template.operation.name} : null,
      templateType: {id: template.templateType, label: t("email_templates.type." + template.templateType.toLowerCase())}
    }
  }

  const title = id ? t('email_templates.form.edit_title') : t('email_templates.form.add_title');
  return loading ? <Loader /> :
    <Box sx={{height: "100vh", backgroundColor: "ornament.main", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
      <form id={API_URL.EMAIL_TEMPLATES + "-form"} onSubmit={submit}>
        <Box sx={{maxHeight: "90vh", overflowY: "auto"}}>
          <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", backgroundColor: "ornament.light",
            borderWidth: "0", borderBottomWidth: "1px", borderStyle: "solid", borderColor: "ornament.dark"}}>
            <BackLink/>
            <Typography variant="h1" sx={{mx: 5, mb: 3, fontWeight: "bold", color: "neutral.dark"}}>{title}</Typography>
          </Box>
          <ElementForm name={API_URL.EMAIL_TEMPLATES}
            redirect={PRIVATE_URL.EMAIL_TEMPLATES}
            initialRecord={template??undefined}
            updateRecordBeforeFetch={updateTemplateBeforeFetch}
            updateRecordBeforeShow={updateTemplateBeforeShow}
            elements={elements}
            submitForm={submitForm} invalidateForm={(): void => setSubmitForm('')}
          />
        </Box>
        <Box sx={{display: "flex", justifyContent: "center", m: 5}}>
          <PrimaryBigButton submit={true} label={t("navigation.validate")} action={(): void => setSubmitForm(API_URL.EMAIL_TEMPLATES + "-form")}/>
        </Box>
      </form>
    </Box>
}
