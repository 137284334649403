export const initialAttributeTypes = [
  {
    label: "",
    trad: ""
  }
];

export const initialDataFilesSave = [{}];

export const participantStepsText: string[] = [
  "participants.import_participants.header_text_upload",
  "participants.import_participants.header_text_mapping",
  "participants.import_participants.header_text_validation"
];

export const participantStepsPaths: string[] = [
  "participants.import_participants.header_path_upload",
  "participants.import_participants.header_path_mapping",
  "participants.import_participants.header_path_validation"
];
