import {del, get, getPaginated, post, put, RequestFilter, RequestOrder} from "../common/methods/ApiService";
import {API_URL} from "../common/struct/urlManager";
import {TCompany, TCompanyWallet} from "../interfaces/company";
import {TFunction} from "i18next";

export async function getCompanyWallets(companyId: string, page: number, itemsPerPage: number, search: string|null, order: RequestOrder|null, filters: RequestFilter[]): Promise<{totalItems: number, items: TCompanyWallet[]}> {
  return getPaginated(`${API_URL.COMPANY}/${companyId}/wallets`, page, itemsPerPage, search, order, filters);
}

export async function postWallet(wallet: {name: string, description: string}): Promise<TCompanyWallet> {
  return post(API_URL.WALLETS, wallet);
}

export async function updateWallet(id: string, wallet: {name: string, description: string}): Promise<TCompanyWallet> {
  return put(API_URL.WALLETS, id, wallet);
}

export async function deleteWallet(id: string, walletId?: string): Promise<TCompanyWallet> {
  if (!walletId) {
    return del(`${API_URL.WALLETS}`, id, false);
  } else {
    return del(`${API_URL.WALLETS}`, id, false, "?newWalletId="+walletId);
  }
}

export async function transferWallet(companyId: string, senderId: string, receiverId: string, amount: string): Promise<TCompanyWallet[]> {
  return post(`${API_URL.COMPANY}/${companyId}/wallet-transfer`, {amount: amount, walletSenderId: senderId, walletReceiverId: receiverId})
}


export function renameWallets(company: TCompany, wallets: TCompanyWallet[], t: TFunction): TCompanyWallet[] {
  return wallets.map((wallet) => {
    if (wallet.id == company.defaultWallet.id && wallet.name == "default") {
      wallet.name = t("wallets.config.default_wallet.name");
    } else if (wallet.id == company.expiredPointsWallet.id) {
      wallet.name = t("wallets.config.expired_points_wallet.name");
    }
    return wallet;
  })
}

export async function getCompanyPointsMovements(companyId: string, page: number, itemsPerPage: number, search: string|null, order: RequestOrder|null, filters: RequestFilter[]): Promise<{totalItems: number, items: TCompanyWallet[]}> {
  return getPaginated(`${API_URL.COMPANY}/${companyId}/transactions`, page, itemsPerPage, search, order, filters);
}

export async function getCompanyWalletsUsed(companyId: string): Promise<{id: string, name: string}[]> {
  return get(`${API_URL.COMPANY}/${companyId}/wallets-used`);
}
