import React, {useContext, useState} from 'react';
import Wizard from '../../patterns/wizard/wizard';
import {WizardSize} from "../../patterns/wizard/wizardProps";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import OperationCharacteristicsCard from "../operationCharacteristics/operationCharacteristicsCard";
import OperationShowcaseCard from "../operationShowcase/operationShowcaseCard";
import OperationUniversesCard from "../operationUniverses/operationUniversesCard";
import WizardHelperBasic from "../../patterns/wizard/wizardHelperBasic";
import CharacteristicsImage from "../../assets/wizard/operation/characteristics.png";
import ShowcaseImage from "../../assets/wizard/operation/showcase.png";
import UniversesImage from "../../assets/wizard/operation/universes.png";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import DeleteDialog from "../../patterns/dialog/deleteDialog";
import {useTranslation} from "react-i18next";
import {deleteOperation} from "../../services/OperationService";
import {operationStoreReducer} from "../../common/methods/context-setter/operationStoreReducer";
import {CANCEL, INIT_STORE} from "../../common/methods/context-setter/globals";
import {useNavigate} from "react-router-dom";

export default function CreateOperationWizard(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const [loading, setLoading] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [error, setError] = useState<string|null>(null);

  const params = {
    loading: loading,
    setLoading: setLoading,
    setError: setError
  }
  const steps = [
    {
      content: <OperationCharacteristicsCard key="operation-characteristics" {...params}/>,
      helper: <WizardHelperBasic helper="operations.characteristics.helper" Image={CharacteristicsImage} imageAlt=""/>,
      size: WizardSize.FIFTY_FIFTY
    },
    {
      content: <OperationShowcaseCard key="operation-showcase" {...params}/>,
      helper: <WizardHelperBasic helper="operations.showcase.helper" Image={ShowcaseImage} imageAlt=""/>,
      size: WizardSize.FIFTY_FIFTY
    },
    {
      content: <OperationUniversesCard key="operation-universes" {...params}/>,
      helper: <WizardHelperBasic helper="operations.shop.universes.helper" Image={UniversesImage} imageAlt=""/>,
      size: WizardSize.SIXTY_FORTY
    },
  ]

  const cancelOperation = (): Promise<void> => {
    if (store.operationWizard.operation == null) {
      operationStoreReducer(STORE, {type: INIT_STORE, notification: t("wizard.operation.cancel.notification")});
      navigate(PRIVATE_URL.LANDING_PAGE);
      return Promise.resolve();
    }

    return deleteOperation(store.operationWizard.operation.id)
      .then(() => {
        operationStoreReducer(STORE, {type: CANCEL, notification: t("wizard.operation.cancel.notification")});
        navigate(PRIVATE_URL.LANDING_PAGE);
      })
      .finally(() => setOpenCancelDialog(false))
  }
  const cancelDialog = <DeleteDialog
    open={openCancelDialog}
    close={(): void => setOpenCancelDialog(false)}
    title={t("wizard.operation.cancel.title")}
    warning={t("wizard.operation.cancel.content")}
    buttonLabel={t("wizard.operation.cancel.button_label")}
    cancelLabel={t("wizard.operation.cancel.cancel_label")}
    deleteElement={cancelOperation}/>

  return (
    <Wizard steps={steps} wizard={store.operationWizard} loading={loading} error={error} endUrl={PRIVATE_URL.LANDING_PAGE}
      cancelInfo={{dialog: cancelDialog, openDialog: openCancelDialog, setOpenDialog: setOpenCancelDialog}}/>
  )
}
