import React, {useState} from "react";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import moment from 'moment';
import {TOperationParticipant, TSendInvitationNow} from "../../../../interfaces/operationParticipant";
import {TParticipant} from "../../../../interfaces/participant";
import {GreySmallButton, PrimaryBigButton, SecondaryBigButton} from "../../../../components/buttons/mainButton";
import InfoIcon from "@mui/icons-material/Info";
import {fr} from "date-fns/locale";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {Link} from "react-router-dom";
import {operationUrl, PRIVATE_URL} from "../../../../common/struct/urlManager";


type InviteParticipantProps = {
  openDialog: boolean,
  loading: boolean,
  closeDialog: () => void,
  sendMail: (sendAt: string) => void,
  operationParticipant: TOperationParticipant|null
}

export default function InviteParticipant(props: InviteParticipantProps): JSX.Element {
  const {openDialog, loading, closeDialog, sendMail, operationParticipant} = props
  const [option, setOption] = useState<TSendInvitationNow>(TSendInvitationNow.NOW);
  const [sendAt, setSendAt] = useState(moment(new Date()).add(1, 'day').set('hour', 9).set('minutes', 0).format("YYYY-MM-DD hh:mm"));
  const [border, setBorder] = useState<boolean>(false);

  const {t} = useTranslation();
  const mailInformation = (operationParticipant: TOperationParticipant): JSX.Element => {
    const invitationEmailDate = operationParticipant.invitationEmailDate;

    return (
      <Box sx={{display: 'flex', marginTop:"3px"}}>
        {invitationEmailDate ?
          <>
            <WarningAmberOutlinedIcon sx={{marginRight:"10px", color: "warning.main", mt: "3px"}}/>
            <Typography variant="body1">{t('participants.invitation.email_sent_to', {'date': moment(invitationEmailDate).format('DD-MM-YYYY')})}</Typography>
          </> :
          <>
            <InfoOutlinedIcon sx={{marginRight:"10px", color: "info.main", mt: "3px"}}/>
            <Typography variant="body1">{t('participants.invitation.no_email_sent_to')}</Typography>
          </>
        }
      </Box>
    )
  }
  const participantName = (participant: TParticipant): string => {
    const names = [participant.firstName??'', participant.lastName??''];
    return participant.lastName ? names.join(' ') : participant.email;
  }
  const actionSendMail = (): void => {
    sendMail(option === TSendInvitationNow.NOW ? 'now' : sendAt)
    closeDialog()
  }

  const shouldShowWarning = (): boolean => {
    if (!operationParticipant || !operationParticipant.operation.endDate) return false;
    if (option === TSendInvitationNow.NOW) {
      return ((new Date(operationParticipant.operation.endDate)) < new Date());
    } else {
      return ((new Date(operationParticipant.operation.endDate)) < new Date(sendAt));
    }
  }

  return (
    <Dialog open={openDialog} onClose={closeDialog} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: "info.main", borderColor: "info.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: 'flex', alignItems: "center"}}>
          <InfoIcon sx={{color: "info.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {t('participants.invitation.send_title')}
          </Typography>
        </Box>
        <IconButton onClick={closeDialog}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        {operationParticipant && <>
          {operationParticipant.operation.endDate && shouldShowWarning() &&
            <Alert severity="warning" variant="filled" sx={{mb: 3, mt: 3}}>
              {t('participants.invitation.send_operation_has_passed_warning', {endDate: moment(operationParticipant.operation.endDate).format('DD/MM/YYYY')})}
              <Box sx={{mt: 2}}>
                <Link to={operationUrl(PRIVATE_URL.OPERATION_CHARACTERISTICS, operationParticipant.operation.id)} style={{width: "inherit"}}>
                  <GreySmallButton label={t('participants.invitation.send_operation_has_passed_update_button')}/>
                </Link>
              </Box>
            </Alert>
          }
          <Typography variant="body1" color="neutral.main" sx={{fontWeight: "unset"}} dangerouslySetInnerHTML={{__html: t('participants.invitation.send_to_name', {name: participantName(operationParticipant.participant)})}}/>
          { mailInformation(operationParticipant) }
          <RadioGroup>
            <FormControlLabel
              disabled={loading}
              checked={option === TSendInvitationNow.NOW}
              onChange={(): void => setOption(TSendInvitationNow.NOW)} control={<Radio sx={{fontSize: 24}}/>}
              label={<Typography variant="body2" color="neutral.dark">{t("participants.invitation.choice.now")}</Typography>}/>
          </RadioGroup>
          <RadioGroup>
            <FormControlLabel
              disabled={loading}
              checked={option === TSendInvitationNow.LATER}
              onChange={(): void => setOption(TSendInvitationNow.LATER)} control={<Radio sx={{fontSize: 24}}/>}
              label={<Typography variant="body2" color="neutral.dark">{t("participants.invitation.choice.later")}</Typography>}/>
            <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
              <DateTimePicker
                inputFormat="dd MMMM yyyy à HH:mm"
                value={sendAt}
                disabled={loading || option !== TSendInvitationNow.LATER}
                minDate={new Date()}
                minutesStep={5}
                ampm={false}
                onChange={(newDate: any): void => {
                  moment.locale('fr')
                  const date = moment(newDate).toString()
                  setSendAt(date)
                }}
                onOpen={(): void => setBorder(true)}
                onClose={(): void => setBorder(false)}
                PopperProps={{
                  sx: {borderWidth: border ? '1px' : '0px', borderStyle: "solid", borderColor: "primary.main", borderRadius: "4px"}
                }}
                renderInput={(params): any => <TextField {...params} inputProps={{...params.inputProps, placeholder: "jj/mm/aaaa"}} sx={{width: "100%", "& input": {p: 3}}} />}
              />
            </LocalizationProvider>
          </RadioGroup>
        </>}
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <SecondaryBigButton sx={{mr: 0}} label={t("list.delete.cancel")} action={closeDialog}/>
        <PrimaryBigButton action={actionSendMail} sx={{height: "56px"}} label={t('participants.invitation.send_confirmation_button')} loading={loading} />
      </DialogActions>
    </Dialog>
  );

}
