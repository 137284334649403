import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Chip, Typography} from "@mui/material";
import AutoRenewIcon from "@mui/icons-material/Autorenew";
import {useGridApiRef} from "@mui/x-data-grid-pro";
import {AppContext} from "../../../App";
import emptyParticipants from "../../../assets/lists/empty_participants.svg";
import {errorManager, manageStringError, RequestFilter, RequestOrder} from "../../../common/methods/ApiService";
import {StoreContext} from "../../../common/struct/store";
import List from "../../../patterns/list/list";
import {
  actionColumn,
  columnDefinition,
  ColumnType,
  enumFilterWithoutNull,
  gridAction
} from "../../../patterns/list/listUtils";
import {TParticipantAttribute, TParticipantAttributeType} from "../../../interfaces/participantAttribute";
import {
  getPaginatedDeletedParticipantAttributes,
  restoreParticipantAttribute
} from "../../../services/ParticipantService";
import RestoreDialog from "../../../patterns/dialog/restoreDialog";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {useNavigate} from "react-router-dom";

interface ListDeletedOperationParticipantAttributeProps {
  addSuccess: (success: string) => void;
  addError: (error: string) => void;
  reload: boolean;
  setReload: (reload: boolean) => void;
  setClose: (close: boolean) => void;
}

export default function ListDeletedOperationParticipantAttributes(props: ListDeletedOperationParticipantAttributeProps): JSX.Element {
  const {addSuccess, addError, reload, setReload, setClose} = props;
  const {t} = useTranslation();
  const apiRef = useGridApiRef();
  const STORE = useContext<StoreContext>(AppContext);

  const [participantAttribute, setParticipantAttribute] = useState<TParticipantAttribute|null>(null);
  const [openRestoreDialog, setOpenRestoreDialog] = useState<boolean>(false);

  const navigate = useNavigate();

  const typeColumnDefinition: GridEnrichedColDef = {
    ...columnDefinition(t, "operation_participants.attributes", "type", 100),
    filterOperators: enumFilterWithoutNull,
    valueOptions: Object.values(TParticipantAttributeType).map(status => ({value: status, label: t(`operation_participants.attributes.type.${status.toLowerCase()}`)})),
    valueGetter: (item): string => t("operation_participants.attributes.type." + item.row.type),
    renderCell: (item): JSX.Element => <Chip sx={{backgroundColor: "primaryLight.main", borderRadius: '4px'}}
      label={<Typography variant="body2" color="primary.main" sx={{fontWeight: "bold"}}>
        {item.value}
      </Typography>}/>
  }

  const listColumns = [
    columnDefinition(t, "operation_participants.attributes", "label", 170, ColumnType.STRING),
    typeColumnDefinition,
    columnDefinition(t, "operation_participants.attributes", "deletedBy", 200, ColumnType.STRING, false),
    columnDefinition(t, "operation_participants.attributes", "deletedAt", 225, ColumnType.DATE_TIME),
    actionColumn(params => [
      gridAction(params, "restore_attribute", t(`operation_participants.attributes.actions.restore`), AutoRenewIcon, (attribute: TParticipantAttribute): void => {
        setParticipantAttribute(attribute);
        setOpenRestoreDialog(true);
      })
    ])
  ]

  const loadAttributes = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TParticipantAttribute[]}> => {
    return getPaginatedDeletedParticipantAttributes(page, maxItems, search, order, filters);
  }

  const restoreAttribute = (): void => {
    if (participantAttribute == null) {
      return;
    }

    restoreParticipantAttribute(participantAttribute.id)
      .then(() => {
        setReload(true);
        addSuccess(t("operation_participants.attributes.actions.restore_success"));
      })
      .catch(error => manageStringError(errorManager(error, t, STORE, navigate), addError, t))
      .finally(() => setClose(true))
  }

  const restoreAttributeDialog = <RestoreDialog
    key="restore-attribute"
    title={t("operation_participants.attributes.actions.restore_title", {"label": participantAttribute?.label})}
    open={openRestoreDialog}
    close={(): void => setOpenRestoreDialog(false)}
    info={t("operation_participants.attributes.actions.restore_warning")}
    restoreElement={restoreAttribute}/>

  return <List
    sx={{mt: 5, mx: 5, gridColumn: "1/5"}}
    translationPrefix="operation_participants.attributes"
    columns={listColumns}
    apiRef={apiRef}
    loadRows={loadAttributes}
    reload={reload}
    setReload={setReload}
    emptyListIcon={emptyParticipants}
    height="100%"
    dialogs={[restoreAttributeDialog]}
  />
}
