import React, {ElementType} from "react";
import SvgIcon from "@mui/material/SvgIcon";
import {Avatar} from "@mui/material";
import {getColors} from "../../tokens/libertyTheme";


export type MainItemProps = {
  type: ItemType,
  parameterType?: ParameterType,
  label?: string,
  icon?: ElementType,
  rightHerringbone?: ElementType,
  avatar?: boolean,
  position?: IconPosition,
  opId?: string,
  nbOp?: number,
  index?: number,
  gOpId?: string,
  isClicked_val?: boolean
}

export type ItemProps = {
  type?: ItemType,
  parameterType?: ParameterType,
  label?: string,
  icon?: ElementType,
  rightHerringbone?: ElementType,
  avatar?: boolean,
  opId?: string,
  nbOp?: number,
  index?: number,
  gOpId?: string,
  isClicked_val?: boolean
}

export type SizeProperties = {
  width: string,
  height: string,
  padding: string,
  iconSize: string,
  fontSize: string
}

export type TypeProperties = {
  activeBackgroundColor: string,
  iconColor: string
}

export enum ItemType {
  OPERATION,
  PARAMETER,
  SELECT_COMPANY,
  CONTACT_ADMIN
}

export enum ParameterType {
  ADMINISTRATORS,
  BILLING,
  FAQ,
  CONTACT,
  HISTORY,
  TEMPLATES,
  WALLETS
}

export enum IconPosition {
  LEFT,
  MIDDLE,
  RIGHT
}

export function getHerringbone(props: ItemProps, iconSize: string): JSX.Element | null {
  if (props.rightHerringbone === undefined) {
    return null;
  }
  const Herringbone = props.rightHerringbone;
  return (
    <SvgIcon component={Herringbone} sx={{color: "ornament.light", fontSize: iconSize, mr: 1}}/>
  );
}

export function getIcon(props: ItemProps, iconSize: string, iconColor: string): JSX.Element | null {
  if (props.icon === undefined) {
    return null;
  }

  const Icon = props.icon;
  return (
    <SvgIcon id="svgIcon" component={Icon} sx={{color: iconColor, fontSize: iconSize, m: 1}}/>
  );
}

export function getAvatar(props: ItemProps, iconSize: string, iconColor: string, fontSize: string): JSX.Element | null {
  if (props.avatar === undefined) {
    return null;
  }

  const avatar = (props.label)?.charAt(0).toUpperCase();
  const bgColor = getColors()[((props.index??0)%((getColors()).length))];

  return (
    <Avatar
      variant="rounded"
      sx={{backgroundColor: bgColor, height: iconSize, width: iconSize, fontSize: fontSize, m: 1, color: iconColor}}>
      {avatar}
    </Avatar>
  );
}

export function getPic(props: ItemProps, iconSize: string, fontSize: string, iconColor: string): JSX.Element | null {
  if (props.avatar) {
    return getAvatar(props, iconSize, iconColor, fontSize);
  }
  else if (props.icon) {
    return getIcon(props, iconSize, iconColor);
  }
  else {
    return null;
  }
}
