import React, {useContext, useEffect, useState} from 'react';
import {Loader} from "../../../components/loader/loader";
import {RequestFilter, RequestOrder} from "../../../common/methods/ApiService";
import {useTranslation} from "react-i18next";
import {TWalletTransaction} from "../../../interfaces/wallet"
import List from "../../../patterns/list/list";
import {GridValueGetterParams, useGridApiRef} from "@mui/x-data-grid-pro";
import {
  addErrorAlert,
  addSuccessAlert,
  columnDefinition,
  ColumnType,
  valueGetter
} from "../../../patterns/list/listUtils";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import emptyTransactions from "../../../assets/lists/empty_transactions.svg";
import {TAlert} from "../../../patterns/list/listSnackbar";
import {AppContext} from '../../../App';
import {StoreContext} from '../../../common/struct/store';
import {getCompanyWalletTransactions} from '../../../services/WalletTransactionService';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ListMenuItem from '../../../patterns/list/listMenuItem';
import {getExportCompanyPointsPurchase} from '../../../services/ImportService';
import {
  handleCancelPayment,
  optionColumnDefinition,
  paymentActions,
  statusColumnDefinition
} from "../../operationTransactions/operationTransactionsPayment";
import DeleteDialog from "../../../patterns/dialog/deleteDialog";
import InfoDialog from "../../../patterns/list/infoDialog";
import {Typography} from "@mui/material";
import {getExportDocument} from "../../../common/methods/exportDocument";
import OperationReferenceDialog from "../../operationTransactions/operationReferenceDialog";
import {useNavigate} from "react-router-dom";
import BankDetails from '../../payment/bankDetails';

export default function ListBillingHistory(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();  
  const apiRef = useGridApiRef();
  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openBankInfoDialog, setOpenBankInfoDialog] = useState(false);
  const [openReferenceDialog, setOpenReferenceDialog] = useState(false);
  const [transaction, setTransaction] = useState<TWalletTransaction|null>(null);
  const [reload, setReload] = useState(false);
  const setError = (error: string): void => addErrorAlert(error, setAlerts);

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const company = store.global.company;

  useEffect(() => {
    document.title = t("tab_title.companyBillingHistory");
  }, []);

  const loadTransactions = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: any[]}> => {
    return getCompanyWalletTransactions(company.id, page, maxItems, search, order, filters);
  }

  const cancelTransaction = (transaction: TWalletTransaction): void => {
    setTransaction(transaction);
    setOpenCancelDialog(true);
  }

  const showBankInfo = (transaction: TWalletTransaction): void => {
    setTransaction(transaction);
    setOpenBankInfoDialog(true);
  }

  const handleCancelDialog = (): void => {
    setOpenCancelDialog(false);
    setTransaction(null);
  }

  const updateTransactionReference = (transaction: TWalletTransaction): void => {
    setTransaction(transaction);
    setOpenReferenceDialog(true);
  }

  const walletColumnDefinition = (): GridEnrichedColDef => {    
    return {
      ...columnDefinition(t, "billing_history", "name", 250, ColumnType.STRING, false),
      valueGetter: (item: GridValueGetterParams): string => valueGetter(item.row.wallet['name'] == store.global.company.defaultWallet!.name ? t("wallets.config.default_wallet.name") : item.row.wallet['name'] == store.global.company.expiredPointsWallet!.name ? t("wallets.config.expired_points_wallet.name") : item.row.wallet['name'], ColumnType.STRING)
    }
  }

  const adminColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "billing_history", "admin", 150, ColumnType.DATE_TIME, false),
      valueGetter: (item: GridValueGetterParams): string => valueGetter(item.row.admin['firstName'] + ' ' + item.row.admin['lastName'], ColumnType.STRING)
    }
  }

  const amountTaxesColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "billing_history", "amount_taxes", 150, ColumnType.EUROS, false),
      valueGetter: (item: GridValueGetterParams): string => valueGetter(item.row['amount'], ColumnType.EUROS)
    }
  }

  const exportTransactions = (): Promise<void> => {
    return getExportCompanyPointsPurchase(company.id!)
      .then(response => {
        getExportDocument(response)
      })
  }

  const exportAction = <ListMenuItem label={t("operation_participants.actions.export")} icon={FileDownloadIcon} action={(): any => exportTransactions()}/>
  const cancelDialog = <DeleteDialog open={openCancelDialog} close={handleCancelDialog} title={t("operation_transactions.cancel.title", {amount: transaction?.amount??0})}
    warning={t("operation_transactions.cancel.warning")} deleteElement={(): Promise<void> => handleCancelPayment(transaction, setReload)}/>

  /** TODO: remplacer par les infos récupérées sur Stripe */
  const bankInfoDialog = <InfoDialog open={openBankInfoDialog} close={(): void => setOpenBankInfoDialog(false)} title={t("operation_transactions.bank_info.title", {amount: transaction?.amount??0})}
    action={{title: t("operation_transactions.bank_info.download"), href: "/rib.pdf", newWindow: true}}>
    <Typography variant="body1" color="neutral.dark" sx={{mb: 3, fontWeight: "unset"}}
      dangerouslySetInnerHTML={{__html: t("operation_transactions.bank_info.content", {amount: transaction?.amount??0, reference: transaction?.odooSaleOrderId??"-"})}}/>
    <BankDetails />
  </InfoDialog>

  const referenceDialog = <OperationReferenceDialog open={openReferenceDialog} close={(): void => setOpenReferenceDialog(false)} transaction={transaction}
    reload={(): void => setReload(true)} setSuccess={(success): void => addSuccessAlert(success, setAlerts)}/>

  const columns = [
    columnDefinition(t, "billing_history", "updatedAt", 200, ColumnType.DATE_TIME),
    columnDefinition(t, "billing_history", "odooOrderName", 200, ColumnType.STRING, true, true),
    walletColumnDefinition(),
    adminColumnDefinition(),
    statusColumnDefinition(t),
    optionColumnDefinition(t),
    columnDefinition(t, "billing_history", "amount", 150, ColumnType.EUROS),
    amountTaxesColumnDefinition(),
    columnDefinition(t, "operation_transactions", "reference", 200, ColumnType.STRING, true, true),
    paymentActions(t, navigate, setLoading, setError, STORE, cancelTransaction, showBankInfo, updateTransactionReference)
  ]

  return loading ? <Loader height={"calc(100vh - 180px - 48px)"} /> : <List
    translationPrefix="operation_transactions"
    alerts={alerts}
    setAlerts={setAlerts}
    columns={columns}
    defaultOrder={{field: 'updatedAt', sort: 'desc'}}
    apiRef={apiRef}
    loadRows={loadTransactions}
    height="75vh"
    emptyListIcon={emptyTransactions}
    listActions={[exportAction]}
    dialogs={[cancelDialog, bankInfoDialog, referenceDialog]}
    reload={reload}
    setReload={setReload}
    sx={{width: "100%"}}
  />
}
