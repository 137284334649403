import {TCompanyAware, TLifecycle} from "./traits";
import {TParticipant, TParticipantDeleted} from "./participant";
import {TOperation} from "./operation";

export interface TOperationParticipant extends TLifecycle, TCompanyAware {
  operation: TOperation;
  rgpdAcceptanceDate?: string;
  clientMentionsAcceptanceDate?: string;
  lastConnectionDate?: string;
  invitationStatus: TInvitationStatus;
  invitationEmailDate?: string;
  participant: TParticipant;
  rewardAmount: number;
  rewardPoints: number;
  savLimitDate?: string;
  blockOrder: boolean;
}

export interface TOperationParticipantForListing extends TLifecycle, TCompanyAware {
  operationId: string;
  lastConnectionDate?: string;
  invitationStatus: TInvitationStatus;
  invitationEmailDate?: string;
  participantId: string;
  rewardEuros: number;
  rewardPoints: number;
  spentEuros: number;
  spentPoints: number;
  totalEuros: number;
  totalPoints: number;
  blockOrder: boolean;
  firstName: string;
  lastName: string;
  email: string;
}

export interface TOperationParticipantDeleted extends TLifecycle, TCompanyAware {
  participant: TParticipantDeleted;
}

export enum TInvitationStatus {
  UNSENT = "UNSENT",
  SENT = "SENT",
  PENDING = "PENDING",
  ERROR = "ERROR"
}

export enum TSendInvitationNow {
  NOW = 'NOW',
  LATER = 'LATER'
}

export interface TOperationParticipantToken {
  operationParticipant: TOperationParticipant;
  token: string;
  type: string;
  expiresAt: string;
}

