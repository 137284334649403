import jwt from 'jwt-decode';
import {Token} from "../../services/AuthenticationService";
import {SelectChoice} from "../../pages/selectCompany/selectCompany";
import {TCompany} from '../../interfaces/company';

export function getToken(): string {
  // @ts-ignore
  return JSON.parse(localStorage.getItem("auth"));
}

export function getSelectedCompany(): string|null {
  return localStorage.getItem("companyId");
}

export function getSelectedCompanyName(): string|null {
  return localStorage.getItem("companyName");
}

export function setSelectedCompany(company: TCompany): void {
  localStorage.setItem("companyId", company.id);
  localStorage.setItem("companyName", company.name);
}

export function setSelectedCompanyChoice(company: SelectChoice): void {
  localStorage.setItem("companyId", company.id);
  localStorage.setItem("companyName", company.label);
}

export function isAuthenticated(): boolean {
  return localStorage.getItem("auth") != null;
}

export function isProd(): boolean {
  return process.env.REACT_APP_ENVIRONMENT == "production";
}

export function mustStayConnected(): boolean {
  return localStorage.getItem("stayConnected") === "true";
}

export function getAdminId(): string|null {
  return localStorage.getItem("id");
}

export function isSuperAdmin(): boolean {
  const token = localStorage.getItem("auth");
  if (!token) {
    return false;
  }
  const jwtToken = jwt<Token>(JSON.parse(token));
  return jwtToken.roles.includes('ROLE_SUPER_ADMIN');
}

export const MOBILE_SWITCH_SIZE = 800;
export const checkIsMobile = (): boolean => window.innerWidth <= MOBILE_SWITCH_SIZE;
