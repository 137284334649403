import React, {MouseEvent, useContext, useEffect, useState} from 'react';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CloseIcon from "@mui/icons-material/Close";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import {useTranslation} from "react-i18next";
import InfoIcon from '@mui/icons-material/Info';
import {putShop} from "../../services/ShopService";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import {AppContext} from "../../App";
import {TOperation} from "../../interfaces/operation";
import {StoreContext} from "../../common/struct/store";
import {useNavigate} from "react-router-dom";

type OperationShopBlockDialogProps = {
  operation: TOperation,
  open: boolean,
  setOpen: (open: boolean) => void,
  setError: (error: string) => void,
  setSuccess: (success: string) => void
}

export default function OperationShopBlockDialog(props: OperationShopBlockDialogProps): JSX.Element {
  const {t} = useTranslation();
  const {operation, open, setOpen, setError, setSuccess} = props;
  const blocked = operation.shops[0].blockOrder;
  const action = blocked ? "unblock" : "block";
  const STORE = useContext<StoreContext>(AppContext);
  useEffect(() => {
    document.title = t("tab_title.operation.shop.setting");
  }, []);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = (event: MouseEvent<HTMLElement>): void => {
    if (event.button > 0) {
      return;
    }
    setOpen(false);
  }

  const updateShopBlockOrder = (): void => {
    setLoading(true);
    putShop(operation.shops[0].id, {blockOrder: !blocked})
      .then(shop => {
        globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: {...operation, shops: [shop]}});
        setSuccess(t(`operations.shop.settings.${action}.success`));
      })
      .catch(error => manageStringError(errorManager(error, t, STORE, navigate), setError, t))
      .finally(() => {
        setLoading(false);
        setOpen(false);
      })
  }

  return (
    <Dialog open={open} onClose={handleClose} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: blocked ? "info.main" : "warning.main", borderColor: blocked ? "info.main" : "warning.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          {!blocked && <ReportProblemIcon sx={{color: "warning.main", width: 24, height: 24, mr: 1}}/>}
          {blocked && <InfoIcon sx={{color: "info.main", width: 24, height: 24, mr: 1}}/>}
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {t(`operations.shop.settings.${action}.title`)}
          </Typography>
        </Box>
        <IconButton onClick={(): void => setOpen(false)}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1" color="neutral.main" sx={{mb: 1}}>
          {t(`operations.shop.settings.${action}.content_1`)}
        </Typography>
        <Typography variant="body1" color="neutral.main" sx={{mb: 1}}>
          {t(`operations.shop.settings.${action}.content_2`)}
        </Typography>
        <Typography variant="body1" color="warning.main" sx={{fontWeight: "medium"}}>
          {t("operations.shop.settings.warning")}
        </Typography>
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <SecondaryBigButton disabled={loading} sx={{mr: 0}} label={t(`operations.shop.settings.${action}.cancel`)} action={handleClose}/>
        <PrimaryBigButton loading={loading} label={t(`operations.shop.settings.${action}.confirm`)} action={updateShopBlockOrder}/>
      </DialogActions>
    </Dialog>
  )
}
