import React, {useEffect, useState} from 'react';
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from "@mui/material";
import {TWalletTransaction} from "../../interfaces/wallet";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from "@mui/icons-material/Close";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import {useTranslation} from "react-i18next";
import ElementInput from "../../patterns/form/elementInput";
import {INPUT_TYPES} from "../../patterns/form/formConstants";
import {updateTransactionReference} from "../../services/OperationService";
import {manageStringError} from "../../common/methods/ApiService";

interface OperationReferenceDialogProps {
  open: boolean;
  close: () => void;
  reload: () => void;
  setSuccess: (success: string) => void;
  transaction: TWalletTransaction|null;
}

export default function OperationReferenceDialog(props: OperationReferenceDialogProps): JSX.Element {
  const {open, close, reload, setSuccess, transaction} = props;
  const {t} = useTranslation();

  const [reference, setReference] = useState(transaction?.reference??"");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string|null>(null);

  useEffect(() => {
    setReference(transaction?.reference??"");
  }, [transaction])

  const updateReference = (): void => {
    setLoading(true);
    setError(null);
    updateTransactionReference(transaction!.id, reference.length > 0 ? reference : null)
      .then(() => {
        setSuccess(t("operation_transactions.actions.update_reference_success"));
        reload();
        close();
      })
      .catch(error => manageStringError(error, setError, t))
      .finally(() => setLoading(false))
  }

  return transaction == null ? <></> :
    <Dialog open={open} onClose={close} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: "info.main", borderColor: "info.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <InfoIcon sx={{color: "info.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {t("operation_transactions.actions.add_reference")}
          </Typography>
        </Box>
        <IconButton onClick={close}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1" color="neutral.main" sx={{mb: 3, fontWeight: "unset"}}
          dangerouslySetInnerHTML={{__html: t("operation_transactions.actions.update_reference_content", {amount: transaction.amount})}}/>
        <ElementInput name="reference" type={INPUT_TYPES.DEFAULT} value={reference} updateValue={setReference} disabled={loading}
          placeholder={t("operation_transactions.actions.update_reference_placeholder")}/>
        {error && <Alert variant="filled" severity="error" sx={{mt: 3}}>{error}</Alert>}
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <SecondaryBigButton sx={{mr: 0}} label={t("form.cancel")} action={close} disabled={loading}/>
        <PrimaryBigButton label={t("form.edit")} action={updateReference} loading={loading}/>
      </DialogActions>
    </Dialog>
}
