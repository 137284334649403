import {Box, Card, FormControlLabel, InputAdornment, Radio, TextField, Typography} from "@mui/material";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import React, {useEffect, useState} from "react";
import {OperationShopPricingProps} from "./operationShopPricing";
import {TShopPricing} from "../../interfaces/shop";
import {useTranslation} from "react-i18next";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {putShop} from "../../services/ShopService";
import {manageStringError} from "../../common/methods/ApiService";

export default function OperationShopPricingRange(props: OperationShopPricingProps): JSX.Element {
  const MIN_EURO_PRICE = 50;

  const {shop, setShop, pricing, changePricing, saved, setError} = props;
  const {t} = useTranslation();

  const [validationError, setValidationError] = useState<string|null>(null);
  const [minPrice, setMinPrice] = useState<string>("");
  const [maxPrice, setMaxPrice] = useState<string>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (shop.pricing === TShopPricing.RANGE) {
      setMinPrice(shop.priceMin?.toString()??"");
      setMaxPrice(shop.priceMax?.toString()??"");
    } else {
      setMinPrice("");
      setMaxPrice("");
    }
  }, [shop])

  const savePriceRange = (): void => {
    const minPriceFloat = minPrice ? parseFloat(minPrice) : null;
    const maxPriceFloat = maxPrice ? parseFloat(maxPrice) : null;
    if (minPriceFloat == null && maxPriceFloat == null) {
      setValidationError(t("operations.shop.price.range.validation_missing"));
      return;
    }

    if (minPriceFloat && minPriceFloat < MIN_EURO_PRICE) {
      setValidationError(t("operations.shop.price.range.validation_minimum", {min: MIN_EURO_PRICE}));
      return;
    }

    if (minPriceFloat && maxPriceFloat && minPriceFloat > maxPriceFloat) {
      setValidationError(t("operations.shop.price.range.validation_error"));
      return;
    }

    setValidationError(null);
    setLoading(true);

    putShop(shop.id, {pricing: TShopPricing.RANGE, priceMin: minPriceFloat?.toString()??null, priceMax: maxPriceFloat?.toString()??null, hidePricing: false})
      .then(shop => setShop(shop))
      .catch(error => manageStringError(error, setError, t))
      .finally(() => setLoading(false))
  }

  return (
    <Card sx={{border: "2px solid", boxShadow: 0, mb: 5,
      borderColor: saved === TShopPricing.RANGE ? "primary.main" : pricing === TShopPricing.RANGE ? "neutral.light" : "ornament.dark",
      backgroundColor: saved === TShopPricing.RANGE ? "alt.light" : "none"}}>
      <Box sx={{p: 6}}>
        <FormControlLabel sx={{mb: 1}} checked={pricing === TShopPricing.RANGE}
          onClick={(): void => changePricing(TShopPricing.RANGE)}
          control={<Radio sx={{fontSize: 24, p: "0px", mr: 4}}/>}
          label={<>
            <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 0}}>{t("operations.shop.price.range.title")}</Typography>
            <Typography variant="body2" color="neutral.main" sx={{fontWeight: 400, mr: 0}} dangerouslySetInnerHTML={{__html: t("operations.shop.price.range.subtitle")}} />
          </>}/>
      </Box>
      {pricing === TShopPricing.RANGE && <>
        <Box sx={{p: 6, display: "flex", flexDirection: "column", borderTop: "1px solid", borderColor: "ornament.dark"}}>
          <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mb: 2}}>
            {t("operations.shop.price.range.description")}
          </Typography>
          <Box sx={{display: "flex", alignItems: validationError ? "center" : "end", width: "100%"}}>
            <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
              <Typography variant="body2" color="neutral.main" sx={{mb: 0}}>
                {t("operations.shop.price.range.min")}
              </Typography>
              <TextField
                sx={{"& input": {p: 3}}}
                fullWidth
                color="info"
                id="min-price"
                placeholder={t("operations.shop.price.range.min_placeholder")}
                value={minPrice}
                onChange={(event): void => setMinPrice(event.target.value)}
                required
                disabled={loading}
                InputProps={{
                  lang: "fr-FR",
                  endAdornment: <InputAdornment position="end"><EuroSymbolIcon fontSize="small"/></InputAdornment>,
                  inputProps: {min: MIN_EURO_PRICE}
                }}
              />
            </Box>
            <CompareArrowsIcon sx={{width: 32, height: 32, py: 2, mx: 1, color: "neutral.main"}}/>
            <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
              <Typography variant="body2" color="neutral.main" sx={{mb: 0}}>
                {t("operations.shop.price.range.max")}
              </Typography>
              <TextField
                sx={{"& input": {p: 3}}}
                fullWidth
                color="info"
                id="max-price"
                placeholder={t("operations.shop.price.range.max_placeholder")}
                value={maxPrice}
                onChange={(event): void => setMaxPrice(event.target.value)}
                required
                disabled={loading}
                InputProps={{
                  lang: "fr-FR",
                  endAdornment: <InputAdornment position="end"><EuroSymbolIcon fontSize="small"/></InputAdornment>
                }}
              />
            </Box>
          </Box>
          {validationError && <Box sx={{display: "flex", alignItems: "center", p: 0, mt: 0}}>
            <ErrorOutlineOutlinedIcon sx={{width: 18, height: 18, color: "error.main"}}/>
            <Typography variant="body2" color="error.main" sx={{ml: 0}}>{validationError}</Typography>
          </Box>}
          <Box sx={{display: "flex", alignItems: "center", p: 0, mt: 2}}>
            <InfoOutlinedIcon sx={{width: 18, height: 18, color: "info.main"}}/>
            <Typography variant="body2" color="info.main" sx={{ml: 0}}>
              {t("operations.shop.price.range.information")}
            </Typography>
          </Box>
        </Box>
        <Box sx={{py: 5, px: 6, borderTop: "1px solid", borderColor: "ornament.dark"}}>
          <PrimaryBigButton loading={loading} label={t("operations.shop.price.validate")} action={savePriceRange}/>
        </Box>
      </>}
    </Card>
  )
}
