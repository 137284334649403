import React, {useEffect, useState} from "react";
import {Alert, Stack, Typography} from "@mui/material";

export interface TAlert {
  label: string;
  isSuccess: boolean;
  timestamp: number;
  visible: boolean;
}

interface ListAlertProps {
  label: string;
  isSuccess: boolean;
  visible: boolean;
  removeAlert: () => void;
}

function ListAlert(props: ListAlertProps): JSX.Element {
  const {label, isSuccess, visible, removeAlert} = props;
  const [open, setOpen] = useState(visible);
  const close = (): void => {
    if (open) {
      setOpen(false);
      removeAlert();
    }
  }

  useEffect(() => {
    setTimeout(close, 10000);
  }, [])

  return !open ? <></> :
    <Alert variant="filled" sx={{alignItems: "center"}} severity={isSuccess ? "success" : "error"} onClose={close}>
      <Typography variant="body1" color="ornament.light">{label}</Typography>
    </Alert>
}

export default function ListSnackbar(props: {alerts: TAlert[], setAlerts: (alerts: TAlert[]) => void}): JSX.Element {
  const {alerts, setAlerts} = props;

  const removeAlert = (key: number): void => {
    const closedAlert = alerts.find(alert => alert.timestamp == key);
    if (closedAlert) {
      closedAlert.visible = false;
      setAlerts(alerts);
    }
  }

  return (
    <Stack sx={{position: "fixed", bottom: 24, left: 24, zIndex: 100}} spacing={2}>
      {alerts.map(alert => <ListAlert removeAlert={(): void => removeAlert(alert.timestamp)} key={alert.timestamp} {...alert}/>)}
    </Stack>
  )
}
