import React, {MutableRefObject, useContext, useEffect, useState} from "react";
import {Box, ClickAwayListener, InputAdornment, Menu, TextField, Tooltip, Typography} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {GreySmallButton, PrimarySmallButton, SecondarySmallButton} from "../../../components/buttons/mainButton";
import {IconPosition} from "../../../components/buttons/buttonProps";
import {useTranslation} from "react-i18next";
import {LIBERTY_THEME} from "../../../tokens/libertyTheme";
import {EuroPointToggle, PointsUnit} from "../../../patterns/list/euroPointToggle";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InventoryIcon from '@mui/icons-material/Inventory';
import ListMenuItem from "../../../patterns/list/listMenuItem";
import ListColumns from "../../../patterns/list/listColumns";
import {GridApiPro} from "@mui/x-data-grid-pro/models/gridApiPro";
import AddIcon from "@mui/icons-material/Add";
import EmailIcon from "@mui/icons-material/Email";
import RemoveIcon from "@mui/icons-material/Remove";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteIcon from "@mui/icons-material/Delete";
import {gridColumnVisibilityModelSelector, GridFilterListIcon, GridFilterModel} from "@mui/x-data-grid-pro";
import AddPointsToSelectedParticipants from "./actions/addPointsToSelectedParticipants";
import BlockOrderToSelectedParticipants from "./actions/blockOrderToSelectedParticipants";
import ResetColumn from "./actions/resetColumn";
import {sendParticipantsMoney, viewParticipantCreditWarning24h} from "../../../services/ParticipantService";
import {errorManager, manageStringError, RequestFilter} from "../../../common/methods/ApiService";
import {sendEmailInvitationsToParticipants, updateBlockOrderToParticipants} from "../../../services/OperationService";
import {TOperation} from "../../../interfaces/operation";
import RemovePointsToSelectedParticipants from "./actions/removePointsToSelectedParticipants";
import InviteSelectedParticipants from "./actions/inviteSelectedParticipants";
import {deleteOperationParticipants} from "../../../services/operationParticipantService";
import DeleteDialog from "../../../patterns/dialog/deleteDialog";
import {TEmailSentResponse} from "../../../interfaces/email";
import {StoreContext} from "../../../common/struct/store";
import {AppContext} from "../../../App";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import {TOperationParticipant} from "../../../interfaces/operationParticipant";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useNavigate} from "react-router-dom";
import {formatFilters, formatIds} from "../../../common/methods/utils";
import {TParticipantAttribute} from "../../../interfaces/participantAttribute";
import {UPDATE_COMPANY} from "../../../common/methods/context-setter/globals";
import {getCompany} from "../../../services/CompanyService";
import {UPDATE, globalStoreReducer} from "../../../common/methods/context-setter/globalStoreReducer";
import PersonIcon from '@mui/icons-material/Person';

interface ListToolbarProps {
  disableFilters: boolean;
  search: string;
  setSearch: (search: string) => void;
  selectedUnit: PointsUnit;
  setSelectedUnit: (unit: PointsUnit) => void;
  attributes: TParticipantAttribute[];
  apiRef: MutableRefObject<GridApiPro>;
  filterModel: GridFilterModel;
  openColumnsDialog: boolean;
  setFiltersAnchorEl: (el: HTMLElement|null) => void;
  setOpenColumnsDialog: (open: boolean) => void;
  openParticipantPanel: (open: boolean|null) => void;
  setOpenDeletedParticipantsPanel: (open: boolean) => void;
  exportParticipants: () => Promise<void>;
  setReload: (reload: boolean) => void;
  operation: TOperation;
  addSuccess: (success: string) => void;
  addError: (error: string) => void;
  setReset: (reset: boolean) => void;
  setPage: (page: number) => void;
  total: number;
}

export default function ListToolbar(props: ListToolbarProps): JSX.Element {
  const {disableFilters, search, setSearch, selectedUnit, setSelectedUnit, openColumnsDialog, setOpenColumnsDialog,
    setOpenDeletedParticipantsPanel, attributes, apiRef, filterModel, setFiltersAnchorEl, exportParticipants, setReload, operation, addSuccess, addError, setReset, setPage, total, openParticipantPanel} = props;
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;  
  
  const visibilityModel = gridColumnVisibilityModelSelector(apiRef);
  const setVisibilityModel = apiRef.current.setColumnVisibilityModel;
  const columns = apiRef.current.getAllColumns();

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [localeSearch, setLocaleSearch] = useState(search);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null|HTMLElement>(null);
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState<null|HTMLElement>(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState(apiRef.current.getSelectedRows().size);
  const [newWallet, setNewWallet] = useState<string|null>(null);

  const [countCreditWarning, setCountCreditWarning] = useState(0);
  const [countDebitWarning, setCountDebitWarning] = useState(0);
  const [openAddPointsDialog, setOpenAddPointsDialog] = useState(false);
  const [openRemovePointsDialog, setOpenRemovePointsDialog] = useState(false);
  const [openInviteParticipantsDialog, setOpenInviteParticipantsDialog] = useState(false);
  const [openDeleteParticipantsDialog, setOpenDeleteParticipantsDialog] = useState(false);
  const [openBlockOrderParticipants, setOpenBlockOrderParticipants] = useState(false);
  const [openDelockOrderParticipants, setOpenDelockOrderParticipants] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setSelectAll(apiRef.current.getRowsCount() === apiRef.current.getSelectedRows().size);
    setSelectedRows(apiRef.current.getSelectedRows().size);
  }, [apiRef.current.getSelectedRows()])

  useEffect(() => {
    setSelectedRows(selectAll ? total : apiRef.current.getSelectedRows().size);
  }, [selectAll])

  useEffect(() => {
    apiRef.current.getVisibleColumns().map(columns => {
      if(columns.field === "rewardEuros" || columns.field === "spendEuros" || columns.field === "totalEuros") {
        setSelectedUnit(PointsUnit.EUROS)
      }
    });
  }, [])

  const handleActionsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleMoreActionsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setMoreActionsAnchorEl(event.currentTarget);
  };

  const handleFiltersClick = (): void => {
    setFiltersAnchorEl(document.getElementById("right-block"));
    apiRef.current.showFilterPanel();
  };

  const handleActionsClose = (): void => {
    setActionsAnchorEl(null);
  };

  const handleMoreActionsClose = (): void => {
    setMoreActionsAnchorEl(null);
  };

  const applySearch = (event:any): void => {
    event.preventDefault();
    if (localeSearch == search) {
      return;
    }
    setPage(0);
    setSelectAll(false);

    setSearch(localeSearch);
  }

  /** ACTIONS */
  const sendMoneyToSelectedParticipants = (amount: number, wallet: string): void => {
    let filters: RequestFilter[] = formatFilters(filterModel, columns, attributes);

    // Si une liste d'id est sélectionnée, nul besoin d'utiliser les filtres sur les autres champs
    if (!selectAll) {
      filters = formatIds(Array.from(apiRef.current.getSelectedRows().keys()).map(key => key.toString()));
    }

    const action = (): Promise<{count: number}> => sendParticipantsMoney(amount.toString(), wallet, operation.id, search, filters);

    action()
      .then(response => {
        setReload(true);
        addSuccess(t("operation_participants.actions.selected.add_points_success", {amount: amount.toFixed(2), number: response.count}));
        getCompany(store.global.company.id)
          .then(company => {
            if (company) {
              globalStoreReducer(STORE, {type: UPDATE_COMPANY, method: UPDATE, company: company});
            }
          })
          .catch(error => {
            errorManager(error, t, STORE, navigate);
          })
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), addError, t);
      })
      .finally(() => closeAddPointsDialog())
  }

  const removeMoneyFromSelectedParticipants = (amount: number, wallet: string): void => {
    let filters: RequestFilter[] = formatFilters(filterModel, columns, attributes);

    // Si une liste d'id est sélectionnée, nul besoin d'utiliser les filtres sur les autres champs
    if (!selectAll) {
      filters = formatIds(Array.from(apiRef.current.getSelectedRows().keys()).map(key => key.toString())).concat(filters);
    }

    const action = (): Promise<{count: number}> => sendParticipantsMoney(amount.toString(), wallet, operation.id, search, filters);

    action()
      .then(response => {
        setReload(true);
        addSuccess(t("operation_participants.actions.selected.remove_points_success", {amount: -amount, number: response.count}));
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), addError, t);
      })
      .finally(() => closeRemovePointsDialog())
  }

  const sendSomeMails = (sendAt: string): void => {
    setLoading(true);
    let filters: RequestFilter[] = formatFilters(filterModel, columns, attributes);

    // Si une liste d'id est sélectionnée, nul besoin d'utiliser les filtres sur les autres champs
    if (!selectAll) {
      filters = formatIds(Array.from(apiRef.current.getSelectedRows().keys()).map(key => key.toString())).concat(filters);
    }

    const action = (): Promise<TEmailSentResponse> => sendEmailInvitationsToParticipants(operation.id, search, filters, sendAt);

    action()
      .then(response => {
        setReload(true);
        if (response.errors.length > 0) {
          addError(t("operation_participants.actions.selected.invite_error", {count: response.errors.length}));
        }
        if (response.sent.count > 0) {
          addSuccess(sendAt == "now" ? t("operation_participants.actions.selected.invite_success", {count: response.sent.count}) : t("operation_participants.actions.selected.invite_planned", {count: response.sent.count}));
        }
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), addError, t);
      })
      .finally(() => {
        setLoading(false);
        closeInviteParticipantsDialog();
      })
  }

  const deleteParticipants = (): Promise<void> => {
    let filters: RequestFilter[] = formatFilters(filterModel, columns, attributes);

    // Si une liste d'id est sélectionnée, nul besoin d'utiliser les filtres sur les autres champs
    if (!selectAll) {
      filters = formatIds(Array.from(apiRef.current.getSelectedRows().keys()).map(key => key.toString())).concat(filters);
    }

    const action = (): Promise<void|void[]> => deleteOperationParticipants(operation.id, search, filters, newWallet!.replace('/bo/wallets/',''));

    return action()
      .then(() => {
        setReload(true);
        addSuccess(t("operation_participants.actions.selected.delete_success", {count: selectedRows}));
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), addError, t);
      })
      .finally(() => { setNewWallet(null), closeDeleteParticipantsDialog() })
  }

  const columnReset = (): void => {
    apiRef.current.setFilterModel({items:[]})
    apiRef.current.setSortModel([])
    setSelectedUnit(PointsUnit.POINTS)
    setSearch('')
    setReset(true)
  }

  useEffect(() => {
    if(search.length > 0 || localeSearch.length > 0) {
      setLocaleSearch(search)
    }
  },[search])

  const updateBlockOrderParticipants = (blockOrder:boolean): void => {
    let filters: RequestFilter[] = formatFilters(filterModel, columns, attributes);

    // Si une liste d'id est sélectionnée, nul besoin d'utiliser les filtres sur les autres champs
    if (!selectAll) {
      filters = formatIds(Array.from(apiRef.current.getSelectedRows().keys()).map(key => key.toString())).concat(filters);
    }

    const action = (): Promise<TOperationParticipant> => updateBlockOrderToParticipants(operation.id, blockOrder, search, filters);

    action()
      .then(() => {
        setReload(true);
        if(blockOrder) {
          addSuccess(t("operation_participants.actions.selected.block.success", {count: selectedRows}));
        }else{
          addSuccess(t("operation_participants.actions.selected.delock.success", {count: selectedRows}));
        }
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), addError, t);
      })
      .finally(() => closeBlockOrderParticipants())
  }


  /** DIALOGS */
  const showColumnsDialog = (): void => {
    setOpenColumnsDialog(true);
    handleActionsClose();
  }
  const showInviteParticipantsDialog = (): void => setOpenInviteParticipantsDialog(true)
  const showAddPointsDialog = (): void => {
    viewParticipantCreditWarning24h(Array.from(apiRef.current.getSelectedRows().keys()).map(id => id.toString()), operation.id)
      .then(data => {
        setCountCreditWarning(data.data.countParticipantsCredit);
        setCountDebitWarning(data.data.countParticipantsDebit);
        setOpenAddPointsDialog(true);
      })
  }
  const showRemovePointsDialog = (): void => {
    viewParticipantCreditWarning24h(Array.from(apiRef.current.getSelectedRows().keys()).map(id => id.toString()), operation.id)
      .then(data => {
        setCountCreditWarning(data.data.countParticipantsCredit);
        setCountDebitWarning(data.data.countParticipantsDebit);
        setOpenRemovePointsDialog(true);
      })
  }
  const showDeleteDialog = (): void => {
    setMoreActionsAnchorEl(null);
    setOpenDeleteParticipantsDialog(true);
  }
  const showDeletedPanel = (): void => {
    setOpenDeletedParticipantsPanel(true);
    handleActionsClose();
  }
  const showResetDialog = (): void => {
    setOpenResetDialog(true);
    handleActionsClose();
  }
  const closeResetDialog = (): void => setOpenResetDialog(false);

  const showBlockOrderDialog = (): void => {
    setOpenBlockOrderParticipants(true);
    setMoreActionsAnchorEl(null);
  }

  const showDelockOrderDialog = (): void => {
    setOpenDelockOrderParticipants(true);
    setMoreActionsAnchorEl(null);
  }

  const closeInviteParticipantsDialog = (): void => setOpenInviteParticipantsDialog(false)
  const closeDeleteParticipantsDialog = (): void => setOpenDeleteParticipantsDialog(false)
  const closeAddPointsDialog = (): void => setOpenAddPointsDialog(false)
  const closeRemovePointsDialog = (): void => setOpenRemovePointsDialog(false)
  const closeBlockOrderParticipants = (): void => setOpenBlockOrderParticipants(false)
  const closeDelockOrderParticipants = (): void => setOpenDelockOrderParticipants(false)
  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      {openAddPointsDialog && <AddPointsToSelectedParticipants key="add-points-selected-participants" openDialog={openAddPointsDialog} closeDialog={closeAddPointsDialog} sendMoney={sendMoneyToSelectedParticipants} operation={operation} selectedElements={selectedRows} countWarningCredit={countCreditWarning} countWarningDebit={countDebitWarning} />}
      {openRemovePointsDialog && <RemovePointsToSelectedParticipants key="remove-points-selected-participants" openDialog={openRemovePointsDialog} closeDialog={closeRemovePointsDialog} removeMoney={removeMoneyFromSelectedParticipants} operation={operation} selectedElements={selectedRows} countWarningCredit={countCreditWarning} countWarningDebit={countDebitWarning} />}
      {openBlockOrderParticipants && <BlockOrderToSelectedParticipants key="block-order-participant" open={openBlockOrderParticipants} close={closeBlockOrderParticipants} title={t("operation_participants.actions.selected.block.title", {count: selectedRows})} content1={t("operation_participants.actions.selected.block.content_1")} content2={t("operation_participants.actions.selected.block.content_2")} button={t("operation_participants.actions.selected.block.confirm")} block={true} setBlockOrder={updateBlockOrderParticipants} />}
      {openDelockOrderParticipants && <BlockOrderToSelectedParticipants key="delock-order-participant" open={openDelockOrderParticipants} close={closeDelockOrderParticipants} title={t("operation_participants.actions.selected.delock.title", {count: selectedRows})} content1={t("operation_participants.actions.selected.delock.content_1")} content2={t("operation_participants.actions.selected.delock.content_2")} button={t("operation_participants.actions.selected.delock.confirm")} block={false} setBlockOrder={updateBlockOrderParticipants} />}
      {openInviteParticipantsDialog && <InviteSelectedParticipants key="invite-selected-participants" openDialog={openInviteParticipantsDialog} closeDialog={closeInviteParticipantsDialog} sendMail={sendSomeMails} mailsToSend={selectedRows} loading={loading} operation={operation}/>}
      {openDeleteParticipantsDialog && <DeleteDialog key="delete-selected-participants" open={openDeleteParticipantsDialog} close={closeDeleteParticipantsDialog} title={t("operation_participants.actions.selected.delete_title", {number: selectedRows})} warning={t("operation_participants.actions.selected.delete_warning")} deleteElement={deleteParticipants} walletSelection={store.global.company.wallets.length > 2 ? true : false} excludedWallets={[store.global.company.expiredPointsWallet!.id]} newWallet={newWallet} setNewWallet={setNewWallet}/>}
      {openResetDialog && <ResetColumn key="column-reset" open={openResetDialog} close={closeResetDialog} title={t("operation_participants.reset_list.title")} content={t("operation_participants.reset_list.content")} reset={columnReset} />}
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", p: 2, backgroundColor: "ornament.main", borderBottom: "1px solid", borderColor: "ornament.dark"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <form onSubmit={applySearch} >
            <ClickAwayListener onClickAway={(): void => setTooltipVisible(false)}>
              <Tooltip title={t("operation_participants.search_help")} open={tooltipVisible} arrow placement="right">
                <TextField sx={{backgroundColor: "ornament.light", width: 294, "& input": {p: 2, "&::placeholder": {textOverflow: "ellipsis",
                  fontSize: LIBERTY_THEME.typography.body1.fontSize, lineHeight: LIBERTY_THEME.typography.body1.lineHeight}}}}
                placeholder={t("operation_participants.search_help")} onClick={(): void => setTooltipVisible(true)}
                value={localeSearch} onChange={(event): void => setLocaleSearch(event.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="end" sx={{mr: "-10px"}}>
                    <SearchIcon onClick={applySearch} sx={{fontSize: 24, p: "7px", borderRadius: "4px",
                      backgroundColor: tooltipVisible ? "primary.main" : "transparent",
                      color: tooltipVisible ? "ornament.light" : "neutral.main",
                      cursor: "pointer",
                      boxShadow: tooltipVisible ? 1 : 0}}/>
                  </InputAdornment>,
                }}/>
              </Tooltip>
            </ClickAwayListener>
          </form>
          {selectedRows > 0 && <>
            <SecondarySmallButton sx={{ml: 3}} label={t("operation_participants.actions.selected.invite")} icon={EmailIcon} position={IconPosition.LEFT} action={showInviteParticipantsDialog}/>
            <SecondarySmallButton sx={{ml: 1}} label={t("operation_participants.actions.selected.add_points")} icon={AddIcon} position={IconPosition.LEFT} action={showAddPointsDialog}/>
            <SecondarySmallButton sx={{ml: 1}} label={t("operation_participants.actions.selected.remove_points")} icon={RemoveIcon} position={IconPosition.LEFT} action={showRemovePointsDialog}/>
            <SecondarySmallButton sx={{ml: 1}} label={t("operation_participants.actions.selected.more")} icon={moreActionsAnchorEl ? KeyboardArrowUpIcon : KeyboardArrowDownIcon} position={IconPosition.RIGHT} action={handleMoreActionsClick}/>
            <Menu id="more-actions-menu" open={moreActionsAnchorEl != null} onClose={handleMoreActionsClose} anchorEl={moreActionsAnchorEl}
              anchorOrigin={{vertical: "bottom", horizontal: "left"}} transformOrigin={{vertical: "top", horizontal: "left"}}
              sx={{"& .MuiPaper-root": {mt: 0, border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", boxShadow: 3}}}>
              <ListMenuItem label={t("operation_participants.actions.selected.block_order")} icon={NotInterestedIcon} action={showBlockOrderDialog} />
              <ListMenuItem label={t("operation_participants.actions.selected.delock_order")} icon={CheckCircleOutlineIcon} action={showDelockOrderDialog}/>
              <ListMenuItem label={t("operation_participants.actions.selected.delete")} icon={DeleteIcon} action={showDeleteDialog}/>
              {/** <ListMenuItem label={t("operation_participants.actions.selected.block_order")} icon={NotInterestedIcon} action={showBlockOrderDialog}/>*/}
            </Menu>
          </>}
        </Box>
        <Box id="right-block" sx={{display: "flex", alignItems: "center"}}>
          <PrimarySmallButton sx={{mr: 1}} label={t("operation_participants.action_create")} icon={PersonIcon} position={IconPosition.LEFT} action={(): void => { openParticipantPanel(null) }}/>
          <EuroPointToggle selected={selectedUnit} setSelected={setSelectedUnit}/>
          <GreySmallButton disabled={disableFilters} sx={{ml: 1}} label={t("operation_participants.actions.filters")} icon={GridFilterListIcon} position={IconPosition.LEFT} action={handleFiltersClick}/>
          <GreySmallButton sx={{ml: 1}} label={t("operation_participants.actions.button")} icon={actionsAnchorEl ? KeyboardArrowUpIcon : KeyboardArrowDownIcon} position={IconPosition.RIGHT} action={handleActionsClick}/>
          <Menu id="actions-menu" open={actionsAnchorEl != null} onClose={handleActionsClose} anchorEl={actionsAnchorEl}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}} transformOrigin={{vertical: "top", horizontal: "right"}}
            sx={{"& .MuiPaper-root": {mt: 0, border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", boxShadow: 3}}}>
            <ListMenuItem label={t("operation_participants.actions.columns")} icon={ViewColumnIcon} action={showColumnsDialog}/>
            <ListMenuItem label={loadingExport ? t("operation_participants.actions.exporting") : t("operation_participants.actions.export")} loading={loadingExport} icon={FileDownloadIcon} action={ (): void => { setLoadingExport(true), exportParticipants().then(() => { setLoadingExport(false), handleActionsClose }) }}/>
            <ListMenuItem label={t("operation_participants.actions.archived")} icon={InventoryIcon} action={showDeletedPanel}/>
            <ListMenuItem label={t("operation_participants.actions.reset")} icon={RestartAltIcon} action={showResetDialog}/>
          </Menu>
        </Box>
      </Box>
      {selectedRows > 0 && selectedRows >= apiRef.current.unstable_getViewportPageSize() && <Box sx={{m: 0, p: 3, display: "flex", backgroundColor: "primary.main", borderRadius: "4px"}}>
        <Typography variant="body2" color="ornament.main" sx={{mr: 0}}>
          {t(selectAll ? "operation_participants.selected_all_participants" : "operation_participants.selected_participants", {number: selectedRows})}
        </Typography>
        <Typography variant="body2" color="ornament.light" sx={{fontWeight: "bold", textDecoration: "underline", cursor: "pointer"}} onClick={(): void => setSelectAll(!selectAll)}>
          {t(selectAll ? "operation_participants.unselect_all" : "operation_participants.select_all")}
        </Typography>
      </Box>}
      {openColumnsDialog && <ListColumns open={openColumnsDialog} setOpen={setOpenColumnsDialog} columns={columns} visibilityModel={visibilityModel} setVisibilityModel={setVisibilityModel}/>}
    </Box>
  )
}
