import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {TWalletTransaction, TWalletTransactionOption, TWalletTransactionStatus,} from "../../interfaces/wallet";
import {Box, Button, Card, CircularProgress, Menu, MenuItem, Typography} from '@mui/material';
import moment from 'moment';
import {PrimarySmallButton, SecondarySmallButton} from '../../components/buttons/mainButton';
import ListSnackbar, {TAlert} from '../../patterns/list/listSnackbar';
import {addErrorAlert, addSuccessAlert} from '../../patterns/list/listUtils';
import OperationReferenceDialog from "../operationTransactions/operationReferenceDialog";
import DeleteDialog from '../../patterns/dialog/deleteDialog';
import {StoreContext} from "../../common/struct/store";
import {AppContext} from '../../App';
import {NavigateFunction, useNavigate} from "react-router-dom";
import CachedIcon from '@mui/icons-material/Cached';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BlockIcon from '@mui/icons-material/Block';
import DownloadIcon from '@mui/icons-material/Download';
import InfoDialog from '../../patterns/list/infoDialog';
import EditIcon from '@mui/icons-material/Edit';
import {initStripePayment} from '../../services/OperationService';
import {paymentStoreReducer} from "../../common/methods/context-setter/paymentStoreReducer";
import {INIT_PAYMENT} from '../../common/methods/context-setter/globals';
import {PRIVATE_URL} from '../../common/struct/urlManager';
import BankDetails from '../payment/bankDetails';
import {
  downloadOrderForm,
  downloadProFormaInvoice,
  handleCancelPayment
} from "../operationTransactions/operationTransactionsPayment";
import {getCompanyWalletTransactions} from '../../services/WalletTransactionService';
import {RequestFilter, RequestFilterOperator} from '../../common/methods/ApiService';
import {Loader} from '../../components/loader/loader';

export default function OngoingTransactions(): JSX.Element {
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [openReferenceDialog, setOpenReferenceDialog] = useState<boolean>(false);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  const [openBankInfoDialog, setOpenBankInfoDialog] = useState<boolean>(false);
  const [activeTransaction, setActiveTransaction] = useState<TWalletTransaction|null>(null);
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [ongoingTransactions, setOngoingTransactions] = useState<TWalletTransaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  
  const setError = (error: string): void => addErrorAlert(error, setAlerts);

  const handleReferenceDialog = (transaction: TWalletTransaction): void => {
    setActiveTransaction(transaction);
    setOpenReferenceDialog(true);
  };

  const handleCancelDialog = (transaction: TWalletTransaction): void => {
    setActiveTransaction(transaction);
    setOpenCancelDialog(true);
  }

  const handleBankInfoDialog = (transaction: TWalletTransaction): void => {
    setActiveTransaction(transaction);
    setOpenBankInfoDialog(true);
  }

  const handlePaymentByCB = (transaction: TWalletTransaction, companyId: string, navigate: NavigateFunction, STORE: StoreContext): Promise<void> => {
    const pointsPurchaseFormatted = (parseFloat(transaction.amount.toString()).toFixed(2)).toString();
    return initStripePayment(pointsPurchaseFormatted, companyId)
      .then(content => {
        paymentStoreReducer(STORE, {type: INIT_PAYMENT, payment: {
          type: TWalletTransactionOption.CB,
          transactionId: transaction.id,
          reference: transaction.reference,
          amount: pointsPurchaseFormatted,
          secret: content.stripeSecret,
          walletId: transaction.wallet.id
        }});
        navigate(PRIVATE_URL.TRANSACTION_PAYMENT_CONFIRM);
      })
  }

  const referenceDialog = <OperationReferenceDialog open={openReferenceDialog} close={(): void => { setActiveTransaction(null), setOpenReferenceDialog(false) }} transaction={activeTransaction}
    reload={(): void => setReload(true)} setSuccess={(success: string): void => { addSuccessAlert(success, setAlerts) }}/>
  const cancelDialog = <DeleteDialog open={openCancelDialog} close={(): void => { setActiveTransaction(null), setOpenCancelDialog(false) }} title={t("operation_transactions.cancel.title", {amount: activeTransaction?.amount??0})}
    warning={t("operation_transactions.cancel.warning")} deleteElement={(): Promise<void> => handleCancelPayment(activeTransaction, setReload)}/>
  const bankInfoDialog = <InfoDialog open={openBankInfoDialog} close={(): void => setOpenBankInfoDialog(false)} title={t("operation_transactions.bank_info.title", {amount: activeTransaction?.amount??0})}
    action={{title: t("operation_transactions.bank_info.download"), href: "/rib.pdf", newWindow: true}}>
    <Typography variant="body1" color="neutral.dark" sx={{mb: 3, fontWeight: "unset"}}
      dangerouslySetInnerHTML={{__html: t("operation_transactions.bank_info.content", {amount: activeTransaction?.amount??0, reference: activeTransaction?.odooSaleOrderId??"-"})}}/>
    <BankDetails />
  </InfoDialog>

  type TransactionCard = {
    index: number,
    transaction: TWalletTransaction
  }

  function TransactionCard(props: TransactionCard): JSX.Element {
    const {index, transaction} = props;
    const [loadingOrderForm, setLoadingOrderForm] = useState<boolean>(false);
    const [loadingProForma, setLoadingProForma] = useState<boolean>(false);
    const [loadingPaymentCb, setLoadingPaymentCb] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = useState<null|HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
      setAnchorEl(null);
    };

    useEffect(() => {
      if (!loadingOrderForm) {
        setAnchorEl(null);
      }
    }, [loadingOrderForm])

    useEffect(() => {
      if (!loadingProForma) {
        setAnchorEl(null);
      }
    }, [loadingProForma])

    return (
      <Card variant="outlined" sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", width: "23.5%", mb: 4, p: 3, boxSizing: "border-box", mr: `${(index+1)%4 == 0 ? "0px" : "2%"}`}}>
        <Box>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Typography color="neutral.dark" variant="h2" sx={{fontWeight: "bold", mb: 0}}>{"Commande du " + moment(transaction.createdAt).format('DD/MM/YYYY')}</Typography>
            <Button
              id="basic-button"
              onClick={handleClick}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{p: "0px"}}
            >
              <MoreVertIcon sx={{cursor: "pointer", height: "20px", width: "20px"}}/>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{width: "fit-content", ".MuiPaper-root": {maxWidth: "unset", width: "fit-content", borderWidth: "1px", borderStyle: "solid", borderColor: "ornament.dark", borderRadius: 1}}}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={(): void => { downloadOrderForm(transaction, navigate, setLoadingOrderForm, setError, t, STORE), handleClose }} sx={{px: 1}}>
                {loadingOrderForm ? <CircularProgress size={18} sx={{mr: 1}} /> : <DownloadIcon fontSize="small" sx={{mr: 1}} />}
                <Typography color="neutral.dark" variant="body1">{t("operation_transactions.actions.download_order_form")}</Typography>
              </MenuItem>
              <MenuItem onClick={(): void => { downloadProFormaInvoice(transaction, navigate, setLoadingProForma, setError, t, STORE), handleClose }} sx={{px: 1}}>
                {loadingProForma ? <CircularProgress size={18} sx={{mr: 1}} /> : <DownloadIcon fontSize="small" sx={{mr: 1}} />}
                <Typography color="neutral.dark" variant="body1">{t("operation_transactions.actions.download_pro_forma_invoice")}</Typography>
              </MenuItem>
              <MenuItem onClick={(): void => { handleReferenceDialog(transaction), handleClose }} sx={{px: 1}}>
                <EditIcon fontSize="small" sx={{mr: 1}} />
                <Typography color="neutral.dark" variant="body1">{transaction.reference ? t("operation_transactions.actions.update_reference") : t("operation_transactions.actions.add_reference")}</Typography>
              </MenuItem>
              <MenuItem onClick={(): void => { handleCancelDialog(transaction), handleClose }} sx={{px: 1}}>
                <BlockIcon fontSize="small" sx={{mr: 1}} />
                <Typography color="neutral.dark" variant="body1">{t("operation_transactions.actions.cancel_transaction")}</Typography>
              </MenuItem>
            </Menu>
          </Box>
          {transaction.admin && <Typography color="neutral.dark" variant="body1" sx={{mb: 3}}>{"Par " + transaction.admin.firstName + " " + transaction.admin.lastName}</Typography>}
          <Box sx={{display: "flex", alignItems: "center", borderRadius: 4, width: "fit-content", px: 1, py: 0, mb: 3, backgroundColor: "#E0ECFF"}}>
            <CachedIcon sx={{color: "info.main", mr: 0}}/>
            <Typography variant="body2" color={"info.dark"} sx={{fontWeight: "medium"}}>{t("operation_transactions.ongoing_orders.ongoing_transfer")}</Typography>
          </Box>
          <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 2, flexWrap: "wrap"}}>
            <Typography color="neutral.dark" variant="body1">{t("operation_transactions.ongoing_orders.amount")}</Typography>
            <Typography color="neutral.dark" variant="body1" sx={{fontWeight: "bold"}}>{transaction.amount + " €"}</Typography>
          </Box>
          {transaction.reference && <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", mb: 5, flexWrap: "wrap"}}>
            <Typography color="neutral.dark" variant="body1">{t("operation_transactions.ongoing_orders.reference")}</Typography>
            <Typography color="neutral.dark" variant="body1" sx={{fontWeight: "bold"}}>{transaction.reference}</Typography>
          </Box>}
        </Box>
        <Box sx={{display: "flex", height: "fit-content"}}>
          <SecondarySmallButton sx={{width: "100%", height: "100%", mr: 0, mb: 2}} loading={loadingPaymentCb} label={t("operation_transactions.ongoing_orders.cb")} action={(): void => { setLoadingPaymentCb(true), handlePaymentByCB(transaction, store.global.company.id, navigate, STORE) }} />
          <PrimarySmallButton sx={{width: "100%", height: "100%", ml: 0}} label={t("operation_transactions.ongoing_orders.transfer")} action={(): void => handleBankInfoDialog(transaction)} />
        </Box>
      </Card>
    )
  }

  useEffect(() => {
    document.title = t("tab_title.operation.points.ongoing");
    
    getCompanyWalletTransactions(store.global.company.id, 1, 10, null, null, filter)
      .then((res) => {            
        if (res.totalItems !== 0) {              
          setOngoingTransactions(res.items)
        } else {
          navigate(PRIVATE_URL.WALLETS_BUY_POINTS);
        }
      }).finally(() => setLoading(false));
  }, []);
  
  const filter: RequestFilter[] = [{field: 'status', items: [{value: TWalletTransactionStatus.ON_GOING, operator: RequestFilterOperator.EQUALS, isDate: false, isDatePrecise: false}]}];

  useEffect(() => {
    if (reload) {
      getCompanyWalletTransactions(store.global.company.id, 1, 10, null, null, filter)
        .then((res) => {            
          if (res.totalItems !== 0) {              
            setOngoingTransactions(res.items)
          } else {
            navigate(PRIVATE_URL.WALLETS_BUY_POINTS);
          }
        }).finally(() => setLoading(false));
    }
  }, [reload]);

  return <>
    <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
    <Box sx={{width: "100%", height: "100%", mb: 6}}>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mt: 1, mb: 5}}>
        <Typography variant="body1" color="neutral.main" dangerouslySetInnerHTML={{__html: t("operations.points_balance.subtitle_ongoing")}}/>
      </Box>
      {referenceDialog}
      {cancelDialog}
      {bankInfoDialog}
      <Box sx={{height: "fit-content", width: "100%", display: "flex", flexFlow: "row wrap"}}>
        {!loading && ongoingTransactions && ongoingTransactions.map((transaction, index) => {          
          return <TransactionCard key={index} index={index} transaction={transaction}/>;
        })}
        {loading && <Loader sx={{width: "100%"}} />}
      </Box>
    </Box>
  </>
}
