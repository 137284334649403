import {Box, Card, FormControlLabel, Radio, Typography} from "@mui/material";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import React, {useEffect, useState} from "react";
import {OperationShopPricingProps} from "./operationShopPricing";
import {TShopPricing} from "../../interfaces/shop";
import {useTranslation} from "react-i18next";
import {putShop} from "../../services/ShopService";
import {manageStringError} from "../../common/methods/ApiService";

export default function OperationShopPricingFull(props: OperationShopPricingProps): JSX.Element {
  const {shop, setShop, pricing, changePricing, saved, setError} = props;
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = t("tab_title.operation.shop.price");
  }, []);
  const savePricing = (): void => {
    setLoading(true);

    putShop(shop.id, {pricing: TShopPricing.FULL})
      .then(shop => setShop(shop))
      .catch(error => manageStringError(error, setError, t))
      .finally((): void => setLoading(false))
  }

  return (
    <Card sx={{border: "2px solid", boxShadow: 0, mb: 5,
      borderColor: saved === TShopPricing.FULL ? "primary.main" : pricing === TShopPricing.FULL ? "neutral.light" : "ornament.dark",
      backgroundColor: saved === TShopPricing.FULL ? "alt.light" : "none"}}>
      <Box sx={{p: 6}}>
        <FormControlLabel sx={{mb: 1}} checked={pricing === TShopPricing.FULL}
          onClick={(): void => changePricing(TShopPricing.FULL)}
          control={<Radio sx={{fontSize: 24, p: "0px", mr: 4}}/>}
          label={<>
            <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 0}}>{t("operations.shop.price.full.title")}</Typography>
            <Typography variant="body2" color="neutral.main" sx={{fontWeight: 400}} dangerouslySetInnerHTML={{__html: t("operations.shop.price.full.subtitle")}} />
          </>}/>
      </Box>
      {pricing === TShopPricing.FULL &&
      <Box sx={{py: 5, px: 6, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <PrimaryBigButton loading={loading} label={t("operations.shop.price.validate")} action={savePricing}/>
      </Box>
      }
    </Card>
  )
}
