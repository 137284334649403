import React, {useContext} from "react";
import {Box, Typography} from "@mui/material";
import {ParameterItem} from "../../components/layoutItems/mainItem";
import WalletInfo from "./walletInfo";
import GroupsIcon from '@mui/icons-material/Groups';
import EuroSymbolTwoToneIcon from '@mui/icons-material/EuroSymbolTwoTone';
import WalletIcon from '@mui/icons-material/Wallet';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import EmailIcon from '@mui/icons-material/Email';
import {useTranslation} from "react-i18next";
import {ParameterType} from "../../components/layoutItems/itemProps";
import {AppContext} from "../../App";
import {useLocation} from "react-router-dom";
import {PRIVATE_URL} from "../../common/struct/urlManager";

export const Parameters = (): JSX.Element => {
  const {t} = useTranslation();
  const location = useLocation();
  const [store] = useContext<any>(AppContext);

  return (
    <Box id="sidebar-menu" sx={{
      display: "flex", flexDirection: "column",
      height: "fit-content",
      width: "100%"
    }}>
      <Typography color="secondaryLight.light" sx={{mb: 2}} variant="caption">
        {t("layout_sideBar.parameters_title").toUpperCase()}
      </Typography>
      <ParameterItem isClicked_val={location.pathname == PRIVATE_URL.COMPANY_ADMINISTRATORS} parameterType={ParameterType.ADMINISTRATORS} label={t("layout_sideBar.parameters_admin")} icon={GroupsIcon}/>
      <ParameterItem isClicked_val={location.pathname == PRIVATE_URL.BILLING_ADDRESS || location.pathname == PRIVATE_URL.BILLING_HISTORY} parameterType={ParameterType.BILLING} label={t("layout_sideBar.parameters_bill")} icon={EuroSymbolTwoToneIcon}/>
      <ParameterItem isClicked_val={location.pathname == PRIVATE_URL.WALLETS_BUY_POINTS || location.pathname == PRIVATE_URL.WALLETS_LIST || location.pathname == PRIVATE_URL.WALLETS_ONGOING || location.pathname == PRIVATE_URL.WALLETS_POINTS_MOVEMENTS} parameterType={ParameterType.WALLETS} label={t("layout_sideBar.parameters_wallets")} icon={WalletIcon}/>
      <WalletInfo />
      {store.global.superAdmin && 
        <>
          <ParameterItem isClicked_val={location.pathname == PRIVATE_URL.COMPANY_HISTORY} parameterType={ParameterType.HISTORY} label={t("layout_sideBar.parameters_history")} icon={HourglassTopIcon}/>
          <ParameterItem isClicked_val={location.pathname == PRIVATE_URL.EMAIL_TEMPLATES} parameterType={ParameterType.TEMPLATES} label={t("layout_sideBar.parameters_templates")} icon={EmailIcon}/>
        </>
      }
    </Box>
  )
}
