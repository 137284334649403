import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, Card, FormControlLabel, Radio, Typography} from "@mui/material";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {OperationRulesProps} from "./operationRules";
import {TOperationRulesFormat} from '../../interfaces/operation';
import {uploadRulesText} from "../../services/RulesService";
import {errorManager} from "../../common/methods/ApiService";
import WysiwygEditor from '../../patterns/wysiwyg/wysiwygEditor';
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {useNavigate} from "react-router-dom";

export default function OperationRulesText(props: OperationRulesProps) : JSX.Element {
  const {t} = useTranslation();
  const {rules, selectedFormat, askForSelectedFormatChange, askForRuleDeletion, setAskForRuleDeletion, operationId, setRules, setSuccess, setError} = props;

  const editorRef = useRef<any|null>(null);
  const STORE = useContext<StoreContext>(AppContext);
  const [openContent, setOpenContent] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const updateOperation = (): void => {
    if (editorRef.current) {
      setLoading(true);
      uploadRulesText(operationId, editorRef.current.getContent())
        .then(operation => {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
          setSuccess(t('operations.rules.text.save_success'));
        })
        .catch(error => {
          const errorResult = errorManager(error, t, STORE, navigate);
          if (typeof errorResult === 'string') setError(errorResult);
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    if (askForRuleDeletion == TOperationRulesFormat.TEXT) {
      setRules(null);
      setAskForRuleDeletion(null);
      setSuccess(t("operations.rules.delete_success"));
    }
  }, [askForRuleDeletion]);

  useEffect(() => {
    if (selectedFormat !== TOperationRulesFormat.TEXT) {
      setOpenContent(false);
    }
  }, [selectedFormat]);

  return (
    <Card sx={{
      border: selectedFormat == TOperationRulesFormat.TEXT ? "2px solid" : "1px solid",
      borderColor: selectedFormat == TOperationRulesFormat.TEXT ? "primary.main" : "ornament.dark",
      backgroundColor: (selectedFormat == TOperationRulesFormat.TEXT && !openContent) ? "primaryLight.light" : "ornament.light",
      boxShadow: 0, mb: 5
    }}>
      <FormControlLabel sx={{p: 6, width: "100%", borderBottom: "1px solid", borderColor: "ornament.dark"}} checked={selectedFormat === TOperationRulesFormat.TEXT} onChange={(): void => askForSelectedFormatChange(TOperationRulesFormat.TEXT)} control={<Radio sx={{fontSize: 24}}/>}
        label={<Box sx={{display: "flex", flexDirection: "column"}} onClick={(): void => setOpenContent(!openContent)}>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 0}}>{t("operations.rules.text.title")}</Typography>
          <Typography variant="body2" color="neutral.dark">{t("operations.rules.text.subtitle")}</Typography>
        </Box>}/>
      {selectedFormat == TOperationRulesFormat.TEXT && openContent && <Box sx={{p: 6}}>
        <WysiwygEditor editorRef={editorRef} operationId={operationId} initialValue={rules?.content??""} setError={setError}/>
        <Box sx={{display: "flex", mt: 6}}>
          <PrimaryBigButton label={t("navigation.validate")} action={updateOperation} loading={loading} />
        </Box>
      </Box>}
    </Card>
  )
}
