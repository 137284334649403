import {OperationProps} from "../operationProps";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import React, {useContext} from "react";
import ParticipantIcon from '../../../assets/person.svg';
import GiftIcon from '../../../assets/gift_circle.svg';
import StarIcon from '../../../assets/star.svg';
import {StoreContext} from "../../../common/struct/store";
import {AppContext} from "../../../App";

export const ParticipantsCard = (props: OperationProps): JSX.Element => {
  const {operation} = props;
  const {t} = useTranslation();
  const [store] = useContext<StoreContext>(AppContext);  

  return (
    <>
      <Box sx={{display: "flex", justifyContent: "space-between", width: "100%"}}>
        <Box sx={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
          {/** @ts-ignore */}
          <img src={ParticipantIcon} alt="" key={"empty-preview-0"} style={{
            height: "61px", width: "61px",
            zIndex: 0,
            opacity: 1
          }}/>
          <Typography color="primaryDark.light" variant="body1" sx={{fontWeight: 600, mt: 1}}>{operation.participantStats.total}</Typography>
          <Typography color="neutral.main" variant="body1" sx={{textAlign: 'center', flex: 1, display: 'flex', alignItems: 'center'}}>{t("operations.customisation.participants.participants")}</Typography>
        </Box>
        <Box sx={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
          {/** @ts-ignore */}
          <img src={GiftIcon} alt="" key={"empty-preview-0"} style={{
            height: "61px", width: "61px",
            zIndex: 0,
            opacity: 1
          }}/>
          <Box sx={{display: "flex", mt: 1}}>
            <Typography color="primaryDark.light" variant="body1" sx={{fontWeight: 600, mr: 0}}>{Math.round(operation.totalSpentAmount/store.global.company.euroPerPoint)}</Typography>
            <Typography color="neutral.main" variant="body1">{t("operations.customisation.participants.points")}</Typography>
          </Box>
          <Typography color="neutral.main" variant="body1" sx={{textAlign: 'center', flex: 1, display: 'flex', alignItems: 'center'}}>{t("operations.customisation.participants.points_used")}</Typography>
        </Box>
        <Box sx={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
          {/** @ts-ignore */}
          <img src={StarIcon} alt="" key={"empty-preview-0"} style={{
            height: "61px", width: "61px",
            zIndex: 0,
            opacity: 1
          }}/>
          <Box sx={{display: "flex", mt: 1}}>
            <Typography color="primaryDark.light" variant="body1" sx={{fontWeight: 600, mr: 0}}>{Math.round((operation.totalRewardAmount-operation.totalSpentAmount)/store.global.company.euroPerPoint)}</Typography>
            <Typography color="neutral.main" variant="body1">{t("operations.customisation.participants.points")}</Typography>
          </Box>
          <Typography color="neutral.main" variant="body1" sx={{textAlign: 'center', flex: 1, display: 'flex', alignItems: 'center'}}>{t("operations.customisation.participants.points_not_used")}</Typography>
        </Box>
      </Box>
    </>
  )
}
