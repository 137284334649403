import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import DialogLogo from "../../assets/landingPage/userDialogLogo.svg";

interface ParticipantInfoDialogProps {
  open: boolean;
  close: () => void;
}

export default function ParticipantInfoDialog(props: ParticipantInfoDialogProps): JSX.Element {
  const {open, close} = props;
  const {t} = useTranslation();

  return (
    <Dialog open={open} onClose={close} scroll="paper" sx={{display: "flex", justifyContent: "center", 
      ".MuiDialog-container": {width: "100%", 
        ".MuiPaper-root": {width: "100%", maxWidth: "550px", minWidth: "350px", position: "relative", borderRadius: "25px"}
      }
    }} maxWidth="lg">
      <CloseIcon onClick={close} sx={{color: "neutral.main", height: "32px", position: "absolute", top: "8px", right: "8px", cursor: "pointer", padding: "10px"}}/>
      <DialogTitle sx={{display: "flex", alignItems: "center", flexDirection: "column", pt: 7}}>
        {/* @ts-ignore */}
        <img src={DialogLogo} alt={t("landing_page.alt.circle")} style={{height: "68px"}} />
        <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", fontSize: "24px", mt: 2, "span": {color: "landing.main"}}} dangerouslySetInnerHTML={{__html: t("landing_page.dialog.title")}} />
      </DialogTitle>
      <DialogContent sx={{mb: 4}}>
        <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "600", fontSize: "18px", width: "75%", mt: 3, mx: "auto", textAlign: "center"}}>
          {t("landing_page.dialog.text")}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
