import React from "react";
import {Box, Typography} from "@mui/material";

import {
  getHerringbone,
  getPic,
  ItemProps,
  ItemType,
  MainItemProps,
  ParameterType,
  SizeProperties,
  TypeProperties
} from "./itemProps";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import {Link} from "react-router-dom";

import './mainItem.scss'

const SizePropertiesList: SizeProperties =
{
  width: "180px",
  height: "40px",
  padding: "4px 12px",
  iconSize: "24px",
  fontSize: "14px"
}

const TypePropertiesList: TypeProperties[] = [
  {
    activeBackgroundColor: "secondary.light",
    iconColor: ""
  },
  {
    activeBackgroundColor: "secondary.light",
    iconColor: "secondaryLight.light"
  },
  {
    activeBackgroundColor: "transparent",
    iconColor: "neutral.dark"
  },
  {
    activeBackgroundColor: "alt.main",
    iconColor: "primary.main"
  },
  {
    activeBackgroundColor: "ornament.dark",
    iconColor: "neutral.main"
  },
  {
    activeBackgroundColor: "secondary.light",
    iconColor: "secondaryLight.light"
  },
];
const openMailto = (): any => {
  return window.open('mailto:' + process.env.REACT_APP_ADMIN_EMAIL)
}
const MainItem = (props: MainItemProps): JSX.Element => {
  return (
    <Box
      style={{width: "inherit"}}
      className="sidebar-item"
    >
      {linkUrlItem(props)}
    </Box>
  );
}

const linkUrlItem = (props: MainItemProps): JSX.Element | any => {
  const sizeProperties = SizePropertiesList;
  const typeProperties = TypePropertiesList[props.type];

  const generateId = (): string => {
    switch (props.type as ItemType) {
    case ItemType.OPERATION: {
      return 'operation-' + props.opId??"";
    }
    case ItemType.PARAMETER: {
      switch (props.parameterType! as ParameterType) {
      case ParameterType.ADMINISTRATORS:
        return "administrators";
      case ParameterType.BILLING:
        return "billing";
      case ParameterType.FAQ:
        return "faq";
      case ParameterType.HISTORY:
        return "history";
      case ParameterType.TEMPLATES:
        return "templates";
      case ParameterType.CONTACT:
        return "contact";
      case ParameterType.WALLETS:
        return "wallets";
      }
      break;
    }
    case ItemType.SELECT_COMPANY: {
      return "select-company";
    }
    case ItemType.CONTACT_ADMIN: {
      return "contact-admin";
    }
    }
  };

  const content = <Box id={generateId()} sx={{display: "flex", width: "inherit", alignItems: "center", justifyContent: "space-between"}}>
    <Box sx={{
      display: "flex", alignItems: "center",
      width: "inherit",
      height: sizeProperties.height,
      borderRadius: 1,
      backgroundColor: props.isClicked_val ? typeProperties.activeBackgroundColor : "transparent",
      ":hover": {
        "& p": {
          fontWeight: (props.nbOp === 0) ? "bold" : "medium",
          color: "ornament.light"
        }
      }
    }}>
      {getPic(props, sizeProperties.iconSize, sizeProperties.fontSize, typeProperties.iconColor)}
      <Typography id='typo' sx={{
        color: (props.nbOp===0) ? "ornament.light" : "secondaryLight.light",
        fontSize: sizeProperties.fontSize,
        fontWeight: (props.nbOp===0 || props.isClicked_val) ? "medium" : "regular",
        overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"
      }}>
        {props.label}
      </Typography>
    </Box>
    {getHerringbone(props, sizeProperties.iconSize)}
  </Box>
  switch (props.type as ItemType) {
  case ItemType.OPERATION : {
    return <Link to={operationUrl(PRIVATE_URL.OPERATION_CUSTOMISATION,props.opId??"")} style={{width: "inherit"}}>{content}</Link>
  }
  case ItemType.PARAMETER : {
    switch (props.parameterType! as ParameterType) {
    case ParameterType.ADMINISTRATORS:
      return <Link to={PRIVATE_URL.COMPANY_ADMINISTRATORS} style={{width: "inherit"}}>{content}</Link>
    case ParameterType.BILLING:
      return <Link to={PRIVATE_URL.BILLING_ADDRESS} style={{width: "inherit"}}>{content}</Link>
    case ParameterType.FAQ:
      return <Link to={PRIVATE_URL.FAQ} style={{width: "inherit"}}>{content}</Link>
    case ParameterType.HISTORY:
      return <Link to={PRIVATE_URL.COMPANY_HISTORY} style={{width: "inherit"}}>{content}</Link>
    case ParameterType.TEMPLATES:
      return <Link to={PRIVATE_URL.EMAIL_TEMPLATES} style={{width: "inherit"}}>{content}</Link>
    case ParameterType.WALLETS:
      return <Link to={PRIVATE_URL.WALLETS_BUY_POINTS} style={{width: "inherit"}}>{content}</Link>
    case ParameterType.CONTACT:
      return <Box onClick={openMailto}>{content}</Box>
    }
    return;
  }
  case ItemType.SELECT_COMPANY : {
    return <Link to={PRIVATE_URL.SUPER_ADMIN_COMPANIES} style={{width: "inherit"}}>{content}</Link>
  }
  case ItemType.CONTACT_ADMIN : {
    return;
  }
  }
}

export const OperationItem = (props: ItemProps): JSX.Element => {
  return <MainItem type={ItemType.OPERATION} avatar={true} {...props}/>
}

export const ParameterItem = (props: ItemProps): JSX.Element => {
  return <MainItem type={ItemType.PARAMETER} {...props}/>
}

export const SelectCompanyItem = (props: ItemProps): JSX.Element => {
  return <MainItem type={ItemType.SELECT_COMPANY} {...props}/>
}

export const ContactAdminItem = (props: ItemProps): JSX.Element => {
  return <MainItem type={ItemType.CONTACT_ADMIN} {...props}/>
}
