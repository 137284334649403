import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {manageStringError} from "../../common/methods/ApiService";
import {WalletSelector} from "../../pages/operationParticipants/listParticipants/actions/pointsUtils";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
  
  interface TransferDialogProps {
    open: boolean;
    close: () => void;
    title: string;
    text: string;
    transferElement: () => Promise<void>;
    amount: number;
    setTransferAmount: (transferAmount: number) => void;
    walletSelection?: boolean;
    excludedWallets?: string[];
    newWallet?: string|null;
    setNewWallet?: (newWallet: string|null) => void;
  }
  
export default function TransferDialog(props: TransferDialogProps): JSX.Element {
  const {open, close, title, text, transferElement, amount, walletSelection, excludedWallets = [], newWallet, setNewWallet, setTransferAmount} = props;
  const {t} = useTranslation();
  const [store] = useContext<StoreContext>(AppContext);
  
  const [error, setError] = useState<string|null>(null);
  const [transfering, setTransfering] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState<string|undefined>(newWallet ? newWallet : store.global.company.defaultWallet ? store.global.company.defaultWallet['@id'] : undefined);

  const actionTransferElement = (): void => {
    setError(null);
    setTransfering(true);
    transferElement()
      .then(close)
      .catch(error => manageStringError(error, setError, t))
      .finally(() => setTransfering(false));
  }

  const handlePointsPurchase = (event: any): void => {
    if (!isNaN(event.target.value)) {
      setTransferAmount(event.target.value);
    }
  }
  
  useEffect(() => {
    setNewWallet && selectedWallet && setNewWallet(selectedWallet)
  }, [selectedWallet])
  
  useEffect(() => {
    newWallet && setSelectedWallet(newWallet)
  }, [open])
  
  useEffect(() => {
    amount && setTransferAmount(amount);
  }, [amount])

  return (
    <Dialog open={open} onClose={close} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: "info.main", borderColor: "info.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {title}
          </Typography>
        </Box>
        <IconButton onClick={close}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1" color="neutral.main" sx={{fontWeight: "unset"}} dangerouslySetInnerHTML={{__html: text}}/>
        <TextField onChange={handlePointsPurchase} color="primary" value={amount}
          sx={{width: '100%', mr: 2, mt: 2, "& input": {p: 3}}}
          InputProps={{
            lang: "fr-FR",
            endAdornment: <InputAdornment position="end"><EuroSymbolIcon fontSize="small"/></InputAdornment>
          }}
        />
        {walletSelection &&
          <WalletSelector label={t("wallets.actions.transfer_which")} selectedWallet={selectedWallet} setSelectedWallet={setSelectedWallet} excludedWallets={excludedWallets}/>
        }
        {error && <Alert variant="filled" severity="error" sx={{mt: 3}}>{error}</Alert>}
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <SecondaryBigButton sx={{mr: 0}} label={t("list.delete.cancel")} action={close}/>
        <PrimaryBigButton label={t("list.delete.confirm")} action={actionTransferElement} loading={transfering}/>
      </DialogActions>
    </Dialog>
  )
}
  