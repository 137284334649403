import Box from "@mui/material/Box";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import Alert from "@mui/material/Alert";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {errorManager, Violation} from "../../common/methods/ApiService";
import {inviteAdmin} from "../../services/CompanyService";
import {TAdmin} from "../../interfaces/admin";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import ElementInputWithLabel from "../../patterns/form/elementInputWithLabel";
import {useNavigate} from "react-router-dom";

interface AdminFormProps {
  onSuccess: (admin: TAdmin) => void;
}

export default function AdminForm(props: AdminFormProps): JSX.Element {
  const {t} = useTranslation();
  const {onSuccess} = props;
  const STORE = useContext<StoreContext>(AppContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [violations, setViolations] = useState<Violation[]>([]);
  const [error, setError] = useState<string|null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) : Promise<void> => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const adminToRegister = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone
    };

    inviteAdmin(adminToRegister)
      .then(onSuccess)
      .catch(async error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
        else if (Array.isArray(errorResult)) setViolations(errorResult);
      })
      .finally(() => setLoading(false))
  };

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{display: 'flex', flexDirection: 'column', p: 7}}>
      <ElementInputWithLabel
        required
        name="lastName"
        value={lastName}
        type="text"
        label={t("register.form.lastName")}
        placeholder={t("register.form.lastName_placeholder")}
        violations={violations}
        sx={{mb: 3}}
        disabled={loading}
        updateValue={setLastName}/>
      <ElementInputWithLabel
        required
        name="firstName"
        value={firstName}
        type="text"
        label={t("register.form.firstName")}
        placeholder={t("register.form.firstName_placeholder")}
        violations={violations}
        sx={{mb: 3}}
        disabled={loading}
        updateValue={setFirstName}/>
      <ElementInputWithLabel
        name="phone"
        value={phone}
        type="tel"
        label={t("register.form.phone")}
        placeholder={t("register.form.phone_placeholder")}
        violations={violations}
        sx={{mb: 3}}
        disabled={loading}
        updateValue={setPhone}/>
      <ElementInputWithLabel
        required
        name="email"
        value={email}
        type="email"
        label={t("register.form.email")}
        placeholder={t("register.form.email_placeholder")}
        violations={violations}
        sx={{mb: 3}}
        disabled={loading}
        updateValue={setEmail}/>
      <PrimaryBigButton submit={true} sx={{mt: 3, mx: "auto"}} loading={loading}
        label={t("register.form.invite_button")}/>
      {error && <Alert variant="filled" severity="error" sx={{mt: 3}}>{error}</Alert>}
    </Box>
  )
}
