import {API_URL} from "../common/struct/urlManager";
import {bodyWithFile, getBlob, post, postWithFile, put} from "../common/methods/ApiService";
import {TOperation} from "../interfaces/operation";

export async function uploadRulesText(operationId: string, content: string): Promise<TOperation> {
  return post(`${API_URL.OPERATION}/${operationId}/rules-text`,{rulesContent: content})
}

export async function uploadRulesPdf(operationId: string, file: File): Promise<TOperation> {
  return postWithFile(`${API_URL.OPERATION}/${operationId}/rules-pdf`, bodyWithFile(file));
}

export async function downloadRulesPdf(operationId: string): Promise<any> {
  return getBlob(`${API_URL.OPERATION}/${operationId}/rules-pdf`);
}

export async function deleteRules(operationId: string): Promise<TOperation> {
  return put(`${API_URL.OPERATION}/${operationId}`, "delete-rules", null);
}
