import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import {translate} from "../operationCustomisation";
import React from "react";
import {OperationProps} from "../operationProps";
import FileIcon from "../../../assets/file.svg";

export function EditorialCard(props: OperationProps): JSX.Element {
  const {t} = useTranslation();
  const {operation} = props;

  return (
    <>
      {operation.presentation && operation.presentationLastUpdate && <Box sx={{display: "flex", backgroundColor: "ornament.main", px: 3, py: 2, boxSizing: "border-box", maxHeight: "66px"}}>
        <Typography variant="body2" color="neutral.main" sx={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: "unset", "& img": {width: "100%", maxHeight: "50px"}}}
          dangerouslySetInnerHTML={{__html: operation.presentation}}/>
      </Box>}
      {operation.presentation == null && <Box sx={{border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", p: 0}}>
        <Box sx={{display: "flex", alignItems: "center", backgroundColor: "ornament.main", borderRadius: "4px", p: 1}}>
          {/** @ts-ignore */}
          <img src={FileIcon} alt="" style={{height: "40px"}}/>
          <Typography variant="body2" sx={{ml: "10px"}}>{translate(t, "presentation.create_presentation")}</Typography>
        </Box>
      </Box>}
      <Typography variant="body2" sx={{fontWeight: "medium", mt: 2}}>
        {operation.presentation && operation.presentationLastUpdate ? translate(t, "presentation.last_updated", {date: new Date(operation.presentationLastUpdate)}) : translate(t, "presentation.no_presentation")}
      </Typography>
    </>
  )
}
