import {Box, Button, Typography} from "@mui/material";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import StarsIcon from "@mui/icons-material/Stars";
import React from "react";
import {useTranslation} from "react-i18next";

export enum PointsUnit {
  POINTS = 'Points',
  EUROS = 'Euros'
}

export function EuroPointToggle(props: {selected: PointsUnit, setSelected: (unit: PointsUnit) => void}): JSX.Element {
  const {selected, setSelected} = props;
  const {t} = useTranslation();

  return (
    <Box sx={{p: "3px", backgroundColor: "ornament.dark", borderRadius: "4px"}}>
      <Button variant="text" startIcon={<EuroSymbolIcon sx={{width: 12, height: 12, color: "inherit"}}/>}
        onClick={(): void => setSelected(PointsUnit.EUROS)} sx={{py: "2px", px: 3, mr: 0, boxShadow: selected == PointsUnit.EUROS ? 2 : 0,
          backgroundColor: selected == PointsUnit.EUROS ? "ornament.light" : "transparent",
          color: selected == PointsUnit.EUROS ? "neutral.dark" : "neutral.main",
          ":hover": {backgroundColor: selected == PointsUnit.EUROS ? "ornament.light" : "neutral.light"}}}>
        <Typography variant="caption" sx={{fontWeight: "medium"}}>
          {t("operation_participants.actions.euros")}
        </Typography>
      </Button>
      <Button variant="text" startIcon={<StarsIcon sx={{width: 12, height: 12, color: "neutral.main"}}/>}
        onClick={(): void => setSelected(PointsUnit.POINTS)} sx={{py: "2px", px: 3, boxShadow: selected == PointsUnit.POINTS ? 2 : 0,
          backgroundColor: selected == PointsUnit.POINTS ? "ornament.light" : "transparent",
          color: selected == PointsUnit.POINTS ? "neutral.dark" : "neutral.main",
          ":hover": {backgroundColor: selected == PointsUnit.POINTS ? "ornament.light" : "neutral.light"}}}>
        <Typography variant="caption" sx={{fontWeight: "medium"}}>
          {t("operation_participants.actions.points")}
        </Typography>
      </Button>
    </Box>
  )
}
