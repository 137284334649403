import React from 'react';
import {useNavigate} from "react-router-dom";
import {Box, Card, Divider, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import emailValidationLogo from '../../assets/email_validation.svg';
import {PrimaryBigButton} from '../../components/buttons/mainButton';

export default function EmailValidationSent(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  
  return (
    <Box sx={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "ornament.main"}}>
      <Card sx={{maxWidth: "750px", width: "100%", padding: "40px 48px 24px", textAlign: "center", mx: "8px", border: "1px solid", borderColor: "ornament.dark", boxShadow: 1}}>
        <Box sx={{display: "flex", justifyContent: "center", mb: "40px"}}>
          {/** @ts-ignore */}
          <img style={{maxWidth: "102px"}} src={emailValidationLogo} alt="email validation logo"/>
        </Box>
        <Typography variant="h1" color="neutral.dark" sx={{fontWeight: "bold", mb: "8px"}}>{t("emailValidation.title")}</Typography>
        <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: "16px"}}>{t("emailValidation.subtitle")}</Typography>
        <Divider sx={{backgroundColor: "ornament.main", mb: "16px"}} />
        <Typography variant="body1" color="neutral.dark" sx={{mb: "40px"}} dangerouslySetInnerHTML={{__html: t("emailValidation.text")}} />
        <PrimaryBigButton label={t("emailValidation.button")} action={(): void => navigate(0)}/>
      </Card>
    </Box>
  )
}