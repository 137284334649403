import React, {useContext} from "react";
import {Box} from "@mui/material";
import {Link} from "react-router-dom";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import logSVG from "../../assets/logoJoozy.svg"
import GlobalSideBar from "./globalSideBar";
import {AppContext} from "../../App";

export const SideBarProperties = [
  {
    width: "244px",
    leftMargin: 3
  },
  {
    width: "0px",
    leftMargin: "0px"
  }
]

export enum SideBarTypes {
  GLOBAL,
  NONE
}

export type SideBarMenuProps = {
  type: SideBarTypes;
}

const SideBarMenu = (props: SideBarMenuProps): JSX.Element => {
  const {type} = props;
  const sideBarProperties = SideBarProperties[type];
  const [store] = useContext<any>(AppContext);

  return store.global.loggedIn && type !== SideBarTypes.NONE ?
    <Box sx={{
      boxShadow: 3,
      display: "flex",
      flexDirection: 'column',
      width: sideBarProperties.width,
      height: "100%",
      position: "fixed",
      top: 0,
      backgroundColor: "secondary.dark",
      zIndex: 2,
      opacity: 1
    }}>
      <Link to={PRIVATE_URL.LANDING_PAGE} style={{height: "10vh"}}>
        <Box sx={{mt: 5, mx: sideBarProperties.leftMargin}}>
          {/* @ts-ignore */}
          <img src={logSVG} alt="JoozyLogo" />
        </Box>
      </Link>
      {type === SideBarTypes.GLOBAL && <GlobalSideBar/>}
    </Box> : <></>
}

export default SideBarMenu;
