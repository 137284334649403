import React, {useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import ListSnackbar, {TAlert} from "../../patterns/list/listSnackbar";
import {TOperation} from "../../interfaces/operation";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import SettingsIcon from "@mui/icons-material/Settings";
import MailIcon from "@mui/icons-material/Mail";
import HistoryIcon from "@mui/icons-material/History";
import Page from "../../patterns/page/page";
import {Box, Typography} from "@mui/material";
import {addErrorAlert, addSuccessAlert} from "../../patterns/list/listUtils";
import OperationMailingSettings from "./operationMailingSettings";
import OperationMailingHistory from "./operationMailingHistory";
import OperationMailingCustomisation from "./operationMailingCustomisation";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import InviteAllParticipants from "../operationParticipants/listParticipants/actions/inviteAllParticipants";
import SendTestEmail from "../operationMailing/sendTestEmail";
import {
  getOperation,
  sendEmailInvitationsToUninvitedParticipants,
  sendTestEmailInvitation
} from "../../services/OperationService";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {IconPosition} from "../../components/buttons/buttonProps";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import OperationMailingPlanned from "./operationMailingPlanned";
import {CalendarMonth} from "@mui/icons-material";
import Panel, {PanelSize} from "../../patterns/panel/panel";
import ListEmails from './listEmails';
import moment from "moment/moment";
import Footer from '../../patterns/footer/footer';

export enum OperationMailingMenu {
  SETTINGS,
  CUSTOMISATION,
  HISTORY = 2,
  PLANNED = 3,
}

export default function OperationMailing(): JSX.Element {
  const {operationId} = useParams<string>();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const extractSelectedMenu = (): OperationMailingMenu|null => {
    switch (location.pathname) {
    case operationUrl(PRIVATE_URL.OPERATION_EMAIL_SETTINGS, operationId!):
      return OperationMailingMenu.SETTINGS;
    case operationUrl(PRIVATE_URL.OPERATION_EMAIL_CUSTOMISATION, operationId!):
      return OperationMailingMenu.CUSTOMISATION;
    case operationUrl(PRIVATE_URL.OPERATION_EMAIL_HISTORY, operationId!):
      return OperationMailingMenu.HISTORY;
    case operationUrl(PRIVATE_URL.OPERATION_EMAIL_PLANNED, operationId!):
      return OperationMailingMenu.PLANNED;
    default:
      navigate(PRIVATE_URL.LANDING_PAGE);
      return null;
    }
  }
  const openPlannedEmailDetails = (sendAt: string): void => {
    setDetailsPanelSendAt(sendAt)
    setOpenDetailsPanel(true);
  };

  const [selectedMenu, setSelectedMenu] = useState(extractSelectedMenu());
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [operation, setOperation] = useState<TOperation|undefined>(undefined);
  const [openInviteAllParticipants, setOpenInviteAllParticipants] = useState(false)
  const [openSendTestEmail, setOpenSendTestEmail] = useState(false)
  const [detailsPanelSendAt, setDetailsPanelSendAt] = useState<string>("")
  const [openDetailsPanel, setOpenDetailsPanel] = useState(false)

  const closeDetailsPanel = (): void => setOpenDetailsPanel(false);

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  useEffect(() => {
    setOperation(store.global.currentOperation);
  }, [store]);

  const menus = [
    {
      icon: MailIcon,
      label: 'operations.mailing.customisation.title',
      url: operationUrl(PRIVATE_URL.OPERATION_EMAIL_CUSTOMISATION, operationId!)
    },
    {
      icon: SettingsIcon,
      label: 'operations.mailing.settings.title',
      url: operationUrl(PRIVATE_URL.OPERATION_EMAIL_SETTINGS, operationId!)
    },
    {
      icon: HistoryIcon,
      label: 'operations.mailing.history.title',
      url: operationUrl(PRIVATE_URL.OPERATION_EMAIL_HISTORY, operationId!)
    },
    {
      icon: CalendarMonth,
      label: 'operations.mailing.planned.title',
      url: operationUrl(PRIVATE_URL.OPERATION_EMAIL_PLANNED, operationId!)
    }
  ]

  useEffect(() => {
    setSelectedMenu(extractSelectedMenu());
  }, [location]);

  const setError = (error: string): void => addErrorAlert(error, setAlerts)
  const setSuccess = (success: string): void => addSuccessAlert(success, setAlerts)

  const closeInviteAllParticipants = (): void => setOpenInviteAllParticipants(false)
  const inviteAllParticipants = (): void => setOpenInviteAllParticipants(true)
  const sendTestEmail = (): void => setOpenSendTestEmail(true)
  const closeSendTestEmail = (): void => setOpenSendTestEmail(false)
  const sendMails = (sendAt: string): Promise<void> => {
    return sendEmailInvitationsToUninvitedParticipants(operationId!, sendAt)
      .then(response => {
        addSuccessAlert(t("operation_participants.action_invite_success", {count: response.sent.count}), setAlerts);
      })
      .then(() => getOperation(operationId!))
      .then(operation => {
        globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        closeInviteAllParticipants();
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), (error: string) => addErrorAlert(error, setAlerts), t);
      })
  }
  const sendMailToAllParticipantsButton = <PrimaryBigButton sx={{ml: 2}} icon={MailIcon} position={IconPosition.LEFT} action={inviteAllParticipants} label={t("operations.mailing.send_to_all_participants")}/>
  const sendTestMailButton = <SecondaryBigButton icon={MailIcon} position={IconPosition.LEFT} action={sendTestEmail} label={t("operations.mailing.send_test_email")}/>
  const sendTestEmails = (): Promise<void> => {    
    return sendTestEmailInvitation(operationId!)
      .then(() => {
        addSuccessAlert(t("operation_participants.action_test_invite_success"), setAlerts);
      })
      .then(() => closeSendTestEmail())
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), (error: string) => addErrorAlert(error, setAlerts), t);
      })
  }

  return (
    <>
      {openSendTestEmail && <SendTestEmail key="send-test-email" openDialog={openSendTestEmail} closeDialog={closeSendTestEmail} sendMail={sendTestEmails} operation={operation} />}
      {openInviteAllParticipants && <InviteAllParticipants key="invite-all-participants" openDialog={openInviteAllParticipants} closeDialog={closeInviteAllParticipants} sendMail={sendMails} operation={operation} />}
      {openDetailsPanel && detailsPanelSendAt && <Panel key="email-details" size={PanelSize.BIG} title={t("operation_mailing_planned.details_panel.title", {date: moment(detailsPanelSendAt).format("DD/MM/YYYY à HH:mm")})} open={openDetailsPanel} close={closeDetailsPanel}><ListEmails sendAt={detailsPanelSendAt}/></Panel>}
      <Page title={t("operations.mailing.title")} menus={menus} backLink={operationUrl(PRIVATE_URL.OPERATION_CUSTOMISATION, operationId!)}
        buttons={[sendTestMailButton, sendMailToAllParticipantsButton]}>
        <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
        {operation == null ? <></> :
          <Box sx={{px: 8, display: "flex", flexDirection: "column", justifyContent: "space-around", flex: 1}}>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mt: 6, mb: 5}}>
              <Typography color="neutral.main" sx={{fontWeight: "bold"}} variant="body2">{t("operations.mailing.subtitle")}</Typography>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", flex: 1}}>
              {selectedMenu === OperationMailingMenu.SETTINGS && <OperationMailingSettings setError={setError} setSuccess={setSuccess}/>}
              {selectedMenu === OperationMailingMenu.CUSTOMISATION && <OperationMailingCustomisation setError={setError} setSuccess={setSuccess}/>}
              {selectedMenu === OperationMailingMenu.HISTORY && <OperationMailingHistory operation={operation} setError={setError} setSuccess={setSuccess}/>}
              {selectedMenu === OperationMailingMenu.PLANNED && <OperationMailingPlanned operation={operation} setError={setError} setSuccess={setSuccess} openPlannedEmailDetails={openPlannedEmailDetails}/>}
            </Box>
            <Footer/>
          </Box>}
      </Page>
    </>
  )
}
