import Joyride, {CallBackProps, Placement, Step} from "react-joyride";
import Tooltip from "./tooltip";
import {LIBERTY_THEME} from "../../tokens/libertyTheme";
import React from "react";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export interface ProductTourStep {
  placement: Placement;
  placementBeacon: Placement;
  target: string;
  name: string;
}

interface ProductTourProps {
  tour: string;
  steps: ProductTourStep[];
  stepIndex: number;
  run: boolean;
  handleProductTour: (data: CallBackProps) => void;
}

export default function ProductTour(props: ProductTourProps): JSX.Element {
  const {tour, steps, stepIndex, run, handleProductTour} = props;
  const {t} = useTranslation();

  const joyrideStep = (step: ProductTourStep, index: number): Step => {
    return {
      ...step,
      content: <Typography variant="body2" color="ornament.light" sx={{fontWeight: "unset", "& ul": {pl: 3}}}
        dangerouslySetInnerHTML={{__html: t(`product_tour.${tour}.${step.name}.content`)}}/>,
      title: t(`product_tour.${tour}.${step.name}.title`),
      disableBeacon: index == 0
    }
  }

  return (
    <Joyride
      steps={steps.map(joyrideStep)}
      stepIndex={stepIndex}
      run={run}
      callback={handleProductTour}
      tooltipComponent={Tooltip}
      styles={{
        options: {
          arrowColor: LIBERTY_THEME.palette.info.main,
          overlayColor: LIBERTY_THEME.palette.neutral.dark
        },
        spotlight: {
          border: "4px solid",
          borderColor: LIBERTY_THEME.palette.info.dark,
          boxSizing: "border-box"
        }
      }}
      continuous
      disableCloseOnEsc
      disableOverlayClose
      scrollToFirstStep
      showProgress
      showSkipButton/>
  )
}
