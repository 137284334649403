import React, {useContext, useEffect, useState} from 'react'
import {Box, Typography} from '@mui/material'
import {operationUrl, PRIVATE_URL} from '../../common/struct/urlManager'
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import RuleIcon from '@mui/icons-material/Rule';
import LanguageIcon from '@mui/icons-material/Language';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import SettingsIcon from '@mui/icons-material/Settings';
import OperationShopCriteria from "../operationShopCriteria/operationShopCriteria";
import OperationUniverses from "../operationUniverses/operationUniverses";
import OperationShopGifts from "../operationShopGifts/operationShopGifts";
import OperationShopPricing from "../operationShopPricing/operationShopPricing";
import OperationShopSettings from '../operationShopSettings/operationShopSettings';
import {AppContext} from "../../App";
import {TOperation} from "../../interfaces/operation";
import {StoreContext} from "../../common/struct/store";
import ListSnackbar, {TAlert} from "../../patterns/list/listSnackbar";
import {addErrorAlert, addSuccessAlert} from "../../patterns/list/listUtils";
import Page from "../../patterns/page/page";
import {IconPosition} from "../../components/buttons/buttonProps";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import VisibilityIcon from "@mui/icons-material/Visibility";

export enum OperationShopMenu {
  SHOP_CRITERIA,
  UNIVERSES,
  GIFTS,
  PRICE,
  SETTINGS
}

export default function OperationShop(): JSX.Element {
  const {operationId} = useParams<string>();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const extractSelectedMenu = (): OperationShopMenu|null => {
    switch (location.pathname) {
    case operationUrl(PRIVATE_URL.OPERATION_SHOP_CRITERIA, operationId!):
      return OperationShopMenu.SHOP_CRITERIA;
    case operationUrl(PRIVATE_URL.OPERATION_SHOP_UNIVERSES, operationId!):
      return OperationShopMenu.UNIVERSES;
    case operationUrl(PRIVATE_URL.OPERATION_SHOP_GIFTS, operationId!):
      return OperationShopMenu.GIFTS;
    case operationUrl(PRIVATE_URL.OPERATION_SHOP_PRICE, operationId!):
      return OperationShopMenu.PRICE;
    case operationUrl(PRIVATE_URL.OPERATION_SHOP_SETTINGS, operationId!):
      return OperationShopMenu.SETTINGS;
    default:
      navigate(PRIVATE_URL.LANDING_PAGE);
      return null;
    }
  }

  const [selectedMenu, setSelectedMenu] = useState(extractSelectedMenu());
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [operation, setOperation] = useState<TOperation|undefined>(undefined);

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const setError = (error: string|null): void => { if (error) addErrorAlert(error, setAlerts) }
  const setSuccess = (success: string): void => addSuccessAlert(success, setAlerts)

  useEffect(() => {
    setOperation(store.global.currentOperation);
  }, [store]);

  const menus = [
    {
      icon: EuroSymbolIcon,
      label: 'operations.shop.price_tab',
      url: operationUrl(PRIVATE_URL.OPERATION_SHOP_PRICE, operationId!)
    },
    {
      icon: RuleIcon,
      label: 'operations.shop.selection_criteria_tab',
      url: operationUrl(PRIVATE_URL.OPERATION_SHOP_CRITERIA, operationId!)
    },
    {
      icon: LanguageIcon,
      label: 'operations.shop.universes_tab',
      url: operationUrl(PRIVATE_URL.OPERATION_SHOP_UNIVERSES, operationId!)
    },
    {
      icon: ShoppingBasketIcon,
      label: 'operations.shop.gifts_tab',
      url: operationUrl(PRIVATE_URL.OPERATION_SHOP_GIFTS, operationId!)
    },
    {
      icon: SettingsIcon,
      label: 'operations.shop.settings_tab',
      url: operationUrl(PRIVATE_URL.OPERATION_SHOP_SETTINGS, operationId!)
    }
  ]

  useEffect(() => {
    setSelectedMenu(extractSelectedMenu());
  }, [location]);

  const mainButton = <PrimaryBigButton key="showcase-button" label={t("operations.shop.showcase_button")}
    icon={VisibilityIcon} position={IconPosition.LEFT} href={"//" + operation?.hostname}/>

  return (
    <>
      <Page title={t("operations.shop.title")} menus={menus} backLink={operationUrl(PRIVATE_URL.OPERATION_CUSTOMISATION, operationId!)}
        buttons={[mainButton]}>
        <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
        {operation == null ? <></> :
          <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mt: 6, mb: 5, px: 8}}>
              <Box sx={{display: "flex"}}>
                <Typography color="neutral.main" sx={{fontWeight: "bold"}} variant="body2">{t("operations.shop.sub_title")}</Typography>
                <Typography color="neutral.main" sx={{fontWeight: "regular", ml: 0}} variant="body2">{t("operations.shop.sub_title_details")}</Typography>
              </Box>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
              {selectedMenu === OperationShopMenu.PRICE && <OperationShopPricing setError={setError} setSuccess={setSuccess}/>}
              {selectedMenu === OperationShopMenu.UNIVERSES && <OperationUniverses setError={setError} setSuccess={setSuccess}/>}
              {selectedMenu === OperationShopMenu.SHOP_CRITERIA && <OperationShopCriteria setError={setError} setSuccess={setSuccess}/>}
              {selectedMenu === OperationShopMenu.GIFTS && <OperationShopGifts setError={setError} setSuccess={setSuccess}/>}
              {selectedMenu === OperationShopMenu.SETTINGS && <OperationShopSettings setError={setError} setSuccess={setSuccess}/>}
            </Box>
          </Box>}
      </Page>
    </>
  )
}
