import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {GreySmallButton, PrimaryBigButton} from "../../../../components/buttons/mainButton";
import InfoIcon from "@mui/icons-material/Info";
import {TSendInvitationNow} from "../../../../interfaces/operationParticipant";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {fr} from "date-fns/locale";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment/moment";
import {TOperation} from "../../../../interfaces/operation";
import {Link} from "react-router-dom";
import {operationUrl, PRIVATE_URL} from "../../../../common/struct/urlManager";

type InviteSelectedParticipantsProps = {
  openDialog: boolean,
  loading: boolean,
  closeDialog: () => void,
  sendMail: (sendAt: string) => void,
  mailsToSend: number,
  operation: TOperation
}

export default function InviteSelectedParticipants(props: InviteSelectedParticipantsProps): JSX.Element {
  const {t} = useTranslation();
  const {openDialog, closeDialog, sendMail, loading, mailsToSend, operation} = props;
  const [option, setOption] = useState<TSendInvitationNow>(TSendInvitationNow.NOW);
  const [sendAt, setSendAt] = useState(moment(new Date()).add(1, 'day').set('hour', 9).set('minutes', 0).format("YYYY-MM-DD hh:mm"));
  const [border, setBorder] = useState<boolean>(false);

  const onConfirmInvite = (): void => {
    closeDialog();
    sendMail(option === TSendInvitationNow.NOW ? 'now' : sendAt);
  }

  const shouldShowWarning = (): boolean => {
    if (!operation.endDate) return false;
    if (option === TSendInvitationNow.NOW) {
      return ((new Date(operation.endDate)) < new Date());
    } else {
      return ((new Date(operation.endDate)) < new Date(sendAt));
    }
  }

  return (
    <Dialog open={openDialog} onClose={closeDialog} sx={{display: "flex", justifyContent: "center"}}>
      <Divider sx={{height: 4, backgroundColor: "info.main", borderColor: "info.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
          <InfoIcon sx={{color: "info.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" sx={{fontWeight: "bold"}}>{t('participants.invitation.send_all_title')}</Typography>
        </Box>
        <IconButton onClick={closeDialog} >
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        {operation.endDate && shouldShowWarning() &&
          <Alert severity="warning" variant="filled" sx={{mb: 3, mt: 3}}>
            {t('participants.invitation.send_operation_has_passed_warning', {endDate: moment(operation.endDate).format('DD/MM/YYYY')})}
            <Box sx={{mt: 2}}>
              <Link to={operationUrl(PRIVATE_URL.OPERATION_CHARACTERISTICS, operation.id)} style={{width: "inherit"}}>
                <GreySmallButton label={t('participants.invitation.send_operation_has_passed_update_button')}/>
              </Link>
            </Box>
          </Alert>
        }
        <Typography variant="body1">{t('participants.invitation.send_total_sent', {count: mailsToSend})}</Typography>
        <RadioGroup>
          <FormControlLabel
            checked={option === TSendInvitationNow.NOW}
            onChange={(): void => setOption(TSendInvitationNow.NOW)} control={<Radio sx={{fontSize: 24}}/>}
            label={<Typography variant="body2" color="neutral.dark">{t("participants.invitation.choice.now")}</Typography>}/>
        </RadioGroup>
        <RadioGroup>
          <FormControlLabel
            checked={option === TSendInvitationNow.LATER}
            onChange={(): void => setOption(TSendInvitationNow.LATER)} control={<Radio sx={{fontSize: 24}}/>}
            label={<Typography variant="body2" color="neutral.dark">{t("participants.invitation.choice.later")}</Typography>}/>
          <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
            <DateTimePicker
              inputFormat="dd MMMM yyyy à HH:mm"
              value={sendAt}
              disabled={option !== TSendInvitationNow.LATER}
              minDate={new Date()}
              minutesStep={5}
              ampm={false}
              onChange={(newDate: any): void => {
                moment.locale('fr')
                const date = moment(newDate).toString()
                setSendAt(date)
              }}
              onOpen={(): void => setBorder(true)}
              onClose={(): void => setBorder(false)}
              PopperProps={{
                sx: {borderWidth: border ? '1px' : '0px', borderStyle: "solid", borderColor: "primary.main", borderRadius: "4px"}
              }}
              renderInput={(params): any => <TextField {...params} inputProps={{...params.inputProps, placeholder: "jj/mm/aaaa"}} sx={{width: "100%", "& input": {p: 3}}} />}
            />
          </LocalizationProvider>
        </RadioGroup>
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <Button variant="outlined" onClick={closeDialog} sx={{height: "56px"}}>
          {t('participants.invitation.send_cancel_button')}
        </Button>
        <PrimaryBigButton action={onConfirmInvite} sx={{marginLeft: 2, height: "56px"}} label={t('participants.invitation.send_confirmation_button')} loading={loading} />
      </DialogActions>
    </Dialog>
  );
}
