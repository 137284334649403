import {ElementType} from "react";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export enum AlertSeverity {
  WARNING,
  ERROR,
  SUCCESS
}

export interface AlertProps {
  color: string;
  Icon: ElementType;
}

export const extractAlertProps = (severity: AlertSeverity): AlertProps => {
  switch (severity) {
  case AlertSeverity.ERROR:
    return {color: "error.main", Icon: ErrorOutlineIcon}
  case AlertSeverity.WARNING:
    return {color: "warning.main", Icon: WarningAmberIcon}
  case AlertSeverity.SUCCESS:
    return {color: "success.main", Icon: CheckCircleIcon}
  }
}
