import React, {useContext, useState} from "react";
import {Box, Chip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useGridApiRef} from "@mui/x-data-grid-pro";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {TEmailForListing, TEMailStatus} from "../../interfaces/email";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import SendIcon from "@mui/icons-material/Send";
import DraftsIcon from "@mui/icons-material/Drafts";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import {
  actionColumn,
  addErrorAlert,
  addSuccessAlert,
  ChipCellProps,
  columnDefinition,
  ColumnType,
  enumFilterWithoutNull,
  gridAction
} from "../../patterns/list/listUtils";
import empty from "../../assets/lists/empty_search.svg";
import List from "../../patterns/list/list";
import {manageStringError, RequestFilter, RequestOrder} from "../../common/methods/ApiService";
import {deletePlannedEmail, getEmailsPlannedDetails, updatePlannedEmail} from "../../services/OperationService";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../../patterns/dialog/deleteDialog";
import {TAlert} from "../../patterns/list/listSnackbar";
import UpdateEmailDialog from "../../patterns/dialog/updateEmailDialog";

export default function listEmails(props: {sendAt: string}): JSX.Element {
  const {sendAt} = props;
  const {t} = useTranslation();
  const apiRef = useGridApiRef();

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const operation = store.global.currentOperation;
  const [emailToDelete, setEmailToDelete] = React.useState<TEmailForListing | null>(null);
  const [emailToUpdate, setEmailToUpdate] = React.useState<TEmailForListing | null>(null);
  const [openCancelEmailDialog, setOpenCancelEmailDialog] = React.useState<boolean>(false);
  const [openUpdateEmailDialog, setOpenUpdateEmailDialog] = React.useState<boolean>(false);
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [reload, setReload] = useState(false);
  const closeCancelEmailDialog = (): void => {
    setOpenCancelEmailDialog(false);
    setEmailToDelete(null);
  }

  const closeUpdateEmailDialog = (): void => {
    setOpenUpdateEmailDialog(false);
    setEmailToUpdate(null);
  }

  const statusProps = (status: string): ChipCellProps => {
    switch (status) {
    case TEMailStatus.JZ_PENDING:
    case TEMailStatus.JZ_CURRENT:
      return {
        icon: <ScheduleSendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "neutral.main",
        color: "neutral.dark",
        backgroundColor: "ornament.main",
      }
    case TEMailStatus.SENT:
      return {
        icon: <SendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "info.main",
        color: "info.dark",
        backgroundColor: "infoLight.light",
      }
    case TEMailStatus.MJ_CLICK:
    case TEMailStatus.MJ_OPEN:
      return {
        icon: <DraftsIcon sx={{width: 18, height: 18}}/>,
        iconColor: "success.main",
        color: "success.dark",
        backgroundColor: "success.light",
      }
    case TEMailStatus.MJ_BOUNCE:
    case TEMailStatus.MJ_SPAM:
    case TEMailStatus.MJ_BLOCKED:
    case TEMailStatus.MJ_UNSUB:
      return {
        icon: <CancelScheduleSendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "error.main",
        color: "error.dark",
        backgroundColor: "error.light",
      }
    case TEMailStatus.UNKNOWN:
    case undefined:
    default:
      return {
        icon: <CancelScheduleSendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "neutral.light",
        color: "neutral.main",
        backgroundColor: "ornament.main"
      }
    }
  }

  const statusColumn = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "operation_mailing_details", "emailStatus", 250, ColumnType.STRING),
      type: "singleSelect",
      filterOperators: enumFilterWithoutNull,
      valueOptions: Object.values(TEMailStatus).map(status => ({value: status, label: t(`operation_mailing_details.columns.status_${status.toLowerCase()}`)})),
      renderCell: (item): JSX.Element => {
        const status = statusProps(item.row.emailStatus);
        return <Chip icon={status.icon}
          sx={{backgroundColor: status.backgroundColor, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: status.iconColor}}}
          label={<Typography variant="body2" color={status.color} sx={{fontWeight: "regular"}}>
            {t(`operation_mailing_details.columns.status_${item.row.emailStatus.toLowerCase()}`)}
          </Typography>}/>
      }
    }
  }

  const updateEmail = (email: TEmailForListing): void => {
    setEmailToUpdate(email);
    setOpenUpdateEmailDialog(true);
  }

  const cancelEmail = (email: TEmailForListing): void => {
    setEmailToDelete(email);
    setOpenCancelEmailDialog(true);
  }

  const cancelEmailAction = (): Promise<void> => {
    if (emailToDelete == null || operation == null) {
      return Promise.resolve();
    }
    return deletePlannedEmail(emailToDelete.id)
      .then(() => {
        setReload(true);
        addSuccessAlert(t("operation_mailing_details.actions.cancel_success"), setAlerts);
      })
      .catch(error => manageStringError(error, error => addErrorAlert(error, setAlerts), t))
      .finally(closeCancelEmailDialog)
  }

  const updateEmailAction = (requestedSendAt: string): Promise<void> => {
    if (emailToUpdate == null || operation == null || requestedSendAt == sendAt) {
      return Promise.resolve();
    }
    return updatePlannedEmail(emailToUpdate.id, requestedSendAt)
      .then(() => {
        setReload(true);
        addSuccessAlert(t("operation_mailing_details.actions.update_success"), setAlerts);
      })
      .catch(error => manageStringError(error, error => addErrorAlert(error, setAlerts), t))
      .finally(closeUpdateEmailDialog)
  }

  const cancelEmailDialog = <DeleteDialog key="cancel-email" open={openCancelEmailDialog} close={closeCancelEmailDialog}
    title={t("operation_mailing_details.actions.cancel_title")} warning={t("operation_mailing_details.actions.cancel_warning")} deleteElement={cancelEmailAction}/>

  const updateEmailDialog = <UpdateEmailDialog key="update-email" openDialog={openUpdateEmailDialog} closeDialog={closeUpdateEmailDialog} action={updateEmailAction}/>

  const columns = [
    statusColumn(),
    columnDefinition(t, "operation_mailing_details", "participantName", 200, ColumnType.STRING),
    columnDefinition(t, "operation_mailing_details", "participantEmail", 200, ColumnType.STRING),
    actionColumn(params => [
      gridAction(params, "update", t("operation_mailing_details.actions.update"), EditIcon, updateEmail),
      gridAction(params, "cancel", t("operation_mailing_details.actions.cancel"), DeleteIcon, cancelEmail),
    ])
  ]

  const loadHistory = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TEmailForListing[]}> => {
    return getEmailsPlannedDetails(operation!.id, sendAt, page, maxItems, search, order, filters);
  }

  return (
    <Box sx={{height: "82.5vh", p: 7}}>
      <List
        translationPrefix="operation_mailing_details"
        columns={columns}
        apiRef={apiRef}
        defaultOrder={{field: 'sendAt', sort: 'desc'}}
        loadRows={loadHistory}
        height="75vh"
        emptyListIcon={empty}
        sx={{width: "100%", mb: 3}}
        enableToolbarSearch={false}
        enableSelection={false}
        dialogs={[cancelEmailDialog, updateEmailDialog]}
        reload={reload}
        setReload={setReload}
        alerts={alerts}
        setAlerts={setAlerts}
      />
    </Box>
  )
}
