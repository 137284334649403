import React from "react";
import {GridColumnMenuContainer, HideGridColMenuItem, SortGridMenuItems} from "@mui/x-data-grid-pro";
import {GridColumnMenuProps} from "@mui/x-data-grid/components/menu/columnMenu/GridColumnMenuProps";

export default function ListColumnMenu(props: GridColumnMenuProps): JSX.Element {
  return (
    <GridColumnMenuContainer {...props}>
      <SortGridMenuItems onClick={props.hideMenu} column={props.currentColumn}/>
      <HideGridColMenuItem onClick={props.hideMenu} column={props.currentColumn}/>
    </GridColumnMenuContainer>
  )
}
