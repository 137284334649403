import React, {useContext} from "react";
import {Box, Typography} from "@mui/material";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {formatAmount} from "../../utils/formatAmount"

export default function WalletInfo(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE; 

  const handleWallets = (): any => {
    navigate(PRIVATE_URL.WALLETS_LIST);
  }

  const walletCount = (): any => {
    let count = 0;
    store.global.company.wallets.forEach(element => {      
      if (element.balance > 0) {
        count++;
      }
    });
    return count;
  }

  return (
    <Box onClick={(): any => handleWallets()} sx={{borderWidth: "1px", borderColor: "secondaryLight.light", borderStyle: "solid", borderRadius: "5px", padding: "16px", mt: "24px", backgroundColor: "rgba(255, 255, 255, 0.04)", cursor: "pointer", transition: "background-color 0.2s", "&:hover": {backgroundColor: "rgba(255, 255, 255, 0.1)"}}}>
      <Typography variant="body2" color="secondaryLight.light" sx={{fontWeight: "medium"}}>{t("layout_sideBar.wallet_card.title")}</Typography>
      <Box sx={{display: "flex", justifyContent: "space-between"}}>
        <Typography variant="body2" color="secondaryLight.light" sx={{fontWeight: 600, fontSize: "18px", mt: "5px"}}>{t("layout_sideBar.wallet_card.amount", {amount: formatAmount(store.global.company.wallets.reduce((accumulator, object) => { return accumulator + object.balance }, 0))})}</Typography>
      </Box>
      {walletCount() > 1 && <Typography variant="body2" color="primary.light" sx={{fontWeight: "regular", mt: "2px"}}>{t("layout_sideBar.wallet_card.dispatch", {count: walletCount()})}</Typography>}
    </Box>
  ); 
}
