export interface TLoggingEntry {
  id: string;
  value: any;
  type: string;
  action: TLoggingEntryAction;
  userId?: string;
  user?: string;
  origin: TLoggingEntryOrigin;
  timestamp: number;
}

export enum TLoggingEntryOrigin {
  FO = 'FO',
  BO = 'BO'
}

export enum TLoggingEntryAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}
