import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {TOperationParticipantForListing} from "../../../../interfaces/operationParticipant";
import {addErrorAlert, addSuccessAlert, gridAction} from "../../../../patterns/list/listUtils";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {GridRowParams} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import RestoreDialog from "../../../../patterns/dialog/restoreDialog";
import {restoreOperationParticipant} from "../../../../services/operationParticipantService";
import {errorManager, manageStringError} from "../../../../common/methods/ApiService";
import {StoreContext} from "../../../../common/struct/store";
import {AppContext} from "../../../../App";
import {TAlert} from "../../../../patterns/list/listSnackbar";
import {useNavigate} from "react-router-dom";

export default function ListDeletedActions(params: GridRowParams, operationId: string, setReload: (reload: boolean) => void, setAlerts: Dispatch<SetStateAction<TAlert[]>>): JSX.Element[] {
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);

  const [operationParticipant, setOperationParticipant] = useState<TOperationParticipantForListing|null>(null);
  const [openRestoreParticipant, setOpenRestoreParticipant] = useState(false);
  const closeRestoreParticipant = (): void => setOpenRestoreParticipant(false)
  const navigate = useNavigate();

  const restore = (): void => {
    if (operationParticipant == null) {
      return;
    }

    restoreOperationParticipant(operationId, operationParticipant.id)
      .then(() => {
        setReload(true);
        addSuccessAlert(t("operation_participants.deleted.actions.restore_success", {name: operationParticipant.email}), setAlerts);
      })
      .catch(error => manageStringError(errorManager(error, t, STORE, navigate), error => addErrorAlert(error, setAlerts), t))
      .finally(() => closeRestoreParticipant())
  }

  const restoreParticipant = (operationParticipant: TOperationParticipantForListing): void => {
    setOperationParticipant(operationParticipant);
    setOpenRestoreParticipant(true);
  }

  const dialogs = [
    <RestoreDialog key="restore-participant" open={openRestoreParticipant} close={closeRestoreParticipant} title={t("operation_participants.deleted.actions.restore_title", {name: operationParticipant?.email})} info={t("operation_participants.deleted.actions.restore_content")} restoreElement={restore}/>
  ]
  return [
    gridAction(params, "update", t("operation_participants.deleted.actions.restore"), AutorenewIcon, restoreParticipant),
    ...(operationParticipant ? dialogs : [])
  ]
}
