import React, {useContext} from "react";
import {Avatar, Box, Card, Typography} from "@mui/material";
import {OperationItem} from "../../components/layoutItems/mainItem";
import AddIcon from '@mui/icons-material/Add';

import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {AppContext} from "../../App";
import {OperationInformation, StoreContext, WizardType} from "../../common/struct/store";
import {LibertyTheme} from '../../tokens/libertyTheme';
import {initWizardStore} from "../../common/methods/context-setter/wizardStoreReducer";

export const OperationsList = (): JSX.Element => {
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const location = useLocation();
  const newOperationIsClicked = location.pathname === PRIVATE_URL.CREATE_OPERATION_WIZARD ||
    location.pathname === PRIVATE_URL.LANDING_PAGE;

  return (
    <Box id="operation-list" sx={{display: "flex", flexDirection: "column", height: "100%", pb: 5, boxSizing: "border-box"}}>
      <Typography color="secondaryLight.main" variant="caption" sx={{height: "fit-content"}}>
        {t("layout_sideBar.operation_title").toUpperCase()}
      </Typography>
      <Link to={PRIVATE_URL.CREATE_OPERATION_WIZARD} onClick={(): void => initWizardStore(STORE, WizardType.CREATE_OPERATION, "")} style={{height: "fit-content"}}>
        <Card sx={{display: "flex", alignItems: "center", p: 1, mt: 2, mb: 1, boxShadow: 1, cursor: "pointer",
          backgroundColor: newOperationIsClicked ? "secondary.main" : "transparent", borderColor: newOperationIsClicked ? "secondary.main" : "secondary.light",
          ":hover": {borderColor: "secondaryLight.dark", "& p": {color: "ornament.light"}}}}>
          <Avatar sx={{backgroundColor: "primary.main", p: "2px", mr: 1, width: 20, height: 20}}>
            <AddIcon sx={{color: "ornament.light"}}/>
          </Avatar>
          <Typography variant="body2" color={newOperationIsClicked ? "ornament.light" : "secondaryLight.light"} sx={{fontWeight: "medium"}}>
            {t("layout_sideBar.new_operation_label")}
          </Typography>
        </Card>
      </Link>
      <Box sx={{overflowY: "auto", width: "100%", maxHeight: "300px", pr: 0, "&::-webkit-scrollbar": {width: "6px"}, "&::-webkit-scrollbar-thumb": {background: LibertyTheme.palette.secondaryLight.main, borderRadius: "8px"}}}>
        {store.global.operations.map((operation: OperationInformation) => {
          const index = store.global.operations.indexOf(operation);
          return <OperationItem
            key={"operation-" + index}
            label={operation.name}
            opId={operation.id}
            index={index}
            isClicked_val={location.pathname.includes(operation.id)}/>
        })}
      </Box>
    </Box>

  )
}
