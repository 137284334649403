import React, {MutableRefObject, useContext, useEffect, useState} from 'react';
import {Editor} from "@tinymce/tinymce-react";
import {uploadImageFile} from "../../services/OperationService";
import {errorManager, Violation} from "../../common/methods/ApiService";
import {useTranslation} from "react-i18next";
import {IMAGE_FILE_TYPES} from "../dialog/downloadFileProps";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {Box, Typography} from '@mui/material';
import {useNavigate} from "react-router-dom";

interface MenuItemProps {
  name: string,
  label: string,
  action: () => void
}

interface MenuProps {
  name: string,
  label: string,
  items: MenuItemProps[],
}

type WyziwygProps = {
  operationId: string,
  editorRef: MutableRefObject<any|null>,
  setError: (error: string) => void,
  initialValue: string|null,
  name?: string,
  violations?: Violation[];
  height?: number,
  customMenus?: MenuProps[]|[],
}

export default function WyziwygEditor(props: WyziwygProps): JSX.Element {
  const {operationId, editorRef, setError, initialValue, name, violations, height, customMenus} = props;
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);

  const [activeViolations, setActiveViolations] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (violations) {
      setActiveViolations(violations.filter(violation => violation.field === name).map(violation => violation.error));
    }
  }, [violations])

  const FONTS = [
    {
      name: "Andale Mono",
      font: "andale mono,times"
    },
    {
      name: "Arial",
      font: "arial,helvetica,sans-serif"
    },
    {
      name: "Arial Black",
      font: "arial black,avant garde"
    },
    {
      name: "Book Antiqua",
      font: "book antiqua,palatino"
    },
    {
      name: "Comic Sans MS",
      font: "comic sans ms,sans-serif"
    },
    {
      name: "Courier New",
      font: "courier new,courier"
    },
    {
      name: "Georgia",
      font: "georgia,palatino"
    },
    {
      name: "Helvetica",
      font: "helvetica"
    },
    {
      name: "Impact",
      font: "impact,chicago"
    },
    {
      name: "Mulish",
      font: "Mulish,sans-serif"
    },
    {
      name: "Roboto",
      font: "Roboto,sans-serif"
    },
    {
      name: "Symbol",
      font: "symbol"
    },
    {
      name: "Tahoma",
      font: "tahoma,arial,helvetica,sans-serif"
    },
    {
      name: "Terminal",
      font: "terminal,monaco"
    },
    {
      name: "Times New Roman",
      font: "times new roman,times"
    },
    {
      name: "Trebuchet MS",
      font: "trebuchet ms,geneva"
    },
    {
      name: "Verdana",
      font: "verdana,geneva"
    },
    {
      name: "Webdings",
      font: "webdings"
    },
    {
      name: "Wingdings",
      font: "wingdings,zapf dingbats"
    }
  ]

  return (
    <Box sx={{".tox-tinymce": {borderColor: `${activeViolations[0] ? "error.main" : "#EEE"}`, borderWidth: `${activeViolations[0] ? "1px" : "2px"}`, borderStyle: "solid"}}}>
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
        onInit={(evt, editor): any => editorRef.current = editor}
        initialValue={initialValue??undefined}
        init={{
          height: height ?? 500,
          menubar: false,
          language: 'fr_FR',
          plugins: ['image', 'link', 'code', 'lists', 'table'],
          image_file_types: IMAGE_FILE_TYPES.join(","),
          file_picker_types: 'image media',
          smart_paste: false,
          image_caption: true,
          link_assume_external_targets: true,
          automatic_uploads: true,
          setup: function (editor): void {
            if (customMenus) {
              customMenus.forEach(menu => {
                menu.items.forEach(item => editor.ui.registry.addMenuItem(item.name, {
                  text: item.label,
                  onAction: item.action
                }));

                editor.ui.registry.addMenuButton(menu.name, {
                  text: menu.label,
                  fetch: (callback): void => callback(menu.items.map(item => item.name))
                });
              });
            }
          },
          images_upload_handler: function(blobInfo): Promise<any> {
            return uploadImageFile(operationId, blobInfo.blob())
              .then(response => response.url)
              .catch(error => {
                const errorResult = errorManager(error, t, STORE, navigate);
                if (typeof errorResult === 'string') setError(errorResult);
                return {message: errorResult, remove: true}
              })
          },
          toolbar: `undo redo | fontfamily fontsize | h1 h2 h3 | bold italic underline strikethrough | forecolor backcolor | link image table | ' +
            'alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | code ${customMenus?.map(menu => menu.name)}`,
          toolbar_mode: 'wrap',
          font_family_formats: FONTS.map(font => `${font.name}=${font.font}`).join(";"),
          valid_children : '+body[style]',
          extended_valid_elements: 'script[language|type|src]',
          content_style: "@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;600;700&family=Roboto:wght@300;600;700&display=swap'); body { font-family: 'Mulish', sans-serif; }"
        }}
      />
      <Box sx={{mb: "4px", mx: 3}}>
        {activeViolations.map((key, violation) =>
          <Typography key={key} variant="body2" sx={{color: "error.main", mt: "3px", fontSize: "12px"}}>{violation}</Typography>
        )}
      </Box>
    </Box>
  )
}
