import React, {MouseEvent, useContext, useState} from 'react';
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import {deleteRules} from "../../services/RulesService";
import {errorManager} from "../../common/methods/ApiService";
import {useTranslation} from "react-i18next";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {useNavigate} from "react-router-dom";

type OperationRulesDialogProps = {
  operationId: string,
  open: boolean,
  setOpen: (open: boolean) => void,
  changeSelectedFormat: () => void
}

export default function OperationRulesDialog(props: OperationRulesDialogProps): JSX.Element {
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);

  const {operationId, open, setOpen, changeSelectedFormat} = props;
  const [error, setError] = useState<string|null>(null);
  const navigate = useNavigate();

  const handleClose = (event: MouseEvent<HTMLElement>): void => {
    if (event.button > 0) {
      return;
    }
    setOpen(false);
  }

  const changeRulesFormat = (): void => {
    deleteRules(operationId)
      .then(operation => {
        changeSelectedFormat();
        globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        setOpen(false);
      })
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
      })
  }

  return (
    <Dialog open={open} onClose={handleClose} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: "warning.main", borderColor: "warning.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <ReportProblemIcon sx={{color: "warning.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {t("operations.rules.warning.title")}
          </Typography>
        </Box>
        <IconButton onClick={(): void => setOpen(false)}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1" color="neutral.main">
          {t("operations.rules.warning.content")}
        </Typography>
        {error && <Alert severity="error" variant="filled">{error}</Alert>}
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <SecondaryBigButton sx={{mr: 0}} label={t("operations.rules.warning.cancel")} action={handleClose}/>
        <PrimaryBigButton label={t("operations.rules.warning.confirm")} action={changeRulesFormat}/>
      </DialogActions>
    </Dialog>
  )
}
