import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from "@mui/icons-material/Close";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

interface InfoDialogProps {
  open: boolean;
  close: () => void;
  title: string;
  children: JSX.Element|JSX.Element[];
  action?: {title: string, onClick?: () => Promise<void>, href?: string, newWindow?: boolean};
}

export default function InfoDialog(props: InfoDialogProps): JSX.Element {
  const {open, close, title, children, action} = props;
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);
  const handleAction = (): void => {
    if (action == null || action.onClick == null) {
      return;
    }
    setLoading(true);
    action.onClick().finally(() => setLoading(false));
  }

  return (
    <Dialog open={open} onClose={close} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: "info.main", borderColor: "info.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <InfoIcon sx={{color: "info.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {title}
          </Typography>
        </Box>
        <IconButton disabled={loading} onClick={close}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        {children}
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        {action && <PrimaryBigButton sx={{mr: 0}} label={action.title} action={handleAction} loading={loading} href={action.href} openNewWindow={action.newWindow}/>}
        <SecondaryBigButton disabled={loading} label={t("list.info.close")} action={close}/>
      </DialogActions>
    </Dialog>
  )
}
