import {RequestFilterOperator} from "../../common/methods/ApiService";
import {getGridStringOperators, GridActionsCellItem, GridRowParams} from "@mui/x-data-grid";
import {GridFilterOperator} from "@mui/x-data-grid/models/gridFilterOperator";
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import React, {Dispatch, ElementType, SetStateAction} from "react";
import {LIBERTY_THEME} from "../../tokens/libertyTheme";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Box, Typography} from "@mui/material";
import moment from "moment";
import {TFunction} from "i18next";
import {TAlert} from "./listSnackbar";
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';

/** COLUMN **/
export interface ChipCellProps {
  icon?: JSX.Element|undefined;
  iconColor?: string|undefined;
  color: string;
  backgroundColor: string;
  date?: string|Date
}

export enum ColumnType {
  STRING = "string",
  DATE = "date",
  DATE_TIME = "datetime",
  NUMBER = "number",
  POINTS = "points",
  EUROS = "euros"
}
export const valueGetter = (value: any, type: ColumnType): any => {
  switch (type) {
  case ColumnType.DATE:
  case ColumnType.DATE_TIME:
    return value == null ? "-" : new Date(value);
  case ColumnType.POINTS:
    return value == null ? 0 : value;
  default:
    return (value == null || value == '') ? "-" : value;
  }
}
export const render = (value: any, type: ColumnType, t: TFunction): JSX.Element => {
  switch (type) {
  case ColumnType.POINTS:
    return <Box sx={{display: 'flex'}}>
      <Typography variant="body2" sx={{lineHeight: 2.5, mr: 0}}>{value}</Typography>
      <Typography variant="body2" sx={{fontSize: 12, fontWeight: "bold", fontVariantPosition: "super"}}>
        {t<string>("list.columns.points")}
      </Typography>
    </Box>
  case ColumnType.EUROS:
    return <Box sx={{display: "flex"}}>
      <Typography variant="body2" sx={{lineHeight: 2.5, mr: 0}}>{value}</Typography>
      <Typography variant="body2" sx={{fontSize: 12, fontWeight: "bold", fontVariantPosition: "super"}}>
        {t<string>("list.columns.euros")}
      </Typography>
    </Box>
  case ColumnType.DATE:
    return <Typography variant="body2" color="neutral.dark">
      {value instanceof Date ? moment(value).format('DD/MM/YYYY') : value}
    </Typography>
  case ColumnType.DATE_TIME:
    return <Typography variant="body2" color="neutral.dark">
      {value instanceof Date ? moment(value).format('DD/MM/YYYY | HH:mm') : value}
    </Typography>
  case ColumnType.STRING:
  case ColumnType.NUMBER:
    return <Typography variant="body2" color="neutral.dark" noWrap>
      {value}
    </Typography>
  }
}
const columnType = (type: ColumnType): string => {
  switch (type) {
  case ColumnType.POINTS:
  case ColumnType.EUROS:
    return ColumnType.NUMBER;
  case ColumnType.DATE_TIME:
    return ColumnType.DATE;
  default:
    return type;
  }
}
export const columnDefinition = (t: TFunction, prefix: string, field: string, width: number, type: ColumnType = ColumnType.STRING, sortable = true, isNullable = false, filterable : boolean|null = null): GridEnrichedColDef => {
  return {
    field: field,
    type: columnType(type),
    align: "left",
    headerAlign: "left",
    headerName: t(`${prefix}.columns.${field}`),
    sortable: sortable,
    hideable: true,
    filterable: (filterable !== null) ? filterable : sortable,
    filterOperators: filterOperators(type, isNullable),
    pinnable: false,
    valueGetter: (item: GridValueGetterParams): string => valueGetter(item.row[field], type),
    renderCell: (item): JSX.Element => render(item.value, type, t),
    renderHeader: (): JSX.Element => <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "bold"}}>
      {t<string>(`${prefix}.columns.${field}`)}
    </Typography>,
    width: width
  }
}

/** FILTERS **/
export const extractOperator = (operator?: string): RequestFilterOperator => {
  if (operator == null) {
    return RequestFilterOperator.LIKE;
  }

  switch (operator) {
  case 'is':
  case '=':
    return RequestFilterOperator.EQUALS;
  case 'after':
  case '>':
    return RequestFilterOperator.GREATER_THAN;
  case 'before':
  case '<':
    return RequestFilterOperator.LESSER_THAN;
  case '>=':
    return RequestFilterOperator.GREATER_THAN_OR_EQUALS;
  case '<=':
    return RequestFilterOperator.LESSER_THAN_OR_EQUALS;
  case 'isEmpty':
    return RequestFilterOperator.IS;
  case 'isNotEmpty':
    return RequestFilterOperator.IS_NOT;
  case 'contains':
  default:
    return RequestFilterOperator.LIKE;
  }
}

export const stringFilter = getGridStringOperators().filter((operator: GridFilterOperator) =>
  ['contains', 'isEmpty'].indexOf(operator.value) > -1
);
export const stringFilterWithoutNull = getGridStringOperators().filter((operator: GridFilterOperator) =>
  ['contains'].indexOf(operator.value) > -1
);
export const enumFilterWithoutNull = [
  ...getGridSingleSelectOperators().filter((operator: GridFilterOperator) => ['is'].indexOf(operator.value) > -1)
];
export const enumFilter = [
  ...enumFilterWithoutNull,
  ...getGridStringOperators().filter((operator: GridFilterOperator) => ['isEmpty', 'isNotEmpty'].indexOf(operator.value) > -1)
];
export const numberFilter = getGridNumericOperators().filter((operator: GridFilterOperator) =>
  ['=', '>', '>=', '<', '<=', 'isEmpty'].indexOf(operator.value) > -1
);
export const numberFilterWithoutNull = getGridNumericOperators().filter((operator: GridFilterOperator) =>
  ['=', '>', '>=', '<', '<='].indexOf(operator.value) > -1
);
export const dateFilter = getGridDateOperators().filter((operator: GridFilterOperator) =>
  ['is', 'after', 'before', 'isEmpty', 'isNotEmpty'].indexOf(operator.value) > -1
);
export const dateFilterWithoutNull = getGridDateOperators().filter((operator: GridFilterOperator) =>
  ['is', 'after', 'before'].indexOf(operator.value) > -1
);
export const filterOperators = (type: ColumnType, isNullable = true): GridFilterOperator[] => {
  switch (type) {
  case ColumnType.STRING:
    return isNullable ? stringFilter : stringFilterWithoutNull;
  case ColumnType.DATE:
  case ColumnType.DATE_TIME:
    return isNullable ? dateFilter : dateFilterWithoutNull;
  case ColumnType.POINTS:
  case ColumnType.EUROS:
  case ColumnType.NUMBER:
    return isNullable ? numberFilter : numberFilterWithoutNull;
  }
}

/** ACTIONS */
export const gridAction = (params: GridRowParams, key: string, label: string, Icon: ElementType, onClick: (row: any) => void, borderBottom = false, disabled = false, showInMenu = true): JSX.Element => {
  return (
    <GridActionsCellItem key={`action-${key}`} icon={<Icon sx={{width: 18, height: 18, color: "neutral.main"}}/>}
      label={label}
      disabled={disabled}
      onClick={(): void => onClick(params.row)} showInMenu={showInMenu}
      sx={{borderBottom: borderBottom ? "1px solid" : "none",
        borderColor: "ornament.dark", py: 1, px: 3, ":hover": {backgroundColor: "ornament.main"},
        color: "neutral.main", fontSize: LIBERTY_THEME.typography.body2.fontSize, lineHeight: LIBERTY_THEME.typography.body2.lineHeight
      }}/>
  )
}

export const actionColumn = (actions: (params: GridRowParams) => JSX.Element[]): GridEnrichedColDef => ({
  field: 'actions',
  type: 'actions',
  headerName: '',
  pinnable: false,
  sortable: false,
  hideable: false,
  filterable: false,
  width: 50,
  getActions: (params: GridRowParams) => actions(params)
})

export const connectColumn = (actions: (params: any) => void): GridEnrichedColDef => ({
  field: 'actions',
  type: 'actions',
  headerName: '',
  pinnable: false,
  sortable: false,
  hideable: false,
  filterable: false,
  width: 50,
  getActions: (params: GridRowParams) => [
    <GridActionsCellItem key="false" icon={<ArrowCircleRightRoundedIcon sx={{width: 22, height: 22, color: "neutral.main"}}/>} label={"Connect"} onClick={(): void => actions(params.row)} />
  ]
})

export const addSuccessAlert = (success: string, setAlerts: Dispatch<SetStateAction<TAlert[]>>): void => {
  setAlerts(value => [{label: success, isSuccess: true, timestamp: Date.now(), visible: true}, ...value]);
}

export const addErrorAlert = (error: string, setAlerts: Dispatch<SetStateAction<TAlert[]>>): void => {
  setAlerts(value => [{label: error, isSuccess: false, timestamp: Date.now(), visible: true}, ...value]);
}
