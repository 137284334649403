import React, {useContext, useState} from 'react';
import {Box, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SuccessIcon from "../../../assets/wizard/operation/success.svg";
import {useTranslation} from "react-i18next";
import {PrimaryBigButton} from "../../../components/buttons/mainButton";
import ReactConfetti from "react-confetti";
import {LIBERTY_THEME} from "../../../tokens/libertyTheme";
import {AppContext} from "../../../App";
import {StoreContext} from "../../../common/struct/store";
import {operationStoreReducer} from "../../../common/methods/context-setter/operationStoreReducer";
import {INIT_STORE} from "../../../common/methods/context-setter/globals";

interface CongratulationsDialogProps {
  startProductTour: () => void;
}

export default function CongratulationsDialog(props: CongratulationsDialogProps): JSX.Element {
  const {startProductTour} = props;
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);

  const [open, setOpen] = useState(true);

  const confettiWidth = window.innerWidth * 0.30;
  const confettiColors = [
    LIBERTY_THEME.palette.primaryLight.dark,
    LIBERTY_THEME.palette.primaryLight.main,
    LIBERTY_THEME.palette.primaryLight.light,
    LIBERTY_THEME.palette.error.main,
    LIBERTY_THEME.palette.warning.main,
    LIBERTY_THEME.palette.success.main,
    LIBERTY_THEME.palette.raspberry.light,
  ]

  const close = (): void => {
    operationStoreReducer(STORE, {type: INIT_STORE});
    setOpen(false);
  }
  const launchProductTour = (): void => {
    startProductTour();
    close();
  }

  return <>
    <Dialog disableEscapeKeyDown open={open} sx={{"& .MuiDialog-container": {height: "100%", "& canvas": {position: "fixed !important"}}}}>
      <ReactConfetti
        numberOfPieces={500}
        colors={confettiColors}
        gravity={0.5}
        recycle={false}
        confettiSource={{x: 0, y: 0, w: confettiWidth, h: 0}}/>
      <ReactConfetti
        numberOfPieces={500}
        colors={confettiColors}
        gravity={0.5}
        recycle={false}
        confettiSource={{x: window.innerWidth * 0.7, y: 0, w: confettiWidth, h: 0}}/>
      <DialogTitle sx={{display: "flex", justifyContent: "flex-end", backgroundColor: "primaryLight.light", pt: 5, pr: 5, pb: "0px"}}>
        <IconButton sx={{p: "0px"}} onClick={close}><ClearIcon sx={{color: "neutral.main"}}/></IconButton>
      </DialogTitle>
      <DialogContent sx={{p: "0px"}}>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "primaryLight.light", px: 5, pb: 8}}>
          {/** @ts-ignore */}
          <img src={SuccessIcon} alt=""/>
          <Typography variant="h1" sx={{fontWeight: "bold", textAlign: "center", mt: 2}}
            dangerouslySetInnerHTML={{__html: t("wizard.operation.success.title")}}/>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", p: 5, pb: 6}}>
          <Typography variant="body2" color="neutral.main" sx={{mb: 2, textAlign: "center"}}>
            {t("wizard.operation.success.subtitle")}
          </Typography>
          <Typography variant="body1" color="primary.main" sx={{fontWeight: "unset", textAlign: "center", my: 2}} dangerouslySetInnerHTML={{__html: t("wizard.operation.success.discover")}}/>
          <PrimaryBigButton label={t("wizard.operation.success.action")} action={launchProductTour} sx={{mt: 2}}/>
        </Box>
      </DialogContent>
    </Dialog>
  </>
}
