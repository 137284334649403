import {ClickAwayListener, InputAdornment, TextField} from "@mui/material";
import {LIBERTY_THEME} from "../../tokens/libertyTheme";
import SearchIcon from "@mui/icons-material/Search";
import React, {useEffect, useState} from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface ListSearchProps {
  search: string;
  setSearch: (search: string) => void;
  placeholder: string;
  sx?: SxProps<Theme>;
}

export default function ListSearch(props: ListSearchProps): JSX.Element {
  const {search, setSearch, placeholder, sx} = props;

  const [localeSearch, setLocaleSearch] = useState("");
  const [focusSearch, setFocusSearch] = useState(false);

  const applySearch = (): void => {
    if (localeSearch == search) {
      return;
    }
    setSearch(localeSearch);
  }

  useEffect(() => {
    if(search.length > 0 || localeSearch.length > 0) {
      setLocaleSearch(search)
    }
  },[search])

  return (
    <ClickAwayListener onClickAway={(): void => setFocusSearch(false)}>
      <TextField sx={{backgroundColor: "ornament.light", width: 295, "& input": {p: 2, "&::placeholder": {textOverflow: "ellipsis",
        fontSize: LIBERTY_THEME.typography.body1.fontSize, lineHeight: LIBERTY_THEME.typography.body1.lineHeight}}, ...sx}}
      placeholder={placeholder} onClick={(): void => setFocusSearch(true)}
      value={localeSearch} onChange={(event): void => setLocaleSearch(event.target.value)}
      onKeyPress={(event): void => {
        if (event.key === "Enter") applySearch()
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end" sx={{mr: "-10px"}}>
          <SearchIcon onClick={applySearch} sx={{fontSize: 24, p: "7px", borderRadius: "4px",
            backgroundColor: focusSearch ? "primary.main" : "transparent",
            color: focusSearch ? "ornament.light" : "neutral.main",
            cursor: "pointer",
            boxShadow: focusSearch ? 1 : 0}}/>
        </InputAdornment>,
      }}/>
    </ClickAwayListener>
  )
}
