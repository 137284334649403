import React, {useEffect, useState} from 'react';

import {Box, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import Page from "../../patterns/page/page";
import OperationCharacteristicsCard from "./operationCharacteristicsCard";
import ListSnackbar, {TAlert} from "../../patterns/list/listSnackbar";
import {addErrorAlert, addSuccessAlert} from "../../patterns/list/listUtils";
import PageForm from "../../patterns/page/pageForm";
import Footer from "../../patterns/footer/footer";


export default function OperationCharacteristics() : JSX.Element {
  const {t} = useTranslation();
  useEffect(() => {
    document.title = t("tab_title.operation.characteristics");
  }, []);
  const [loading, setLoading] = useState(false);
  const [triggerAction, setTriggerAction] = useState(false);
  const [alerts, setAlerts] = useState<TAlert[]>([]);

  const setError = (error: string|null): void => { if (error) addErrorAlert(error, setAlerts) }
  const setSuccess = (success: string): void => addSuccessAlert(success, setAlerts)

  const updateOperation = (): void => {
    setTriggerAction(true);
  }

  return (
    <>
      <Page title={t("operations.characteristics.page_title")}>
        <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
        <Box sx={{px: 8, display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mt: 6, mb: 5}}>
            <Typography color="neutral.main" sx={{fontWeight: "regular"}} variant="body2">{t("operations.characteristics.subtitle")}</Typography>
          </Box>
          <PageForm submit={updateOperation} loading={loading}>
            <OperationCharacteristicsCard setError={setError} loading={loading} setLoading={setLoading}
              propsNoWizard={{triggerAction, setTriggerAction, setSuccess}} autoHostname={false} isWizard={false}/>
          </PageForm>
        </Box>
        <Footer/>
      </Page>
    </>
  )
}
