import {useTranslation} from "react-i18next";
import {Box, Card, Typography} from "@mui/material";
import {translate} from "../operationCustomisation";
import React from "react";
import {GiftBoxProps} from "./operationCardProps";
import {OperationProps} from "../operationProps";
import UNIVERSES_IMAGES from "../../operationUniverses/universesImages";
import UniverseIcon from "../../../assets/universe.png";
import {LibertyTheme} from "../../../tokens/libertyTheme";

const ShopPreview = (props: GiftBoxProps): JSX.Element => {
  const {universe, marginLeft, noUniverse} = props;

  return !universe ? <img src={UniverseIcon} alt="" style={{
    height: noUniverse ? "88px" : "80px", width: noUniverse ? "88px" : "80px",
    opacity: 1,
    marginLeft: marginLeft
  }}/> :
    <Card sx={{
      display: "flex", alignItems: "center", justifyContent: "center", ml: marginLeft,
      width: "80px", height: "80px", boxShadow: 0, borderWidth: "4px", borderColor: "ornament.light", borderRadius: "17px"
    }}>
      {/* @ts-ignore Toutes les images provenant d'Odoo sont stockées dans l'enum */}
      {<img src={UNIVERSES_IMAGES[universe]} alt={UNIVERSES_IMAGES[universe]} style={{margin: "auto", height: "100%", objectFit: "cover"}}/>}
    </Card>
}

export default function ShopCard(props: OperationProps): JSX.Element {
  const {t} = useTranslation();
  const shop = props.operation.shops[0];

  return (
    <>
      <Box sx={{display: "flex", mb: 2, alignItems: "center", justifyContent: "space-around"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <ShopPreview noUniverse={shop.preview.length == 0} universe={shop.preview[0]}/>
          <ShopPreview noUniverse={shop.preview.length == 0} marginLeft={LibertyTheme.spacing(-4)} universe={shop.preview[1]}/>
          <ShopPreview noUniverse={shop.preview.length == 0} marginLeft={LibertyTheme.spacing(-4)} universe={shop.preview[2]}/>
        </Box>
        <Box sx={{textAlign: "center", px: 2}}>
          <Typography color="neutral.main" sx={{fontWeight: "bold"}} variant="body1">
            {shop.giftCountAdmin}
          </Typography>
          <Typography color="neutral.main" variant="body1">
            {translate(t, "shop.selected_gifts")}
          </Typography>
        </Box>
      </Box>
      <Typography variant="body2" sx={{fontWeight: "medium", pt: 0}}>
        {translate(t, "shop.selected_universes", {nb_universes: shop.selectedUniversIds.length})}
      </Typography>
    </>
  )
}
