import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Card, Typography} from "@mui/material";
import {AppContext} from "../../App";
import {errorManager, Violation} from "../../common/methods/ApiService";
import {StoreContext} from "../../common/struct/store";
import ElementInputWithLabel from "../../patterns/form/elementInputWithLabel";
import {INPUT_TYPES} from "../../patterns/form/formConstants";
import WysiwygEditor from "../../patterns/wysiwyg/wysiwygEditor";
import {TEmailTemplate, TEmailTemplateType} from "../../interfaces/email";
import {createEmailTemplate, getOperationEmailTemplateByType, updateEmailTemplate} from "../../services/AdminService";
import {getOperation} from "../../services/OperationService";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import {Loader} from "../../components/loader/loader";
import PageForm from "../../patterns/page/pageForm";
import {getAllParticipantAttributes} from "../../services/ParticipantService";

export default function OperationMailingCustomisation(props: {setError: (error: string) => void, setSuccess: (success: string) => void}): JSX.Element {
  const {t} = useTranslation();
  const {operationId} = useParams<string>();
  const {setError, setSuccess} = props;
  useEffect(() => {
    document.title = t("tab_title.operation.mailingCustomisation");
  }, []);
  const STORE = useContext<StoreContext>(AppContext);
  const editorRef = useRef<any|null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [violations, setViolations] = useState<Violation[]>([]);

  const [template, setTemplate] = useState<TEmailTemplate>();
  const [loadingAttributes, setLoadingAttributes] = useState<boolean>(true);
  const [subject, setSubject] = useState<string|undefined>(undefined);
  const [content, setContent] = useState<string|null>(null);
  const [buttonLabel, setButtonLabel] = useState<string|undefined>(undefined);

  const navigate = useNavigate();

  const [editorMenus, setEditorMenus] = useState([{
    name: 'variables',
    label: t('operations.mailing.customisation.wysiwyg.title'),
    items: [{
      name: 'participantFirstName',
      label: t('operations.mailing.customisation.wysiwyg.participant_firstname'),
      action: () => editorRef.current.insertContent("{{user.firstName}}")
    },
    {
      name: 'participantLastName',
      label: t('operations.mailing.customisation.wysiwyg.participant_lastname'),
      action: () => editorRef.current.insertContent("{{user.lastName}}")
    },
    {
      name: 'civility',
      label: t('operations.mailing.customisation.wysiwyg.civility'),
      action: () => editorRef.current.insertContent("{{user.civility}}")
    },
    {
      name: 'point',
      label: t('operations.mailing.customisation.wysiwyg.points'),
      action: () => editorRef.current.insertContent("{{user.points}}")
    }]
  }]);

  const loadTemplate = (): Promise<TEmailTemplate> => {
    return getOperationEmailTemplateByType(operationId!, TEmailTemplateType.PARTICIPANT_INVITATION)
      .then(template => {
        setSubject(template?.subject??undefined);
        setContent(template?.extraVariables?.content ?? null);
        setButtonLabel(template?.extraVariables?.buttonLabel ?? null);

        setTemplate(template)
        return template;
      })
  }

  const submit = (): void => {
    if (operationId == null) {
      return;
    }

    const invitationTemplate = {
      templateType: template!.templateType,
      mailjetId: template!.mailjetId,
      operation: 'bo/operations/' + operationId,
      subject: subject === "" ? null : subject,
      extraVariables: {
        content: editorRef.current.getContent() === "" ? null : editorRef.current.getContent(),
        buttonLabel: buttonLabel === "" ? null : buttonLabel
      }
    };

    setLoading(true);
    setViolations([]);
    setContent(editorRef.current.getContent());
    (template?.operation ? updateEmailTemplate(template.id, invitationTemplate) : createEmailTemplate(invitationTemplate))
      .then(template => {
        setTemplate(template);
        setSuccess(t("operations.mailing.customisation.success"))
        return getOperation(operationId)
      })
      .then(operation => globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation}))
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
        else setViolations(errorResult);
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    loadTemplate();
    getAllParticipantAttributes().then((datas) => {
      datas.map((data) => {
        setEditorMenus(prevState =>
          prevState.map((prevItem) => {
            return {...prevItem, items: [...prevItem.items, {
              name: data.label,
              label: data.label,
              action: () => editorRef.current.insertContent("{{user.attributes."+data.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()+"}}")
            }]};
          })
        )
      })
    }).finally(() => setLoadingAttributes(false))
  }, [])

  return (template == null || loadingAttributes) ? <Loader height="calc(100vh - 229px)" /> :
    <PageForm submit={submit} loading={loading}>
      <Card sx={{boxShadow: 1, borderColor: "ornament.dark", p: 5, mb: 5}}>
        <Typography variant="body2" sx={{mb: "4px", color: "neutral.main"}}>
          <strong>{t("operations.mailing.customisation.object_title")}</strong>
          {t("operations.mailing.customisation.object_helper")}
        </Typography>
        <ElementInputWithLabel
          name="subject"
          value={subject}
          placeholder={t("operations.mailing.customisation.object_placeholder")}
          type={INPUT_TYPES.DEFAULT}
          violations={violations}
          updateValue={setSubject}
          disabled={loading}
        />
        <Box sx={{mb: 3, width: "100%"}}>
          <Typography variant="body2" sx={{mb: "4px", color: "neutral.main"}}>
            <strong>{t("operations.mailing.customisation.content_title")}</strong>
            {t("operations.mailing.customisation.content_helper")}
          </Typography>
          <WysiwygEditor editorRef={editorRef} operationId={operationId!} initialValue={content} setError={setError} name={"content"} violations={violations} height={300} customMenus={editorMenus}/>
        </Box>
        <Typography variant="body2" sx={{mb: "4px", color: "neutral.main"}}>
          <strong>{t("operations.mailing.customisation.button_label_title")}</strong>
          {t("operations.mailing.customisation.button_label_helper")}
        </Typography>
        <ElementInputWithLabel
          name="buttonLabel"
          value={buttonLabel}
          placeholder={t("operations.mailing.customisation.button_label_placeholder")}
          type={INPUT_TYPES.DEFAULT}
          violations={violations}
          updateValue={setButtonLabel}
          disabled={loading}
        />
        {/** <Box sx={{display: "flex", width: "40%", ml: 5}}>
          <Card sx={{display: "flex", flexDirection: "column", width: "100%", height: "auto", px: 5, py: 3, backgroundColor: "infoLight.light"}}>
            <Typography variant="body2" sx={{fontWeight: "bold", mb: "4px", color: "neutral.main"}}>{t("operations.mailing.customisation.content")}</Typography>
            <Box sx={{width: "100%", height: "100%", backgroundColor: "ornament.light"}}>
              TODO
            </Box>
          </Card>
        </Box> */}
      </Card>
    </PageForm>
}
