import {getPaginated, RequestFilter, RequestOrder} from "../common/methods/ApiService";
import {API_URL} from "../common/struct/urlManager";
import {TWalletTransaction} from "../interfaces/wallet";

export async function getCompanyWalletTransactions(companyId: string, page: number, itemsPerPage: number, search: string|null, order: RequestOrder|null, filters: RequestFilter[]): Promise<{totalItems: number, items: TWalletTransaction[]}> {
  return getPaginated(`${API_URL.COMPANY}/${companyId}/transactions-paid`, page, itemsPerPage, search, order, filters);
}

export async function getCompanyWalletTransactionsCount(companyId: string, filters: RequestFilter[]): Promise<{totalItems: number, items: TWalletTransaction[]}> {
  return getPaginated(`${API_URL.COMPANY}/${companyId}/transactions-paid`, 1, 0, null, null, filters);
}