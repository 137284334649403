import React, {useContext, useEffect, useState} from 'react';
import {Box, Menu, Typography} from "@mui/material";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import {useTranslation} from "react-i18next";
import {manageStringError, RequestFilter, RequestOrder} from "../../common/methods/ApiService";
import {getExportGifts} from "../../services/ImportService";
import moment from "moment";
import {AppContext} from "../../App";
import {useParams} from "react-router-dom";
import {TOperation} from "../../interfaces/operation";
import {StoreContext} from "../../common/struct/store";
import List from "../../patterns/list/list";
import {TAlert} from "../../patterns/list/listSnackbar";
import {addErrorAlert, columnDefinition, ColumnType} from "../../patterns/list/listUtils";
import {GridFilterListIcon, GridFilterModel, useGridApiRef} from "@mui/x-data-grid-pro";
import {PrimarySmallButton} from "../../components/buttons/mainButton";
import {IconPosition} from "../../components/buttons/buttonProps";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {getGiftsHistory} from "../../services/OperationService";
import emptyTransactions from "../../assets/lists/empty_transactions.svg";
import Page from "../../patterns/page/page";
import ListMenuItem from '../../patterns/list/listMenuItem';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {QuickFilterType} from "../../interfaces/quickFilter";
import {getExportDocument} from "../../common/methods/exportDocument";
import Footer from "../../patterns/footer/footer";

export default function OperationGiftsHistory(): JSX.Element {
  const {t} = useTranslation();
  const {operationId} = useParams<string>();
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  useEffect(() => {
    document.title = t("tab_title.operation.giftHistory");
  }, []);
  const apiRef = useGridApiRef();
  const [operation, setOperation] = useState<TOperation|undefined>(undefined);
  const [alerts, setAlerts] = useState<TAlert[]>([]);

  const [search, setSearch] = useState<string>("");
  const [order, setOrder] = useState<RequestOrder>({field: "", sort: ""});
  const [filters, setFilters] = useState<RequestFilter[]>([]);

  const [quickFilters, setQuickFilters] = useState<GridFilterModel>({items: []});
  const [quickFilterSelected, setQuickFilterSelected] = useState<QuickFilterType|null>(null);

  const [actionsAnchorEl, setActionsAnchorEl] = useState<undefined|HTMLElement>(undefined);

  const handleActionsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = (): void => {
    setActionsAnchorEl(undefined);
  };

  useEffect(() => {
    setOperation(store.global.currentOperation);
  }, [store])

  const loadGifts = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: any[]}> => {
    setSearch(search);
    setOrder(order);
    setFilters(filters);

    return getGiftsHistory(operationId!, page, maxItems, search, order, filters);
  }

  const updateFilters = (dateStart: string, dateEnd: string, filterSelected: QuickFilterType): void => {    
    if (filterSelected === quickFilterSelected) {
      setQuickFilters({items: []});
      setQuickFilterSelected(null);
    } else {
      setQuickFilters({items: [{columnField: "createdAt", operatorValue: "after", value: dateStart}, {columnField: "createdAt", operatorValue: "before", value: dateEnd}]})
      setQuickFilterSelected(filterSelected);
    }
  }

  const exportAllGifts = (): void => {
    if (operation == null) {
      return;
    }

    getExportGifts(operation.id, search, order, filters)
      .then(response => {
        getExportDocument(response)
      })
      .catch((error) => manageStringError(error, error => addErrorAlert(error, setAlerts), t));
  }

  const exportAction = <PrimarySmallButton key="export-button" label={t("operation_gifts_history.actions.export")} action={exportAllGifts}
    icon={FileDownloadIcon} position={IconPosition.LEFT} sx={{mr: 1}}/>
  const quickFilterAction = <Box>
    <PrimarySmallButton label={t("operation_gifts_history.actions.quick_filter")} action={handleActionsClick}
      icon={GridFilterListIcon} position={IconPosition.LEFT} sx={{mr: 1}}/>
    <Menu id="actions-menu" open={actionsAnchorEl != null} onClose={handleActionsClose} anchorEl={actionsAnchorEl}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}} transformOrigin={{vertical: "top", horizontal: "right"}}
      sx={{"& .MuiPaper-root": {mt: 0, border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", boxShadow: 3}}}>
      <ListMenuItem label={t("operation_participants.actions.quick.last_week")} icon={quickFilterSelected === QuickFilterType.LASTWEEK ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'), moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'), QuickFilterType.LASTWEEK) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.actual_month")} icon={quickFilterSelected === QuickFilterType.ACTUALMONTH ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().startOf('month').format('YYYY-MM-DD'), moment().endOf("month").format('YYYY-MM-DD'), QuickFilterType.ACTUALMONTH) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.last_month")} icon={quickFilterSelected === QuickFilterType.LASTMONTH ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'), QuickFilterType.LASTMONTH) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.last_quarter")} icon={quickFilterSelected === QuickFilterType.LASTQUARTER ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().subtract(1, 'Q').startOf('Q').format('YYYY-MM-DD'), moment().subtract(1, 'Q').endOf('Q').format('YYYY-MM-DD'), QuickFilterType.LASTQUARTER) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.last_year")} icon={quickFilterSelected === QuickFilterType.LASTYEAR ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'), moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'), QuickFilterType.LASTYEAR) }}/>
      <ListMenuItem label={t("operation_participants.actions.quick.actual_year")} icon={quickFilterSelected === QuickFilterType.CURRENTYEAR ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(moment().startOf('year').format('YYYY-MM-DD'), moment().add(1, 'day').startOf("day").format('YYYY-MM-DD'), QuickFilterType.CURRENTYEAR) }}/>
    </Menu>
  </Box>

  const columns = [
    columnDefinition(t, "operation_gifts_history", "createdAt", 150, ColumnType.DATE_TIME),
    columnDefinition(t, "operation_gifts_history", "odooOrderName", 150, ColumnType.STRING, true, true),
    columnDefinition(t, "operation_gifts_history", "reference", 100),
    columnDefinition(t, "operation_gifts_history", "name", 600),
    columnDefinition(t, "operation_gifts_history", "quantity", 100, ColumnType.NUMBER),
    columnDefinition(t, "operation_gifts_history", "rawPrice", 100, ColumnType.EUROS),
    columnDefinition(t, "operation_gifts_history", "netPrice", 100, ColumnType.EUROS)
  ]

  return (
    <Page title={t("operation_gifts_history.title")} backLink={operationUrl(PRIVATE_URL.OPERATION_DASHBOARD, operationId!)}>
      <Box sx={{px: 8, pb: 3, display: "flex", flexDirection: "column", flex: 1}}>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mt: 6, mb: 5}}>
          <Typography color="neutral.main" sx={{fontWeight: "regular"}} variant="body2">{t("operation_gifts_history.subtitle")}</Typography>
        </Box>
        {operation == null ? <></> :
          <List
            translationPrefix="operation_gifts_history"
            alerts={alerts}
            setAlerts={setAlerts}
            columns={columns}
            apiRef={apiRef}
            loadRows={loadGifts}
            height="100%"
            emptyListIcon={emptyTransactions}
            globalActions={[exportAction, quickFilterAction]}
            filters={quickFilters}
            setQuickFilterSelected={setQuickFilterSelected}
            sx={{width: "100%"}}
          />}
      </Box>
      <Footer/>
    </Page>
  )
}
