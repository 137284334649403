import React, {useContext, useEffect, useState} from "react";

import {Autocomplete, Box, Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {getBrands} from "../../services/ShopService";
import {trackPromise} from "react-promise-tracker";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {translate} from "./operationShopCriteria";
import {AutocompleteRenderInputParams} from "@mui/material/Autocomplete/Autocomplete";
import {TOperation} from "../../interfaces/operation";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {useNavigate} from "react-router-dom";

type ExcludedBrandsProps = {
  operation: TOperation,
  setError: (error: string) => void,
  setExcludedBrands: (excludeBrands: string[]) => void,
  loading?: boolean
}

export const ExcludedBrandsCardChildren = (props: ExcludedBrandsProps): JSX.Element => {
  const {operation, setError, setExcludedBrands, loading} = props;
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);

  const [allBrands, setAllBrands] = useState<string[]>([]);
  const [localExcludeBrands, setLocalExcludeBrands] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    trackPromise(
      getBrands()
        .then(brands => setAllBrands(brands))
        .catch(error => manageStringError(errorManager(error, t, STORE, navigate), setError, t))
    )
  }, []);

  useEffect(() => {
    if (operation) {
      setExcludedBrands!(operation.shops[0].excludedBrands);
      setLocalExcludeBrands(operation.shops[0].excludedBrands);
    }
  }, [operation])

  const onBrandChange = (excludeBrands: string[]) : void => {
    setExcludedBrands!(excludeBrands);
    setLocalExcludeBrands(excludeBrands);
  };

  return (
    <Box sx={{display:'flex', flexDirection: 'column', borderBottom: "1px solid", borderColor: "ornament.dark", p: 5}}>
      <Typography color="neutral.dark" sx={{fontWeight: "bold", mb: 3}} variant="body1">{translate(t, "brands_cards.title")}</Typography>
      <Autocomplete
        multiple
        options={allBrands}
        getOptionLabel={(option:string) : string => option}
        value={localExcludeBrands}
        onChange={(_, excludeBrands: string[]) : void => {
          onBrandChange(excludeBrands);
        }}
        disabled={loading}
        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={translate(t, "brands_cards.placeHolder")}
          />
        )}
      />
    </Box>
  )
}

type SpecificCriteriaProps = {
  setOnlyMadeInFrance: (isSelected: boolean) => void,
  setOnlyEthic: (isSelected: boolean) => void,
  setWithoutAlcohol: (isSelected: boolean) => void,
  onlyMadeInFrance: boolean,
  onlyEthic: boolean,
  withoutAlcohol: boolean,
  loading?: boolean,
}

export const SpecificCriteriaCardChildren = (props: SpecificCriteriaProps): JSX.Element => {
  const {setOnlyMadeInFrance, setOnlyEthic, setWithoutAlcohol, onlyMadeInFrance, onlyEthic, withoutAlcohol, loading} = props;
  const {t} = useTranslation();

  return (
    <Box sx={{display:'flex', flexDirection:'column', p: 5}}>
      <Typography color="neutral.dark" sx={{fontWeight: "bold", mb: 3}} variant="body1">{translate(t, "criteria_cards.title")}</Typography>
      <Box sx={{display:'flex', alignItems:'center'}}>
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'left', mr: 5}}>
          <FormControlLabel
            name="made_in_france"
            sx={{m: "unset", mb: 3}}
            disabled={loading}
            control={<Checkbox checked={onlyMadeInFrance} sx={{color: "primary.main", width: "24px", height: "24px", "& .MuiSvgIcon-root": {fontSize: "24px"}}} onClick={(): void => setOnlyMadeInFrance(!onlyMadeInFrance)}/>}
            label={<Typography variant="body1" display="inline" sx={{color: onlyMadeInFrance ? "neutral.dark" : "neutral.main", fontWeight: onlyMadeInFrance ? "bold" : "regular", mx: 0}}>{translate(t, "criteria_cards.made_in_france")}</Typography>}
          />
          <FormControlLabel
            name="made_ethics"
            sx={{m: "unset", mb: 3}}
            disabled={loading}
            control={<Checkbox checked={onlyEthic} sx={{color: "primary.main", width: "24px", height: "24px", "& .MuiSvgIcon-root": {fontSize: "24px"}}} onClick={(): void => setOnlyEthic(!onlyEthic)}/>}
            label={<Typography variant="body1" display="inline" sx={{color: onlyEthic ? "neutral.dark" : "neutral.main", fontWeight: onlyEthic ? "bold" : "regular", mx: 0}}>{translate(t, "criteria_cards.made_ethics")}</Typography>}
          />
          <FormControlLabel
            name="without_alcool"
            sx={{m: "unset", mb: 3}}
            disabled={loading}
            control={<Checkbox checked={withoutAlcohol} sx={{color: "primary.main", width: "24px", height: "24px", "& .MuiSvgIcon-root": {fontSize: "24px"}}} onClick={(): void => setWithoutAlcohol(!withoutAlcohol)}/>}
            label={<Typography variant="body1" display="inline" sx={{color: withoutAlcohol ? "neutral.dark" : "neutral.main", fontWeight: withoutAlcohol ? "bold" : "regular", mx: 0}}>{translate(t, "criteria_cards.without_alcool")}</Typography>}
          />
        </Box>
      </Box>
    </Box>
  )
}
