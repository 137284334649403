import {WizardSize} from "./wizardProps";
import React from "react";
import {Box} from "@mui/material";

interface WizardHelperProps {
  children: JSX.Element|JSX.Element[];
  size: WizardSize;
}

export default function WizardHelper(props: WizardHelperProps): JSX.Element {
  const {children, size} = props;

  const contentWidth = (): {width: string, maxWidth: string} => {
    switch (size) {
    case WizardSize.FIFTY_FIFTY:
      return {width: "50%", maxWidth: "650px"};
    case WizardSize.SIXTY_FORTY:
      return {width: "40%", maxWidth: "520px"};
    case WizardSize.SEVENTY_THIRTY:
      return {width: "30%", maxWidth: "390px"};
    case WizardSize.FULL:
      return {width: "0%", maxWidth: "0px"};
    }
  }

  const widths = contentWidth();
  return (
    <Box sx={{overflow: "auto", height: "auto", minHeight: "calc(100vh - 181px)", boxSizing: "border-box", py: 11, px: 7,
      width: widths.width, backgroundColor: "ornament.main", display: "flex", alignItems: "center", justifyContent: "center"}}>
      <Box sx={{maxWidth: widths.maxWidth, boxSizing: "border-box"}}>
        {children}
      </Box>
    </Box>
  )
}
