import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {getCompanies} from "../../services/CompanyService";
import {getSelectedCompany, isSuperAdmin, setSelectedCompanyChoice} from "../../common/struct/globalVar";
import {getAllOperations, getAllOperationsForAdmin} from "../../services/OperationService";
import {Autocomplete, Card, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {errorManager} from "../../common/methods/ApiService";
import {AppContext} from "../../App";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {ADD_OPERATIONS} from "../../common/methods/context-setter/globals";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {StoreContext} from "../../common/struct/store";

export interface OperationReference extends SelectChoice {
  operationName: string;
  companyId: string;
  reference?: string;
  name?: string;
}

export interface SelectChoice {
  id: string;
  label: string;
}

export default function SelectCompany(): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [companies, setCompanies] = useState<SelectChoice[]>([]);
  const [operationNames, setOperationNames] = useState<OperationReference[]>([]);
  const [operationReferences, setOperationReferences] = useState<OperationReference[]>([]);
  const [company, setCompany] = useState<SelectChoice|null>(null);
  const [companyError, setCompanyError] = useState<string|null>(null);
  const [operationName, setOperationName] = useState<OperationReference|null>(null);
  const [operationReference, setOperationReference] = useState<OperationReference|null>(null);
  const STORE = useContext<StoreContext>(AppContext);

  const initCompanies = (): Promise<void> => {
    return getCompanies().then(data => setCompanies(data.map(company => ({id: company.id, label: company.name}))));
  }

  const initOperations = (): Promise<void> => {
    return getAllOperationsForAdmin().then(data => {
      setOperationNames(data.map(operation => ({
        id: operation.id,
        label: operation.hostname + ' (' + operation.name + ')',
        operationName: operation.name,
        companyId: operation.company.id,
        reference: operation.reference
      })));
      setOperationReferences(data.map(operation => ({
        id: operation.id,
        label: operation.reference,
        operationName: operation.name,
        companyId: operation.company.id,
        name: operation.name + ' (' + operation.id + ')'
      })));
    });
  }

  useEffect(() => {
    if (!isSuperAdmin()) {
      navigate(PRIVATE_URL.LANDING_PAGE, {replace: true});
    }
    initCompanies().then(() => initOperations());
  }, []);

  useEffect(() => {
    setCompany(companies.find(company => company.id === getSelectedCompany())??null);
  }, [companies]);

  useEffect(() => {
    if (company === undefined) {
      setCompanyError(t('select_company.error'));
    } else {
      setCompanyError(null);
    }
  }, [company]);

  const selectCompany = (): void => {
    if (company) {
      setSelectedCompanyChoice(company);
      getAllOperations()
        .then(operations => {
          globalStoreReducer(STORE, {type: ADD_OPERATIONS, operations: operations});
          navigate(PRIVATE_URL.LANDING_PAGE);
        })
        .catch(e => errorManager(e, t, STORE, navigate))
    }
  }
  const buttonLabel = (): string => {
    if (company == null) {
      return t('select_company.confirm');
    }
    return t('select_company.confirm') + t('select_company.confirm_suffix') + company.label;
  }

  const clearSelects = (): void => {
    setOperationReference(null);
    setOperationName(null);
    setCompany(null);
  }

  return (
    <Card sx={{m: 7, p: 5}}>
      <Typography variant="body1" sx={{fontWeight: "bold", mb: 2}}>{t('select_company.description')}</Typography>
      <Typography variant="body2" color="neutral.main" sx={{fontWeight: "medium"}}>{t('select_company.description_company')}</Typography>
      <Autocomplete
        key={"select-company"}
        id={"select-company"}
        options={companies}
        aria-label={t('select_company.company')}
        autoHighlight
        value={company}
        onChange={(e: any, newValue: any): any => {
          if (newValue === null) {
            clearSelects();
          } else {
            setCompany(companies.find(company => company.id === newValue.id)??null);
            setOperationName(null);
            setOperationReference(null);
          }
        }}
        renderInput={(params: any): JSX.Element =>
          <TextField {...params} error={companyError} helperText={companyError}
            sx={{mt: 2, "& input": {backgroundColor: "ornament.light", p: 3}, "& legend": {pr: company ? 4 : "0px"}}}
            label={t('select_company.company')} required={true}/>
        }
      />
      <Typography variant="body2" color="neutral.main" sx={{mt: 6, fontWeight: "medium"}}>{t('select_company.description_operation')}</Typography>
      <Autocomplete
        key={"select-operation-name"}
        id={"select-operation-name"}
        options={operationNames}
        aria-label={t('select_company.operation_name')}
        autoHighlight
        value={operationName}
        isOptionEqualToValue={(option: any, value: any): boolean => option.id === value.id}
        onChange={(e: any, newValue: any): any => {
          if (newValue === null) {
            clearSelects()
          } else {
            setOperationName(newValue);
            setOperationReference({
              id: newValue.id,
              label: newValue.reference,
              companyId: newValue.companyId,
              name: newValue.name,
              operationName: newValue.operationName
            });
            setCompany(companies.find(company => company.id === newValue.companyId)??null);
          }
        }}
        renderInput={(params: any): JSX.Element =>
          <TextField {...params} label={t('select_company.operation_name')}
            sx={{mt: 2, "& input": {backgroundColor: "ornament.light", p: 3}, "& legend": {pr: operationName ? 6 : "0px"}}}/>
        }
      />
      <Typography variant="body2" color="neutral.main" sx={{fontWeight: "medium", mt: 2}}>{t('select_company.description_operation_reference')}</Typography>
      <Autocomplete
        key={"select-operation-reference"}
        id={"select-operation-reference"}
        options={operationReferences}
        aria-label={t('select_company.operation_reference')}
        autoHighlight
        value={operationReference}
        isOptionEqualToValue={(option: any, value: any): boolean => option.id === value.id}
        onChange={(e: any, newValue: any): any => {
          if (newValue === null) {
            clearSelects()
          } else {
            setOperationReference(newValue);
            setOperationName({
              id: newValue.id,
              label: newValue.name,
              companyId: newValue.companyId,
              reference: newValue.reference,
              operationName: newValue.operationName
            });
            setCompany(companies.find(company => company.id === newValue.companyId)??null);
          }
        }}
        renderInput={(params: any): JSX.Element =>
          <TextField {...params} label={t('select_company.operation_reference')}
            sx={{mt: 2, "& input": {backgroundColor: "ornament.light", p: 3}, "& legend": {pr: operationReference ? 6 : "0px"}}}/>
        }
      />
      <PrimaryBigButton sx={{mt: 5}} label={buttonLabel()} action={selectCompany} disabled={company == null}/>
    </Card>
  );
}
