import React from "react";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

export default function GoogleAnalyticsTracker(): JSX.Element {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location, initialized]);

  return <></>;
}
