import {postExternalWithUrlEncoded, postWithoutToken} from "../common/methods/ApiService";
import {API_URL, HCAPTCHA_VERIFY} from "../common/struct/urlManager";
import jwt from "jwt-decode";
import {getToken} from "../common/struct/globalVar";

export type TokenResponse = {
  token: string
}

export type Token = {
  /** Correspond à l'ID de l'utilisateur */
  username: string,
  roles: string[],
  mustChangePassword?: boolean,
  refreshToken: string
}

export async function login(email: string, password: string, stayConnected: boolean): Promise<string> {
  return postWithoutToken(API_URL.LOGIN, {email: email, password: password}).then((auth: TokenResponse) => {
    const authJSON = JSON.stringify(auth.token);
    const jwtToken = jwt<Token>(auth.token);

    localStorage.setItem('auth', authJSON);
    localStorage.setItem('id', jwtToken.username);
    if (stayConnected) localStorage.setItem('stayConnected', stayConnected.toString());
    return auth.token;
  });
}

export function logout(): void {
  localStorage.removeItem('auth');
  localStorage.removeItem('id');
  localStorage.removeItem('companyId');
  localStorage.removeItem('companyName');
  localStorage.removeItem('operationId');
  localStorage.removeItem('stayConnected');
}

export async function refreshToken(): Promise<string> {
  const token = getToken();
  return postWithoutToken(API_URL.REFRESH_TOKEN, {refreshToken: jwt<Token>(token).refreshToken}).then((auth: TokenResponse) => {
    const authJSON = JSON.stringify(auth.token);
    const jwtToken = jwt<Token>(auth.token);

    localStorage.setItem('auth', authJSON);
    localStorage.setItem('id', jwtToken.username);
    return auth.token;
  });
}

export async function verifyCaptcha(response: string): Promise<void> {
  return postExternalWithUrlEncoded(HCAPTCHA_VERIFY, {response: response, secret: process.env.REACT_APP_HCAPTCHA_SECRET});
}

export function resetPassword(email: string): Promise<string> {
  return postWithoutToken(API_URL.RESET_PASSWORD, {email: email});
}

export function loginWithToken(token: string): Promise<string> {
  return postWithoutToken(API_URL.LOGIN_WITH_TOKEN,{token}).then((auth: TokenResponse) => {
    const authJSON = JSON.stringify(auth.token);
    const jwtToken = jwt<Token>(auth.token);

    localStorage.setItem('auth', authJSON);
    localStorage.setItem('id', jwtToken.username);
    return jwtToken.username;
  });
}

export async function saveCookieConsent(): Promise<void> {
  return postWithoutToken(API_URL.COOKIES, {})
}
