import {Box, LinearProgress, Typography} from "@mui/material";
import LogoJoozy from "../../assets/logoJoozy.svg";
import React from "react";
import {useTranslation} from "react-i18next";
import {PrimarySmallButton} from "../../components/buttons/mainButton";

interface WizardHeaderProps {
  currentStep: number;
  maxSteps: number;
}

export default function WizardHeader(props: WizardHeaderProps): JSX.Element {
  const {t} = useTranslation();
  const {currentStep, maxSteps} = props;
  const progress = currentStep * 100 / maxSteps;

  const openMailto = (): any => {
    return window.open('mailto:' + process.env.REACT_APP_ADMIN_EMAIL)
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", position: "sticky", top: 0}}>
      <LinearProgress variant="determinate" value={progress} sx={{width: "100%", backgroundColor: "secondary.light", "& .MuiLinearProgress-bar": {backgroundColor: "primary.light"}}}/>
      <Box sx={{width: "100%", boxSizing: "border-box", display: "flex", justifyContent: "space-between", backgroundColor: "secondary.dark", py: 3, px: 7}}>
        {/** @ts-ignore */}
        <img src={LogoJoozy} alt="" style={{width: "110px"}}/>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography variant="body2" color="secondaryLight.light" sx={{fontWeight: "medium", height: "fit-content", mr: 4}}>
            {t("wizard.help_text")}
          </Typography>
          <PrimarySmallButton key="help_button" label={t("wizard.help_button")} sx={{mr: 1}} action={(): void => openMailto()}/>
        </Box>
      </Box>
    </Box>
  )
}
