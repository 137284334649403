import React, {useEffect, useState} from "react";
import {Alert, Box, Dialog, FormControlLabel, IconButton, Switch, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ClearIcon from '@mui/icons-material/Clear';
import ListSearch from "./listSearch";
import {GridColumnVisibilityModel} from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import {GridColumns} from "@mui/x-data-grid/models/colDef/gridColDef";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";

interface ListColumnsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  columns: GridColumns;
  visibilityModel: GridColumnVisibilityModel;
  setVisibilityModel: (model: GridColumnVisibilityModel) => void;
}

function ColumnTriggerVisibility(props: {field: string, label: string, checked: boolean, updateVisibility: (field: string) => void}): JSX.Element {
  const {field, label, checked, updateVisibility} = props;

  return (
    <FormControlLabel onChange={(): void => updateVisibility(field)} control={<Switch defaultChecked={checked}/>}
      label={<Typography variant="body2" color="neutral.dark">{label}</Typography>} sx={{m: "unset"}}/>
  )
}

export default function ListColumns(props: ListColumnsProps): JSX.Element {
  const {open, setOpen, columns, visibilityModel, setVisibilityModel} = props;
  const {t} = useTranslation();
  const initialColumns = columns.filter(column => column.headerName && column.headerName.length > 0 && column.cellClassName !== "MuiDataGrid-cellCheckbox");
  const [effectiveColumns, setEffectiveColumns] = useState<GridColumns>([]);
  const [localeVisibilityModel, setLocaleVisibilityModel] = useState(structuredClone(visibilityModel));
  const [search, setSearch] = useState("");
  const [disableApply, setDisableApply] = useState(false);

  useEffect(() => {
    if (search.length == 0) setEffectiveColumns(initialColumns);
    else setEffectiveColumns(initialColumns.filter(column => column.headerName && column.headerName.toLowerCase().includes(search.toLowerCase())))
  }, [search])

  const visibilityModelIsEmpty = (): boolean => {
    if (effectiveColumns.length > Object.keys(localeVisibilityModel).length) {
      return false;
    }
    return Object.values(localeVisibilityModel).find(value => value) == undefined;
  }

  const closeDialog = (): void => setOpen(false)

  const updateVisibility = (field: string): void => {
    localeVisibilityModel[field] = localeVisibilityModel[field] == undefined ? false : !localeVisibilityModel[field];
    setLocaleVisibilityModel(localeVisibilityModel);
    setDisableApply(visibilityModelIsEmpty());
  }

  const applyModel = (): void => {
    setVisibilityModel(localeVisibilityModel);
    closeDialog();
  }

  const isChecked = (field: string): boolean => visibilityModel[field] == undefined ? true : visibilityModel[field]
  return (
    <Dialog open={open} sx={{"& .MuiDialog-paper": {width: 600}}}>
      <Box sx={{display: "flex", flexDirection: "column", p: 5}}>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mb: 3}}>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {t("list.columns.title")}
          </Typography>
          <IconButton onClick={closeDialog}>
            <ClearIcon sx={{width: 24, height: 24, color: "neutral.main"}}/>
          </IconButton>
        </Box>
        <Typography variant="body2" color="neutral.main" sx={{mb: 0}}>
          {t("list.columns.search")}
        </Typography>
        <ListSearch sx={{mb: 3, width: "100%"}} search={search} setSearch={setSearch} placeholder={t("list.columns.search_placeholder")}/>
        <Box sx={{display: "flex", flexDirection: "column", maxHeight: 350, overflowY: "auto"}}>
          {effectiveColumns.map(column => <ColumnTriggerVisibility
            key={column.field}
            field={column.field}
            label={column.headerName??""}
            checked={isChecked(column.field)}
            updateVisibility={updateVisibility}/>)}
        </Box>
        {disableApply && <Alert sx={{mt: 3}} variant="filled" severity="error">{t("list.columns.alert_empty")}</Alert>}
      </Box>
      <Box sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark", display: "flex", justifyContent: "flex-end"}}>
        <SecondaryBigButton label={t("list.columns.cancel")} action={closeDialog}/>
        <PrimaryBigButton disabled={disableApply} label={t("list.columns.apply")} sx={{ml: 3}} action={applyModel}/>
      </Box>
    </Dialog>
  )
}
