import {TLifecycle} from "./traits";

export interface TGiftOrderLine extends TLifecycle {
  pointsTotal: number;
  status: TGiftOrderLineStatus;
}

export enum TGiftOrderLineStatus {
  ON_GOING = 'ONGOING',
  VALIDATED = 'VALIDATED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}
