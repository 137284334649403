import {Box, Chip, Typography} from "@mui/material";
import BackLink from "../../patterns/navigation/backLink";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {TEmailTemplate, TEmailTemplateType} from "../../interfaces/email";
import emptyTransactions from "../../assets/lists/empty_transactions.svg";
import List from "../../patterns/list/list";
import {TAlert} from "../../patterns/list/listSnackbar";
import {useNavigate} from "react-router-dom";
import {GridValueGetterParams, useGridApiRef} from "@mui/x-data-grid-pro";
import {
  actionColumn,
  addErrorAlert,
  addSuccessAlert,
  columnDefinition,
  ColumnType,
  enumFilter,
  gridAction,
  valueGetter
} from "../../patterns/list/listUtils";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {deleteEmailTemplate, getEmailTemplates} from "../../services/AdminService";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {PrimarySmallButton} from "../../components/buttons/mainButton";
import {IconPosition} from "../../components/buttons/buttonProps";
import AddIcon from "@mui/icons-material/Add";
import DeleteDialog from "../../patterns/dialog/deleteDialog";
import {manageStringError} from "../../common/methods/ApiService";

export default function EmailTemplates(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [reload, setReload] = useState(false);
  const [template, setTemplate] = useState<TEmailTemplate|null>(null);
  const [openDeleteTemplate, setOpenDeleteTemplate] = useState(false);
  const closeDeleteTemplate = (): void => setOpenDeleteTemplate(false)

  const updateTemplate = (template: TEmailTemplate): void => {
    navigate(PRIVATE_URL.UPDATE_EMAIL_TEMPLATE.replace(":id", template.id))
  }
  const deleteTemplate = (template: TEmailTemplate): void => {
    setTemplate(template);
    setOpenDeleteTemplate(true);
  }
  const deleteTemplateAction = (): Promise<void> => {
    if (template == null) {
      return Promise.resolve();
    }

    return deleteEmailTemplate(template.id)
      .then(() => {
        setReload(true);
        addSuccessAlert(t("email_templates.actions.delete_success"), setAlerts);
      })
      .catch(error => manageStringError(error, error => addErrorAlert(error, setAlerts), t))
      .finally(closeDeleteTemplate)
  }

  const typeColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "email_templates", "templateType", 400),
      type: "singleSelect",
      filterOperators: enumFilter,
      valueOptions: Object.keys(TEmailTemplateType).map(type => ({value: type, label: t(`email_templates.type.${type.toLowerCase()}`)})),
      renderCell: (item): JSX.Element => {
        return <Chip sx={{backgroundColor: 'ornament.dark', borderRadius: "4px", boxShadow: 5, py: 0, px: "0px"}}
          label={<Typography variant="body2" color="neutral.dark" sx={{fontWeight: "regular"}}>
            {t("email_templates.type." + item.value.toLowerCase())}
          </Typography>}/>
      }
    }
  }

  const operationColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "email_templates", "name", 350),
      valueGetter: (item: GridValueGetterParams): string => valueGetter(item.row.operation?.name, ColumnType.STRING),
      renderCell: (item): JSX.Element => {
        return <Chip sx={{backgroundColor: 'primaryLight.main', borderRadius: "4px", boxShadow: 5, py: 0, px: "0px"}}
          label={<Typography variant="body2" color="primary.main" sx={{fontWeight: "regular"}}>
            {item.value}
          </Typography>}/>
      }
    }
  }

  const columns = [
    typeColumnDefinition(),
    columnDefinition(t, "email_templates", "mailjetId", 125),
    operationColumnDefinition(),
    columnDefinition(t, "email_templates", "createdAt", 150, ColumnType.DATE),
    columnDefinition(t, "email_templates", "updatedAt", 250, ColumnType.DATE, true, true),
    actionColumn(params => [
      gridAction(params, "update", t("email_templates.actions.update"), EditIcon, updateTemplate),
      ...((params.row.operation == null) ? [] : [gridAction(params, "delete", t("email_templates.actions.delete"), DeleteIcon, deleteTemplate)]),
    ])
  ]

  const deleteDialog = <DeleteDialog key="delete-template" open={openDeleteTemplate} close={closeDeleteTemplate}
    title={t("email_templates.actions.delete_title")} warning={t("email_templates.actions.delete_warning")} deleteElement={deleteTemplateAction}/>

  const createAction = <PrimarySmallButton key="create-template" label={t("email_templates.actions.create")} icon={AddIcon} sx={{mr: 1}}
    position={IconPosition.LEFT} action={(): void => navigate(PRIVATE_URL.ADD_EMAIL_TEMPLATE)}/>

  return (
    <Box sx={{height: "100vh", backgroundColor: "ornament.main", display: "flex", flexDirection: "column", justifyContent: "space-between", overflowY: "auto"}}>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", backgroundColor: "ornament.light",
        borderWidth: "0", borderBottomWidth: "1px", borderStyle: "solid", borderColor: "ornament.dark"}}>
        <BackLink/>
        <Typography variant="h1" sx={{mx: 5, mb: 1, fontWeight: "bold", color: "neutral.dark"}}>{t("email_templates.title")}</Typography>
        <Typography variant="body2" sx={{mx: 5, mb: 1, color: "neutral.main"}}>{t("email_templates.subtitle")}</Typography>
      </Box>
      <Box sx={{display: "flex", justifyContent: "center", m: 5}}><List
        translationPrefix="email_templates"
        alerts={alerts}
        dialogs={[deleteDialog]}
        setAlerts={setAlerts}
        columns={columns}
        apiRef={apiRef}
        reload={reload}
        setReload={setReload}
        globalActions={[createAction]}
        loadRows={getEmailTemplates}
        height="75vh"
        emptyListIcon={emptyTransactions}
        sx={{width: "100%"}}
      />
      </Box>
    </Box>
  )
}
