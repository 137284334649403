import React, {MouseEvent, useContext, useState} from 'react';
import {Alert, Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {useTranslation} from "react-i18next";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {putOperation} from "../../services/OperationService";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {useNavigate} from "react-router-dom";

type OperationPresentationDialogProps = {
  operationId: string,
  open: boolean,
  setOpen: (open: boolean) => void,
  setSuccess: (success: string) => void
}

export default function OperationPresentationDialog(props: OperationPresentationDialogProps): JSX.Element {
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);

  const {operationId, open, setOpen, setSuccess} = props;
  const [error, setError] = useState<string|null>(null);
  const navigate = useNavigate();

  const handleClose = (event: MouseEvent<HTMLElement>): void => {
    if (event.button > 0) {
      return;
    }
    setOpen(false);
  }

  const changePresentationFormat = (): void => {
    putOperation(operationId, {presentation: null})
      .then(operation => {
        globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        setSuccess(t("operations.presentation.delete_success"));
        setOpen(false);
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), setError, t);
      })
  }

  return (
    <Dialog open={open} onClose={handleClose} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: "warning.main", borderColor: "warning.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <ReportProblemIcon sx={{color: "warning.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {t("operations.presentation.warning.title")}
          </Typography>
        </Box>
        <CloseIcon sx={{color: "neutral.main"}}/>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1" color="neutral.main">
          {t("operations.presentation.warning.content")}
        </Typography>
        {error && <Alert severity="error" variant="filled">{error}</Alert>}
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <SecondaryBigButton sx={{mr: 0}} label={t("operations.presentation.warning.cancel")} action={handleClose}/>
        <PrimaryBigButton label={t("operations.presentation.warning.confirm")} action={changePresentationFormat}/>
      </DialogActions>
    </Dialog>
  )
}
