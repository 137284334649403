import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {extractOperator} from "../../patterns/list/listUtils";
import {RequestFilter, RequestFilterOperator, RequestOrder} from "./ApiService";
import {GridColumns} from "@mui/x-data-grid/models/colDef/gridColDef";
import {TParticipantAttribute} from "../../interfaces/participantAttribute";

export const formatEuroValues = (amount: number): string => new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(amount);

export const formatFilters = (filterModel: GridFilterModel, columns: GridColumns, attributes: TParticipantAttribute[]): RequestFilter[] => {
  const filters: RequestFilter[] = [];

  filterModel.items.map(item => {
    const columnAttributeField = attributes.find(a => a.label == item.columnField);
    const columnField = columnAttributeField ? `attribute.${columnAttributeField.id}` : item.columnField;
    const columnIsDate = ["date", "datetime"].includes(columns.find(column => column.field == item.columnField)?.type??'');
    filters[0] ? filters.map(filter => {
      if (filter.field === columnField) {
        filter.items.push({value: item.value??'NULL', operator: extractOperator(item.operatorValue), isDate: columnIsDate, isDatePrecise: false});
      } else {
        filters.push({field: columnField, items: [{value: item.value??'NULL', operator: extractOperator(item.operatorValue), isDate: columnIsDate, isDatePrecise: false}]});
      }
    }) : filters.push({field: columnField, items: [{value: item.value??'NULL', operator: extractOperator(item.operatorValue), isDate: columnIsDate, isDatePrecise: false}]});
  });

  return filters;
}

export const formatOrders = (sortingModel: GridSortModel): RequestOrder => {
  if (sortingModel[0] && sortingModel[0].sort) {
    return {field: sortingModel[0].field, sort: sortingModel[0].sort};
  } else {
    return {field: "createdAt", sort: "DESC"}
  }
}

export const formatQuickFilters = (filterModel: GridFilterModel): RequestFilter[] => {
  const filters: RequestFilter[] = [];

  filterModel.items.map(item => {
    filters[0] ? filters.map(filter => {
      if (filter.field === item.columnField) {
        filter.items.push({value: item.value??'NULL', operator: extractOperator(item.operatorValue), isDate: false, isDatePrecise: false});
      } else {
        filters.push({field: item.columnField, items: [{value: item.value??'NULL', operator: extractOperator(item.operatorValue), isDate: false, isDatePrecise: false}]});
      }
    }) : filters.push({field: item.columnField, items: [{value: item.value??'NULL', operator: extractOperator(item.operatorValue), isDate: false, isDatePrecise: false}]});
  });

  return filters;
}

export const formatIds = (ids: string[]): RequestFilter[] => {
  const filters: RequestFilter[] = [];

  ids.map(id => {
    filters[0] ? filters.map(filter => {
      if (filter.field === "id") {
        filter.items.push({value: id??'NULL', operator: RequestFilterOperator.EQUALS, isDate: false, isDatePrecise: false});
      } else {
        filters.push({field: "id", items: [{value: id??'NULL', operator: RequestFilterOperator.EQUALS, isDate: false, isDatePrecise: false}]});
      }
    }) : filters.push({field: "id", items: [{value: id??'NULL', operator: RequestFilterOperator.EQUALS, isDate: false, isDatePrecise: false}]});
  });

  return filters;
}
