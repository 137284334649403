import {Box, MenuItem, Select, Typography} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {formatEuroValues} from "../../../../common/methods/utils";
import {SelectChangeEvent} from "@mui/material/Select";
import {StoreContext} from "../../../../common/struct/store";
import {AppContext} from "../../../../App";
import {formatAmount} from "../../../../utils/formatAmount";

export function WarningPoints(props: {label: string, count1: number, count2?: number}): JSX.Element {
  const {t} = useTranslation();
  const {label, count1, count2} = props;

  return <Box sx={{display: 'flex', mt: 2}}>
    <InfoOutlinedIcon sx={{color: 'primary.main', height: "24px", mr: 0}}/>
    <Typography color="primary.main" variant="body2">
      {t(`participants.money_to_selected_participants.${label}`, {count1, count2})}
    </Typography>
  </Box>
}

export function InfoPoints(props: {label: string, euroValue: number, selectedElements: number}): JSX.Element {
  const {t} = useTranslation();
  const {label, euroValue, selectedElements} = props;

  return <Box sx={{display: 'flex', mt: 2}}>
    <InfoOutlinedIcon sx={{color: 'info.main', height: "24px", mr: 0}}/>
    <Typography color="info.main" variant="body2">
      {t(`participants.${label}.content`, {euroValue: formatEuroValues(euroValue), lengthParticipants: selectedElements})}
    </Typography>
  </Box>
}

export function WalletSelector(props: {selectedWallet: string|undefined, setSelectedWallet: any, excludedWallets?: string[], label?: string}): JSX.Element {
  const {t} = useTranslation();
  const {selectedWallet, setSelectedWallet, excludedWallets = [], label} = props;
  const [store] = useContext<StoreContext>(AppContext);

  const handleWalletChange = (event: SelectChangeEvent): void => {
    const newValue = event.target.value;
    setSelectedWallet(newValue);
  }

  return <Box>
    <Typography variant="subtitle2" sx={{mt: 2, mb: 1}}>
      {label ? label : t("wallets.import.which_wallet")}
    </Typography>
    <Select
      color="primary"
      displayEmpty
      sx={{width: "100%", "& .MuiSelect-select": {display: "flex", alignItems: "center"}}}
      name={'points-wallet'}
      id={"select-wallet"}
      value={selectedWallet}
      onChange={handleWalletChange}
    >
      {store.global.company.wallets.map((wallet, i) => (
        (store.global.company.expiredPointsWallet && wallet.id !== store.global.company.expiredPointsWallet.id && !excludedWallets.includes(wallet.id)) &&
        <MenuItem key={`wallet-${i}`} value={wallet['@id']} sx={{color: "neutral.main"}}>
          {(wallet.name == "default" && wallet.id == store.global.company.defaultWallet!.id) ? t("operations.points_balance.wallets.default") : (wallet.id == store.global.company.expiredPointsWallet!.id) ? t("operations.points_balance.wallets.expired") : wallet.name} ({formatAmount(wallet.balance) ?? 0} €)
        </MenuItem>
      ))}
    </Select>
  </Box>
}
