import React from 'react';
import {useTranslation} from "react-i18next";
import {Chip, Typography} from "@mui/material";
import {LIBERTY_THEME} from "../../tokens/libertyTheme";

export default function TipsButton(props: {action: () => void}): JSX.Element {
  const {t} = useTranslation();
  return (
    <Chip variant="outlined" onClick={props.action} label={
      <Typography variant="body2" color="info.main" sx={{fontWeight: "medium"}}>
        {t("tips.button")}
      </Typography>} sx={{boxShadow: 1, py: 1, px: 2, "& span": {p: "0px"},
      borderColor: "infoLight.main", backgroundColor: "infoLight.light",
      // La couleur depuis le thème et le important sont nécessaires ici
      ":hover": {borderColor: "infoLight.dark", backgroundColor: LIBERTY_THEME.palette.infoLight.light + "!important"}}}
    />
  )
}
