import {TCompanyAware, TLifecycle} from "./traits";

export enum TParticipantImportStatus {
  ON_GOING = 'ONGOING',
  VALIDATED = 'VALIDATED',
  FAILED = 'FAILED',
  SAVED = 'SAVED',
  SAVING = 'SAVING',
  CANCELLED = 'CANCELLED'
}

export interface TParticipantImport extends TLifecycle, TCompanyAware {
  originalName: string;
  fileName: string;
  mapping: any;
  status: TParticipantImportStatus;
}

export interface TParticipantImportPreview extends TParticipantImport {
  preview: any;
  url: string;
  size: number;
}

export interface TParticipantImportValidate extends TParticipantImport {
  created: TImportAction;
  updated: TImportAction;
  violations: TImportViolation[];
  warnings: TImportWarning[];
}

export interface TParticipantImportInformation extends TParticipantImport {
  added: number;
  updated: number;
  errors: number;
}

export interface TImportAction {
  users: any[];
  count: number;
}

export interface TImportViolation {
  line: number;
  value: any;
  violations: TViolation[];
}

export interface TViolation {
  message: string;
  code: string;
  mappedPropertyPath: string;
  originPropertyPath: string;
}

export interface TImportWarning {
  line: number;
  type: TImportActionType;
  value: any;
  warnings: TViolation[]
}

export enum TImportActionType {
  CREATION = 'CREATION',
  UPDATE = 'UPDATE'
}
