import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {trackPromise} from "react-promise-tracker";
import {Alert, Box, MenuItem, Select, Typography} from "@mui/material";
import {errorManager, Violation} from '../../common/methods/ApiService';
import {PrimaryBigButton, SecondaryBigButton} from '../../components/buttons/mainButton';
import {Loader} from "../../components/loader/loader";
import {COMPONENT_TYPES, INPUT_TYPES} from "../../patterns/form/formConstants";
import {createParticipant, getAllParticipantAttributes, updateParticipant} from "../../services/ParticipantService";
import {TOperationParticipant} from "../../interfaces/operationParticipant";
import {CivilityType} from "../../interfaces/user";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import ElementInputWithLabel from "../../patterns/form/elementInputWithLabel";

type ParticipantFormProps = {
  addSuccess: (success: string) => void;
  addError: (error: string) => void;
  setClose: (close: boolean) => void;
  setReload: (reload: boolean) => void;
  operationParticipant?: TOperationParticipant | null;
};

export default function ParticipantForm(props: ParticipantFormProps): JSX.Element {
  const {t} = useTranslation();
  const {operationId} = useParams<string>();
  const {addSuccess, setClose, setReload, operationParticipant} = props;
  const STORE = useContext<StoreContext>(AppContext);
  const [loading, setLoading] = useState(true);
  const [violations, setViolations] = useState<Violation[]>([]);
  const [error, setError] = useState<string|null>(null);

  const [lastName, setLastName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [job, setJob] = useState<string>('');
  const [civility, setCivility] = useState<CivilityType>(CivilityType.MME);
  const [formElementsAttributes, setFormElementsAttributes] = useState<any[]>([]);
  const [attributesValues, setAttributesValues] = useState<any[]>([]);
  const CivilityTypes = Object.values(CivilityType).map(type => ({id: type, value: type, label:  t('participants.participant_form.civility.'+type)}));
  const navigate = useNavigate();

  const formElementSelect = [
    {
      component: COMPONENT_TYPES.SELECT,
      type: INPUT_TYPES.DEFAULT,
      name: 'civility',
      label: t('participants.participant_form.civility.label'),
      choices: CivilityType,
      select: CivilityType,
      value: civility,
      callback: setCivility
    }
  ];
  const formElements = [
    {
      component: COMPONENT_TYPES.INPUT,
      type: INPUT_TYPES.DEFAULT,
      name: 'lastName',
      label: t('participants.participant_form.lastName.label'),
      placeholder: t('participants.participant_form.lastName.placeholder'),
      value: lastName,
      callback: setLastName
    },
    {
      component: COMPONENT_TYPES.INPUT,
      type: INPUT_TYPES.DEFAULT,
      name: 'firstName',
      label: t('participants.participant_form.firstName.label'),
      placeholder: t('participants.participant_form.firstName.placeholder'),
      value: firstName,
      callback: setFirstName
    },
    {
      component: COMPONENT_TYPES.INPUT,
      type: INPUT_TYPES.EMAIL,
      name: 'email',
      label: t('participants.participant_form.email.label'),
      placeholder: t('participants.participant_form.email.placeholder'),
      value: email,
      callback: setEmail,
      required: true
    },
    {
      component: COMPONENT_TYPES.INPUT,
      type: INPUT_TYPES.TELEPHONE,
      name: 'phone',
      label: t('participants.participant_form.phone.label'),
      placeholder: t('participants.participant_form.phone.placeholder'),
      value: phone,
      callback: setPhone
    },
    {
      component: COMPONENT_TYPES.INPUT,
      type: INPUT_TYPES.DEFAULT,
      name: 'job',
      label: t('participants.participant_form.job.label'),
      placeholder: t('participants.participant_form.job.placeholder'),
      value: job,
      callback: setJob
    }
  ];

  useEffect(() => {
    getAllParticipantAttributes()
      .then(attributes => {
        attributes.map((attribute) => {
          setFormElementsAttributes(prevState => [...prevState,
            {
              component: COMPONENT_TYPES.INPUT,
              type: attribute.type === "string" ? INPUT_TYPES.DEFAULT : attribute.type === "date" ? INPUT_TYPES.DATE : INPUT_TYPES.NUMBER,
              name: attribute.label,
              label: attribute.label,
              placeholder: attribute.description,
              value: null,
              callback: setAttributesValues
            }
          ])
          setAttributesValues(prevState => [...prevState, {id: attribute['@id'], field: attribute.label, value: null}])
        })
      })
      .finally(() => {
        operationParticipant?.participant.participantAttributeValues.map((participantAttributeValue) => {          
          setAttributesValues(prevState =>
            prevState.map((prevItem) => {
              if (prevItem.field === participantAttributeValue.participantAttribute.label) {
                return {...prevItem, value: participantAttributeValue.value};
              } else {
                return prevItem;
              }
            })
          )
        })
      });

    const initialRecord = operationParticipant?.participant;

    setCivility(initialRecord?.civility ? initialRecord?.civility : CivilityType.MME);
    setFirstName(initialRecord?.firstName ? initialRecord?.firstName : '');
    setLastName(initialRecord?.lastName ? initialRecord?.lastName : '');
    setEmail(initialRecord?.email ? initialRecord?.email : '');
    setPhone(initialRecord?.phone ? initialRecord?.phone : '');
    setJob(initialRecord?.job ? initialRecord?.job : '');
    setLoading(false);
  }, []);

  useEffect(() => {
    if (attributesValues[0]) {
      attributesValues.map((attributesValue) => {
        setFormElementsAttributes(prevState =>
          prevState.map((prevItem) => {
            if (prevItem.name === attributesValue.field) {
              return {...prevItem, value: attributesValue.value};
            } else {
              return prevItem;
            }
          })
        )
      })

    }
  }, [attributesValues])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) : Promise<void> => {
    event.preventDefault();
    setLoading(true);

    const operation = operationId ? {participatedOperations: [{operation: 'bo/operations/' + operationId}]} : {};
    const attributes = attributesValues[0] ? {participantAttributeValues:
      attributesValues.map((attributesValue) => {
        return {participantAttribute: attributesValue.id, value: attributesValue.value == '' ? null : attributesValue.value}
      })
    } : {};

    const record = {
      civility: civility,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      job: job,
      ...attributes,
      ...operation
    };
    const call = (): Promise<any> => (operationParticipant?.participant) ?updateParticipant(operationParticipant.participant.id, record) : createParticipant(record);
    trackPromise(
      call()
        .then(() => {
          setReload(true);
          addSuccess((operationParticipant?.participant) ? t("operation_participants.actions.update_success") : t("operation_participants.actions.create_success"));
          setClose(true);
        })
        .catch(async error => {
          const errorResult = errorManager(error, t, STORE, navigate);
          if (typeof errorResult === 'string') setError(errorResult);
          else if (Array.isArray(errorResult)) setViolations(errorResult);
        })
        .finally(() => setLoading(false))
    );
  };

  return loading ? <Loader/> :
    <Box component="form" onSubmit={handleSubmit} sx={{position: "relative", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
      <Box sx={{backgroundColor: "ornament.main", display: "flex", flexDirection: "column", justifyContent: "space-between", mx: 5, mt: 5}}>
        {formElementSelect.map((element, index) =>
          <Box sx={{mb: 3}} key={index}>
            <Typography variant="body2" sx={{fontWeight: "bold", mb: "4px", color: "neutral.main"}}>{element.label}</Typography>
            <Select
              id={element.value+'_'+index}
              type={element.type}
              value={element.value}
              sx={{backgroundColor: "ornament.light", "& input": {backgroundColor: "ornament.light", p: 3}}}
              onChange={(e: any): void => element.callback(e.target.value)}
              fullWidth
            >
              {CivilityTypes.map((type, index) => {
                return (
                  <MenuItem key={index+'_'+type.value} value={type.value} >
                    {type.label}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>
        )}
        {formElements.map((element, index) =>
          <ElementInputWithLabel
            key={index}
            name={element.name}
            value={element.value}
            label={element.label}
            placeholder={element.placeholder}
            type={element.type}
            violations={violations}
            updateValue={element.callback}
            disabled={loading}
          />
        )}
        {formElementsAttributes.map((element, index) =>
          <ElementInputWithLabel
            key={index}
            name={element.name}
            value={element.value}
            label={element.label}
            placeholder={element.placeholder}
            type={element.type}
            violations={violations}
            updateValue={element.callback}
            disabled={loading}
            attribute={true}
          />
        )}
        {error && <Alert variant="filled" severity="error">{error}</Alert> }
      </Box>
      <Box sx={{display: "flex", flexDirection: "column", zIndex: 1, backgroundColor: "ornament.light"}}>
        <Box sx={{display: "flex", px: 5, py: 3, borderTop: "1px solid", borderColor: "ornament.dark"}}>
          <SecondaryBigButton action={(): void => setClose(true)} sx={{mr: 3}} label={t("navigation.cancel")}/>
          <PrimaryBigButton submit={true} sx={{width: "100%"}} loading={loading} label={(operationParticipant?.participant) ? t("participants.update_participant_button") : t("participants.create_participant_button")}/>
        </Box>
      </Box>
    </Box>
}
