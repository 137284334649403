export enum ParticipantImportWizardSteps {
  MENU,
  UPLOAD,
  MAPPING,
  VALIDATION
}

export interface ImportWizardCardProps {
  operationId: string;
  setError: (error: string|null) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  disableNextStep: boolean;
  setDisableNextStep: (disable: boolean) => void;
  saveHeader: boolean;
  handleSaveHeader?: (saveHeader: boolean) => void;
}

export interface ParticipantCount {
  created: number;
  updated: number;
}

export type Attribute = {
  label: string,
  trad: string
}
