import React from "react";
import {
  ButtonProps,
  ButtonSize,
  ButtonType,
  getEndIcon,
  getIcon,
  getStartIcon,
  MainButtonProps,
  SizeProperties,
  TypeProperties
} from "./buttonProps";
import {Box, Button} from "@mui/material";
import {Link} from "react-router-dom";
import {loaderPrimaryButton, loaderSecondaryButton} from "../loader/loader";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const SizePropertiesList: SizeProperties[] = [
  {
    height: "30px",
    padding: "4px 8px",
    iconSize: 18,
    fontSize: "14px"
  },
  {
    height: "30px",
    padding: "4px 12px",
    iconSize: 18,
    fontSize: "14px"
  },
  {
    height: "56px",
    padding: "16px 24px",
    iconSize: 24,
    fontSize: "16px"
  }
]

export const TypePropertiesList: TypeProperties[] = [
  {
    variant: "contained",
    fontWeight: "medium",
    color: "ornament.light",
    borderColor: "inherit",
    backgroundColor: "primary.main",
    disabledColor: "ornament.light",
    disabledBorderColor: "inherit",
    disabledBackgroundColor: "primaryLight.main",
    hoverColor: "ornament.light",
    hoverBorderColor: "inherit",
    hoverBackgroundColor: "primary.light",
    focusColor: "ornament.light",
    focusBorderColor: "inherit",
    focusBackgroundColor: "primary.dark",
  },
  {
    variant: "contained",
    fontWeight: "medium",
    color: "primary.main",
    borderColor: "inherit",
    backgroundColor: "ornament.light",
    disabledColor: "primary.light",
    disabledBorderColor: "inherit",
    disabledBackgroundColor: "ornament.main",
    hoverColor: "primary.dark",
    hoverBorderColor: "inherit",
    hoverBackgroundColor: "ornament.light",
    focusColor: "primary.main",
    focusBorderColor: "inherit",
    focusBackgroundColor: "ornament.light",
  },
  {
    variant: "outlined",
    fontWeight: "medium",
    color: "primary.main",
    borderColor: "primaryLight.main",
    backgroundColor: "ornament.light",
    disabledColor: "primaryLight.main",
    disabledBorderColor: "primaryLight.main",
    disabledBackgroundColor: "ornament.light",
    hoverColor: "primary.main",
    hoverBorderColor: "primaryLight.dark",
    hoverBackgroundColor: "ornament.light",
    focusColor: "primary.main",
    focusBorderColor: "primary.main",
    focusBackgroundColor: "primaryLight.light"
  },
  {
    variant: "text",
    fontWeight: "medium",
    color: "primary.main",
    borderColor: "inherit",
    backgroundColor: "inherit",
    disabledColor: "primaryLight.main",
    disabledBorderColor: "inherit",
    disabledBackgroundColor: "inherit",
    hoverColor: "ornament.light",
    hoverBorderColor: "inherit",
    hoverBackgroundColor: "primary.main",
    focusColor: "ornament.light",
    focusBorderColor: "inherit",
    focusBackgroundColor: "primary.main"
  },
  {
    variant: "outlined",
    fontWeight: "medium",
    color: "neutral.main",
    borderColor: "neutral.light",
    backgroundColor: "ornament.light",
    disabledColor: "neutral.light",
    disabledBorderColor: "ornament.dark",
    disabledBackgroundColor: "ornament.light",
    hoverColor: "neutral.main",
    hoverBorderColor: "neutral.main",
    hoverBackgroundColor: "ornament.dark",
    focusColor: "neutral.main",
    focusBorderColor: "neutral.main",
    focusBackgroundColor: "ornament.dark"
  }
]

const MainButton = (props: MainButtonProps): JSX.Element => {
  const sizeProperties = SizePropertiesList[props.size];
  const typeProperties = TypePropertiesList[props.type];
  const {loading = false, disabled = false, rightChevron = false, openNewWindow = false} = props;
  const id = props.id ? {id: props.id} : {};
  const loader = props.type === ButtonType.SECONDARY ? loaderSecondaryButton : loaderPrimaryButton;

  return (
    <Button {...id} type={props.submit?"submit":"button"} variant={typeProperties.variant} className={props.selected?"selected":""} sx={{
      textTransform: "none", fontSize: sizeProperties.fontSize, height: sizeProperties.height, boxShadow: 0, fontWeight: typeProperties.fontWeight,
      padding: sizeProperties.padding,
      color: typeProperties.color,
      borderColor: typeProperties.borderColor,
      backgroundColor: typeProperties.backgroundColor,
      ":disabled": {
        color: typeProperties.disabledColor,
        borderColor: typeProperties.disabledBorderColor,
        backgroundColor: typeProperties.disabledBackgroundColor
      },
      ":hover": {
        color: typeProperties.hoverColor,
        borderColor: typeProperties.hoverBorderColor,
        backgroundColor: typeProperties.hoverBackgroundColor
      },
      "&.selected": {
        color: typeProperties.focusColor,
        borderColor: typeProperties.focusBorderColor,
        backgroundColor: typeProperties.focusBackgroundColor
      },
      ...loading && {"&.Mui-disabled": {backgroundColor: typeProperties.backgroundColor, position: "relative"}},
      ...props.sx
    }}
    startIcon={getStartIcon(sizeProperties.iconSize, props.position, props.icon, props.image, props.loading)}
    endIcon={rightChevron ? getIcon(sizeProperties.iconSize, KeyboardArrowRightIcon, props.loading) : getEndIcon(sizeProperties.iconSize, props.position, props.icon, props.image, props.loading)}
    onClick={props.action} disabled={loading || disabled}
    component={(props.href?.startsWith('/')) && !props.download ? Link : Button}
    to={props.href} href={props.href} download={props.download??false} target={(props.href?.startsWith('/') && !props.href?.startsWith("//") && !openNewWindow) ? "_self" : "_blank"}>
      {props.loading && loader}
      {props.loading ? <Box sx={{visibility: "hidden"}}>{props.label}</Box> : <Box>{props.label}</Box>}
    </Button>
  )
}

export const PrimaryBigButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.PRIMARY} size={ButtonSize.BIG} {...props}/>
}

export const PrimaryNegativeBigButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.PRIMARY_NEGATIVE} size={ButtonSize.BIG} {...props}/>
}

export const PrimarySmallButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.PRIMARY} size={ButtonSize.SMALL} {...props}/>
}

export const SecondaryBigButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.SECONDARY} size={ButtonSize.BIG} {...props}/>
}

export const SecondarySmallButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.SECONDARY} size={ButtonSize.SMALL} {...props}/>
}

export const GreySmallButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.GREY} size={ButtonSize.SMALL} {...props}/>
}

export const GhostButton = (props: ButtonProps): JSX.Element => {
  return <MainButton type={ButtonType.GHOST} size={ButtonSize.VERY_SMALL} {...props}/>
}
