import {operationStepsPaths} from '../../struct/createOperationTypes'
import {CANCEL, INIT_STORE, NEXT_STEP, PREV_STEP, SET_OPERATION} from './globals'
import {initialStore, StoreContext} from "../../struct/store";
import {TOperation} from "../../../interfaces/operation";

export interface OperationAction {
  type: string
  step?: number
  operation?: TOperation
  notification?: string
}

export function operationStoreReducer(STORE: StoreContext, action: OperationAction) : void {
  const [store, setStore] = STORE;
  switch (action.type) {
  case PREV_STEP: {
    const current_step = store.operationWizard.currentStep;
    setStore({
      ...store,
      operationWizard: {
        ...store.operationWizard,
        currentStep: current_step - 1,
        currentPath: operationStepsPaths[current_step-2]
      }
    });
    return;
  }
  case NEXT_STEP: {
    const current_step = store.operationWizard.currentStep;
    setStore({
      ...store,
      operationWizard: {
        ...store.operationWizard,
        currentStep: current_step + 1,
        currentPath: operationStepsPaths[current_step],
        operation: action.operation ? action.operation : store.operationWizard.operation
      },
      global: {
        ...store.global,
        currentOperation: action.operation ? action.operation : store.operationWizard.operation
      }
    });
    return;
  }
  case CANCEL: {
    setStore((previousStore) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        operations: previousStore.global.operations.filter(item => item.id !== previousStore.global.currentOperation?.id),
        currentOperation: undefined,
        notification: action.notification
      },
      operationWizard: initialStore.operationWizard
    }));
    return;
  }
  case SET_OPERATION: {    
    setStore({
      ...store,
      operationWizard: {
        ...store.operationWizard,
        operation: action.operation ? action.operation : store.operationWizard.operation
      },
      global: {
        ...store.global,
        currentOperation: action.operation ? action.operation : store.operationWizard.operation
      }
    });
    return;
  }
  case INIT_STORE: {
    setStore({
      ...store,
      operationWizard: initialStore.operationWizard,
      global: {
        ...store.global,
        notification: action.notification
      }
    });
    return;
  }
  default:
    return;
  }
}

export default {operationStoreReducer};
