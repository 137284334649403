import React, {useContext, useEffect, useState} from 'react';
import {Box, Card, InputAdornment, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import moment from "moment";
import slugify from "slugify";
import ElementInput from "../../patterns/form/elementInput";
import {INPUT_TYPES} from "../../patterns/form/formConstants";
import {errorManager, manageStringError, Violation} from "../../common/methods/ApiService";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {fr} from "date-fns/locale";
import {DateRangePicker} from "@mui/x-date-pickers-pro/DateRangePicker";
import EastIcon from "@mui/icons-material/East";
import EventIcon from "@mui/icons-material/Event";
import {OperationWizardCardProps, OperationWizardSteps} from "../createOperationWizard/operationWizardSteps";
import {TOperation} from "../../interfaces/operation";
import {createOperation, putOperation} from "../../services/OperationService";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {ADD_OPERATION, NEXT_STEP} from "../../common/methods/context-setter/globals";
import {operationStoreReducer} from "../../common/methods/context-setter/operationStoreReducer";
import {StoreContext, WizardType} from "../../common/struct/store";
import {AppContext} from "../../App";
import {initWizardStore, setStepToValidateWizardStore} from "../../common/methods/context-setter/wizardStoreReducer";
import {useNavigate} from "react-router-dom";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

export default function OperationCharacteristicsCard(props: OperationWizardCardProps): JSX.Element {
  const {t} = useTranslation();
  const {setError, propsNoWizard, loading, setLoading, autoHostname = true, isWizard = true} = props;
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const navigate = useNavigate();
  useEffect(() => {
    document.title = t("tab_title.wizard.step1");
  }, []);
  const [border, setBorder] = useState<boolean>(false);
  const [errorStartDate, setErrorStartDate] = useState<string|null>(null);
  const [errorEndDate, setErrorEndDate] = useState<string|null>(null);
  const [errorHostname, setErrorHostname] = useState<string|null>(null);
  const [violations, setViolations] = useState<Violation[]>([]);

  const operation = propsNoWizard ? store.global.currentOperation : store.operationWizard.operation;
  const defaultHostname = process.env.REACT_APP_DEFAULT_FRONT_HOSTNAME;

  const [name, setName] = useState(operation?.name ?? "");
  const [hostname, setHostname] = useState(operation?.hostname.replace("." + defaultHostname, "") ?? "");
  const [chosenHostname, setChosenHostname] = useState(false);
  const [startDate, setStartDate] = useState(operation?.startDate ?? moment(new Date()).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(operation?.endDate ?? "");
  const [expirationDate, setExpirationDate] = useState(operation?.expirationDate ?? moment(new Date()).add(12, 'M').format("YYYY-MM-DD"));
  const [errorExpirationDate, setErrorExpirationDate] = useState<string|null>(null);

  const handleNameChange = (value: string): void => {
    setName(value);
    if ((!chosenHostname) && autoHostname) setHostname(cleanHostname(value));
  };

  const isValidHostname = (value: string): boolean => {
    if (!value) {
      return false;
    }

    const pattern = /^[a-z0-9-]+$/;
    return pattern.test(value);
  }

  const handleHostnameChange = (event: any): void => {
    setChosenHostname(true);

    setHostname(event.target.value);
    if (!isValidHostname(event.target.value)) {
      setErrorHostname(t("operations.characteristics.hostname.url_error"));
    } else {
      setErrorHostname(null);
    }
  };

  const updateStartDate = (newDate: any): void => {
    setStartDate(newDate ? moment(newDate).format("YYYY-MM-DD") : "");
  }

  const updateEndDate = (newDate: any): void => {
    setEndDate(newDate ? moment(newDate).format("YYYY-MM-DD") : "");
  }

  const updateExpirationDate = (expirationDate: any): void => {
    setExpirationDate(expirationDate ? moment(expirationDate).format("YYYY-MM-DD") : "");
  }

  const cleanHostname = (hostname: string): string => {
    slugify.extend({'%': '%', '<': '<', '>': '>', '&': '&'});
    return slugify(hostname, {
      replacement: '-',
      remove: /[*+~.()'"!:@#&%<>]/gi,
      lower: true,
      strict: true,
      locale: 'vi',
      trim: true
    }).substring(0, 47 - (defaultHostname?.length??0))
  }

  useEffect(() => {
    setErrorStartDate(null);
    setErrorEndDate(null);
    setErrorHostname(null);
    setErrorExpirationDate(null);
    violations.forEach(violation => {
      if (violation.field == "startDate") setErrorStartDate(violation.error)
      if (violation.field == "endDate") setErrorEndDate(violation.error)
      if (violation.field == "hostname") setErrorHostname(violation.error)
      if (violation.field == "expirationDate") setErrorExpirationDate(violation.error)
    })
  }, [violations])

  useEffect(() => {
    if (operation && !operation.customHostname) {
      setHostname(operation.hostname.replace("." + defaultHostname, ""));
    } else {
      setHostname(cleanHostname(name));
    }
  }, [])

  useEffect(() => {
    if (store.operationWizard.stepToValidate !== OperationWizardSteps.CHARACTERISTICS) {
      return;
    }    

    const body = {
      name: name,
      hostname: hostname + "." + process.env.REACT_APP_DEFAULT_FRONT_HOSTNAME,
      startDate: startDate.length > 0 ? startDate : undefined,
      endDate: endDate.length > 0 ? endDate : undefined
    }

    setError(null);
    setViolations([]);
    setLoading(true);

    // Si l'opération a déjà été créée et qu'on retourne à l'étape 1, on ne la recrée pas
    const call = (): Promise<TOperation> => (store.operationWizard.operation) ? putOperation(store.operationWizard.operation.id, body) : createOperation(body);
    call()
      .then(operation => {
        setLoading(false);
        globalStoreReducer(STORE, {type: ADD_OPERATION, operation: operation});
        operationStoreReducer(STORE, {type: NEXT_STEP, operation: operation});
      })
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
        else if (Array.isArray(errorResult)) setViolations(errorResult);
      })
      .finally(() => {
        setStepToValidateWizardStore(STORE, WizardType.CREATE_OPERATION, 0);
        setLoading(false);
      })
  }, [store.operationWizard.stepToValidate])

  useEffect(() => {
    if (loading || propsNoWizard == null || !propsNoWizard.triggerAction || store.global.currentOperation == null) {
      return;
    }

    const body = {
      name: name,
      hostname: hostname + "." + process.env.REACT_APP_DEFAULT_FRONT_HOSTNAME,
      startDate: startDate.length > 0 ? startDate : undefined,
      endDate: endDate.length > 0 ? endDate : undefined,
      expirationDate: expirationDate.length > 0 ? expirationDate : undefined
    }

    setViolations([]);
    setLoading(true);
    

    putOperation(store.global.currentOperation.id, body)
      .then(operation => {
        globalStoreReducer(STORE, {type: ADD_OPERATION, operation: operation});
        propsNoWizard.setSuccess(t("operations.characteristics.success"));
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), setError, t);
      })
      .finally(() => {
        setLoading(false);
        initWizardStore(STORE, WizardType.CREATE_OPERATION, "");
        propsNoWizard.setTriggerAction(false);
      })
  }, [propsNoWizard?.triggerAction])

  return (
    <Card sx={{border: "1px solid", borderColor: "ornament.dark", boxShadow: 1, p: 6}}>
      <Typography variant="body1" sx={{fontWeight: "bold", mb: 3}}>
        {t("operations.characteristics.title")}
      </Typography>
      <ElementInput name="name" type={INPUT_TYPES.DEFAULT} value={name} updateValue={handleNameChange} sx={{mb: 2, "& .Mui-focused": {"& .MuiOutlinedInput-notchedOutline": {borderColor: "#6725D2 !important"}}}}
        placeholder={t("operations.characteristics.title_placeholder")} violations={violations}/>
      <Typography variant="body1" color="neutral.main" sx={{mt: 2, mb: 3, fontWeight: "bold"}}>
        {t("operations.characteristics.hostname.title")}
      </Typography>
      <TextField
        value={hostname}
        name="hostname"
        disabled={loading}
        error={errorHostname !== null}
        helperText={errorHostname}
        onChange={handleHostnameChange}
        sx={{
          width: "100%", mb: 0, "& .MuiInputBase-root": {pr: "0px"},
          "& .MuiFormHelperText-root": {marginLeft: "0px"},
          "&:hover": {"& .MuiOutlinedInput-notchedOutline": {borderColor: `${chosenHostname && isValidHostname(hostname) ? "success.main" : "#C4C4C4"}`}},
          "& .MuiOutlinedInput-notchedOutline": {borderColor: `${chosenHostname && isValidHostname(hostname) ? "success.main" : "#C4C4C4"}`},
          "& .Mui-focused": {"& .MuiOutlinedInput-notchedOutline": {borderColor: `${chosenHostname && isValidHostname(hostname) ? "success.main" : "#C4C4C4"}`}}
        }}
        InputProps={{
          endAdornment: <InputAdornment sx={{backgroundColor: "primaryLight.main", color: "primary.dark", px: 3, py: "26px", fontWeight: "medium", mr: "0px", borderTopRightRadius: "4px", borderBottomRightRadius: "4px"}} position="end">
            .{process.env.REACT_APP_DEFAULT_FRONT_HOSTNAME}
          </InputAdornment>,
        }}
      />
      <Typography variant="caption" color="neutral.main">
        {t("operations.characteristics.hostname.helper")}
      </Typography>
      <Typography variant="body1" sx={{fontWeight: "bold", mt: 3}}>
        {t("operations.characteristics.duration.title")}
      </Typography>
      <Typography variant="body2" color="neutral.main" sx={{mb: 3}}>
        {t("operations.characteristics.duration.description")}
      </Typography>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={fr}
        localeText={{start: "", end: ""}}
      >
        <DateRangePicker
          value={[startDate, endDate]}
          onChange={(newDate): void => {
            updateStartDate(newDate[0])
            updateEndDate(newDate[1])
          }}
          disabled={loading}
          onOpen={(): void => setBorder(true)}
          onClose={(): void => setBorder(false)}
          PopperProps={{
            sx: {borderWidth: `${border ? '1px' : '0px'}`, borderStyle: "solid", borderColor: "primary.main", borderRadius: "4px", mb: "18px !important"}
          }}
          renderInput={(startProps, endProps): any => (
            <Box sx={{display: "flex", width: "100%", mb: 3}}>
              <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                <Typography variant="body2" color="neutral.main" sx={{mb: 0, fontWeight: "medium"}}>
                  {t("operations.characteristics.duration.start")}
                </Typography>
                <TextField {...startProps} disabled={loading} sx={{mb: (errorStartDate == null && errorEndDate !== null) ? 4 : "0px"}} error={errorStartDate !== null}
                  inputProps={{...startProps.inputProps, placeholder: "jj/mm/aaaa"}} helperText={errorStartDate}
                  InputProps={{endAdornment: <InputAdornment position="end"><EventIcon sx={{width: 24, height: 24}}/></InputAdornment>}} />
              </Box>
              <EastIcon sx={{width: 16, height: 16, color: "neutral.main", alignSelf: "center", mx: 6, mt: (errorEndDate == null && errorStartDate == null) ? 4 : "0px"}}/>
              <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                <Typography variant="body2" color="neutral.main" sx={{mb: 0, fontWeight: "medium"}}>
                  {t("operations.characteristics.duration.end")}
                </Typography>
                <TextField {...endProps} disabled={loading} sx={{mb: (errorEndDate == null && errorStartDate !== null) ? 4 : "0px"}}
                  inputProps={{...endProps.inputProps, placeholder: "jj/mm/aaaa"}} error={errorEndDate !== null} helperText={errorEndDate}
                  InputProps={{endAdornment: <InputAdornment position="end"><EventIcon sx={{width: 24, height: 24}}/></InputAdornment>}} />
              </Box>
            </Box>
          )}
        />
      </LocalizationProvider>
      {!isWizard && <><Typography variant="body1" sx={{fontWeight: "bold", mb: 3, mt: 3}}>
        {t("operations.points.expiration.title")}
      </Typography>
      <Typography variant="body2" color="neutral.main" sx={{fontWeight: "unset", "& b": {fontWeight: "medium"}, mb: 1}} dangerouslySetInnerHTML={{__html: t("operations.points.expiration.subtitle")}}/>
      <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
        <DatePicker
          value={expirationDate}
          disabled={loading}
          onChange={(newDate: any): void => {
            updateExpirationDate(newDate)
          }}
          onOpen={(): void => setBorder(true)}
          onClose={(): void => setBorder(false)}
          PopperProps={{
            sx: {borderWidth: border ? '1px' : '0px', borderStyle: "solid", borderColor: "primary.main", borderRadius: "4px"}
          }}
          renderInput={(params): any => <TextField {...params} inputProps={{...params.inputProps, placeholder: "jj/mm/aaaa"}} sx={{width: "100%", "& input": {p: 3}}} error={errorExpirationDate !== null} helperText={errorExpirationDate} />}
        />
      </LocalizationProvider></>}
    </Card>
  )
}
