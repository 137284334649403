import * as React from "react";
import {Box} from "@mui/material";


const ElementButton = (props: {button: JSX.Element}): JSX.Element => {
  return (
    <Box sx={{pt: 1, pb: 1, display: "flex", justifyContent: "center"}}>
      {props.button}
    </Box>
  )
}
export default ElementButton;
