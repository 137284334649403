import React, {useContext, useEffect, useState} from "react";
import {Box, Chip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DataGridPro, useGridApiRef} from "@mui/x-data-grid-pro";
import {LIBERTY_THEME} from "../../../../tokens/libertyTheme";
import {frFR, GridFilterModel, GridValueGetterParams} from "@mui/x-data-grid";
import ListPagination from "../../../../patterns/list/listPagination";
import EmptyList from "../../../../patterns/list/emptyList";
import ListColumnMenu from "../../../../patterns/list/listColumnMenu";
import DeleteIcon from "@mui/icons-material/Delete";
import emptySearch from "../../../../assets/lists/empty_search.svg";
import emptyTransactions from "../../../../assets/lists/empty_transactions.svg";
import {GridSortModel} from "@mui/x-data-grid/models/gridSortModel";
import {GridColumnVisibilityModel} from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {StoreContext} from "../../../../common/struct/store";
import ListSnackbar, {TAlert} from "../../../../patterns/list/listSnackbar";
import {getOperation} from "../../../../services/OperationService";
import {globalStoreReducer} from "../../../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../../../common/methods/context-setter/globals";
import {
  ChipCellProps,
  columnDefinition,
  ColumnType,
  enumFilterWithoutNull,
  valueGetter
} from "../../../../patterns/list/listUtils";
import {getOperationParticipantTransactions} from "../../../../services/operationParticipantService";
import ListParticipantTransactionsToolbar from "./listParticipantTransactionsToolbar";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {RequestFilter} from "../../../../common/methods/ApiService";
import {formatFilters} from "../../../../common/methods/utils";
import {TReward, TRewardCategory} from "../../../../interfaces/reward";
import StarsIcon from "@mui/icons-material/Stars";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {AppContext} from "../../../../App";
import {TFunction} from "react-i18next";

export const categoryProps = (category: TRewardCategory): ChipCellProps => {
  switch (category) {
  case TRewardCategory.UNITARY_CREDIT:
  case TRewardCategory.IMPORT_CREDIT:
    return {
      icon: <StarsIcon sx={{width: 18, height: 18}}/>,
      iconColor: "success.main",
      color: "success.main",
      backgroundColor: "success.light"
    }
  case TRewardCategory.UNITARY_DEBIT:
  case TRewardCategory.IMPORT_DEBIT:
    return {
      icon: <PriorityHighIcon sx={{width: 18, height: 18}}/>,
      iconColor: "warning.main",
      color: "warning.main",
      backgroundColor: "warning.light",
    }
  case TRewardCategory.REFUND:
    return {
      icon: <AutorenewIcon sx={{width: 18, height: 18}}/>,
      iconColor: "success.main",
      color: "success.main",
      backgroundColor: "success.light",
    }
  case TRewardCategory.ORDER:
    return {
      icon: <AccountBalanceWalletIcon sx={{width: 18, height: 18}}/>,
      iconColor: "neutral.main",
      color: "neutral.dark",
      backgroundColor: "ornament.dark",
    }
  case TRewardCategory.EXPIRATION:
    return {
      icon: <StopCircleIcon sx={{width: 18, height: 18}}/>,
      iconColor: "neutral.dark",
      color: "neutral.dark",
      backgroundColor: "neutral.light",
    }
  default:
    return {
      icon: <StopCircleIcon sx={{width: 18, height: 18}}/>,
      iconColor: "neutral.dark",
      color: "neutral.dark",
      backgroundColor: "neutral.light",
    }
  }
}  

export const categoryColumnDefinition = (t: TFunction): GridEnrichedColDef => {
  return {
    ...columnDefinition(t, "rewards", "category", 250),
    type: "singleSelect",
    filterOperators: enumFilterWithoutNull,
    valueOptions: Object.keys(TRewardCategory).map(category => ({value: category, label: t(`operation_participants.transactions.category.${category.toLowerCase()}`)})),
    renderCell: (item): JSX.Element => {
      const category = categoryProps(item.row.category);
      return <Chip icon={category.icon}
        sx={{backgroundColor: category.backgroundColor, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: category.iconColor}}}
        label={<Typography variant="body2" color={category.color} sx={{fontWeight: "regular"}}>
          {t("operation_participants.transactions.category." + item.value.toLowerCase(), {date: category.date})}
        </Typography>}/>
    }
  }
}

export default function ListParticipantTransactions(props: {operationParticipantId: string}): JSX.Element {
  const {operationParticipantId} = props;
  const {t} = useTranslation();
  const apiRef = useGridApiRef();

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const operation = store.global.currentOperation;

  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [transactions, setTransactions] = useState<TReward[]>([]);
  const [rows, setRows] = useState<TReward[]>([]);
  const [transactionsTotal, setTransactionsTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [maxItems, setMaxItems] = useState(25);
  const [search, setSearch] = useState("");
  const [sortingModel, setSortingModel] = useState<GridSortModel>([{field: 'createdAt', sort: 'desc'}]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({items: []});
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<null|HTMLElement>(null);
  const [openColumnsDialog, setOpenColumnsDialog] = useState(false);
  const [reload, setReload] = useState(false);
  const [alerts, setAlerts] = useState<TAlert[]>([]); 

  const walletColumnDefinition = (): GridEnrichedColDef => {    
    return {
      ...columnDefinition(t, "rewards", "wallet.name", 150, ColumnType.STRING, true),
      filterOperators: enumFilterWithoutNull,
      valueOptions: store.global.company.wallets.map(wallet => ({value: wallet.name, label: wallet.id != store.global.company.defaultWallet!.id && wallet.id != store.global.company.expiredPointsWallet!.id ? wallet.name : wallet.id == store.global.company.defaultWallet!.id && wallet.name == 'default' ? "Budget par défaut" : "Budget points expirés"})),
      valueGetter: (item: GridValueGetterParams): string => valueGetter(item.row.wallet ? (item.row.wallet['name'] == store.global.company.defaultWallet!.name ? t("wallets.config.default_wallet.name") : item.row.wallet['name'] == store.global.company.expiredPointsWallet!.name ? t("wallets.config.expired_points_wallet.name") : item.row.wallet['name']) : "-", ColumnType.STRING)
    }
  }

  const columns = [
    columnDefinition(t, "operation_participants.transactions", "createdAt", 100, ColumnType.DATE),
    categoryColumnDefinition(t),
    columnDefinition(t, "operation_participants.transactions", "pointAmount", 150, ColumnType.POINTS),
    columnDefinition(t, "operation_participants.transactions", "reason", 450, ColumnType.STRING),
    walletColumnDefinition()
  ]

  const loadTransactions = (): Promise<void> => {
    if (filterModel.items.length > 0 && filterModel.items.find(item => item.operatorValue !== "isEmpty" && item.value == null)) {
      return Promise.resolve();
    }

    setLoadingTransactions(true);
    const filters: RequestFilter[] = formatFilters(filterModel, columns, []);
    const sort = sortingModel.length == 0 ? {field: 'createdAt', sort: 'desc'} : {field: sortingModel[0].field, sort: sortingModel[0].sort??""}
    return getOperationParticipantTransactions(operationParticipantId, page + 1, maxItems, search, sort, filters).then(rewards => {
      setTransactionsTotal(rewards.totalItems);
      setTransactions(rewards.items);
      setRows(rewards.items);
    }).finally(() => setLoadingTransactions(false))
  }

  useEffect(() => {
    if (reload) {
      loadTransactions()
        .then(() => getOperation(operation!.id))
        .then(operation => {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
          return Promise.resolve();
        })
        .finally(() => setReload(false));
    }
  }, [reload])

  useEffect(() => {
    loadTransactions()
  }, [page, search, sortingModel, filterModel, maxItems])

  return (
    <Box sx={{height: "82.5vh", p: 7}}>
      <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
      <DataGridPro apiRef={apiRef}
        sx={{overflowX: "auto", backgroundColor: "ornament.light", borderColor: "ornament.dark",
          "& .MuiDataGrid-columnSeparator": {color: "neutral.light"},
          "& .MuiDataGrid-columnHeader:first-of-type": {"& .MuiDataGrid-columnSeparator": {display: "none"}},
          "& .MuiDataGrid-columnHeader:last-of-type": {"& .MuiDataGrid-columnSeparator": {display: "none"}},
          "& .MuiDataGrid-columnHeaderTitleContainer": {ml: 2},
          "& .MuiDataGrid-columnHeaderTitleContainer:nth-of-type(1)": {ml: "unset"},
          "& .MuiSvgIcon-root": {fontSize: 24},
          "& .MuiDataGrid-footerContainer": {backgroundColor: "ornament.main", minHeight: "unset"},
          "& .MuiDataGrid-pinnedColumns": {boxShadow: 4},
          "& .MuiDataGrid-pinnedColumnHeaders": {boxShadow: 4},
          "& .MuiDataGrid-selectedRowCount": {visibility: "hidden"},
          // Le thème ici n'est pas compris, d'où le passage par la valeur directement de la couleur
          "& .MuiDataGrid-row.Mui-hovered": {backgroundColor: LIBERTY_THEME.palette.ornament.main},
          "& .MuiDataGrid-row.Mui-selected": {backgroundColor: LIBERTY_THEME.palette.primaryLight.light},
          "& .MuiDataGrid-actionsCell": {"& button": {borderRadius: "4px", ":hover": {backgroundColor: "neutral.light"}}}
        }}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        loading={loadingTransactions}
        pagination
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        page={page}
        pageSize={maxItems}
        onPageSizeChange={(newSize): void => setMaxItems(newSize)}
        sortModel={sortingModel}
        onSortModelChange={(model): void => setSortingModel(model)}
        filterModel={filterModel}
        onFilterModelChange={(model): void => setFilterModel(model)}
        columnVisibilityModel={visibilityModel}
        onColumnVisibilityModelChange={(model): void => setVisibilityModel(model)}
        columns={columns}
        rows={rows}
        rowCount={transactionsTotal}
        rowsPerPageOptions={[25,50,100]}
        rowHeight={54}
        headerHeight={90}
        initialState={{
          pinnedColumns: {right: ['actions']},
          pagination: {pageSize: 10},
        }}
        components={{
          Toolbar: ListParticipantTransactionsToolbar,
          Pagination: ListPagination,
          NoRowsOverlay: EmptyList,
          ColumnMenu: ListColumnMenu,
          FilterPanelDeleteIcon: (): JSX.Element => <DeleteIcon sx={{color: "neutral.main"}}/>,
          ColumnMenuIcon: DragIndicatorIcon
        }}
        componentsProps={{
          panel: {
            anchorEl: filtersAnchorEl,
            sx: {position: "fixed !important", "& .MuiDataGrid-paper": {mt: "18px", mr: 7}}
          },
          toolbar: {
            disableActions: transactions.length === 0,
            disableFilters: transactions.length === 0 && filterModel.items.length === 0,
            search,
            setSearch,
            apiRef,
            openColumnsDialog,
            setFiltersAnchorEl,
            setOpenColumnsDialog
          },
          pagination: {
            page,
            setPage,
            total: transactionsTotal,
            maxItems,
            setMaxItems
          },
          noRowsOverlay: {
            label: (search.length > 0 || filterModel.items.length) > 0 ? t("list.empty_search") : t("operation_participants.transactions.empty_list"),
            image: (search.length > 0 || filterModel.items.length) > 0 ? emptySearch : emptyTransactions
          },
          filterPanel: {
            sx: {
              border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", boxShadow: 3,
              "& .MuiDataGrid-panelFooter": {px: 3, pb: 3, pt: "0px"},
              "& .MuiButton-root": {color: "neutral.main"}
            },
            filterFormProps: {
              sx: {
                alignItems: "center",
                p: 3,
                "& legend": {pr: 1},
                "& .MuiDataGrid-filterFormLinkOperatorInput": {display: "none", ml: 3, mr: "0px", "& legend": {pr: "0px"}},
                "& .MuiDataGrid-filterFormColumnInput": {ml: 3},
                "& .MuiDataGrid-filterFormOperatorInput": {ml: 3},
                "& .MuiDataGrid-filterFormValueInput": {ml: 3}
              },
              deleteIconProps: {
                sx: {
                  m: "0px"
                }
              },
              linkOperatorInputProps: {
                variant: "outlined"
              },
              columnInputProps: {
                variant: "outlined"
              },
              operatorInputProps: {
                variant: "outlined"
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: "outlined"
                }
              }
            }
          }
        }}
        checkboxSelection={false}
        disableSelectionOnClick
      />
    </Box>
  )
}
