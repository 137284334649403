import React, {FormEvent, useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Alert, Box, Card, Typography} from "@mui/material";
import {PRIVATE_URL, PUBLIC_URL} from "../../common/struct/urlManager";
import {resetPassword} from "../../services/AuthenticationService";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {INPUT_TYPES} from '../../patterns/form/formConstants';
import {useNavigate} from 'react-router-dom';
import LogoutPage from "../../patterns/logout/logoutPage";
import {checkIsMobile, isAuthenticated} from "../../common/struct/globalVar";
import {errorManager, Violation} from "../../common/methods/ApiService";
import ElementInputWithLabel from "../../patterns/form/elementInputWithLabel";
import {AppContext} from "../../App";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {RESET_PASSWORD} from "../../common/methods/context-setter/globals";

export default function ForgetPassword() : JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const STORE = useContext(AppContext);

  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const [error, setError] = useState<string|null>(null);
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [violations, setViolations] = useState<Violation[]>([]);

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(PRIVATE_URL.LANDING_PAGE, {replace: true});
    }

    const handleWindowSizeChange = (): void => setIsMobile(checkIsMobile());
    window.addEventListener('resize', handleWindowSizeChange);
    return (): any => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    resetPassword(email)
      .then(() => {
        setLoading(false);
        globalStoreReducer(STORE, {type: RESET_PASSWORD});
        navigate(PUBLIC_URL.LOGIN, {state: {reset: true}});
      })
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate, false);
        if (typeof errorResult === 'string') setError(errorResult);
        else if (Array.isArray(errorResult)) setViolations(errorResult);
      })
      .finally(() => setLoading(false))
  }

  return (
    <LogoutPage isMobile={isMobile}>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflowY: "auto", width: isMobile ? "90%" : "40%", height: "inherit", mx: "auto"}}>
        <Typography variant="h1" sx={{fontWeight: "bold", mb: 6}}>
          {t("forget_password.title")}
        </Typography>
        {error && <Alert severity="error" variant="filled" sx={{width: "100%", boxSizing: "border-box", mb: 3}}>{error}</Alert>}
        <Card sx={{backgroundColor: "ornament.light", p: 6, mb: 6, width: "100%", boxSizing: "border-box", border: "1px solid", borderColor: "ornament.dark"}}>
          <Typography variant="body2" sx={{mb: 3}}>
            {t("forget_password.text")}
          </Typography>
          <form onSubmit={handleSubmit}>
            <ElementInputWithLabel disabled={loading} type={INPUT_TYPES.EMAIL} label={t("forget_password.form.email")} name="email" value={email} updateValue={setEmail} placeholder={t("forget_password.form.email_placeholder")} violations={violations}/>
            <PrimaryBigButton label={t("forget_password.form.submit_button")} sx={{mb: 2, width: "100%"}} submit={true} loading={loading}/>
          </form>
        </Card>
      </Box>
    </LogoutPage>
  )
}
