import {TAlert} from "../../patterns/list/listSnackbar";
import {Dispatch, SetStateAction} from "react";

export enum WalletsSection {
  BUY_POINTS = "achat-points",
  ONGOING = 'achat-en-cours',
  WALLETS = 'liste',
  MOVEMENTS = 'mouvements',
}

export type WalletsProps = {
  setAlerts: Dispatch<SetStateAction<TAlert[]>>;
}
