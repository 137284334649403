import React from "react";
import {Box, Card, Divider, Typography} from "@mui/material";

export default function BankDetails(): JSX.Element {
  return (
    <Card sx={{p: 6, boxShadow: 2, mx: 'auto', width: "80%", boxSizing: "border-box"}}>
      <Typography variant="h1" color="neutral.dark" sx={{mb: 5, fontWeight: "bold"}}>
                Relevé d'identité bancaire
      </Typography>
      <Typography variant="h2" color="neutral.dark" sx={{mb: 0}}>
                Identifiant national de compte bancaire - RIB
      </Typography>
      <Divider sx={{backgroundColor: "neutral.dark", height: "0.5px", mb: 3, border: "none"}} />
      <Box sx={{width: "100%", mb: 5}}>
        <Box sx={{display: "flex", justifyContent: "space-between", width: "100%", mb: 3}}>
          <Box>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", userSelect: "none", "-webkit-user-select": "none", "-ms-user-select": "none", mb: 0}}>Banque</Typography>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", fontWeight: 700, textTransform: "uppercase"}}>30047</Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", userSelect: "none", "-webkit-user-select": "none", "-ms-user-select": "none", mb: 0}}>Guichet</Typography>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", fontWeight: 700, textTransform: "uppercase"}}>14299</Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", userSelect: "none", "-webkit-user-select": "none", "-ms-user-select": "none", mb: 0}}>N° compte</Typography>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", fontWeight: 700, textTransform: "uppercase"}}>00022390001</Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", userSelect: "none", "-webkit-user-select": "none", "-ms-user-select": "none", mb: 0}}>Clé</Typography>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", fontWeight: 700, textTransform: "uppercase"}}>64</Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", userSelect: "none", "-webkit-user-select": "none", "-ms-user-select": "none", mb: 0}}>Devise</Typography>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", fontWeight: 700, textTransform: "uppercase"}}>Eur</Typography>
          </Box>
        </Box>
        <Box sx={{width: "100%"}}>
          <Typography variant="body1" color="neutral.dark" sx={{userSelect: "none", "-webkit-user-select": "none", "-ms-user-select": "none", mb: 0}}>Domiciliation</Typography>
          <Typography variant="body1" color="neutral.dark" sx={{fontWeight: 700, textTransform: "uppercase"}}>Cic Angers centre</Typography>
        </Box>
      </Box>
      <Typography variant="h2" color="neutral.dark" sx={{mb: 0}}>
                Identifiant international de compte bancaire
      </Typography>
      <Divider sx={{backgroundColor: "neutral.dark", height: "0.5px", mb: 3, border: "none"}} />
      <Box sx={{width: "100%", mb: 5}}>
        <Box sx={{width: "100%", mb: 3}}>
          <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", userSelect: "none", "-webkit-user-select": "none", "-ms-user-select": "none", mb: 0}}>IBAN (International Bank Account Number)</Typography>
          <Box sx={{display: "flex", justifyContent: "space-between"}}>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", fontWeight: 700, textTransform: "uppercase"}}>fr76</Typography>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", fontWeight: 700, textTransform: "uppercase"}}>3004</Typography>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", fontWeight: 700, textTransform: "uppercase"}}>7142</Typography>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", fontWeight: 700, textTransform: "uppercase"}}>9900</Typography>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", fontWeight: 700, textTransform: "uppercase"}}>0223</Typography>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", fontWeight: 700, textTransform: "uppercase"}}>9000</Typography>
            <Typography variant="body1" color="neutral.dark" sx={{textAlign: "center", fontWeight: 700, textTransform: "uppercase"}}>164</Typography>
          </Box>
        </Box>
        <Box sx={{width: "100%"}}>
          <Typography variant="body1" color="neutral.dark" sx={{userSelect: "none", "-webkit-user-select": "none", "-ms-user-select": "none", mb: 0}}>BIC (Bank Identifier Code)</Typography>
          <Typography variant="body1" color="neutral.dark" sx={{fontWeight: 700, textTransform: "uppercase"}}>Cmcifrpp</Typography>
        </Box>
      </Box>
    </Card>
  )
}
