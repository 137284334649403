import React from "react";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {OperationProps} from "../operationProps";

interface DashboardCardProps {
  primaryMetric: number;
  secondaryMetric: number;
  label: string;
  pointsIndicator?: boolean;
}

export const DashboardCard = (props: DashboardCardProps): JSX.Element => {
  const {primaryMetric, secondaryMetric, label, pointsIndicator = false} = props;
  const {t} = useTranslation();
  return (
    <Box sx={{display: 'flex', flexDirection: "column"}}>
      <Box sx={{mt: 2, mb: 1, display: "flex"}}>
        <Typography display="inline" color="primary.main" sx={{fontWeight: "bold"}} variant="h1">{primaryMetric}</Typography>
        {pointsIndicator && <Typography display="inline" color="primary.main" sx={{fontWeight: "medium", ml: 0}} variant="body1">{t("operations.dashboard.points")}</Typography>}
      </Box>
      <Typography color="neutral.main" variant="body2" sx={{fontWeight: "unset"}}
        dangerouslySetInnerHTML={{__html: t(`operations.dashboard.${label}.subtitle`, {total: secondaryMetric})}}/>
    </Box>
  )
}

export const TopGiftsCardChildren = (props: OperationProps): JSX.Element => {
  const {operation} = props;
  const {t} = useTranslation();
  return (
    <>
      {operation.topGifts.length > 0 ?
        <Box sx={{display: 'flex', flexDirection: "column", width: "100%"}}>
          <Box sx={{display:'flex',justifyContent: 'space-between', borderBottom: 1, pb: 2, borderColor: "ornament.dark"}}>
            {Object.keys(operation.topGifts[0]).map((name, index) => (
              <Typography key={index} color="neutral.main" variant="body2" sx={{justifyContent: 'flex-end'}}>{t("operations.dashboard.best_gifts." + name)}</Typography>
            ))}
          </Box>
          <Box>
            {operation.topGifts.map((row, index) => (
              <Box
                sx={{display:'flex', justifyContent: 'space-between', py:2 , borderBottom: 1, borderColor: "ornament.dark"}}
                key={index}
              >
                <Box>
                  <Typography color="neutral.dark" sx={{fontWeight: "medium"}} variant="body2">{row.name}</Typography>
                </Box>
                <Box sx={{justifyContent: 'flex-start', width:55}}>
                  <Typography color="neutral.dark" variant="body2">{row.quantity}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        :
        <Typography color="neutral.main" sx={{fontWeight: "regular"}} variant="body2">{t("operations.dashboard.best_gifts.no_order")}</Typography>
      }
    </>
  )
}

export const TopBrandsCardChildren = (props: OperationProps): JSX.Element => {
  const {operation} = props;
  const {t} = useTranslation();
  return (
    <>
      {operation.topBrands[0] ?
        <Box sx={{display: 'flex', flexDirection: "column", width: "100%"}}>
          <Box sx={{display:'flex',justifyContent: 'space-between', borderBottom: 1, pb: 2, borderColor: "ornament.dark"}}>
            {Object.keys(operation.topBrands[0]).map((name, index) => (
              <Typography key={index} color="neutral.main" variant="body2">{t("operations.dashboard.best_brands." + name)}</Typography>
            ))}
          </Box>
          <Box>
            {operation.topBrands.map((row, index) => (
              <Box
                sx={{display:'flex', justifyContent: 'space-between', py:2 , borderBottom: 1, borderColor: "ornament.dark"}}
                key={index}
              >
                <Box >
                  <Typography color="neutral.dark" sx={{fontWeight: "medium"}} variant="body2">{row.brand}</Typography>
                </Box>
                <Box sx={{justifyContent: 'flex-start', width:55}}>
                  <Typography color="neutral.dark" variant="body2">{row.quantity}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        :
        <Typography color="neutral.main" sx={{fontWeight: "regular"}} variant="body2">{t("operations.dashboard.best_brands.no_order")}</Typography>
      }
    </>
  )
}
