import React, {FormEvent, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {PRIVATE_URL, PUBLIC_URL} from "../../common/struct/urlManager";
import {loginWithToken} from "../../services/AuthenticationService";
import {Alert, Box, Card, Typography} from "@mui/material";
import {checkIsMobile, getAdminId, isAuthenticated} from "../../common/struct/globalVar";
import {changeAdminPassword} from "../../services/CompanyService";
import {manageStringError} from "../../common/methods/ApiService";
import {useTranslation} from "react-i18next";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {LOGIN} from "../../common/methods/context-setter/globals";
import {AppContext} from "../../App";
import LogoutPage from "../../patterns/logout/logoutPage";
import ElementInputWithLabel from "../../patterns/form/elementInputWithLabel";
import {INPUT_TYPES} from "../../patterns/form/formConstants";
import {PrimaryBigButton} from "../../components/buttons/mainButton";

export default function ResetPassword() : JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const STORE = useContext(AppContext);

  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const [password, setPassword] = useState('');
  const [adminId, setAdminId] = useState('');
  const [loading, setLoading] = useState(true);
  const [errorLogin, setErrorLogin] = useState<string|null>(null);
  const [errorReset, setErrorReset] = useState<string|null>(null);
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    const adminId = getAdminId();
    if (!isAuthenticated() && (token == null || token.length === 0)) {
      navigate(PUBLIC_URL.LOGIN);
      return;
    }

    if (token) {
      loginWithToken(token)
        .then((id: string) => {
          setAdminId(id);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          setErrorLogin(error);
        });
    } else if (adminId == null) {
      navigate(PUBLIC_URL.LOGIN);
    } else {
      // @ts-ignore getAdmin null checked in previous if
      setAdminId(adminId);
      setLoading(false);
    }

    const handleWindowSizeChange = (): void => setIsMobile(checkIsMobile());
    window.addEventListener('resize', handleWindowSizeChange);
    return (): any => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    setErrorReset(null);

    changeAdminPassword(adminId, password)
      .then(admin => {
        globalStoreReducer(STORE, {type: LOGIN, admin: admin??undefined, notification: t("reset_password.success")});
        navigate(PRIVATE_URL.LANDING_PAGE);
      })
      .catch(error => manageStringError(error, setErrorReset, t))
      .then(() => setLoading(false));
  }

  return (
    <LogoutPage isMobile={isMobile}>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflowY: "auto", width: isMobile ? "90%" : "40%", height: "inherit", mx: "auto"}}>
        <Typography variant="h1" sx={{fontWeight: "bold", mb: 6}}>
          {t("reset_password.title")}
        </Typography>
        {errorReset && <Alert severity="error" variant="filled" sx={{width: "100%", boxSizing: "border-box", mb: 3}}>{errorReset}</Alert>}
        {errorLogin && <Alert severity="error" variant="filled" sx={{width: "100%", boxSizing: "border-box", mb: 3}}>{errorLogin}</Alert>}
        <Card sx={{backgroundColor: "ornament.light", p: 6, mb: 6, width: "100%", boxSizing: "border-box", border: "1px solid", borderColor: "ornament.dark"}}>
          <form onSubmit={handleSubmit}>
            <ElementInputWithLabel disabled={loading} passwordCheck={true} type={INPUT_TYPES.PASSWORD} label={t("reset_password.form.password")} name="password" value={password} updateValue={setPassword} placeholder={t("reset_password.form.password_placeholder")} violations={[]} canSubmit={canSubmit} setCanSubmit={setCanSubmit}/>
            <PrimaryBigButton label={t("reset_password.form.submit_button")} sx={{mb: 2, width: "100%"}} submit={true} disabled={!canSubmit} loading={loading}/>
          </form>
        </Card>
      </Box>
    </LogoutPage>
  )
}
