import {TCompanyAware, TLifecycle} from "./traits";

export interface TShop extends TLifecycle, TCompanyAware {
  extraCriteria: TShopExtraCriteria;
  excludedGiftIds: number[];
  excludedBrands: string[];
  selectedUniversIds: number[];
  selectedUniverses: string[];
  pricing: TShopPricing;
  priceMin?: number;
  priceMax?: number;
  giftsMax?: number;
  hidePricing: boolean;
  blockOrder: boolean;
  blockOrderDate?: string;
  giftCountAdmin: number;
  preview: string[];
}

export interface TShopUpdate {
  extraCriteria?: TShopExtraCriteria;
  excludedGiftIds?: number[];
  excludedBrands?: string[];
  selectedUniversIds?: number[];
  selectedUniverses?: string[];
  pricing?: TShopPricing;
  priceMin?: string|null;
  priceMax?: string|null;
  giftsMax?: number|null;
  blockOrder?: boolean;
  hidePricing?: boolean;
}

export enum TShopPricing {
  FULL = "FULL",
  RANGE = "RANGE",
  UNIQUE = "UNIQUE"
}

export interface TShopExtraCriteria {
  x_french?: boolean;
  x_alcool?: boolean;
  x_ecofriendly?: boolean;
}

export interface TShopUniverse {
  parent: TShopUniverseDetails;
  children: TShopUniverseDetailsWithId[];
}

export interface TShopUniverseDetails {
  x_icon: string;
  x_name: string;
  gift_count: number;
  gift_count_admin: number;
}
export interface TShopUniverseDetailsWithId extends TShopUniverseDetails {
  id: number;
}
