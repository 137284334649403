import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {GridValueGetterParams, useGridApiRef} from "@mui/x-data-grid-pro";
import emptyParticipants from "../../../../assets/lists/empty_participants.svg";
import {TOperationParticipantForListing} from "../../../../interfaces/operationParticipant";
import ListSnackbar, {TAlert} from "../../../../patterns/list/listSnackbar";
import {getDeletedOperationParticipants} from "../../../../services/OperationService";
import {actionColumn, columnDefinition, ColumnType} from "../../../../patterns/list/listUtils";
import listDeletedActions from "./listDeletedActions";
import List from "../../../../patterns/list/list";
import {RequestFilter, RequestOrder} from "../../../../common/methods/ApiService";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {getExportDeletedParticipants} from "../../../../services/ImportService";
import {PrimarySmallButton} from "../../../../components/buttons/mainButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {IconPosition} from "../../../../components/buttons/buttonProps";
import {getExportDocument} from "../../../../common/methods/exportDocument";

export default function ListDeletedOperationParticipants(props: {setReloadParticipants: (reload: boolean) => void}): JSX.Element {
  const {operationId} = useParams<string>();
  const {setReloadParticipants} = props;
  const {t} = useTranslation();
  const apiRef = useGridApiRef();

  const [reload, setReload] = useState(false);
  const [alerts, setAlerts] = useState<TAlert[]>([]);

  const setReloadGlobal = (reload: boolean): void => {
    setReload(reload);
    setReloadParticipants(reload);
  }

  const nameColumn = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "operation_participants.deleted", "simplesearch", 150, ColumnType.STRING),
      renderHeader: (): JSX.Element => <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "bold"}}>
        {t(`operation_participants.deleted.columns.name`)}
      </Typography>,
      valueGetter: (item: GridValueGetterParams): string => item.row.firstName ? item.row.firstName+' '+item.row.lastName : item.row.email,
    }
  }

  const columns = [
    nameColumn(),
    columnDefinition(t, "operation_participants", "email", 225, ColumnType.STRING),
    columnDefinition(t, "operation_participants.deleted", "deletedAt", 225, ColumnType.DATE_TIME),
    columnDefinition(t, "operation_participants.deleted", "deletedBy", 225, ColumnType.STRING, false),
    actionColumn(params => listDeletedActions(params, operationId!, setReloadGlobal, setAlerts))
  ]

  const loadDeletedOperationParticipants = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TOperationParticipantForListing[]}> => {
    return getDeletedOperationParticipants(operationId!, page, maxItems, search, order, filters);
  }

  const exportDeletedParticipants = (): void => {
    getExportDeletedParticipants(operationId!)
      .then(response => {
        getExportDocument(response)
      });
  }

  const exportDeletedParticipantsButton = <PrimarySmallButton key="export-button" label={t("operation_participants.deleted.actions.export")}
    icon={FileDownloadIcon} position={IconPosition.LEFT} action={exportDeletedParticipants} sx={{mr: 1}}/>

  return (
    <Box sx={{height: "82.5vh", p: 7}}>
      <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
      <List
        translationPrefix="operation_participants.deleted"
        columns={columns}
        apiRef={apiRef}
        loadRows={loadDeletedOperationParticipants}
        height="75vh"
        reload={reload}
        setReload={setReloadGlobal}
        emptyListIcon={emptyParticipants}
        globalActions={[exportDeletedParticipantsButton]}
        sx={{width: "100%"}}
      />
    </Box>
  )
}
