import React, {useContext, useState} from "react";
import {
  Avatar,
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import {formatEuroValues} from "../../../../common/methods/utils";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {PrimaryBigButton, SecondaryBigButton} from "../../../../components/buttons/mainButton";
import RemoveIcon from '@mui/icons-material/Remove';
import {IconPosition} from "../../../../components/buttons/buttonProps";
import {TOperationParticipant} from "../../../../interfaces/operationParticipant";
import {TOperation} from "../../../../interfaces/operation";
import {TParticipant} from "../../../../interfaces/participant";
import {StoreContext} from "../../../../common/struct/store";
import {AppContext} from "../../../../App";
import {WalletSelector} from "./pointsUtils";


type RemovePointsProps = {
  openDialog: boolean,
  removeMoney: (amount: number, wallet: string) => void,
  closeDialog: () => void,
  operationParticipant: TOperationParticipant|null,
  operation?: TOperation
}

export default function RemovePointsToParticipant(props: RemovePointsProps): JSX.Element {
  const {openDialog, removeMoney, closeDialog, operationParticipant, operation} = props;
  const [store] = useContext<StoreContext>(AppContext);
  const [pointValue, setPointValue] = useState<number>(1);
  const [selectedWallet, setSelectedWallet] = useState<string|undefined>(store.global.company.defaultWallet ? store.global.company.defaultWallet['@id']: undefined);
  const displayWalletSelector = store.global.company.wallets.length > 2;
  const {t} = useTranslation();

  const participantName = (participant: TParticipant): string => {
    const names = [participant.firstName??'', participant.lastName??''];
    return names.join(' ');
  }

  const onConfirmMoney = (): void => {
    if (operation == null) {
      return;
    }

    closeDialog();
    removeMoney(-store.global.company.euroPerPoint*pointValue, selectedWallet ? selectedWallet : store.global.company.defaultWallet ? store.global.company.defaultWallet['@id']: 'none');
  }

  return (operationParticipant == null || operation == null) ? <></> :
    <Dialog open={openDialog} onClose={closeDialog} sx={{display: "flex", justifyContent: "center"}}>
      <DialogTitle>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb:5}}>
          <Typography color="neutral.dark" sx={{fontWeight: "bold"}} variant="h2">{(operationParticipant.participant.firstName || operationParticipant.participant.lastName) ? t('participants.remove_money_to_participant.title', {name: participantName(operationParticipant.participant)}) : t('participants.remove_money_to_participant.sub_title')}</Typography>
          <IconButton onClick={closeDialog}><CloseIcon/></IconButton>
        </Box>
        <Card sx={{display: 'flex', flexDirection:'row', alignItems:'center', my:3, p:3, backgroundColor: 'ornament.main'}}>
          <Avatar
            sx={{height: "24px", width: "24px", backgroundColor: "ornament.dark", p:1, mr:2}}
          >
            <Typography color="neutral.dark" variant="body2" sx={{fontWeight: "bold"}}>
              {operationParticipant.participant.firstName && operationParticipant.participant.firstName.charAt(0).toUpperCase()}
              {operationParticipant.participant.lastName && operationParticipant.participant.lastName.charAt(0).toUpperCase()}
            </Typography>
          </Avatar>
          <Typography color="neutral.main" sx={{fontWeight: "medium", pr: 3, borderRight: "1px solid", borderColor: "ornament.dark"}} variant="body1">{participantName(operationParticipant.participant)}</Typography>
          <Typography color="neutral.main" sx={{fontWeight: "medium", pl: 3}} variant="body1">{t('participants.remove_money_to_participant.sold_text')}</Typography>
          <Typography color="neutral.dark" sx={{fontWeight: "bold", ml: 10}} variant="body1">{t('participants.remove_money_to_participant.sold_value', {pointsValue: operationParticipant.rewardPoints})}</Typography>
        </Card>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={onConfirmMoney} >
          <Typography color="neutral.dark" sx={{fontWeight: "bold", mb:2}} variant="body1">{t('participants.remove_money_to_participant.sub_title')}</Typography>
          <TextField onChange={(event: any): void => !isNaN(event.target.value)?setPointValue(Math.abs(event.target.value)):setPointValue(0)} type="string" color="info" value={pointValue}
            sx={{width: '100%', mr: 2, "& input": {p: 3}}}
            InputProps={{
              lang: "fr-FR",
              endAdornment: <InputAdornment position="end"><Typography color="neutral.dark" variant="body2">{t('participants.remove_money_to_participant.unit')}</Typography></InputAdornment>,
              inputProps: {
                min: 1,
              }
            }}
          />
          {displayWalletSelector &&
            <WalletSelector selectedWallet={selectedWallet} setSelectedWallet={setSelectedWallet}/>
          }
        </form>
        {operationParticipant.rewardPoints >= pointValue && <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
          <InfoOutlinedIcon sx={{color: 'info.main', height: "15px", mr: 0}}/>
          <Typography color="info.main" variant="body2">{t('participants.remove_money_to_participant.content', {euroValue: formatEuroValues(store.global.company.euroPerPoint*pointValue)})}</Typography>
        </Box>}
        {operationParticipant.rewardPoints < pointValue && <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
          <ReportProblemOutlinedIcon sx={{color: 'warning.main', height: "15px", mr: 0}}/>
          <Typography color="warning.main" variant="body2">{t('participants.remove_money_to_participant.warning')}</Typography>
        </Box>}
      </DialogContent>
      <DialogActions sx={{display: 'flex', justifyContent: 'right', borderTop: "1px solid", borderColor: "ornament.dark", p: 5}}>
        <SecondaryBigButton
          label={t('participants.remove_money_to_participant.cancel_button')}
          action={closeDialog}
        />
        <PrimaryBigButton
          label={t('participants.remove_money_to_participant.confirm_button')}
          icon={RemoveIcon}
          position={IconPosition.LEFT}
          action={onConfirmMoney}
        />
      </DialogActions>
    </Dialog>
}
