import {WizardStepProps} from "../../patterns/wizard_old/wizardStepProps";
import {Card, FormControl, Grid, RadioGroup, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import OperationShopPricingFull from "./operationShopPricingFull";
import OperationShopPricingRange from "./operationShopPricingRange";
import OperationShopPricingUnique from "./operationShopPricingUnique";
import {TShop, TShopPricing} from "../../interfaces/shop";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import {AppContext} from "../../App";
import {TOperation} from "../../interfaces/operation";
import {StoreContext} from "../../common/struct/store";
import Footer from "../../patterns/footer/footer";

export type OperationShopPricingProps = {
  shop: TShop,
  pricing: TShopPricing|null,
  changePricing: (pricing: TShopPricing|null) => void,
  saved: TShopPricing|null,
  setShop: (shop: TShop) => void,
  setError: (error: string) => void
}

export default function OperationShopPricing(props: WizardStepProps): JSX.Element {
  const {operationId} = useParams<string>();
  const {t} = useTranslation();
  const {setSuccess, setError} = props;
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const [operation, setOperation] = useState<TOperation|undefined>(undefined);
  const [pricing, setPricing] = useState<null|TShopPricing>(null);
  const [saved, setSaved] = useState<null|TShopPricing>(null);

  useEffect(() => {
    if (operationId) {
      setOperation(store.global.currentOperation);
    } else {
      setOperation(store.operationWizard.operation??undefined);
    }
  }, [store]);

  useEffect(() => {
    if (operation) {
      setPricing(operation.shops[0].pricing);
      setSaved(operation.shops[0].pricing);
    }
  }, [operation])

  const changePricing = (pricing: TShopPricing|null): void => {
    setPricing(pricing);
    setSaved(null);
  }

  const setShop = (shop: TShop): void => {
    if (operation == null) {
      return;
    }

    globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: {...operation, shops: [shop]}});
    setSaved(shop.pricing);
    if (setSuccess) setSuccess(t("operations.shop.price.success"));
  }

  return operation == null ? <></> :
    <Grid container columns={12} columnSpacing={5} sx={{px: 8}}>
      <Grid item xs={8}>
        <FormControl sx={{width: "100%"}}>
          <RadioGroup>
            <OperationShopPricingFull
              shop={operation!.shops[0]} setShop={setShop} setError={setError!}
              pricing={pricing} changePricing={changePricing}
              saved={saved}/>
            <OperationShopPricingRange
              shop={operation!.shops[0]} setShop={setShop} setError={setError!}
              pricing={pricing} changePricing={changePricing} saved={saved}/>
            <OperationShopPricingUnique
              shop={operation!.shops[0]} setShop={setShop} setError={setError!}
              pricing={pricing} changePricing={changePricing}
              saved={saved}/>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{display: "flex", flexDirection: "column", boxShadow: 1, p: 3,
          border: "1px solid", borderColor: "infoLight.main", backgroundColor: "infoLight.light"}}>
          <Typography variant="body1" color="info.dark" sx={{fontWeight: "bold"}}>
            {t("operations.shop.price.value.title")}
          </Typography>
          <Typography variant="body2" color="info.dark" sx={{mt: 3, fontWeight: "unset"}}
            dangerouslySetInnerHTML={{__html: t("operations.shop.price.value.subtitle")}}/>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Footer/>
      </Grid>
    </Grid>
}
