import React, {useState} from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import {fr} from "date-fns/locale";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import {TSendInvitationNow} from "../../interfaces/operationParticipant";
import moment from "moment";


type UpdateEmailDialogProps = {
  openDialog: boolean,
  closeDialog: () => void,
  action: (sendAt: string) => void,
}

export default function UpdateEmailDialog(props: UpdateEmailDialogProps): JSX.Element {
  const {openDialog, closeDialog, action} = props
  const [option, setOption] = useState<TSendInvitationNow>(TSendInvitationNow.NOW);
  const [sendAt, setSendAt] = useState(moment(new Date()).add(1, 'day').set('hour', 9).set('minutes', 0).format("YYYY-MM-DD hh:mm"));
  const [border, setBorder] = useState<boolean>(false);

  const {t} = useTranslation();

  const actionSendMail = (): void => {
    action(option === TSendInvitationNow.NOW ? moment(new Date()).format("YYYY-MM-DD HH:mm") : sendAt)
    closeDialog()
  }

  return (
    <Dialog open={openDialog} onClose={closeDialog} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: "info.main", borderColor: "info.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: 'flex', alignItems: "center"}}>
          <InfoIcon sx={{color: "info.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {t("operation_mailing_details.actions.update_title")}
          </Typography>
        </Box>
        <IconButton onClick={closeDialog}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1" color="neutral.main" sx={{fontWeight: "unset"}} dangerouslySetInnerHTML={{__html: t("operation_mailing_details.actions.update_warning")}}/>
        <RadioGroup>
          <FormControlLabel
            checked={option === TSendInvitationNow.NOW}
            onChange={(): void => setOption(TSendInvitationNow.NOW)} control={<Radio sx={{fontSize: 24}}/>}
            label={<Typography variant="body2" color="neutral.dark">{t("participants.invitation.choice.now")}</Typography>}/>
        </RadioGroup>
        <RadioGroup>
          <FormControlLabel
            checked={option === TSendInvitationNow.LATER}
            onChange={(): void => setOption(TSendInvitationNow.LATER)} control={<Radio sx={{fontSize: 24}}/>}
            label={<Typography variant="body2" color="neutral.dark">{t("participants.invitation.choice.later")}</Typography>}/>
          <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
            <DateTimePicker
              inputFormat="dd MMMM yyyy à HH:mm"
              value={sendAt}
              disabled={option !== TSendInvitationNow.LATER}
              minDate={new Date()}
              minutesStep={5}
              ampm={false}
              onChange={(newDate: any): void => {
                moment.locale('fr')
                const date = moment(newDate).toString()
                setSendAt(date)
              }}
              onOpen={(): void => setBorder(true)}
              onClose={(): void => setBorder(false)}
              PopperProps={{
                sx: {borderWidth: border ? '1px' : '0px', borderStyle: "solid", borderColor: "primary.main", borderRadius: "4px"}
              }}
              renderInput={(params): any => <TextField {...params} inputProps={{...params.inputProps, placeholder: "jj/mm/aaaa"}} sx={{width: "100%", "& input": {p: 3}}} />}
            />
          </LocalizationProvider>
        </RadioGroup>
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <SecondaryBigButton sx={{mr: 0}} label={t("list.delete.cancel")} action={closeDialog}/>
        <PrimaryBigButton action={actionSendMail} sx={{height: "56px"}} label={t('participants.invitation.send_confirmation_button')} />
      </DialogActions>
    </Dialog>
  );

}
