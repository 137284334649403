import React, {ElementType, FunctionComponent} from 'react';
import {Box, Card, Typography} from "@mui/material";
import {SecondarySmallButton} from "../../components/buttons/mainButton";
import {IconPosition} from "../../components/buttons/buttonProps";
import EastIcon from "@mui/icons-material/East";
import {useTranslation} from "react-i18next";

export interface JoozyMessageProps {
  background: FunctionComponent;
  message: string;
  redirect: string;
  Icon?: ElementType;
}

export default function JoozyMessage(props: JoozyMessageProps): JSX.Element {
  const {background, message, redirect, Icon} = props;
  const {t} = useTranslation();
  const sx = Icon ? {color: "neutral.dark", ":hover": {
    color: "neutral.dark",
    borderColor: "primaryLight.dark",
    backgroundColor: "ornament.light"
  }} : {};

  return (
    <Card sx={{display: "flex", flexDirection: "column", alignItems: "center", p: 5, mb: 5, boxShadow: 0,
      background: `url(${background}) no-repeat`, backgroundSize: "cover", backgroundPosition: "bottom"}}>
      <Box sx={{display: "flex", alignItems: "center", mb: 1}}>
        {Icon && <Icon sx={{width: 32, height: 32, color: "ornament.light", pr: 3}}/>}
        <Typography variant="body1" color="ornament.light" sx={{pl: Icon ? 3 : "0px", fontWeight: "unset", borderLeft: Icon ? "4px solid" : "none", borderColor: "ornament.light"}}
          dangerouslySetInnerHTML={{__html: t(`message.${message}.title`)}}/>
      </Box>
      <SecondarySmallButton label={t(`message.${message}.action`)} href={redirect} position={IconPosition.RIGHT} icon={EastIcon} sx={sx}/>
    </Card>
  )
}
