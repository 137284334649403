import {Box, Typography} from "@mui/material";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {IconPosition} from "../../components/buttons/buttonProps";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {StoreContext, StoreWizard} from "../../common/struct/store";
import {
  goToStepWizardStore,
  setStepToValidateWizardStore
} from "../../common/methods/context-setter/wizardStoreReducer";
import {AppContext} from "../../App";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {useNavigate} from "react-router-dom";

interface WizardFooterProps {
  wizard: StoreWizard;
  loading: boolean;
  error: string|null;
  disableNextStep: boolean;
  setOpenCancelDialog?: (open: boolean) => void;
}

export default function WizardFooter(props: WizardFooterProps): JSX.Element {
  const {wizard, loading, disableNextStep, error, setOpenCancelDialog} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const STORE = useContext<StoreContext>(AppContext);

  const cancelWizard = (): void => {
    if (loading) {
      return;
    }
    if (setOpenCancelDialog) {
      setOpenCancelDialog(true);
    } else {
      navigate(PRIVATE_URL.LANDING_PAGE);
    }
  }

  const goToNextStep = (): void => setStepToValidateWizardStore(STORE, wizard.type, wizard.currentStep)

  const goToPreviousStep = (): void => {
    setStepToValidateWizardStore(STORE, wizard.type, 0);
    goToStepWizardStore(STORE, wizard.type, wizard.currentStep - 1);
  }

  return (
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", position: "sticky", bottom: 0,
      width: "100%", boxSizing: "border-box", borderTop: "1px solid", borderColor: "ornament.dark", backgroundColor: "ornament.light", zIndex: 100, py: 3, px: 7}}>
      <Box sx={{display: "flex", alignItems: "center"}}>
        {wizard.currentStep > 1 && <SecondaryBigButton label={t("wizard.back")} sx={{mr: 5}}
          icon={KeyboardArrowLeftIcon} position={IconPosition.LEFT} action={goToPreviousStep} disabled={loading}/>}
        <Box sx={{display: "flex", alignItems: "center", cursor: "pointer"}} onClick={cancelWizard}>
          <ClearIcon sx={{width: 18, height: 18, color: "neutral.main", mr: 0}}/>
          <Typography variant="body2" color="neutral.main" sx={{textDecoration: "underline", fontWeight: "bold"}}>
            {t("wizard.cancel")}
          </Typography>
        </Box>
      </Box>
      <PrimaryBigButton label={wizard.currentStep == wizard.maximumStep ? t(`wizard.${wizard.type}.end_label`) : t("wizard.next")}
        icon={KeyboardArrowRightIcon} position={IconPosition.RIGHT}
        action={goToNextStep} loading={loading} disabled={disableNextStep || error !== null}/>
    </Box>
  )
}
