import React from 'react';
import {Box, CircularProgress} from '@mui/material';
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export type LoaderProps = {
  height?: string;
  width?: string;
  sx?: SxProps<Theme>;
}

export const Loader = (props: LoaderProps): JSX.Element => {
  const {height, width, sx} = props;

  return <Box key="loader" sx={{display: "flex", justifyContent: "center", alignItems: "center", width: width ?? "initial", height: height ?? "100vh", ...sx}}>
    <CircularProgress size={50}/>
  </Box>
}

export const loaderPrimaryButton = <Box sx={{height: "35px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
  <CircularProgress size={35} sx={{color: "ornament.light"}} />
</Box>

export const loaderSecondaryButton = <Box sx={{height: "30px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", display: "flex", alignItems: "center"}}>
  <CircularProgress size={20} sx={{color: "primary.main"}} />
</Box>
