import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Typography} from '@mui/material';
import ListSnackbar, {TAlert} from "../../patterns/list/listSnackbar";
import {addErrorAlert, addSuccessAlert} from "../../patterns/list/listUtils";
import Page from "../../patterns/page/page";
import OperationShowcaseCard from "./operationShowcaseCard";
import PageForm from "../../patterns/page/pageForm";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {AccessToShopButton} from '../operationMainPage/customisation/accessToShopButton';

export default function OperationShowcase(): JSX.Element {
  const {t} = useTranslation();
  useEffect(() => {
    document.title = t("tab_title.operation.showcase");
  }, []);
  const [store] = useContext<StoreContext>(AppContext);
  const [loading, setLoading] = useState(false);
  const [triggerAction, setTriggerAction] = useState(false);
  const [alerts, setAlerts] = useState<TAlert[]>([]);

  const setError = (error: string|null): void => { if (error) addErrorAlert(error, setAlerts) }
  const setSuccess = (success: string): void => addSuccessAlert(success, setAlerts)

  const updateOperation = (): void => {
    setTriggerAction(true);
  }

  return (
    <Page title={t("operations.showcase.title")} buttons={store.global.currentOperation && [<AccessToShopButton key={store.global.currentOperation.id} operation={store.global.currentOperation} />]}>
      <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
      <Box sx={{px: 8, pb: 5, display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mt: 6, mb: 5}}>
          <Typography color="neutral.main" sx={{fontWeight: "regular"}} variant="body2">{t("operations.showcase.subtitle")}</Typography>
        </Box>
        <PageForm submit={updateOperation} loading={loading}>
          <OperationShowcaseCard setError={setError} loading={loading} setLoading={setLoading} propsNoWizard={{triggerAction, setTriggerAction, setSuccess}} sx={{width: "50%", mx: "auto"}}/>
        </PageForm>
      </Box>
    </Page>
  )
}
