import {Box, Typography} from "@mui/material";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";

interface WizardHelperBasicProps {
  Image: FunctionComponent;
  imageAlt: string;
  helper: string;
}

export default function WizardHelperBasic(props: WizardHelperBasicProps): JSX.Element {
  const {t} = useTranslation();
  const {Image, imageAlt, helper} = props;

  return (
    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      {/* @ts-ignore */}
      <img src={Image} alt={imageAlt} style={{maxWidth: "435px"}}/>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", mt: 10}}>
        <Typography variant="h2" color="neutral.main" sx={{fontWeight: "bold", mb: 2}}>
          {t(`${helper}.title`)}
        </Typography>
        <Typography variant="body1" color="neutral.main" sx={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: t(`${helper}.description`)}}/>
      </Box>
    </Box>
  )
}
