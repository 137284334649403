import React, {useContext, useEffect} from 'react'
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DashboardCard, TopBrandsCardChildren, TopGiftsCardChildren} from './dashboard/dashboardCards';
import PageCard from "../../patterns/page/pageCard";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import {OperationMainPageProps} from "./operationProps";
import {StoreContext} from '../../common/struct/store';
import {AppContext} from '../../App';

export default function OperationDashboard(props: OperationMainPageProps) : JSX.Element {
  const {t} = useTranslation();
  const {operation} = props;
  const [store] = useContext<StoreContext>(AppContext);
  useEffect(() => {
    document.title = t("tab_title.operation.dashboard");
    
  }, []);
  return <>
    <Grid container rowSpacing={5} columnSpacing={5} sx={{mb: 5}}>
      <Grid item xs={6}>
        <PageCard
          sx={{mb: 5}}
          id="dashboard-points"
          title={t("operations.dashboard.participant_balance.title")}
          action={t("operations.dashboard.participant_balance.button_label")}
          actionUrl={operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANTS, operation!.id)}>
          <DashboardCard
            primaryMetric={Math.ceil((operation.totalRewardAmount - operation.totalSpentAmount) / store.global.company.euroPerPoint)}
            secondaryMetric={Math.ceil(operation.totalRewardAmount / store.global.company.euroPerPoint)}
            label="participant_balance"
            pointsIndicator={true}
          />
        </PageCard>
        <PageCard
          id="dashboard-gifts"
          title={t("operations.dashboard.participant_orders.title")}
          action={t("operations.dashboard.participant_orders.button_label")}
          actionUrl={operationUrl(PRIVATE_URL.OPERATION_GIFTS_HISTORY, operation!.id)}>
          <DashboardCard
            primaryMetric={operation.orderStats.totalOrderLines}
            secondaryMetric={operation.orderStats.averageOrderEuro}
            label="participant_orders"
          />
        </PageCard>
      </Grid>
      <Grid item xs={6}>
        <PageCard
          sx={{mb: 5}}
          id="dashboard-balance"
          title={t("operations.dashboard.consumed_points.title")}>
          <DashboardCard
            primaryMetric={Math.ceil(operation.totalSpentAmount / store.global.company.euroPerPoint)}
            secondaryMetric={Math.ceil(operation.totalRewardAmount / store.global.company.euroPerPoint)}
            label="consumed_points"
            pointsIndicator={true}
          />
        </PageCard>
        <PageCard
          id="dashboard-basket"
          title={t("operations.dashboard.participants_with_order.title")}>
          <DashboardCard
            primaryMetric={operation.orderStats.totalParticipants}
            secondaryMetric={operation.participantStats.total}
            label="participants_with_order"
          />
        </PageCard>
      </Grid>
    </Grid>
    <Grid container rowSpacing={5} columnSpacing={5}>
      <Grid item xs={6}>
        <PageCard
          sx={{height: "100%", boxSizing: "border-box", justifyContent: "unset"}}
          id="dashboard-top-gifts"
          title={t("operations.dashboard.best_gifts.title")}>
          <TopGiftsCardChildren operation={operation}/>
        </PageCard>
      </Grid>
      <Grid item xs={6}>
        <PageCard
          id="dashboard-top-brands"
          sx={{height: "100%", boxSizing: "border-box", justifyContent: "unset"}}
          title={t("operations.dashboard.best_brands.title")}>
          <TopBrandsCardChildren operation={operation}/>
        </PageCard>
      </Grid>
    </Grid>
  </>
}
