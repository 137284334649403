import React, {useContext, useEffect, useState} from 'react';
import {Box, Card, Checkbox, FormControlLabel, FormGroup, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {emptyFilter, errorManager, TFilter} from "../../common/methods/ApiService";
import {OperationWizardCardProps, OperationWizardSteps} from "../createOperationWizard/operationWizardSteps";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION, NEXT_STEP} from "../../common/methods/context-setter/globals";
import {operationStoreReducer} from "../../common/methods/context-setter/operationStoreReducer";
import {StoreContext, WizardType} from "../../common/struct/store";
import {AppContext} from "../../App";
import {setStepToValidateWizardStore} from "../../common/methods/context-setter/wizardStoreReducer";
import {TShopUniverse} from "../../interfaces/shop";
import {getAllGiftsCount, getShopUniverses, putShop} from "../../services/ShopService";
import OperationUniverseCard from "./OperationSelectionUniversesCard";
import {Loader} from '../../components/loader/loader';
import {useNavigate} from "react-router-dom";

export default function OperationUniversesCard(props: OperationWizardCardProps): JSX.Element {
  const {t} = useTranslation();
  const {propsNoWizard, setError, loading, setLoading, sx} = props;
  useEffect(() => {
    document.title = t("tab_title.wizard.step3");
  }, []);
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const operation = store.operationWizard.operation??store.global.currentOperation;

  const [universes, setUniverses] = useState<TShopUniverse[]>([]);
  const [subUniversesIds, setSubUniversesIds] = useState<number[]>([]);
  const [selectedUniverses, setSelectedUniverses] = useState<number[]>([]);
  const [nbGiftsSelected, setNbGiftsSelected] = useState<number>(0);
  const [nbGifts, setNbGifts] = useState<number>(0);
  const [allChecked, setAllChecked] = useState(true);
  const [filter, setFilter] = useState<TFilter>(emptyFilter);
  const [loadingUniverses, setLoadingUniverses] = useState<boolean>(true);
  const navigate = useNavigate();

  const selectAllUniverses = (): void => {
    setSelectedUniverses(allChecked ? [] : subUniversesIds);
  }

  const handleSelectedUniverses = (universesId: number[]): void => {
    setSelectedUniverses(universesId);
  }

  const loadShopUniverses = (shopId: string): void => {
    setLoadingUniverses(true);

    getShopUniverses(shopId)
      .then(universes => {
        setUniverses(universes);

        const allIds: number[] = [];
        universes.forEach(universe => universe.children.forEach(child => allIds.push(child.id)));
        setSubUniversesIds(allIds);
        setLoadingUniverses(false);
      })
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
        setLoadingUniverses(false);
      });
  }

  const loadShopGiftsCount = (shopId: string, filter: TFilter): void => {
    getAllGiftsCount(shopId, filter.universIds)
      .then(data => setNbGiftsSelected(data))
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
      });
  }

  const loadTotalGiftsCount = (shopId: string): void => {
    getAllGiftsCount(shopId)
      .then(data => setNbGifts(data))
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
      });
  }

  useEffect(() => {
    if (operation == null) {
      return;
    }

    if (universes.length == 0) {
      loadShopUniverses(operation.shops[0].id);
      loadShopGiftsCount(operation.shops[0].id, filter);
      loadTotalGiftsCount(operation.shops[0].id);
    }

    setSelectedUniverses(operation.shops[0].selectedUniversIds);
  }, []);

  useEffect(() => {
    if (selectedUniverses.length > 0 && operation !== undefined) {
      setFilter(prevState => ({
        ...prevState,
        universIds: selectedUniverses
      }))
    } else {
      setNbGiftsSelected(0);
    }
  }, [selectedUniverses]);

  useEffect(() => {
    if (filter.universIds[0] && operation !== undefined) {
      loadShopGiftsCount(operation.shops[0].id, filter);
    }
  }, [filter]);

  useEffect(() => {
    setAllChecked(selectedUniverses.length === subUniversesIds.length);
  }, [selectedUniverses, subUniversesIds]);

  useEffect(() => {
    if (store.operationWizard.stepToValidate !== OperationWizardSteps.SHOP || operation == undefined) {
      return;
    }

    setError(null);
    setLoading(true);

    putShop(operation.shops[0].id, {selectedUniversIds: selectedUniverses})
      .then(shop => {
        setLoading(false);
        operationStoreReducer(STORE, {type: NEXT_STEP, operation: {...operation, shops: [shop]}});
      })
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
      })
      .finally(() => {
        setStepToValidateWizardStore(STORE, WizardType.CREATE_OPERATION, 0);
        setLoading(false);
      })
  }, [store.operationWizard.stepToValidate]);

  useEffect(() => {
    if (loading || propsNoWizard == null || !propsNoWizard.triggerAction || operation == undefined) {
      return;
    }

    setLoading(true);
    putShop(operation.shops[0].id, {selectedUniversIds: selectedUniverses})
      .then(shop => {
        propsNoWizard.setSuccess(t("operations.shop.universes.save_success"));
        globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: {...operation, shops: [shop]}});
      })
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
      })
      .finally(() => {
        propsNoWizard.setTriggerAction(false);
        setLoading(false);
      })
  }, [propsNoWizard?.triggerAction]);

  return (
    loadingUniverses ?
      <Loader/>
      :
      <Box sx={{display: "flex", flexDirection: "column", ...sx}}>
        <Card sx={{display: "flex", alignItems: "center", justifyContent: "space-between", border: "1px solid", borderColor: "ornament.dark", boxShadow: 0, p: 0, mb: 5}}>
          <Box sx={{display: "flex", ml: 2}}>
            <Typography variant="body2" color="neutral.main" sx={{fontWeight: "bold", mr: 0}}>
              {t("operations.shop.universes.subtitle_1", {selected: nbGiftsSelected})}
            </Typography>
            <Typography variant="body2" color="neutral.main">
              {t("operations.shop.universes.subtitle_2", {available: nbGifts})}
            </Typography>
          </Box>
          <Card sx={{backgroundColor: "ornament.main", borderColor: "ornament.dark", py: 1, px: 2, boxShadow: 0}}>
            <FormGroup>
              <FormControlLabel sx={{margin: "unset"}} control={
                <Checkbox checked={allChecked} disabled={loading} onClick={selectAllUniverses} sx={{color: "primary.main", width: "24px", height: "24px", "& .MuiSvgIcon-root": {fontSize: "24px"}}}/>
              } label={<Typography variant="body1" color="neutral.main" sx={{fontWeight: "bold", ml: 0}}>{t("operations.shop.universes.select_all")}</Typography>}/>
            </FormGroup>
          </Card>
        </Card>
        <Box sx={{display: "grid", gridTemplateColumns: "repeat(3, 1fr)", columnGap: 5, rowGap: 5}}>
          {universes.map(universe =>
            <OperationUniverseCard
              key={"universe-" + universe.parent.x_icon}
              universe={universe}
              selectedUniverses={selectedUniverses}
              handleSelectedUniverses={handleSelectedUniverses}
              loading={loading}
            />
          )}
        </Box>
      </Box>
  )
}
