import React, {useState} from "react";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {TParticipant} from "../../../../interfaces/participant";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import {PrimaryBigButton, SecondaryBigButton} from "../../../../components/buttons/mainButton";
import {INPUT_TYPES} from "../../../../patterns/form/formConstants";
import ElementInputWithLabel from "../../../../patterns/form/elementInputWithLabel";

type ChangeParticipantPasswordProps = {
  openDialog: boolean,
  closeDialog: () => void,
  changePassword: (password: string) => Promise<void>,
  participant?: TParticipant
}

export default function ChangeParticipantPassword(props: ChangeParticipantPasswordProps): JSX.Element {
  const {openDialog, closeDialog, changePassword, participant} = props;
  const {t} = useTranslation();

  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const changeParticipantPassword = (): void => {
    setLoading(true);
    changePassword(password).finally(() => {
      setLoading(false);
      closeDialog();
    })
  }

  const participantName = (participant: TParticipant|null): string => {
    if (participant == null) {
      return "";
    }
    const names = [participant.firstName??"", participant.lastName??""];
    return names.join(" ");
  }

  return participant == null ? <></> :
    <Dialog open={openDialog} onClose={closeDialog} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: "warning.main", borderColor: "warning.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <ReportProblemIcon sx={{color: "warning.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {t("participants.change_password.title")}
          </Typography>
        </Box>
        <IconButton onClick={closeDialog}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1" sx={{mb: 3}}>{t("participants.change_password.subtitle", {name: participantName(participant)})}</Typography>
        <ElementInputWithLabel name="password" type={INPUT_TYPES.PASSWORD} passwordCheck value={password} updateValue={setPassword} canSubmit={canSubmit} setCanSubmit={setCanSubmit} violations={[]}/>
        <Box sx={{display: "flex", alignItems: "start", mt: 3}}>
          <WarningAmberOutlinedIcon sx={{mr: 1, mt: 1, color: "warning.main"}}/>
          <Typography variant="body1" color="warning.main">
            {t("participants.change_password.alert_text")}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <SecondaryBigButton sx={{mr: 0}} label={t("participants.change_password.cancel_button")} action={closeDialog} disabled={loading}/>
        <PrimaryBigButton action={changeParticipantPassword} sx={{ml: 2}} label={t("participants.change_password.confirmation_button")} disabled={!canSubmit} loading={loading} />
      </DialogActions>
    </Dialog>
}
