import {Box} from "@mui/material";
import React from "react";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import fr from "../../locales/fr/frenchMessages.json";
import dev from "../../locales/dev/emptyMessages.json";
import {format as formatDate, isDate} from "date-fns";
import {enGB as english, fr as french} from "date-fns/locale";
import {isProd} from "../../common/struct/globalVar";

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      fr: {
        translation: fr
      },
      dev: {
        translation: dev
      }
    },
    lng: "fr",
    fallbackLng: "dev",
    initImmediate: true,
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (isDate(value) && lng && format) {
          const locale = lng == "en" ? english : french;
          return formatDate(value, format, {locale});
        } else {
          return value;
        }
      }
    },
  });

export default function LanguageSelector(): JSX.Element {
  const changeLanguage = (lng: string): void => {
    i18n.changeLanguage(lng);
  }

  return (
    <Box sx={{display: isProd() ? "none" : "flex", position: "fixed", bottom: 0, left: 0, zIndex: 999}}>
      <button onClick={():void => changeLanguage('fr')}>fr</button>
      <button onClick={():void => changeLanguage('dev')}>dev</button>
    </Box>
  )
}
