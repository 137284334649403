import React from "react";
import {OperationProps} from "../operationProps";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import {translate} from "../operationCustomisation";
import {TEmailTemplateType} from "../../../interfaces/email";

export default function EmailsContentCard(props: OperationProps): JSX.Element {
  const {t} = useTranslation();
  const {operation} = props;
  const invitationTemplate = operation.templates?.find(template => template.templateType == TEmailTemplateType.PARTICIPANT_INVITATION);

  return (
    <>
      <Box sx={{display: "flex", alignItems: "center", backgroundColor: "ornament.main", borderRadius: "4px", py: 1, px: 2, mb: 1}}>
        <Box sx={{display: "flex", alignItems: "center", mr: 1}}>
          <EmailIcon sx={{width: 18, height: 18, color: invitationTemplate ? "neutral.main" : "neutral.light", py: "2px"}}/>
          {invitationTemplate && <Typography variant="body2" color="neutral.main" sx={{ml: 1}}>{translate(t, "emails.invitation")}</Typography>}
        </Box>
        <Typography variant="body2" sx={{fontWeight: invitationTemplate ? "medium" : "regular", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
          {invitationTemplate?.subject??translate(t, "emails.invitation_empty")}
        </Typography>
      </Box>
      <Box sx={{display: "flex", alignItems: "center", backgroundColor: "ornament.main", borderRadius: "4px", py: "6px", px: 2}}>
        <Box sx={{display: "flex", alignItems: "center", mr: 1}}>
          <PersonIcon sx={{width: 18, height: 18, color: operation.mailSender ? "neutral.main" : "neutral.light", py: "2px"}}/>
          {operation.mailSender && <Typography variant="body2" color="neutral.main" sx={{ml: 1}}>{translate(t, "emails.sender")}</Typography>}
        </Box>
        <Typography variant="body2" sx={{fontWeight: operation.mailSender ? "medium" : "regular"}}>
          {operation.mailSender??translate(t, "emails.sender_empty")}
        </Typography>
      </Box>
    </>
  )
}
