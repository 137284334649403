import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {PrimaryBigButton} from "../../../components/buttons/mainButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {IconPosition} from "../../../components/buttons/buttonProps";
import {getPreviewToken} from "../../../services/OperationService";
import {TOperation} from "../../../interfaces/operation";

export function AccessToShopButton(props: {operation: TOperation}): JSX.Element {
  const {operation} = props;
  const {t} = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const generatePreviewLink = (): void => {
    setLoading(true);
    getPreviewToken(operation.id)
      .then((res) => {        
        window.open("//" + operation.hostname + "/connexion?token=" + res.token, "_blank");
      })
      .finally(() => 
        setLoading(false)
      );
  }

  return (
    <PrimaryBigButton id={"show-vitrine"} label={t("operations.customisation.preview")} action={generatePreviewLink} icon={VisibilityIcon} position={IconPosition.LEFT} rightChevron loading={loading} />
  )
}
