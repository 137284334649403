import {TAdmin} from "./admin";
import {TCompanyAware, TLifecycle} from "./traits";

export enum TParticipantAttributeType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date'
}

export interface TParticipantAttribute extends TLifecycle, TCompanyAware {
  label: string;
  description?: string;
  type: TParticipantAttributeType;
  createdBy: string;
  admin: TAdmin;
}

export interface TParticipantAttributeValue extends TLifecycle {
  value: string;
  participantAttribute: TParticipantAttribute;
}

export interface TParticipantAttributeCreate {
  label: string;
  description?: string;
  type: string;
}

export interface TParticipantAttributeUpdate {
  label: string;
  description?: string;
  type: string;
}
