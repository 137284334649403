import {useTranslation} from "react-i18next";
import * as React from "react";
import {useEffect, useState} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {INPUT_TYPES} from "./formConstants";

export type ElementInputProps = {
  name: string,
  label?: string,
  placeholder?: string,
  type: string,
  value: string,
  updateValue: (value: string) => void,
  violations?: any[],
  required?: boolean,
  disabled?: boolean,
  password?: boolean,
  sx?: SxProps<Theme>
}

// TODO: ne plus utiliser, passer uniquement par elementInputWithLabel avec un label vide (modifier le nom une fois le changement effectué)
const ElementInput = (props: ElementInputProps): JSX.Element => {
  const {t} = useTranslation();
  const {name, label, placeholder, type, value, updateValue, violations, required, disabled, password, sx} = props;
  const [errorMessage, setErrorMessage] = useState(null);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (): void => setShowPassword(!showPassword);
  const handleMouseDownPassword = (): void => setShowPassword(!showPassword);

  useEffect(() => {
    setErrorMessage(violations?.find((violation: any) => violation.field === name)?.error);
  }, [violations]);

  const isPhone = (value: string): boolean => {
    const number = Number(value);
    return !isNaN(number) && number >= 0;
  }
  const handleChange = (e: any): void => {
    const value = e.target.value;
    if (value.length == 0) {
      updateValue(value);
      return;
    }
    if (type == INPUT_TYPES.NUMBER && isNaN(value)) {
      return;
    }
    if (type == INPUT_TYPES.TELEPHONE && !isPhone(value)) {
      return;
    }
    updateValue(value);
  }
  return <TextField
    sx={{"&input": {p: 3}, ...sx}}
    color="info"
    type={showPassword ? "text" : type}
    fullWidth
    error={errorMessage !== undefined}
    helperText={errorMessage}
    id={name}
    label={label ? t(label) : ""}
    placeholder={placeholder}
    value={value}
    onChange={handleChange}
    required={required ?? false}
    disabled={disabled ?? false}
    InputProps={{
      endAdornment: (
        password ?
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment> : ''
      )
    }}
  />
}
export default ElementInput;
