import React from "react";
import {OperationProps} from "../operationProps";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import {translate} from "../operationCustomisation";
import FileIcon from '../../../assets/file.svg';
import FilePdfIcon from '../../../assets/file_pdf.svg';
import FileTextIcon from '../../../assets/file_other.svg';
import {TOperationRulesFormat} from "../../../interfaces/operation";

export default function RulesCard(props: OperationProps): JSX.Element {
  const {t} = useTranslation();
  const rules = props.operation.rules;
  const size = (ruleSize?: number): string => {
    if (ruleSize == null) {
      return '0 Ko';
    }
    return ruleSize < 1000 ? `${ruleSize} octets` : `${Math.round(ruleSize / 1000)} Ko`;
  }

  return <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
    {rules && <Box sx={{display: "flex", alignItems: "center", backgroundColor: "ornament.main", p: 2}}>
      {/** @ts-ignore */}
      <img src={rules.format == TOperationRulesFormat.PDF ? FilePdfIcon : FileTextIcon} alt="" style={{height: 48}}/>
      <Typography variant="body2" sx={{ml: 3}}>{rules.name??translate(t, "rules.text")}</Typography>
    </Box>}
    {rules == null && <Box sx={{border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", p: 0}}>
      <Box sx={{display: "flex", alignItems: "center", backgroundColor: "ornament.main", borderRadius: "4px", p: 1}}>
        {/** @ts-ignore */}
        <img src={FileIcon} alt=""/>
        <Box sx={{display: "flex", flexDirection: "column", ml: 3}}>
          <Typography variant="body2">{translate(t, "rules.create_label_1")}</Typography>
          <Typography variant="body2">{translate(t, "rules.create_label_2")}</Typography>
        </Box>
      </Box>
    </Box>}
    <Box sx={{display: "flex", mt: 1}}>
      <Typography variant="body2" sx={{fontWeight: "bold", mr: 1}}>{size(rules?.size)}</Typography>
      <Typography variant="body2">{rules ? translate(t, "rules.last_updated", {date: new Date(rules.lastUpdate)}) : translate(t, "rules.no_rules")}</Typography>
    </Box>
  </Box>
}
