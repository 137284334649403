import {emptyFilter, ESorting, get, getPaginatedShop, post, put, TFilter} from "../common/methods/ApiService";
import {TShop, TShopUniverse, TShopUpdate} from "../interfaces/shop";

export const GIFTS_PER_PAGE = 16;
export const GIFTS_LIMIT = 96;
export const BATCHS = 6;

export function excludeGiftFromShop(shopId: string, giftId: number): Promise<number[]> {
  return post(`shops/${shopId}/gifts/${giftId}/exclude`, null);
}

export function includeGiftInShop(shopId: string, giftId: number): Promise<number[]> {
  return post(`shops/${shopId}/gifts/${giftId}/include`, null);
}

export function getAllGiftsCount(shopId: string, universIds?: number[]): Promise<number> {
  if (universIds) {
    return get(`shops/${shopId}/allGiftsCount?universIds=`+universIds.join(","));
  } else {
    return get(`shops/${shopId}/allGiftsCount`);
  }
}

export function getShopGiftsCount(shopId: string): Promise<number> {
  return get(`shops/${shopId}/giftsCount`);
}

export function getShopGifts(shopId: string, page: number, itemsPerPage?: number|null, filter?: TFilter, sorting?: ESorting, search?: string): Promise<{totalItems: number, items: any[]}> {
  return getPaginatedShop(`shops/${shopId}/gifts`, page, itemsPerPage ?? GIFTS_PER_PAGE, search ?? null, sorting ?? null, filter ?? emptyFilter);
}

export function getShopUniverses(shopId: string): Promise<TShopUniverse[]> {
  return get(`shops/${shopId}/universes`);
}

export function getBrands(): Promise<string[]> {
  return get("brands");
}

export function getShop(shopId: string): Promise<TShop> {
  return get(`shops/${shopId}`);
}

export function putShop(shopId: string, entity: TShopUpdate): Promise<TShop> {
  return put("shops", shopId, entity);
}
