import React from 'react';
import {Alert, Box, Typography} from "@mui/material";
import {StoreWizard} from "../../common/struct/store";
import {useTranslation} from "react-i18next";
import {WizardSize} from "./wizardProps";

interface WizardContentProps {
  children: JSX.Element|JSX.Element[];
  wizard: StoreWizard;
  size: WizardSize;
  error: string|null;
}

export default function WizardContent(props: WizardContentProps): JSX.Element {
  const {children, wizard, size, error} = props;
  const {t} = useTranslation();

  const contentWidth = (): {width: string, maxWidth: string} => {
    switch (size) {
    case WizardSize.FIFTY_FIFTY:
      return {width: "50%", maxWidth: "650px"};
    case WizardSize.SIXTY_FORTY:
      return {width: "60%", maxWidth: "780px"};
    case WizardSize.SEVENTY_THIRTY:
      return {width: "70%", maxWidth: "910px"};
    case WizardSize.FULL:
      return {width: "100%", maxWidth: "1300px"};
    }
  }

  const widths = contentWidth();
  return (
    <Box sx={{overflow: "auto", height: "auto", minHeight: "calc(100vh - 181px)", boxSizing: "border-box", py: 11, px: 7,
      width: widths.width, backgroundColor: "ornament.light"}}>
      <Box sx={{maxWidth: widths.maxWidth, boxSizing: "border-box", mx: "auto"}}>

        <Box sx={{mb: 1, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          {((): any => {
            const elements = [];
            for (let i = 0; i < wizard.maximumStep; i++) {
              if (!(i == (wizard.currentStep-1))) {
                elements.push(<><Box sx={{borderRadius: "100%", backgroundColor: "ornament.light", borderColor: "primary.light", borderStyle: "solid", borderWidth: "1px", height: "34px", width: "34px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <Typography variant="body1" color="primary.light" sx={{fontWeight: "unset", lineHeight: "unset"}}>{i+1}</Typography>
                </Box>
                {!(i == (wizard.maximumStep-1)) && <Box sx={{flex: 1, height: "1px", backgroundColor: "primary.main", mx: "24px"}}></Box>}</>);
              } else {
                elements.push(<><Box sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "fit-content"}}>
                  <Box sx={{borderRadius: "100%", backgroundColor: "primary.main", height: "34px", width: "34px", display: "flex", alignItems: "center", justifyContent: "center", mr: 2}}>
                    <Typography variant="body1" color="ornament.light" sx={{fontWeight: "unset", lineHeight: "unset"}}>{wizard.currentStep}</Typography>
                  </Box>
                  <Typography variant="body1" color="primary.main" sx={{fontWeight: "unset", lineHeight: "unset", fontSize: "20px"}}>{t(`wizard.${wizard.type}.step_${wizard.currentStep}.title`)}</Typography>
                </Box>
                {!(i == (wizard.maximumStep-1)) && <Box sx={{flex: 1, height: "1px", backgroundColor: "primary.main", mx: "24px"}}></Box>}</>);
              }
            }
            return elements;
          })()}
        </Box>
        <Typography variant="body1" color="neutral.main" sx={{fontWeight: "unset", mt: 3, mb: 7}}
          dangerouslySetInnerHTML={{__html: t(`wizard.${wizard.type}.step_${wizard.currentStep}.description`)}}/>
        {error && <Alert severity="error" variant="filled" sx={{mb: 3}}>{error}</Alert>}
        {children}
      </Box>
    </Box>
  )
}
