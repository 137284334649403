import React, {MutableRefObject, useState} from "react";
import {Box, ClickAwayListener, InputAdornment, TextField, Tooltip} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {GreySmallButton} from "../../../../components/buttons/mainButton";
import {IconPosition} from "../../../../components/buttons/buttonProps";
import {useTranslation} from "react-i18next";
import {LIBERTY_THEME} from "../../../../tokens/libertyTheme";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ListColumns from "../../../../patterns/list/listColumns";
import {GridApiPro} from "@mui/x-data-grid-pro/models/gridApiPro";
import {gridColumnVisibilityModelSelector, GridFilterListIcon} from "@mui/x-data-grid-pro";

interface ListParticipantTransactionsToolbar {
  disableActions: boolean;
  disableFilters: boolean;
  search: string;
  setSearch: (search: string) => void
  apiRef: MutableRefObject<GridApiPro>;
  openColumnsDialog: boolean;
  setFiltersAnchorEl: (el: HTMLElement|null) => void;
  setOpenColumnsDialog: (open: boolean) => void;
}

export default function ListParticipantTransactionsToolbar(props: ListParticipantTransactionsToolbar): JSX.Element {
  const {disableActions, disableFilters, search, setSearch, openColumnsDialog, setOpenColumnsDialog,
    apiRef, setFiltersAnchorEl} = props;
  const {t} = useTranslation();

  const visibilityModel = gridColumnVisibilityModelSelector(apiRef);
  const setVisibilityModel = apiRef.current.setColumnVisibilityModel;
  const columns = apiRef.current.getAllColumns();

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [localeSearch, setLocaleSearch] = useState(search);

  const handleFiltersClick = (): void => {
    setFiltersAnchorEl(document.getElementById("right-block"));
    apiRef.current.showFilterPanel();
  };

  const applySearch = (event:any): void => {
    event.preventDefault()
    if (localeSearch == search) {
      return;
    }
    setSearch(localeSearch);
  }

  /** ACTIONS */

  /** DIALOGS */
  const showColumnsDialog = (): void => setOpenColumnsDialog(true)

  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", p: 2, backgroundColor: "ornament.main", borderBottom: "1px solid", borderColor: "ornament.dark"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <form onSubmit={applySearch}>
            <ClickAwayListener onClickAway={(): void => setTooltipVisible(false)}>
              <Tooltip title={t("operation_participants.search_help")} open={tooltipVisible} arrow placement="right">
                <TextField sx={{backgroundColor: "ornament.light", width: 294, "& input": {p: 2, "&::placeholder": {textOverflow: "ellipsis",
                  fontSize: LIBERTY_THEME.typography.body1.fontSize, lineHeight: LIBERTY_THEME.typography.body1.lineHeight}}}}
                placeholder={t("operation_participants.search_help")} onClick={(): void => setTooltipVisible(true)}
                value={localeSearch} onChange={(event): void => setLocaleSearch(event.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="end" sx={{mr: "-10px"}}>
                    <SearchIcon onClick={applySearch} sx={{fontSize: 24, p: "7px", borderRadius: "4px",
                      backgroundColor: tooltipVisible ? "primary.main" : "transparent",
                      color: tooltipVisible ? "ornament.light" : "neutral.main",
                      cursor: tooltipVisible ? "pointer" : "none",
                      boxShadow: tooltipVisible ? 1 : 0}}/>
                  </InputAdornment>,
                }}/>
              </Tooltip>
            </ClickAwayListener>
          </form>
        </Box>
        <Box id="right-block" sx={{display: "flex", alignItems: "center"}}>
          <GreySmallButton disabled={disableActions} label={t("operation_participants.deleted.actions.columns")} icon={FileDownloadIcon} position={IconPosition.LEFT} action={showColumnsDialog}/>
          <GreySmallButton disabled={disableFilters} sx={{ml: 1}} label={t("operation_participants.deleted.actions.filters")} icon={GridFilterListIcon} position={IconPosition.LEFT} action={handleFiltersClick}/>
        </Box>
      </Box>
      {openColumnsDialog && <ListColumns open={openColumnsDialog} setOpen={setOpenColumnsDialog} columns={columns} visibilityModel={visibilityModel} setVisibilityModel={setVisibilityModel}/>}
    </Box>
  )
}
