import Box from "@mui/material/Box";
import {PRIVATE_URL, PUBLIC_URL} from "../../common/struct/urlManager";
import React from "react";
import {useTranslation} from "react-i18next";
import {SxProps, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {Theme} from "@mui/material/styles";


function FooterMenu(props: {menu: string, url?: string, last?: boolean}): JSX.Element {
  const {t} = useTranslation();
  return (
    <Typography variant="body2" color="neutral.main" component={Link} to={props.url??PRIVATE_URL.LANDING_PAGE} sx={{mr: props.last ? "0px" : 5}} target={props.url ? "_blank" : "_self"}>
      {t(`footer.${props.menu}`)}
    </Typography>
  )
}

export default function Footer(props: {sx?: SxProps<Theme>;}): JSX.Element {
  const {sx} = props;
  return (
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", pb: 3, ...sx}}>
      <FooterMenu menu="cgvu" url={PUBLIC_URL.CGVU}/>
      <FooterMenu menu="ppdp" url={PUBLIC_URL.PPDP}/>
      <FooterMenu menu="byLiberty&Co" last/>
    </Box>
  )
}
