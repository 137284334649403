import React, {useContext, useEffect, useState} from "react";
import {Navigate, useLocation, useParams} from "react-router-dom";
import {PUBLIC_URL} from "../struct/urlManager";
import {isAuthenticated} from "../struct/globalVar";
import {getOperation} from "../../services/OperationService";
import {globalStoreReducer} from "../methods/context-setter/globalStoreReducer";
import {AppContext} from "../../App";
import {CURRENT_OPERATION} from "../methods/context-setter/globals";
import {StoreContext} from "../struct/store";
import {Loader} from "../../components/loader/loader";

const PrivateRoute = ({children}: { children: JSX.Element }): JSX.Element => {
  const location = useLocation();
  const {operationId} = useParams<string>();
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (operationId && isAuthenticated() && store.global.currentOperation?.id !== operationId) {
      setLoading(true)
      getOperation(operationId)
        .then(operation => globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation}))
        .catch(error => console.error(error))
        .finally(() => setLoading(false))
    }
  }, [operationId])

  return (
    loading ? <Loader width="100%" /> :
      !isAuthenticated() ? <Navigate to={PUBLIC_URL.LOGIN} state={{from: location}} replace/> : children
  );
}

export default PrivateRoute;
