import Box from "@mui/material/Box";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import Alert from "@mui/material/Alert";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {errorManager, Violation} from "../../common/methods/ApiService";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import ElementInputWithLabel from "../../patterns/form/elementInputWithLabel";
import {useNavigate} from "react-router-dom";
import {TCompanyWallet} from "../../interfaces/company";
import {postWallet, updateWallet} from "../../services/WalletService";
import {trackPromise} from "react-promise-tracker";

interface WalletFormProps {
  onSuccess: (wallet: TCompanyWallet) => void;
  wallet?: TCompanyWallet | null;
}

export default function WalletForm(props: WalletFormProps): JSX.Element {
  const {t} = useTranslation();
  const {onSuccess, wallet} = props;
  const STORE = useContext<StoreContext>(AppContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [violations, setViolations] = useState<Violation[]>([]);
  const [error, setError] = useState<string|null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) : Promise<void> => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const walletToCreate = {
      name: name,
      description: description
    };

    const call = (): Promise<TCompanyWallet> => (wallet) ? updateWallet(wallet.id, walletToCreate) : postWallet(walletToCreate);

    trackPromise(
      call()
        .then(onSuccess)
        .catch(async error => {
          const errorResult = errorManager(error, t, STORE, navigate);
          if (typeof errorResult === 'string') setError(errorResult);
          else if (Array.isArray(errorResult)) setViolations(errorResult);
        })
        .finally(() => setLoading(false))
    );
  };

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setName(wallet?.name ? wallet.name : '');
    setDescription(wallet?.description ? wallet.description : '');

    setLoading(false);
  }, [])

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{display: 'flex', flexDirection: 'column', p: 7}}>
      <ElementInputWithLabel
        required
        name="name"
        value={name}
        type="text"
        label={t("wallets.form.name")}
        placeholder={t("wallets.form.name_placeholder")}
        violations={violations}
        sx={{mb: 3}}
        disabled={loading}
        updateValue={setName}/>
      <ElementInputWithLabel
        name="description"
        value={description}
        type="text"
        label={t("wallets.form.description")}
        violations={violations}
        sx={{mb: 3}}
        disabled={loading}
        updateValue={setDescription}/>
      <PrimaryBigButton submit={true} sx={{mt: 3, mx: "auto"}} loading={loading}
        label={wallet ? t("wallets.form.update_button") : t("wallets.form.create_button")}/>
      {error && <Alert variant="filled" severity="error" sx={{mt: 3}}>{error}</Alert>}
    </Box>
  )
}
