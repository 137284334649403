import {TBrandStats, TGiftStats, TOrderStats, TParticipantStats} from "./stats";
import {TCompanyAware, TLifecycle} from "./traits";
import {TShop} from "./shop";
import {TEmailTemplate} from "./email";

export interface TOperation extends TLifecycle, TCompanyAware {
  /** CHARACTERISTICS */
  name: string;
  description?: string;
  hostname: string;
  customHostname: boolean;
  startDate?: string;
  endDate?: string;
  numId: number;
  reference: string;
  template: number;
  shops: TShop[];

  /** POINTS */
  expirationDate?: string;

  /** SHOWCASE */
  logoFileName?: string;
  logoOriginalFileName?: string;
  logoUrl?: string;
  logoSize?: number;
  bannerFileName?: string;
  bannerOriginalFileName?: string;
  bannerUrl?: string;
  bannerSize?: number;
  faviconFileName?: string;
  faviconOriginalFileName?: string;
  faviconUrl?: string;
  faviconSize?: number;
  primaryColor: string;
  contrastColor: boolean;

  /** MAILS */
  mailSender?: string;
  templates: TEmailTemplate[];

  /** PRESENTATION */
  presentation: string;
  presentationLastUpdate: string;

  /** RULES */
  rules: TOperationRules;

  /** AMOUNTS */
  walletAmount: number;
  totalRewardAmount: number;
  totalSpentAmount: number;
  totalExpiredAmount: number;

  /** DASHBOARD */
  participantStats: TParticipantStats;
  orderStats: TOrderStats;
  transactionCount: number;
  topGifts: TGiftStats[];
  topBrands: TBrandStats[];
}

export interface TOperationLite {
  id: string;
  name: string;
  hostname: string;
  reference: string;
  company: {id: string};
}

export interface TOperationCreate {
  /** CHARACTERISTICS */
  name: string;
  hostname: string;
  startDate?: string;
  endDate?: string;
}

export interface TOperationUpdate {
  /** CHARACTERISTICS */
  name?: string;
  description?: string;
  hostname?: string;
  customHostname?: boolean;
  startDate?: string;
  endDate?: string;
  numId?: number;
  reference?: string;
  template?: number;
  shops?: TShop[];
  mailSender?: string;

  /** POINTS */
  expirationDate?: string;

  /** SHOWCASE */
  primaryColor?: string;
  contrastColor?: boolean;

  /** PRESENTATION */
  presentation?: string|null;
}

export enum TOperationRulesFormat {
  PDF = "PDF",
  TEXT = "TEXT"
}

export interface TOperationRules {
  format: TOperationRulesFormat;
  name?: string;
  content: string;
  size: number;
  lastUpdate: string;
}
