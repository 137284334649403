import {getSelectedCompany, getToken, isSuperAdmin, mustStayConnected} from "../struct/globalVar";
import {API_BO_URL, PUBLIC_URL} from "../struct/urlManager";
import {refreshToken} from "../../services/AuthenticationService";
import {TFunction} from "i18next";
import {StoreContext} from "../struct/store";
import {globalStoreReducer} from "./context-setter/globalStoreReducer";
import {LOGOUT} from "./context-setter/globals";
import {NavigateFunction} from "react-router-dom";

const DEFAULT_HEADERS = {
  'Accept': 'application/ld+json,application/json',
  'Origin': '*',
  'Cache-Control': 'no-cache',
  'x-requested-with': 'XMLHttpRequest',
  'Accept-Language': 'fr'
};
const HEADERS_WITH_JSON = {
  ...DEFAULT_HEADERS,
  "Content-Type": "application/json"
};
const HEADERS_WITH_FORM_URL = {
  "Content-Type": "application/x-www-form-urlencoded",
};
const HEADERS_WITH_CSV = {
  ...DEFAULT_HEADERS,
  'Accept': 'text/csv',
  "Content-Type": 'text/csv'
};
export type RequestOrder = {
  field: string;
  sort: string;
}
export enum RequestFilterOperator {
  EQUALS = "=",
  LIKE = "like",
  IS = "IS",
  IS_NOT = "IS_NOT",
  GREATER_THAN = ">",
  GREATER_THAN_OR_EQUALS = ">=",
  LESSER_THAN = "<",
  LESSER_THAN_OR_EQUALS = "<="
}
export type RequestFilterItem = {
  operator: RequestFilterOperator;
  value: string;
  isDate: boolean;
  isDatePrecise: boolean;
}
export type RequestFilter = {
  field: string;
  items: RequestFilterItem[];
}
export type RequestFilterFormatted = {
  field: string;
  value: string;
}
const addOneDayToDate = (dateString: string): string => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  return date.toISOString().substring(0, 10);
}
const addOneSecondToDate = (dateString: string): string => {
  const date = new Date(dateString);
  date.setSeconds(date.getSeconds() + 1);
  return date.toISOString().substring(0, 19);
}
export const formatFilter = (filter: RequestFilter): RequestFilterFormatted[] => {
  return filter.items.flatMap(item => {
    switch (item.operator) {
    case RequestFilterOperator.EQUALS:
    case RequestFilterOperator.IS:
    case RequestFilterOperator.IS_NOT:
      if (item.value == 'NULL') return [{field: `exists[${filter.field}]`, value: item.operator == RequestFilterOperator.IS ? 'false' : 'true'}];
      else if (!item.isDate) return [{field: filter.items.length > 1 ? `${filter.field}[]` : filter.field, value: item.value}];
      return [
        {field: `${filter.field}[after]`, value: item.value},
        {field: `${filter.field}[strictly_before]`, value: (item.isDatePrecise) ? addOneSecondToDate(item.value) : addOneDayToDate(item.value)},
      ]
    case RequestFilterOperator.LIKE:
      return [{field: filter.items.length > 1 ? `${filter.field}[]` : filter.field, value: item.value}];
    case RequestFilterOperator.GREATER_THAN:
      return [{field: `${filter.field}[${item.isDate ? "strictly_after" : "gt"}]`, value: item.value}];
    case RequestFilterOperator.GREATER_THAN_OR_EQUALS:
      return [{field: `${filter.field}[${item.isDate ? "after" : "gte"}]`, value: item.value}];
    case RequestFilterOperator.LESSER_THAN:
      return [{field: `${filter.field}[${item.isDate ? "strictly_before" : "lt"}]`, value: item.value}];
    case RequestFilterOperator.LESSER_THAN_OR_EQUALS:
      return [{field: `${filter.field}[${item.isDate ? "before" : "lte"}]`, value: item.value}];
    }
  })
}

export interface TFilter {
  universIds: number[];
  brands: string[];
  french: boolean;
  ecofriendly: boolean;
  minPoints?: number;
  maxPoints?: number;
}
export enum ESorting {
  POINTS_ASC = "ASC",
  POINTS_DESC = "DESC"
}
export const emptyFilter = {
  universIds: [],
  brands: [],
  french: false,
  ecofriendly: false
}

const headersWithToken = (initialHeaders: HeadersInit = DEFAULT_HEADERS): Headers => {
  const headers = new Headers({...initialHeaders, "Authorization": `Bearer ` + getToken()});
  if (isSuperAdmin() && getSelectedCompany() !== null) {
    headers.append('companyId', getSelectedCompany()??'');
  }

  return headers;
}

export function bodyWithFile(file: File, parameters?: {[key: string]: any}): FormData {
  const body = new FormData();
  body.append("file", file);

  if (parameters) {
    for (const property in parameters) {
      body.append(property, parameters[property]);
    }
  }

  return body;
}

export function bodyWithBlob(blob: Blob): FormData {
  const body = new FormData();
  body.append("file", blob);

  return body;
}

export function get(url: string, order?: RequestOrder, retried = false): Promise<any> {
  const requestUrl = new URL(`${API_BO_URL}/${url}`);
  if (order) requestUrl.searchParams.append(`order[${order.field}]`, order.sort);
  const request = new Request(requestUrl.href, {
    method: 'GET',
    headers: headersWithToken(),
    mode: 'cors'
  });
  return fetch(request)
    .then(response => manageResponse(response, false, retried ? undefined : (): Promise<void> => get(url, order, true)));
}

export function getWithoutToken(url: string): Promise<any> {
  const request = new Request(`${API_BO_URL}/${url}`, {
    method: 'GET',
    headers: DEFAULT_HEADERS,
    mode: 'cors'
  });
  return fetch(request).then(manageResponse);
}

export function getPaginated(url: string, page: number, itemsPerPage: number, search?: string|null, order?: RequestOrder|null, filters: RequestFilter[]|null = [], retried = false): Promise<{totalItems: number, items: any[]}> {
  const requestUrl = new URL(`${API_BO_URL}/${url}`);
  requestUrl.searchParams.append('page', page.toString());
  requestUrl.searchParams.append('itemsPerPage', itemsPerPage.toString());
  if (order) requestUrl.searchParams.append(`order[${order.field}]`, order.sort);
  if (search) requestUrl.searchParams.append('simplesearch', search);
  if (filters) filters.forEach(filter => formatFilter(filter).forEach(formattedFilter => requestUrl.searchParams.append(formattedFilter.field, formattedFilter.value)));

  const request = new Request(requestUrl.href, {
    method: 'GET',
    headers: headersWithToken(),
    mode: 'cors'
  });
  return fetch(request)
    .then((response: Response) => {
      if (response.status === 401 && !retried && mustStayConnected()) {
        return getPaginated(url, page, itemsPerPage, search, order, filters, true);
      }
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json()
        .then((json) => {
          return {
            totalItems: json['hydra:totalItems'] ?? 0,
            items: json['hydra:member'] ?? []
          };
        })
        .catch(() => {
          return {
            totalItems: 0,
            items: []
          }
        });
    })
    .catch(() => {
      return {
        totalItems: 0,
        items: []
      }
    });
}

export function getPaginatedShop(url: string, page: number, itemsPerPage: number, search: string|null, order?: ESorting|null, filters: TFilter = emptyFilter, retried = false): Promise<{totalItems: number, items: any[]}> {
  const requestUrl = new URL(`${API_BO_URL}/${url}`);
  requestUrl.searchParams.append('page', page.toString());
  requestUrl.searchParams.append('itemsPerPage', itemsPerPage.toString());

  if (order) requestUrl.searchParams.append(`order[points]`, order);
  if (search) requestUrl.searchParams.append('simplesearch', search);
  for (const [key, value] of Object.entries(filters)) {
    if (Array.isArray(value)) {
      if (value[0] !== undefined) {
        let requestValue = "";
        value.map((item) => {
          if (item !== value[0]) {
            requestValue = requestValue + "," + item.toString();
          } else {
            requestValue = requestValue + item.toString();
          }
        })

        requestUrl.searchParams.append(key, requestValue)
      }
    } else if (typeof value == "boolean") {
      if (value !== false) {
        requestUrl.searchParams.append(key, value.toString());
      }
    } else if (value !== null) {
      requestUrl.searchParams.append(key, value);
    }
  }

  const request = new Request(requestUrl.href, {
    method: 'GET',
    headers: headersWithToken(),
    mode: 'cors'
  });
  return fetch(request)
    .then((response: Response) => {
      if (response.status === 401 && !retried && mustStayConnected()) {
        return getPaginatedShop(url, page, itemsPerPage, search, order, filters, true);
      }
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json()
        .then((json) => {
          return {
            totalItems: json['hydra:totalItems'] ?? 0,
            items: json['hydra:member'] ?? []
          };
        })
        .catch(() => {
          return {
            totalItems: 0,
            items: []
          }
        });
    })
    .catch(() => {
      return {
        totalItems: 0,
        items: []
      }
    });
}

export function getBlob(url: string, retried = false, search?: string, order?: RequestOrder, filters?: RequestFilter[], visibility?: Record<string, boolean>): Promise<any> {
  const requestUrl = new URL(`${API_BO_URL}/${url}`);

  if (order) requestUrl.searchParams.append(`order[${order.field}]`, order.sort);
  if (search) requestUrl.searchParams.append('simplesearch', search);
  if (filters) filters.forEach(filter => requestUrl.searchParams.append(`filter[${filter.field}]`, `${filter.items.map(item => `${item.operator},${item.value}`).join(';')}`));
  if (visibility) Object.keys(visibility).forEach(function(key) {
    requestUrl.searchParams.append(`visibility[${key}]`, `${visibility[key]}`);
  });

  const request = new Request(requestUrl.href, {
    method: 'GET',
    headers: headersWithToken(),
    mode: 'cors'
  });

  return fetch(request)
    .then(response => manageResponse(response, true, retried ? undefined : (): Promise<void> => getBlob(url, true)));
}

export function getCsv(url: string, retried = false): Promise<any> {
  const request = new Request(`${API_BO_URL}/${url}`, {
    method: 'GET',
    headers: headersWithToken(HEADERS_WITH_CSV),
    mode: 'cors'
  });
  return fetch(request)
    .then(response => manageResponse(response, true, retried ? undefined : (): Promise<void> => getCsv(url, true)));
}

export function post(url: string, entity?: any, search?: string|null, filters: RequestFilter[]|null = [], retried = false): Promise<any> {
  const requestUrl = new URL(`${API_BO_URL}/${url}`);

  if (search) requestUrl.searchParams.append('simplesearch', search);
  if (filters) filters.forEach(filter => formatFilter(filter).forEach(formattedFilter => requestUrl.searchParams.append(formattedFilter.field, formattedFilter.value)));

  const request = new Request(requestUrl.href, {
    method: 'POST',
    headers: headersWithToken(HEADERS_WITH_JSON),
    mode: 'cors',
    body: (entity) ? JSON.stringify(entity) : null
  });
  return fetch(request)
    .then(response => manageResponse(response, false, retried ? undefined : (): Promise<void> => post(url, entity, null, null, true)));
}

export function postWithoutToken(url: string, entity: any): Promise<any> {
  const request = new Request(`${API_BO_URL}/${url}`, {
    method: 'POST',
    headers: new Headers(HEADERS_WITH_JSON),
    mode: 'cors',
    body: JSON.stringify(entity)
  });
  return fetch(request).then(manageResponse);
}

export function postExternalWithUrlEncoded(url: string, entity: any): Promise<any> {
  const params = new URLSearchParams();
  Object.keys(entity).forEach(key => params.append(key, entity[key]));

  const request = new Request(url, {
    method: 'POST',
    headers: new Headers(HEADERS_WITH_FORM_URL),
    mode: 'no-cors',
    body: params.toString()
  });
  return fetch(request).then(manageResponse);
}

export function postWithFile(url: string, entity: any, retried = false): Promise<any> {
  const request = new Request(`${API_BO_URL}/${url}`, {
    method: 'POST',
    headers: headersWithToken(),
    mode: 'cors',
    body: entity
  });
  return fetch(request)
    .then(response => manageResponse(response, false, retried ? undefined : (): Promise<void> => postWithFile(url, entity, true)));
}

export function put(url: string, id: string, entity: any, retried = false): Promise<any> {
  const request = new Request(`${API_BO_URL}/${url}/${id}`, {
    method: 'PUT',
    headers: headersWithToken(HEADERS_WITH_JSON),
    mode: 'cors',
    body: JSON.stringify(entity)
  });
  return fetch(request)
    .then(response => manageResponse(response, false,retried ? undefined : (): Promise<void> => put(url, id, entity, true)));
}

export function del(url: string, id: string, retried = false, options = ""): Promise<any> {
  const requestUrl = new URL(`${API_BO_URL}/${url}/${id}${options}`);

  const request = new Request(requestUrl, {
    method: 'DELETE',
    headers: headersWithToken(),
    mode: 'cors'
  });

  return fetch(request)
    .then(response => manageResponse(response, false,retried ? undefined : (): Promise<void> => del(url, id, true)));
}

export function multiDel(url: string, search?: string|null, filters: RequestFilter[]|null = [], walletId?: string|null, retried = false): Promise<any> {
  const requestUrl = new URL(`${API_BO_URL}/${url}`);

  if (search) requestUrl.searchParams.append('simplesearch', search);
  if (filters) filters.forEach(filter => formatFilter(filter).forEach(formattedFilter => requestUrl.searchParams.append(formattedFilter.field, formattedFilter.value)));

  let request;
  if (walletId) {
    request = new Request(requestUrl, {
      method: 'DELETE',
      headers: headersWithToken(),
      mode: 'cors',
      body: JSON.stringify({'walletId': walletId})
    });
  } else {
    request = new Request(requestUrl, {
      method: 'DELETE',
      headers: headersWithToken(),
      mode: 'cors'
    });
  }

  

  return fetch(request)
    .then(response => manageResponse(response, false,retried ? undefined : (): Promise<void> => multiDel(url, null, null, null, true)));
}

function manageResponse(response: Response, isBlob = false, callback?: () => Promise<any>): Promise<any> {
  if (response.status === 401) {
    if (callback && mustStayConnected()) {
      return refreshToken().then(() => callback());
    }
  }

  // Mock responses
  if (response.type === 'opaque' && response.status === 0) {
    return Promise.resolve(null);
  }
  if (!response.ok) {
    return response.json()
      .catch(() => Promise.reject(response))
      .then((json) => {
        if (json['violations']) {
          return Promise.reject(json['violations']);
        }
        if (json['hydra:description']) {
          return Promise.reject(json['hydra:description']);
        }
        return Promise.reject(response);
      });
  }
  if (isBlob) {
    return Promise.resolve(response);
  }
  if (response.body == null) {
    return Promise.resolve(null);
  }

  return response.json()
    .then((json) => {
      return (json['hydra:member']) ? json['hydra:member'] : json;
    })
    .catch(() => null);
}

export type Violation = {
  field: string,
  error: string
}

export function errorManager(error: any, t: TFunction, store: StoreContext, navigate: NavigateFunction, redirectToLogin = true): string | Violation[] {
  let errorMessage: any;
  if (Array.isArray(error)) {
    errorMessage = error.map((violation) => {
      return {
        field: violation.propertyPath,
        error: violation.message
      }
    });
  }
  else if (error instanceof Response) {
    switch (error.status) {
    case 500:
      errorMessage = t('auth_provider.error500');
      break;
    case 503:
      navigate(PUBLIC_URL.MAINTENANCE_MODE_ON);
      break;
    case 401:
      if (redirectToLogin) {
        globalStoreReducer(store, {type: LOGOUT});
      } else {
        errorMessage = t('auth_provider.error401');
      }
      break;
    default:
      errorMessage = t("global.default_error_message");
      break;
    }
  }
  else if (typeof error === "string") errorMessage = error;
  else errorMessage = t("global.default_error_message");

  return errorMessage;
}

export function manageStringError(error: string|Violation[], setError: (error: string) => void, t: TFunction): void {
  if (typeof error === 'string') setError(error);
  else setError(t("global.default_error_message"));
}
