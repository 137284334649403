import React, {MutableRefObject, useEffect, useState} from "react";
import {Box, Menu, Typography} from "@mui/material";
import {GreySmallButton} from "../../components/buttons/mainButton";
import {IconPosition} from "../../components/buttons/buttonProps";
import {useTranslation} from "react-i18next";
import ListColumns from "./listColumns";
import {GridApiPro} from "@mui/x-data-grid-pro/models/gridApiPro";
import {
  gridColumnVisibilityModelSelector,
  GridFilterListIcon,
  gridFilterModelSelector,
  gridVisibleRowCountSelector
} from "@mui/x-data-grid-pro";
import ListSearch from "./listSearch";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ListMenuItem from "./listMenuItem";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ResetColumn from "../../pages/operationParticipants/listParticipants/actions/resetColumn";


interface ListToolbarProps {
  translationPrefix: string;
  dialogs?: JSX.Element[];
  selectedActions?: JSX.Element[];
  globalActions?: JSX.Element[];
  listActions?: JSX.Element[];
  apiRef: MutableRefObject<GridApiPro>;
  search: string;
  setSearch: (search: string) => void;
  setFiltersAnchorEl: (el: HTMLElement|null) => void;
  enableToolbarSearch: boolean
}

export default function ListToolbar(props: ListToolbarProps): JSX.Element {
  const {translationPrefix, dialogs, selectedActions, globalActions, listActions, apiRef, search, setSearch, setFiltersAnchorEl, enableToolbarSearch} = props;
  const {t} = useTranslation();

  const visibilityModel = gridColumnVisibilityModelSelector(apiRef);
  const setVisibilityModel = apiRef.current.setColumnVisibilityModel;
  const columns = apiRef.current.getAllColumns();
  const disableFilters = gridFilterModelSelector(apiRef).items.length == 0 && gridVisibleRowCountSelector(apiRef) == 0;

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState(apiRef.current.getSelectedRows().size);
  const [openColumnsDialog, setOpenColumnsDialog] = useState(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null|HTMLElement>(null);
  const [openResetDialog, setOpenResetDialog] = useState(false);

  const showColumnsDialog = (): void => setOpenColumnsDialog(true)
  const closeResetDialog = (): void => setOpenResetDialog(false);
  const handleFiltersClick = (): void => {
    setFiltersAnchorEl(document.getElementById("right-block"));
    apiRef.current.showFilterPanel();
  };

  const handleActionsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = (): void => {
    setActionsAnchorEl(null);
  };

  const columnReset = (): void => {
    apiRef.current.setFilterModel({items: []})
    apiRef.current.setSortModel([])
    apiRef.current.updateColumns(columns)
    setSearch('')
  }

  const showResetDialog = (): void => {
    setOpenResetDialog(true);
    handleActionsClose();
  }

  useEffect(() => {
    setSelectAll(false);
    setSelectedRows(apiRef.current.getSelectedRows().size);
  }, [apiRef.current.getSelectedRows()])

  useEffect(() => {
    setSelectedRows(selectAll ? apiRef.current.getRowsCount() : apiRef.current.getSelectedRows().size);
  }, [selectAll])

  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      {openResetDialog && <ResetColumn key="column-reset" open={openResetDialog} close={closeResetDialog} title={t("operation_participants.reset_list.title")} content={t("operation_participants.reset_list.content")} reset={columnReset} />}
      {dialogs}
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", p: 2, backgroundColor: "ornament.main", borderBottom: "1px solid", borderColor: "ornament.dark"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          {enableToolbarSearch && <ListSearch search={search} setSearch={setSearch} placeholder={t(`${translationPrefix}.search_help`)}/>}
          {selectedRows > 0 && selectedActions}
        </Box>
        <Box id="right-block" sx={{display: "flex", alignItems: "center"}}>
          {globalActions}
          <GreySmallButton disabled={columns.filter(column => column.filterable).length == 0 || disableFilters} label={t("list.actions.filters")} icon={GridFilterListIcon} position={IconPosition.LEFT} action={handleFiltersClick}/>
          <GreySmallButton sx={{ml: 1}} label={t("operation_participants.actions.button")} icon={actionsAnchorEl ? KeyboardArrowUpIcon : KeyboardArrowDownIcon} position={IconPosition.RIGHT} action={handleActionsClick}/>
          <Menu id="actions-menu" open={actionsAnchorEl != null} onClose={handleActionsClose} onBlur={handleActionsClose} anchorEl={actionsAnchorEl}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}} transformOrigin={{vertical: "top", horizontal: "right"}}
            sx={{"& .MuiPaper-root": {mt: 0, border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", boxShadow: 3}}}>
            <ListMenuItem label={t("operation_participants.actions.columns")} icon={ViewColumnIcon} action={showColumnsDialog}/>
            <ListMenuItem label={t("operation_participants.actions.reset")} icon={RestartAltIcon} action={showResetDialog}/>
            {listActions}
          </Menu>
        </Box>
      </Box>
      {selectedRows > 0 && selectedRows >= apiRef.current.unstable_getViewportPageSize() && <Box sx={{m: 0, p: 3, display: "flex", backgroundColor: "primary.main", borderRadius: "4px"}}>
        <Typography variant="body2" color="ornament.main" sx={{mr: 0}}>
          {t(selectAll ? `${translationPrefix}.selected_all` : `${translationPrefix}.selected`, {number: selectedRows})}
        </Typography>
        <Typography variant="body2" color="ornament.light" sx={{fontWeight: "bold", textDecoration: "underline", cursor: "pointer"}} onClick={(): void => setSelectAll(!selectAll)}>
          {t(selectAll ? `${translationPrefix}.unselect_all` : `${translationPrefix}.select_all`)}
        </Typography>
      </Box>}
      {openColumnsDialog && <ListColumns open={openColumnsDialog} setOpen={setOpenColumnsDialog} columns={columns} visibilityModel={visibilityModel} setVisibilityModel={setVisibilityModel}/>}
    </Box>
  )
}
