import {Box, IconButton, InputAdornment, LinearProgress, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Violation} from "../../common/methods/ApiService";
import {INPUT_TYPES} from "./formConstants";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {useTranslation} from "react-i18next";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {fr} from "date-fns/locale";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

interface ElementInputWithLabelProps {
  label?: string;
  name: string;
  value?: string;
  placeholder?: string;
  type?: string;
  violations: Violation[];
  updateValue: (value: any) => void;
  disabled?: boolean;
  required?: boolean;
  passwordCheck?: boolean;
  canSubmit?: boolean;
  attribute?: boolean;
  setCanSubmit?: (canSubmit: boolean) => void;
  sx?: SxProps<Theme>;
}

export default function ElementInputWithLabel(props: ElementInputWithLabelProps): JSX.Element {
  const {label, name, value, placeholder, type = INPUT_TYPES.DEFAULT, violations, updateValue, disabled = false, required = false, passwordCheck = false, canSubmit, attribute=false, setCanSubmit, sx} = props;
  const {t} = useTranslation();

  const isPhone = (value: string): boolean => {
    const number = Number(value);
    return !isNaN(number) && number >= 0;
  }
  const handleChange = (e: any): void => { 
    const value = type != INPUT_TYPES.DATE ? e.target.value : moment(e).format("YYYY-MM-DD");
     
    if (!attribute && value.length == 0) {
      updateValue("");
      return;
    }
    if (type == INPUT_TYPES.NUMBER && isNaN(value)) {
      return;
    }
    if (type == INPUT_TYPES.TELEPHONE && !isPhone(value)) {
      return;
    }
    if (type == INPUT_TYPES.PASSWORD) {
      checkPassword(value);
    }
    if (attribute) {
      updateValue((prevState: any) => 
        prevState.map((prevItem: any) => {
          if (prevItem.field === name) {     
            return {...prevItem, value: value != "" ? value : type == INPUT_TYPES.NUMBER ? '0' : ''};
          } else {
            return prevItem;
          }
        })
      )
      return;
    }
    updateValue(value);
  }

  const [showPassword, setShowPassword] = useState(false);
  const [passHasSize, setPassHasSize] = useState<boolean>(false);
  const [passHasUpper, setPassHasUpper] = useState<boolean>(false);
  const [passHasLower, setPassHasLower] = useState<boolean>(false);
  const [passHasDigit, setPassHasDigit] = useState<boolean>(false);
  const [passHasSpecial, setPassHasSpecial] = useState<boolean>(false);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [progressState, setProgressState] = useState<string>(t('form.password.progress.empty'));
  const [passwordViolations, setPasswordViolations] = useState<string[]>([]);
  const [activeViolations, setActiveViolations] = useState<string[]>([]);

  useEffect(() => {
    if (!attribute) {
      setActiveViolations(violations.filter(violation => violation.field === name).map(violation => violation.error));
    } else {
      setActiveViolations(violations.filter(violation => violation.field === "participantAttributeValues."+name).map(violation => violation.error));
    }
  }, [violations])

  const checkPassword = (password: string): void => {
    const violations = [];

    setPassHasSize(password.length >= 8);
    if (password.length < 8) {
      violations.push(t('form.password.violations.too_short'));
    }

    setPassHasUpper(/[A-Z]/.test(password));
    if (!/[A-Z]/.test(password)) {
      violations.push(t('form.password.violations.no_uppercase'))
    }

    setPassHasLower(/[a-z]/.test(password));
    if (!/[a-z]/.test(password)) {
      violations.push(t('form.password.violations.no_lowercase'));
    }

    setPassHasDigit(/\d/.test(password));
    if (!/\d/.test(password)) {
      violations.push(t('form.password.violations.no_digit'));
    }

    const hasSpecialCharacter = /[-_@$!%*#?&+/\\]/.test(password);
    setPassHasSpecial(hasSpecialCharacter);
    if (!hasSpecialCharacter) {
      violations.push(t('form.password.violations.no_special'))
    }

    if (violations.length === 0 || (violations.length === 1 && password.length >= 16)) {
      setProgressState(t('form.password.progress.strong'));
    } else if (violations.length === 1) {
      setProgressState(t('form.password.progress.medium'));
    } else if (password.length > 0) {
      setProgressState(t('form.password.progress.weak'));
    } else {
      setProgressState(t('form.password.progress.empty'));
    }

    setPasswordViolations(violations);
  }

  useEffect(() => {
    if (setCanSubmit == null) {
      return;
    }

    const passwordCriteria = [passHasUpper, passHasLower, passHasDigit, passHasSpecial];
    setProgressValue([passHasSize, ...passwordCriteria].filter(value => value).length * 100 / [passHasSize, ...passwordCriteria].length);
    setCanSubmit(passHasSize && passwordCriteria.filter(value => !value).length < 1);
  }, [passHasSize, passHasUpper, passHasLower, passHasDigit, passHasSpecial])

  return (
    <Box sx={{mb: 5, width: "100%", boxSizing: "border-box", ...sx}}>
      {label && <Typography variant="body2" sx={{fontWeight: "bold", mb: 0, color: "neutral.main"}}>{label}{required ? " *" : ""}</Typography>}
      {type != INPUT_TYPES.DATE ? <TextField
        id={name}
        type={showPassword ? INPUT_TYPES.DEFAULT : type}
        disabled={disabled}
        sx={{"& input": {backgroundColor: "ornament.light", p: 3}}}
        error={activeViolations.length > 0}
        helperText={activeViolations.length > 0 && <div dangerouslySetInnerHTML={{__html: activeViolations.join("<br />")}}/>}
        placeholder={placeholder??""}
        value={value??""}
        onChange={handleChange}
        fullWidth
        InputProps={{
          endAdornment: (type == INPUT_TYPES.PASSWORD) && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={(): void => setShowPassword(!showPassword)}
                onMouseDown={(event): void => event.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff/> : <Visibility/>}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
        :
        <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
          <DatePicker
            key={name}
            value={value}
            inputFormat="dd/MM/yyyy"
            onChange={(newDate: any): void => {
              handleChange(newDate)
            }}
            disabled={disabled}
            renderInput={(params): any =>
              <Box sx={{width: "100%", boxSizing: "border-box"}}>
                <TextField {...params} id={name} value={value??""} inputProps={{...params.inputProps, placeholder: "jj/mm/aaaa"}} error={activeViolations.length > 0} helperText={activeViolations.length > 0 && <div dangerouslySetInnerHTML={{__html: activeViolations.join("<br />")}}/>} sx={{width: "100%", "& input": {backgroundColor: "ornament.light", p: 3}}} />
              </Box>
            }
          />
        </LocalizationProvider>
      }
      {type == INPUT_TYPES.PASSWORD && passwordCheck &&
        <Box sx={{display: "flex", alignItems: "center"}}>
          <LinearProgress sx={{backgroundColor: "ornament.dark", flexGrow: 1}} color={canSubmit ? "success" : "error"} variant="determinate" value={progressValue} />
          <Typography variant="caption" display="inline" sx={{ml: 1, color: "neutral.main"}}>{progressState}</Typography>
        </Box>
      }
      {type == INPUT_TYPES.PASSWORD && passwordCheck && passwordViolations.map((violation, index) => (
        <Box key={index} sx={{display: "flex", alignItems: "center"}}>
          <CheckCircleOutlineIcon sx={{color: "neutral.main", mr: 0}}/>
          <Typography variant="caption" display="inline" sx={{color: "neutral.main"}}>{violation}</Typography>
        </Box>
      ))}
    </Box>
  )
}
