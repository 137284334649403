import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CloseIcon from "@mui/icons-material/Close";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {manageStringError} from "../../common/methods/ApiService";
import {WalletSelector} from "../../pages/operationParticipants/listParticipants/actions/pointsUtils";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";

interface DeleteDialogProps {
  open: boolean;
  close: () => void;
  title: string;
  warning: string;
  deleteElement: () => Promise<void>;
  extraValidation?: string;
  walletSelection?: boolean;
  excludedWallets?: string[];
  buttonLabel?: string;
  cancelLabel?: string;
  newWallet?: string|null;
  setNewWallet?: (newWallet: string|null) => void;
}

export default function DeleteDialog(props: DeleteDialogProps): JSX.Element {
  const {open, close, title, warning, deleteElement, extraValidation, walletSelection, excludedWallets = [], buttonLabel, cancelLabel, newWallet, setNewWallet} = props;
  const {t} = useTranslation();
  const [store] = useContext<StoreContext>(AppContext);

  const [error, setError] = useState<string|null>(null);
  const [value, setValue] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState<string|undefined>(newWallet ? newWallet : store.global.company.defaultWallet ? store.global.company.defaultWallet['@id']: undefined);

  const handleValueChange = (event: any): void => {    
    setValue(event.target.value);
  };

  const actionDeleteElement = (): void => {
    if (extraValidation && value !== extraValidation) {
      setError(t("list.delete.validation_error"));
      return;
    }

    setError(null);
    setDeleting(true);
    deleteElement()
      .then(close)
      .catch(error => manageStringError(error, setError, t))
      .finally(() => setDeleting(false));
  }

  useEffect(() => {
    setNewWallet && selectedWallet && setNewWallet(selectedWallet)
  }, [selectedWallet])

  useEffect(() => {
    newWallet && setSelectedWallet(newWallet)
  }, [open])

  return (
    <Dialog open={open} onClose={close} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: "warning.main", borderColor: "warning.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <ReportProblemIcon sx={{color: "warning.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {title}
          </Typography>
        </Box>
        <IconButton onClick={close}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1" color="neutral.main" sx={{fontWeight: "unset"}} dangerouslySetInnerHTML={{__html: warning}}/>
        {extraValidation && <TextField
          onChange={handleValueChange}
          value={value}
          color="warning"
          disabled={deleting}
          sx={{mt: 3, width: "100%", "& input": {p: 3}}}
          error={error !== null}
          helperText={error}/>}
        {walletSelection &&
          <WalletSelector label={t("wallets.actions.delete_which")} selectedWallet={selectedWallet} setSelectedWallet={setSelectedWallet} excludedWallets={excludedWallets}/>
        }
        {error && <Alert variant="filled" severity="error" sx={{mt: 3}}>{error}</Alert>}
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <SecondaryBigButton sx={{mr: 0}} label={cancelLabel??t("list.delete.cancel")} action={close}/>
        <PrimaryBigButton label={buttonLabel??t("list.delete.confirm")} action={actionDeleteElement} loading={deleting}/>
      </DialogActions>
    </Dialog>
  )
}
