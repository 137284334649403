import {useTranslation} from "react-i18next";
import React, {ElementType} from "react";
import {Link, useLocation} from "react-router-dom";
import {Box, Divider, Typography} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export type ThirdMenuProps = {
  icon: ElementType,
  label: string,
  url: string
}

type ThirdLevelMenuProps = {
  menus: ThirdMenuProps[],
  sx?: SxProps<Theme>
}

function ThirdLevelMenuItem(props: ThirdMenuProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const selected = location.pathname + location.search === props.url;
  const color = selected ? "primary.main" : "neutral.main";
  const Icon = props.icon;

  return (
    <Box sx={{mr: 6}}>
      <Link to={props.url} style={{display: "flex", alignItems: "center"}}>
        <Icon sx={{width: 18, height: 18, color: color}}/>
        <Typography variant="body1" sx={{color: color, fontWeight: "medium", ml: 1}}>
          {t(props.label)}
        </Typography>
      </Link>
      {selected && <Divider sx={{mt: 1, backgroundColor: color, borderRadius: "2px", borderColor: color, width: "100%", height: "2px"}}/>}
    </Box>
  )
}

function ThirdLevelMenu(props: ThirdLevelMenuProps): JSX.Element {
  return (
    <Box sx={{display: "flex", mx: 5, ...props.sx}}>
      {props.menus.map((menu: ThirdMenuProps) => {
        return <ThirdLevelMenuItem key={"menu-" + menu.label} {...menu}/>
      })}
    </Box>
  )
}

export default ThirdLevelMenu;
