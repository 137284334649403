import React, {useState} from "react";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import {FO_URL} from "../../../../common/struct/urlManager";
import {PrimaryBigButton, SecondaryBigButton} from "../../../../components/buttons/mainButton";
import {TOperation} from "../../../../interfaces/operation";
import {TOperationParticipant} from "../../../../interfaces/operationParticipant";
import {TParticipant} from "../../../../interfaces/participant";
import InfoIcon from "@mui/icons-material/Info";

type ConnectAsParticipantProps = {
  openDialog: boolean,
  closeDialog: () => void,
  operation?: TOperation,
  operationParticipant: TOperationParticipant|null
  operationParticipantToken: string|null
}

export default function ConnectAsParticipant(props: ConnectAsParticipantProps): JSX.Element {
  const {t} = useTranslation();
  const [linkCopiedFlag, setLinkCopiedFlag] = useState<boolean>(false);
  const envDev = process.env.REACT_APP_API_HOST;

  const participantName = (participant: TParticipant): string => {
    const names = [participant.firstName??'', participant.lastName??''];
    return participant.lastName ? names.join(' ') : participant.email;
  }

  const autoConnectedLink = (): string => {
    if (envDev !== 'http://localhost') {
      return `https://${props.operation?.hostname}`+ FO_URL.LOGIN +`?token=${props.operationParticipantToken}`;
    } else {
      return `http://${props.operation?.hostname}`+ FO_URL.LOGIN +`?token=${props.operationParticipantToken}`;
    }
  }

  const onLinkCopied = (): void => {
    navigator.clipboard.writeText(autoConnectedLink());
    setLinkCopiedFlag(true);
    setTimeout(() => {
      setLinkCopiedFlag(false);
    }, 3000);
  }

  return (props.operationParticipant && props.operation) ?
    <Dialog open={props.openDialog} onClose={props.closeDialog} sx={{display: "flex", justifyContent: "center"}}>
      <Divider sx={{height: 4, backgroundColor: "info.main", borderColor: "info.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <InfoIcon sx={{color: "info.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" sx={{fontWeight: "bold"}}>{t('participants.auto_connect.title', {name: participantName(props.operationParticipant.participant)})}</Typography>
        </Box>
        <IconButton onClick={props.closeDialog} sx={{padding: "0 0 0 0", width: "24px", height: "24x", maxHeight: "24px"}}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1">{t('participants.auto_connect.content', {name: participantName(props.operationParticipant.participant), operation: props.operation.name})}</Typography>
      </DialogContent>
      <DialogActions sx={{display: 'flex', justifyContent: 'flex-end', p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        {linkCopiedFlag && <Typography color="neutral.main" sx={{fontWeight: "regular", mr: 2}} variant="body2">{t('participants.auto_connect.copy_message')}</Typography>}
        <SecondaryBigButton sx={{height: '56px'}} label={t('participants.auto_connect.copy_button')} action={onLinkCopied}/>
        <PrimaryBigButton sx={{ml: 2, height: '56px'}} action={props.closeDialog} label={t('participants.auto_connect.confirm_button')} href={autoConnectedLink()}/>
      </DialogActions>
    </Dialog> : <></>
}
