export enum WizardSize {
  FIFTY_FIFTY,
  SIXTY_FORTY,
  SEVENTY_THIRTY,
  FULL
}

export interface WizardStep {
  content: JSX.Element|JSX.Element[];
  helper: JSX.Element|JSX.Element[];
  size: WizardSize;
}
