import {Box} from "@mui/material";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {LIBERTY_THEME} from "../../tokens/libertyTheme";
import {frFR, GridFilterModel} from "@mui/x-data-grid";
import ListPagination from "./listPagination";
import EmptyList from "./emptyList";
import ListColumnMenu from "./listColumnMenu";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import emptySearch from "../../assets/lists/empty_search.svg";
import React, {ElementType, MutableRefObject, useContext, useEffect, useState} from "react";
import ListSnackbar, {TAlert} from "./listSnackbar";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {GridSortItem, GridSortModel} from "@mui/x-data-grid/models/gridSortModel";
import {GridColumnVisibilityModel} from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import {GridColumns} from "@mui/x-data-grid/models/colDef/gridColDef";
import {useTranslation} from "react-i18next";
import {RequestFilter, RequestOrder} from "../../common/methods/ApiService";
import {getOperation} from "../../services/OperationService";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {GridApiPro} from "@mui/x-data-grid-pro/models/gridApiPro";
import ListToolbar from "./listToolbar";
import {formatFilters, formatQuickFilters} from "../../common/methods/utils";
import {QuickFilterType} from "../../interfaces/quickFilter";

interface ListProps {
  enableSelection?: boolean;
  enableToolbar?: boolean;
  translationPrefix: string;
  alerts?: TAlert[];
  setAlerts?: (alerts: TAlert[]) => void;
  dialogs?: JSX.Element[];
  selectedDialogs?: JSX.Element[];
  panels?: JSX.Element[];
  selectedActions?: JSX.Element[];
  globalActions?: JSX.Element[];
  listActions?: JSX.Element[];
  columns: GridColumns;
  apiRef: MutableRefObject<GridApiPro>;
  loadRows: (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]) => Promise<{totalItems: number, items: any[]}>;
  height: string;
  reload?: boolean;
  setReload?: (reload: boolean) => void;
  getRowId?: (row: any) => string;
  defaultOrder?: GridSortItem;
  emptyListIcon: ElementType;
  filters?: GridFilterModel;
  quickFilters?: GridFilterModel;
  setQuickFilterSelected?: (quickFilterSelected: QuickFilterType|null) => void;
  sx?: SxProps<Theme>;
  enableToolbarSearch?: boolean;
  enablePagination?: boolean;
}

export default function List(props: ListProps): JSX.Element {
  const {enableSelection = true, enableToolbar = true, translationPrefix, dialogs, alerts, setAlerts, selectedDialogs, panels, selectedActions, globalActions, listActions, columns,
    apiRef, loadRows, reload, setReload, getRowId, defaultOrder, height, emptyListIcon, filters, quickFilters, setQuickFilterSelected, sx, enableToolbarSearch = true, enablePagination = true} = props;
  const {t} = useTranslation();

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const operationId = store.global.currentOperation?.id;

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [maxItems, setMaxItems] = useState(50);
  const [search, setSearch] = useState("");
  const [sortingModel, setSortingModel] = useState<GridSortModel>([defaultOrder ? defaultOrder : {field: 'createdAt', sort: 'desc'}]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({items: []});
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<null|HTMLElement>(null);

  const loadElements = (): Promise<void> => {
    if (filterModel.items.length > 0 && filterModel.items.find(item => item.operatorValue !== "isEmpty" && item.value == null)) {
      return Promise.resolve();
    }

    setLoading(true);
    let filters: RequestFilter[] = formatFilters(filterModel, columns, []);
    let sort: RequestOrder = {field: "", sort: ""};
    if (sortingModel[0]) {
      sort = {field: sortingModel[0].field, sort: sortingModel[0].sort??""}
    } else {
      sort = {field: defaultOrder ? defaultOrder.field : "createdAt", sort: (defaultOrder && typeof defaultOrder.sort === "string") ? defaultOrder.sort : "desc"}
    }

    if (quickFilters) {
      filters = filters && quickFilters ? filters.concat(formatQuickFilters(quickFilters)) : filters ? filters : formatQuickFilters(quickFilters);
    }

    return loadRows(page + 1, maxItems, search, sort, filters).then(result => {
      setRows(result.items);
      setTotalRows(result.totalItems);
    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    loadElements();
  }, [page, search, sortingModel, filterModel, maxItems])

  useEffect(() => {
    if (filters) {
      setFilterModel((prevState) => ({items: prevState.items.filter((prevItem) => prevItem.columnField !== "createdAt")}));
      filters?.items.map(filter => {
        setFilterModel(prevState => ({
          items: [...prevState.items, {columnField: filter.columnField, operatorValue: filter.operatorValue, value: filter.value, id: Math.floor(Math.random() * 1000000)}]
        }))
      })
    }
  }, [filters])

  useEffect(() => {
    // Reset de la sélection de filtre rapide si l'un des filtres de date est supprimé ou modifier
    let quickFilterCount = 0;
    filters && filters.items.map(item => {
      filterModel && filterModel.items.map(quickFilterItem => {
        if (quickFilterItem.columnField === item.columnField && quickFilterItem.operatorValue === item.operatorValue && quickFilterItem.value === item.value) {
          quickFilterCount++;
        }
      })
    })

    if (quickFilterCount !== 2) {
      setQuickFilterSelected && setQuickFilterSelected(null);
    }
  }, [filterModel])

  useEffect(() => {
    if (reload == true && setReload) {
      loadElements()
        .then(() => operationId ? getOperation(operationId) : Promise.resolve(null))
        .then(operation => {
          if (operation) {
            globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
            return Promise.resolve();
          }
        })
        .finally(() => setReload(false));
    }
  }, [reload])

  return (
    <Box sx={{height: height, display: "flex", flexDirection: "column", flex: 1, ...sx}}>
      {dialogs}
      {panels}
      {alerts && setAlerts && <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>}
      <DataGridPro apiRef={apiRef}
        sx={{overflowX: "auto", backgroundColor: "ornament.light", borderColor: "ornament.dark",
          "& .MuiDataGrid-columnSeparator": {color: "neutral.light"},
          "& .MuiDataGrid-columnHeader:first-of-type": {pl: enableSelection ? "0px" : 5, "& .MuiDataGrid-columnSeparator": {display: enableSelection ? "none" : "inherit"}},
          "& .MuiDataGrid-cell:first-of-type": {pl: enableSelection ? "0px" : 5},
          "& .MuiDataGrid-pinnedColumnHeaders--right": {"& .MuiDataGrid-columnSeparator": {display: "none !important"}},
          "& .MuiDataGrid-pinnedColumns--right": {"& .MuiDataGrid-cell:first-of-type": {pl: "10px"}},
          "& .MuiDataGrid-columnHeaderTitleContainer": {ml: 2},
          "& .MuiDataGrid-columnHeaderTitleContainer:nth-of-type(1)": {ml: "unset"},
          "& .MuiSvgIcon-root": {fontSize: 24},
          "& .MuiDataGrid-footerContainer": {backgroundColor: "ornament.main", minHeight: "unset"},
          "& .MuiDataGrid-pinnedColumns": {boxShadow: 4},
          "& .MuiDataGrid-pinnedColumnHeaders": {boxShadow: 4},
          "& .MuiDataGrid-selectedRowCount": {visibility: "hidden"},
          // Le thème ici n'est pas compris, d'où le passage par la valeur directement de la couleur
          "& .MuiDataGrid-row.Mui-hovered": {backgroundColor: LIBERTY_THEME.palette.ornament.main},
          "& .MuiDataGrid-row.Mui-selected": {backgroundColor: LIBERTY_THEME.palette.primaryLight.light},
          "& .MuiDataGrid-actionsCell": {"& button": {borderRadius: "4px", ":hover": {backgroundColor: "neutral.light"}}}
        }}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        loading={loading}
        pagination={enablePagination}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        page={page}
        pageSize={maxItems}
        onPageSizeChange={(newSize): void => setMaxItems(newSize)}
        sortModel={sortingModel}
        onSortModelChange={(model): void => setSortingModel(model)}
        filterModel={filterModel}
        onFilterModelChange={(model): void => { setFilterModel(model) }}
        columnVisibilityModel={visibilityModel}
        onColumnVisibilityModelChange={(model): void => setVisibilityModel(model)}
        columns={columns}
        rows={rows}
        rowCount={totalRows}
        rowsPerPageOptions={[25,50,100]}
        rowHeight={54}
        getRowId={getRowId ? getRowId : (row): any => row.id}
        headerHeight={90}
        initialState={{
          pinnedColumns: {right: ['actions']},
          pagination: {pageSize: 50},
        }}
        components={{
          Toolbar: enableToolbar ? ListToolbar : null,
          Pagination: ListPagination,
          NoRowsOverlay: EmptyList,
          ColumnMenu: ListColumnMenu,
          FilterPanelDeleteIcon: (): JSX.Element => <DeleteIcon sx={{color: "neutral.main"}}/>,
          ColumnMenuIcon: DragIndicatorIcon
        }}
        componentsProps={{
          panel: {
            anchorEl: filtersAnchorEl,
            sx: {position: "fixed !important", "& .MuiDataGrid-paper": {mt: "18px", mr: 7}}
          },
          toolbar: {
            translationPrefix,
            dialogs: selectedDialogs,
            selectedActions,
            globalActions,
            listActions,
            apiRef,
            search,
            setSearch,
            setFiltersAnchorEl,
            enableToolbarSearch
          },
          pagination: {
            page,
            setPage,
            total: totalRows,
            maxItems,
            setMaxItems
          },
          noRowsOverlay: {
            label: (search.length > 0 || filterModel.items.length) > 0 ? t("list.empty_search") : t(`${translationPrefix}.empty_list`),
            image: (search.length > 0 || filterModel.items.length) > 0 ? emptySearch : emptyListIcon
          },
          filterPanel: {
            sx: {
              border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", boxShadow: 3,
              "& .MuiDataGrid-panelFooter": {px: 3, pb: 3, pt: "0px"},
              "& .MuiButton-root": {color: "neutral.main"}
            },
            filterFormProps: {
              sx: {
                alignItems: "center",
                p: 3,
                "& legend": {pr: 1},
                "& .MuiDataGrid-filterFormLinkOperatorInput": {display: "none", ml: 3, mr: "0px", "& legend": {pr: "0px"}},
                "& .MuiDataGrid-filterFormColumnInput": {ml: 3},
                "& .MuiDataGrid-filterFormOperatorInput": {ml: 3},
                "& .MuiDataGrid-filterFormValueInput": {ml: 3}
              },
              deleteIconProps: {
                sx: {
                  m: "0px"
                }
              },
              linkOperatorInputProps: {
                variant: "outlined"
              },
              columnInputProps: {
                variant: "outlined"
              },
              operatorInputProps: {
                variant: "outlined"
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: "outlined"
                }
              }
            }
          }
        }}
        checkboxSelection={enableSelection}
        disableSelectionOnClick
      />
    </Box>
  )
}
