import {CircularProgress, MenuItem, Typography} from "@mui/material";
import React, {ElementType, useState} from "react";

interface ListMenuItemProps {
  label: string;
  icon: ElementType;
  action: () => void;
  iconSize?: any|null;
  loading?: boolean
}

export default function ListMenuItem(props: ListMenuItemProps): JSX.Element {
  const {label, icon, action, iconSize, loading} = props;
  const Icon = icon;

  const [hover, setHover] = useState(false);
  const color = hover ? "neutral.dark" : "neutral.main"
  const size = iconSize ? {width: iconSize, height: iconSize} : {width: "24px", height: "24px"};

  return (
    <MenuItem onClick={action} onMouseOver={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)} sx={{py: 1, px: 3, ":hover": {backgroundColor: "ornament.main"}}}>
      {loading ? <CircularProgress size={iconSize ?? 24} sx={{color: color, mr: 1}} /> : <Icon sx={{...size, color: color, mr: 1}}/>}
      <Typography variant="body2" color={color}>{label}</Typography>
    </MenuItem>
  )
}
