import {Box, TablePagination} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

interface ListPaginationProps {
  page: number;
  setPage: (page: number) => void;
  total: number;
  maxItems: number;
  setMaxItems: (max: number) => void;
}

export default function ListPagination(props: ListPaginationProps): JSX.Element {
  const {page, setPage, total, maxItems, setMaxItems} = props;
  const {t} = useTranslation();
  return (
    <Box sx={{display: "flex", justifyContent: "flex-end", backgroundColor: "ornament.main", p: 1,
      "& .MuiToolbar-root": {minHeight: "unset"}, "& p": {my: "0px"}, "& button:first-of-type": {mr: 1},
      "& .MuiTablePagination-select": {fontWeight: "bold"},
      "& button": {p: 0, border: "1px solid", borderColor: "neutral.light", borderRadius: "4px"}
    }}>
      <TablePagination
        component="div"
        labelDisplayedRows={(info): React.ReactNode => t("list.footer.rows_info", {start: info.from, end: info.to, total: info.count})}
        labelRowsPerPage={t("list.footer.rows_number")}
        page={page}
        count={total}
        onPageChange={(event, value): void => setPage(value)}
        rowsPerPage={maxItems}
        rowsPerPageOptions={[25,50,100]}
        onRowsPerPageChange={(event): void => {
          setPage(0);
          setMaxItems(parseInt(event.target.value, 10));
        }}/>
    </Box>
  )
}
