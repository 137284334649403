import React, {useContext, useEffect, useState} from 'react';

// COMPONENTS IMPORT
import {Box, Card, Checkbox, Typography} from '@mui/material';
import defaultLogo from '../../assets/default_gift.svg'

// STRUCT IMPORT
import {excludeGiftFromShop, includeGiftInShop} from "../../services/ShopService";
import {useTranslation} from "react-i18next";
import {errorManager, manageStringError} from '../../common/methods/ApiService';
import {formatEuroValues} from '../../common/methods/utils';
import {TGift} from "../../interfaces/gift";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import GiftCardDetail from "./giftCardDetail";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {UPDATE_EXCLUDED_GIFTS} from "../../common/methods/context-setter/globals";
import {useNavigate} from "react-router-dom";

type OperationGiftCardProps = {
  gift: TGift;
  setSuccess: (success: string) => void;
  setError: (error: string) => void;
}

export default function OperationGiftCard(props: OperationGiftCardProps): JSX.Element {
  const {gift, setSuccess, setError} = props;
  const {t} = useTranslation();
  useEffect(() => {
    document.title = t("tab_title.operation.shop.list");
  }, []);
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const shopId = store.global.currentOperation?.shops[0].id;
  const excludedGifts = store.global.currentOperation?.shops[0].excludedGiftIds??[];

  const imageToDisplay = (gift.image_urls[0]?.small)??defaultLogo;
  const [showDetails, setShowDetails] = useState(false);
  const [isDeleted, setDeleted] = useState(excludedGifts.includes(gift.id));
  const navigate = useNavigate();

  const includeOrExcludeGift = (): void => {
    if (shopId == null) {
      return;
    }

    const mustExclude = !excludedGifts.includes(gift.id);
    const call = (mustExclude) ? excludeGiftFromShop : includeGiftInShop;

    call(shopId, gift.id)
      .then(newExcludedGifts => {
        setSuccess(t("operations.shop.gifts.save_success"));
        setDeleted(mustExclude);
        globalStoreReducer(STORE, {type: UPDATE_EXCLUDED_GIFTS, excludedGifts: newExcludedGifts})
      })
      .catch(error => manageStringError(errorManager(error, t, STORE, navigate), setError, t));
  }

  return (
    <Card sx={{display: 'flex', flexDirection: 'column', p: 3, "&:hover": {boxShadow: 3}, opacity: !isDeleted ? "1" : "0.4"}}>
      {showDetails && <GiftCardDetail open={true} close={(): void => setShowDetails(false)} gift={gift} isDeleted={isDeleted} excludeOrIncludeGift={includeOrExcludeGift}/>}
      <Box>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="body1" sx={{color: "neutral.dark", fontWeight: "bold"}}>{gift.name}</Typography>
          <Checkbox
            name={gift.id.toString()}
            checked={!isDeleted}
            sx={{color: "primary.main", width: "24px", height: "24px", "& .MuiSvgIcon-root": {fontSize: "24px"}, ml:4}}
            onClick= {includeOrExcludeGift}
          />
        </Box>
        <Typography variant="body2" sx={{color: "neutral.main", fontWeight: "regular", mb: 3}}>{t("operations.shop.gifts.giftCard_subTitle", {points: gift.sellingPointPrice, euroValue: formatEuroValues(gift.sellingEuroNetPrice)})}</Typography>
      </Box>
      <Box sx={{flex: 1, display: 'flex', justifyContent: 'center', maxWidth: "100%", mx: 0, mt: 5, p: 4}}>
        <Box sx={{width: "238px", cursor: "pointer"}} component="img" src={imageToDisplay} onClick={(): void => setShowDetails(true)}/>
      </Box>
    </Card>
  );
}
