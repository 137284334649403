import {Box, Card, Chip, IconButton, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useNavigate, useParams} from "react-router-dom";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import List from "../../patterns/list/list";
import {GridValueGetterParams, useGridApiRef} from "@mui/x-data-grid-pro";
import emptyFile from "../../assets/lists/empty_file.svg";
import ImportLogo from "../../assets/wizard/import/logo.png";
import {
  addErrorAlert,
  ChipCellProps,
  columnDefinition,
  ColumnType,
  enumFilter,
  gridAction
} from "../../patterns/list/listUtils";
import {downloadImportFile, getOperationParticipantImports} from "../../services/ImportService";
import {TParticipantImportInformation, TParticipantImportStatus} from "../../interfaces/participantImport";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/Info";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {TGiftOrderLineStatus} from "../../interfaces/giftOrderLine";
import DownloadIcon from '@mui/icons-material/Download';
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {TAlert} from "../../patterns/list/listSnackbar";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {getExportDocument} from "../../common/methods/exportDocument";

export default function ImportOperationParticipants(): JSX.Element {
  const {operationId} = useParams<string>();
  const {t} = useTranslation();
  useEffect(() => {
    document.title = t("tab_title.operation.participant.import");
  }, []);
  const navigate = useNavigate();
  const STORE = useContext<StoreContext>(AppContext);

  const apiRef = useGridApiRef();
  const [alerts, setAlerts] = useState<TAlert[]>([]);

  const loadImports = (page: number, maxItems: number): Promise<{totalItems: number, items: TParticipantImportInformation[]}> => {
    return getOperationParticipantImports(operationId!, page, maxItems);
  }

  const exportFile = (participantImport: TParticipantImportInformation): void => {
    downloadImportFile(participantImport.id)
      .then(response => {
        getExportDocument(response)
      })
      .catch((error) => {
        if (error.status === 404) addErrorAlert(t("operation_transactions.actions.download_no_invoice"), setAlerts)
        else manageStringError(errorManager(error, t, STORE, navigate), error => addErrorAlert(error, setAlerts), t)
      })
  }

  const fileNameColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "operation_participants.import", "originalName", 250, ColumnType.STRING, false),
      renderCell: (item): JSX.Element => <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "bold"}} noWrap>
        {item.value}
      </Typography>
    }
  }

  const adminColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "operation_participants.import", "admin", 140, ColumnType.STRING, false),
      valueGetter: (item: GridValueGetterParams): string => item.row.admin.firstName + ' ' + item.row.admin.lastName
    }
  }

  const statusProps = (row: TParticipantImportInformation): ChipCellProps => {
    switch (row.status) {
    case TParticipantImportStatus.ON_GOING:
      return {
        icon: <HelpOutlineIcon sx={{width: 18, height: 18}}/>,
        iconColor: "neutral.main",
        color: "neutral.main",
        backgroundColor: "ornament.dark"
      }
    case TParticipantImportStatus.VALIDATED:
      return {
        icon: <InfoIcon sx={{width: 18, height: 18}}/>,
        iconColor: "info.main",
        color: "info.dark",
        backgroundColor: "infoLight.light"
      }
    case TParticipantImportStatus.FAILED:
      return {
        icon: <ErrorOutlineIcon sx={{width: 18, height: 18}}/>,
        iconColor: "error.main",
        color: "error.dark",
        backgroundColor: "error.light"
      }
    case TParticipantImportStatus.SAVING:
      return {
        icon: <SaveIcon sx={{width: 18, height: 18}}/>,
        iconColor: "info.main",
        color: "info.dark",
        backgroundColor: "infoLight.light"
      }
    case TParticipantImportStatus.SAVED:
      return {
        icon: <CheckCircleOutlineIcon sx={{width: 18, height: 18}}/>,
        iconColor: "success.main",
        color: "success.dark",
        backgroundColor: "success.light"
      }
    case TParticipantImportStatus.CANCELLED:
      return {
        icon: <CloseIcon sx={{width: 18, height: 18}}/>,
        iconColor: "error.main",
        color: "error.dark",
        backgroundColor: "error.light"
      }
    }
  }

  const statusColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "operation_participants.import", "status", 240, ColumnType.STRING, false),
      type: "singleSelect",
      filterOperators: enumFilter,
      valueOptions: Object.keys(TGiftOrderLineStatus).map(status => ({value: status, label: t(`operation_participants.import.columns.status_${status.toLowerCase()}`)})),
      renderCell: (item): JSX.Element => {
        const status = statusProps(item.row);
        return <Chip icon={status.icon}
          sx={{backgroundColor: status.backgroundColor, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: status.iconColor}}}
          label={<Typography variant="body2" color={status.color} sx={{fontWeight: "regular"}}>
            {t(`operation_participants.import.columns.status_${item.row.status.toLowerCase()}`)}
          </Typography>}/>
      }
    }
  }

  const exportColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "operation_participants.import", "export", 80, ColumnType.STRING, false),
      type: 'actions',
      getActions: ((params): JSX.Element[] => [gridAction(params, "export", "", DownloadIcon, exportFile)]),
      renderCell: (item): JSX.Element => <IconButton onClick={(): void => exportFile(item.row)}>
        <DownloadIcon sx={{color: "neutral.main", width: 24, height: 24}}/>
      </IconButton>
    }
  }

  const nullableColumnDefinition = (field: string): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "operation_participants.import", field, 210, ColumnType.NUMBER, true, true),
      valueGetter: (item: GridValueGetterParams): string => {
        if (item.row[field] == null) {
          return "-";
        }
        return t(`operation_participants.import.columns.${field}_` + (item.row[field] == 0 ? "none" : "some"), {number: item.row[field]});
      }
    }
  }

  const columns = [
    fileNameColumnDefinition(),
    nullableColumnDefinition("addedCount"),
    nullableColumnDefinition("updatedCount"),
    columnDefinition(t, "operation_participants.import", "errorCount", 75, ColumnType.NUMBER),
    statusColumnDefinition(),
    adminColumnDefinition(),
    columnDefinition(t, "operation_participants.import", "createdAt", 140, ColumnType.DATE, false),
    exportColumnDefinition(),
  ]

  const redirectToImportWizard = (): void => navigate(operationUrl(PRIVATE_URL.IMPORT_PARTICIPANTS_WIZARD, operationId!))
  return (
    <Box sx={{display: "grid", gridTemplateColumns: "repeat(12, 1fr)", gap: 5}}>
      <Card sx={{display: "flex", flexDirection: "row", alignItems: "center", gridColumn: "3/11", p: 3, mt: "28px", mb: "36px",
        cursor: "pointer", ":hover": {boxShadow: 3}}} onClick={redirectToImportWizard}>
        <img src={ImportLogo} alt="" style={{width: 160, height: 160}}/>
        <Box sx={{display: "flex", flexDirection: "column", mx: 5}}>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 3}}>
            {t("operation_participants.import.title")}
          </Typography>
          <Typography variant="body1" color="neutral.main" dangerouslySetInnerHTML={{__html: t("operation_participants.import.subtitle")}}/>
        </Box>
        <IconButton>
          <ArrowForwardIosIcon sx={{width: 24, height: 24}}/>
        </IconButton>
      </Card>
      <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "medium", gridColumn: "1/13"}}>
        {t("operation_participants.import.past")}
      </Typography>
      <List
        sx={{gridColumn: "1/13"}}
        translationPrefix="operation_participants.import"
        alerts={alerts}
        setAlerts={setAlerts}
        columns={columns}
        apiRef={apiRef}
        loadRows={loadImports}
        height="50vh"
        emptyListIcon={emptyFile}
        enableSelection={false}
        enableToolbar={false}/>
    </Box>
  )
}
