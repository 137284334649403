import {Box, Card, TextareaAutosize, Typography} from "@mui/material";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {putOperation} from "../../services/OperationService";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import WysiwygEditor from "../../patterns/wysiwyg/wysiwygEditor";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import OperationPresentationDialog from "./operationPresentationDialog";
import {AppContext} from "../../App";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import {StoreContext} from "../../common/struct/store";
import Page from "../../patterns/page/page";
import ListSnackbar, {TAlert} from "../../patterns/list/listSnackbar";
import {addErrorAlert, addSuccessAlert} from "../../patterns/list/listUtils";
import Footer from "../../patterns/footer/footer";
import {Loader} from "../../components/loader/loader";
import {AccessToShopButton} from "../operationMainPage/customisation/accessToShopButton";
import {getAllParticipantAttributes} from "../../services/ParticipantService";
import {isSuperAdmin} from "../../common/struct/globalVar";

export default function OperationPresentation(): JSX.Element {
  const {t} = useTranslation();
  const {operationId} = useParams<string>();
  useEffect(() => {
    document.title = t("tab_title.operation.presentation");
  }, []);
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const [presentation, setPresentation] = useState<string|null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loadingAttributes, setLoadingAttributes] = useState<boolean>(true);
  const [textarea, setTextarea] = useState<boolean>(false);
  const editorRef = useRef<any|null>(null);
  const navigate = useNavigate();

  const [editorMenus, setEditorMenus] = useState([{
    name: 'variables',
    label: t('operations.mailing.customisation.wysiwyg.title'),
    items: [{
      name: 'participantFirstName',
      label: t('operations.mailing.customisation.wysiwyg.participant_firstname'),
      action: () => editorRef.current.insertContent("{{user.firstName}}")
    },
    {
      name: 'participantLastName',
      label: t('operations.mailing.customisation.wysiwyg.participant_lastname'),
      action: () => editorRef.current.insertContent("{{user.lastName}}")
    },
    {
      name: 'civility',
      label: t('operations.mailing.customisation.wysiwyg.civility'),
      action: () => editorRef.current.insertContent("{{user.civility}}")
    },
    {
      name: 'point',
      label: t('operations.mailing.customisation.wysiwyg.points'),
      action: () => editorRef.current.insertContent("{{user.points}}")
    }]
  }]);

  useEffect(() => {
    if (store.global.currentOperation?.id === operationId) {
      setPresentation(store.global.currentOperation?.presentation??null);
      setLoading(false);
    }
  }, [store]);

  useEffect(() => {
    getAllParticipantAttributes().then((datas) => {
      datas.map((data) => {
        setEditorMenus(prevState =>
          prevState.map((prevItem) => {
            return {...prevItem, items: [...prevItem.items, {
              name: data.label,
              label: data.label,
              action: () => editorRef.current.insertContent("{{user.attributes."+data.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()+"}}")
            }]};
          })
        )
      })
    }).finally(() => setLoadingAttributes(false))
  }, [])

  const updateOperation = (): void => {
    if (editorRef.current) {
      setLoadingButton(true);
      putOperation(operationId!, {presentation: textarea ? presentation : editorRef.current.getContent()})
        .then(operation => {
          globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
          addSuccessAlert(t('operations.presentation.save_success'), setAlerts);
        })
        .catch(error => {
          manageStringError(errorManager(error, t, STORE, navigate), setError, t);
        })
        .finally(() => {
          setLoadingButton(false);
        })
    }
  }

  const setError = (error: string): void => addErrorAlert(error, setAlerts)
  const setSuccess = (success: string): void => addSuccessAlert(success, setAlerts)

  return loadingAttributes ? <Loader height="calc(100vh - 229px)" /> :
    <>
      <Page title={t("operations.presentation.title")} backLink={operationUrl(PRIVATE_URL.OPERATION_CUSTOMISATION, operationId!)} buttons={store.global.currentOperation && [<AccessToShopButton key={store.global.currentOperation.id} operation={store.global.currentOperation} />]}>
        <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
        <Box sx={{px: 8, pb: 3, display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mt: 6, mb: 5}}>
            <Typography color="neutral.main" sx={{fontWeight: "regular"}} variant="body2">{t("operations.presentation.subtitle")}</Typography>
          </Box>
          {loading ? <Loader height="calc(100vh - 269px)" /> :
            <Card sx={{backgroundColor: "ornament.light", p: 6, boxShadow: 1}}>
              <OperationPresentationDialog
                open={openDialog}
                setOpen={setOpenDialog}
                operationId={operationId!}
                setSuccess={setSuccess} />
              {!textarea && <WysiwygEditor editorRef={editorRef} operationId={operationId!} initialValue={presentation??""} setError={setError} customMenus={editorMenus}/>}
              {isSuperAdmin() && textarea && <Box sx={{"textarea": {width: "100%", height: "500px !important"}}}><TextareaAutosize value={presentation??""} onChange={(e): void => setPresentation(e.target.value)} /></Box>}
              <Box sx={{display: "flex", justifyContent: "space-between", mt: 6}}>
                <Box sx={{display: "flex"}}>
                  <PrimaryBigButton label={t("navigation.validate")} action={updateOperation} loading={loadingButton} />
                  <SecondaryBigButton disabled={presentation == null} label={t("navigation.delete")} action={(): void => setOpenDialog(true)} sx={{ml: 3}}/>
                </Box>
                <Box>
                  {isSuperAdmin() && <SecondaryBigButton label={textarea ? t("operations.presentation.button_wysiwyg") : t("operations.presentation.button_code")} action={(): void => setTextarea(!textarea)} />}
                </Box>
              </Box>
            </Card>}
        </Box>
        <Footer/>
      </Page>
    </>
}
