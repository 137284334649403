import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export enum OperationWizardSteps {
  MENU,
  CHARACTERISTICS,
  SHOWCASE,
  SHOP,
}

export interface OperationWizardCardPropsNoWizard {
  triggerAction: boolean;
  setTriggerAction: (trigger: boolean) => void;
  setSuccess: (success: string) => void;
}

export interface OperationWizardCardProps {
  propsNoWizard?: OperationWizardCardPropsNoWizard;
  sx?: SxProps<Theme>;
  setError: (error: string|null) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  autoHostname?: boolean;
  isWizard?: boolean;
}
