import {API_URL, GLOBAL_OPERATION_ID} from "../common/struct/urlManager";
import {del, get, getPaginated, post, put, RequestFilter, RequestOrder} from "../common/methods/ApiService";
import {
  TParticipantAttribute,
  TParticipantAttributeCreate,
  TParticipantAttributeUpdate
} from "../interfaces/participantAttribute";
import {TParticipant, TParticipantCreate, TParticipantUpdate} from "../interfaces/participant";
import {TRewardWarningResponse} from "../interfaces/reward";

export async function getAllParticipantAttributeTypes(): Promise<string[]> {
  return get(API_URL.IMPORT_PARTICIPANT_ATTRIBUTE_TYPES);
}

export async function getAllParticipantAttributes(): Promise<TParticipantAttribute[]> {
  return get(API_URL.PARTICIPANT_ATTRIBUTES);
}

export async function getPaginatedParticipantAttributes(page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TParticipantAttribute[]}> {
  return getPaginated(API_URL.PARTICIPANT_ATTRIBUTES, page, itemsPerPage, search, order, filters);
}

export async function getPaginatedDeletedParticipantAttributes(page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TParticipantAttribute[]}> {
  return getPaginated(API_URL.PARTICIPANT_ATTRIBUTES_DELETED, page, itemsPerPage, search, order, filters);
}

export async function getParticipantAttribute(participantAttributeId: string): Promise<TParticipantAttribute> {
  return get(`${API_URL.PARTICIPANT_ATTRIBUTES}/${participantAttributeId}`);
}

export async function createParticipantAttribute(participantAttribute: TParticipantAttributeCreate): Promise<TParticipantAttribute> {
  return post(API_URL.PARTICIPANT_ATTRIBUTES, participantAttribute);
}

export async function updateParticipantAttribute(participantAttributeId: string, participantAttribute: TParticipantAttributeUpdate): Promise<TParticipantAttribute> {
  return put(API_URL.PARTICIPANT_ATTRIBUTES, participantAttributeId, participantAttribute);
}

export async function deleteParticipantAttribute(participantAttributeId: string): Promise<void> {
  return del(API_URL.PARTICIPANT_ATTRIBUTES, participantAttributeId);
}

export async function restoreParticipantAttribute(participantAttributeId: string): Promise<TParticipantAttribute> {
  return post(`${API_URL.PARTICIPANT_ATTRIBUTES}/${participantAttributeId}/restore`);
}

export async function createParticipant(participant: TParticipantCreate): Promise<TParticipant> {
  return post(API_URL.PARTICIPANT, participant);
}

export async function getParticipant(participantId: string): Promise<TParticipant> {
  return get(`${API_URL.PARTICIPANT}/${participantId}`);
}

export async function updateParticipant(participantId: string, updatedParticipant: TParticipantUpdate): Promise<TParticipant> {
  return put(API_URL.PARTICIPANT, participantId, updatedParticipant)
}

export async function changeParticipantPassword(participantId: string, password: string): Promise<TParticipant> {
  return put(API_URL.PARTICIPANT, participantId, {password: password});
}

export async function sendParticipantsMoney(euroAmount: string, wallet: string, operationId: string, search?: string|null, filters: RequestFilter[]|null = []): Promise<{count: number}> {
  return post(`${API_URL.OPERATION}/${operationId}/rewards`, {amount: euroAmount, wallet: wallet}, search, filters);
}

export async function viewParticipantCreditWarning24h(participantsId: Array<string>, idOperation: string): Promise<TRewardWarningResponse> {
  return post(API_URL.VIEW_PARTICIPANT_WARNING_CREDIT.replace(":" + GLOBAL_OPERATION_ID, idOperation), {rewardParticipants: participantsId})
}
