import {ExcludedBrandsCardChildren, SpecificCriteriaCardChildren} from "./selectionCriteriaCard";
import {Box, Card, Grid} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../App";
import {TFunction, useTranslation} from "react-i18next";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {putShop} from "../../services/ShopService";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {TShop} from "../../interfaces/shop";
import {StoreContext} from "../../common/struct/store";
import Footer from "../../patterns/footer/footer";
import {useNavigate} from "react-router-dom";

export const translate = (t: TFunction, text: string): string => t("operations.shop.selection_criteria." + text);

export default function OperationShopCriteria(props: {setError: (error: string) => void, setSuccess: (success: string) => void}): JSX.Element {
  const {setError, setSuccess} = props;
  const {t} = useTranslation();
  useEffect(() => {
    document.title = t("tab_title.operation.shop.criteria");
  }, []);
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const operation = store.global.currentOperation;

  const [excludeBrands, setExcludeBrands] = useState<string[]>([]);
  const [onlyMadeInFrance, setOnlyMadeInFrance] = useState<boolean>(false);
  const [onlyEthic, setOnlyEthic] = useState<boolean>(false);
  const [withoutAlcohol, setWithoutAlcohol] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (operation) {
      const shop = operation.shops[0];
      setExcludeBrands(shop.excludedBrands);
      setOnlyMadeInFrance(shop.extraCriteria?.x_french??false);
      setOnlyEthic(shop.extraCriteria?.x_ecofriendly??false);
      setWithoutAlcohol(shop.extraCriteria?.x_alcool == null ? false : !shop.extraCriteria.x_alcool);
    }
  }, [operation])

  const updateShop = (shopId: string): Promise<TShop> => {
    const body = {
      excludedBrands: excludeBrands,
      extraCriteria: {
        x_alcool: withoutAlcohol?false:undefined,
        x_ecofriendly: onlyEthic?true:undefined,
        x_french: onlyMadeInFrance?true:undefined
      }
    };
    return putShop(shopId, body);
  }

  const onSendSelectionCriteria = (): void => {
    if (operation == null) {
      return;
    }

    setLoading(true);
    updateShop(operation.shops[0].id)
      .then((shop: TShop) => {
        const updatedOperation = {...operation, shops: [shop]};
        globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: updatedOperation});
        setSuccess(translate(t, "success"));
      })
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        manageStringError(errorResult, setError, t)
      })
      .finally(() => setLoading(false))
  };

  return operation == null ? <></> :
    <Grid item xs={12} sx={{px: 8}}>
      <Card sx={{mb: 3}}>
        <ExcludedBrandsCardChildren
          operation={operation}
          setError={setError??setError}
          setExcludedBrands={setExcludeBrands}
          loading={loading}
        />
        <SpecificCriteriaCardChildren
          setOnlyMadeInFrance={setOnlyMadeInFrance}
          setOnlyEthic={setOnlyEthic}
          setWithoutAlcohol={setWithoutAlcohol}
          onlyMadeInFrance={onlyMadeInFrance}
          onlyEthic={onlyEthic}
          withoutAlcohol={withoutAlcohol}
          loading={loading}
        />
        <Box sx={{px: 6, py: 5, pb: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
          <PrimaryBigButton label={translate(t, "brands_cards.label_button")} action={onSendSelectionCriteria} loading={loading}/>
        </Box>
      </Card>
      <Footer/>
    </Grid>
}
