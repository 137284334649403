import React, {useEffect, useState} from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {Box} from '@mui/material';
import {TOptions} from "i18next";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import OperationUniversesCard from "./operationUniversesCard";
import Footer from "../../patterns/footer/footer";

export const translate = (t: TFunction, text: string, options?: TOptions<any>): string => t('operations.shop.universes.' + text, options);

export default function OperationUniverses(props: {setError: (error: string|null) => void, setSuccess: (success: string) => void}): JSX.Element {
  const {t} = useTranslation();
  useEffect(() => {
    document.title = t("tab_title.operation.shop.univers");
  }, []);
  const {setError, setSuccess} = props;
  const [loading, setLoading] = useState(false);
  const [triggerAction, setTriggerAction] = useState(false);

  const updateOperation = (): void => {
    setTriggerAction(true);
  }

  return (
    <Box>
      <Box sx={{width: "100%", backgroundColor: "ornament.main", display: "flex", flexDirection: "column", justifyContent: "space-between", px: 8, boxSizing: "border-box", mb: 12}}>
        <OperationUniversesCard setError={setError} loading={loading} setLoading={setLoading} propsNoWizard={{triggerAction, setTriggerAction, setSuccess}} sx={{pb: 3}}/>
        <Footer/>
      </Box>
      <Box sx={{display: "flex", justifyContent: "start", width: "100%", height: "88px", position: "fixed", px: 8, bottom: "0%", backgroundColor: "ornament.light", alignItems: "center", boxShadow: "inset 0px 1px 0px #CDCEDE"}}>
        <PrimaryBigButton label={t("navigation.validate")} action={updateOperation} loading={loading} />
      </Box>
    </Box>
  )
}
