import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {errorManager} from "../../common/methods/ApiService";
import {Alert, Box, Card, FormControlLabel, Radio, Typography} from "@mui/material";
import {deleteRules, downloadRulesPdf, uploadRulesPdf} from "../../services/RulesService";
import {OperationRulesProps} from "./operationRules";
import {TOperationRulesFormat} from '../../interfaces/operation';
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import {AppContext} from "../../App";
import FilePreviewCompact from "../../patterns/preview/filePreviewCompact";
import UploadFile from "../../patterns/upload/uploadFile";
import {FileType} from "../../patterns/dialog/downloadFileProps";
import {useNavigate} from "react-router-dom";

export default function OperationRulesPdf(props: OperationRulesProps) : JSX.Element {
  const {t} = useTranslation();
  const {rules, selectedFormat, askForSelectedFormatChange, askForRuleDeletion, setAskForRuleDeletion, operationId, setRules, setSuccess} = props;
  const STORE = useContext(AppContext);

  const [openContent, setOpenContent] = useState<boolean>(true);
  const [errorAlert, setErrorAlert] = useState<string|null>(null);
  const navigate = useNavigate();

  const handleUploadFile = (file: File|null): Promise<void> => {
    if (file == null) {
      return Promise.resolve();
    }

    return uploadRulesPdf(operationId, file)
      .then(operation => {
        setSuccess(t("operations.rules.pdf.save_success"));
        setRules(operation.rules);
        globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
      })
  }

  const handleDownloadFile = (): Promise<any> => {
    setErrorAlert(null);
    return downloadRulesPdf(operationId).then(response => response.blob());
  }

  const handleDeleteFile = (): Promise<void> => {
    return deleteRules(operationId)
      .then(operation => {
        setSuccess(t("operations.rules.pdf.delete_success"));
        setRules(null);
        globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
      })
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setErrorAlert(errorResult);
      })
  }

  useEffect(() => {
    if (askForRuleDeletion == TOperationRulesFormat.PDF) {
      setRules(null);
      setAskForRuleDeletion(null);
      setSuccess(t("operations.rules.delete_success"));
    }
  }, [askForRuleDeletion]);

  useEffect(() => {
    if (selectedFormat !== TOperationRulesFormat.PDF) {
      setOpenContent(false);
    }
  }, [selectedFormat]);

  return (
    <Card sx={{
      border: selectedFormat == TOperationRulesFormat.PDF ? "2px solid" : "1px solid",
      borderColor: selectedFormat == TOperationRulesFormat.PDF ? "primary.main" : "ornament.dark",
      backgroundColor: (selectedFormat == TOperationRulesFormat.PDF && !openContent) ? "primaryLight.light" : "ornament.light",
      boxShadow: 0, mb: 5, width: "100%"
    }}>
      <FormControlLabel sx={{p: 6, width: "100%", borderBottom: "1px solid", borderColor: "ornament.dark"}} checked={selectedFormat === TOperationRulesFormat.PDF} onChange={(): void => askForSelectedFormatChange(TOperationRulesFormat.PDF)} control={<Radio sx={{fontSize: 24}}/>}
        label={<Box sx={{display: "flex", flexDirection: "column"}} onClick={(): void => setOpenContent(!openContent)}>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 0}}>{t("operations.rules.pdf.title")}</Typography>
          <Typography variant="body2" color="neutral.dark">{t("operations.rules.pdf.subtitle")}</Typography>
        </Box>}/>
      {selectedFormat == TOperationRulesFormat.PDF && openContent &&
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", p: 6, width: "100%", boxSizing: "border-box"}}>
          {errorAlert && <Box sx={{mb: 2, width: "100%"}}><Alert severity="error" variant="filled">{errorAlert}</Alert></Box>}
          {!rules && <UploadFile action={handleUploadFile} setError={setErrorAlert} />}
          {rules && rules.format === TOperationRulesFormat.PDF && <FilePreviewCompact type={FileType.PDF} size={rules.size} name={rules.name!} deleteFile={handleDeleteFile} accessFile={handleDownloadFile} setError={setErrorAlert} sx={{width: "100%"}}/>}
        </Box>}
    </Card>
  )
}
