import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface BounceProps {
  children: JSX.Element|JSX.Element[];
  sx?: SxProps<Theme>;
}

export default function Bounce(props: BounceProps): JSX.Element {
  const {children, sx} = props;

  const [animate, setAnimate] = useState<boolean>(false);
  
  useEffect(() => {
    if (!animate) {
      setTimeout(() => (setAnimate(true)), 3000);
    } else {
      setTimeout(() => (setAnimate(false)), 2000);
    }
  }, [animate])

  return (
    <Box sx={{
      animation: `${animate ? "bounce 1s linear" : "none"}`, 
      "@keyframes bounce": {
        "0%": {
          transform: "translateY(0)"
        },
        "20%": {
          transform: "translateY(0)"
        },
        "40%": {
          transform: "translateY(-20px)"
        },
        "50%": {
          transform: "translateY(-5px)"
        },
        "60%": {
          transform: "translateY(-15px)"
        },
        "80%": {
          transform: "translateY(0)"
        },
        "100%": {
          transform:  "translateY(0)"
        }
      },
      ...sx
    }}>
      {children}
    </Box> 
  )
}
