/** GLOBAL ACTIONS */
export const INIT_STORE = "INIT_STORE";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_EXCLUDED_GIFTS = "UPDATE_EXCLUDED_GIFTS";
export const ADD_OPERATIONS = "ADD_OPERATIONS";
export const ADD_OPERATION = "ADD_OPERATION";
export const CURRENT_OPERATION = "CURRENT_OPERATION";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const ADD_ERROR_NOTIFICATION = "ADD_ERROR_NOTIFICATION";
export const INIT_PAYMENT = "INIT_PAYMENT";
export const ADD_TRANSACTION_ID = "ADD_TRANSACTION_ID";
export const ADD_PAYMENT_REFERENCE = "ADD_PAYMENT_REFERENCE";
export const UPDATE_WALLETS = "UPDATE_WALLETS";
export const UPDATE_ALL_WALLETS = "UPDATE_ALL_WALLETS";
export const UPDATE_COMPANY = "UPDATE_COMPANY";

/** WIZARD ACTIONS */
export const PREV_STEP = "PREV_STEP";
export const NEXT_STEP = "NEXT_STEP";
export const CANCEL = "CANCEL";
export const ERROR_IN_FILE = "ERROR_IN_FILE";
export const SET_UPLOAD_FILE = "SET_UPLOAD_FILE";
export const SET_OPERATION = "SET_OPERATION";
export const SET_MAPPING = "SET_MAPPING";
export const SET_IMPORT_WALLET = 'SET_IMPORT_WALLET';
export const SET_SAVE_HEADER = 'SET_SAVE_HEADER';
