import React from 'react';
import {Box, Typography, Link, useMediaQuery} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import Footer from "../../assets/landingPage/footer.png";
import {PUBLIC_URL} from '../../common/struct/urlManager';
import LogoJoozy from "../../assets/logoJoozyWhite.svg";
import Linkedin from "../../assets/landingPage/linkedin.svg";
import Cursor from "../../assets/landingPage/cursor.svg";
import {useTranslation} from "react-i18next";

export default function LandingFooter(props: {openDialog: () => void}): JSX.Element {

  const {openDialog} = props;

  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleNav = (): void => {
    navigate(PUBLIC_URL.LOGIN);
  }

  const breakpointL = useMediaQuery('(min-width:1200px)');
  const breakpointM = useMediaQuery('(min-width:1000px)');
  const breakpointXS = useMediaQuery('(min-width:730px)');

  return (
    <Box sx={{height: "fit-content", mt: breakpointM ? "150px" : "80px", width: "100%", backgroundImage: `url(${Footer})`, backgroundRepeat: "repeat-x"}}>
      <Box sx={{height: "fit-content", width: "100%", backgroundColor: "primary.dark"}}>
        <Box sx={{maxWidth: "1440px", width: "100%", height: "fit-content", boxSizing: "border-box", position: "relative", margin: "0 auto", px: 4, pb: 12, display: "flex", justifyContent: "space-between", mt: "150px", flexDirection: breakpointXS ? "row" : "column"}}>
          <Box sx={{width: breakpointL ? "20%" : breakpointXS ? "30%" : "100%", height: "fit-content", display: "flex", justifyContent: "center", alignItems: breakpointXS ? "start" : "center", flexDirection: "column"}}>
            {/* @ts-ignore */}
            <img src={LogoJoozy} alt={t("landing_page.alt.joozy_logo")} style={{height: "45px"}} />
            <Link href={process.env.REACT_APP_LIBERTY_WEBSITE} target="_blank" sx={{position: "relative"}}>
              <Typography variant="body1" color="ornament.light" sx={{fontWeight: "600", fontSize: "18px", width: "190px", mt: 3}}>
                {t("landing_page.footer.title")}
              </Typography>
              {/* @ts-ignore */}
              <img src={Cursor} alt={t("landing_page.alt.cursor_logo")} style={{height: "18px", position: "absolute", bottom: "4%", right: "30%"}} />
            </Link>
          </Box>
          <Box sx={{width: breakpointL ? "22%" : breakpointXS ? "35%" : "100%", height: "fit-content", cursor: "pointer", mt: breakpointXS ? "0px" : 5, display: "flex", justifyContent: breakpointL ? "end" : "center"}} >
            <Box sx={{width: "fit-content"}}>
              <Link href={process.env.REACT_APP_LIBERTY_LINKEDIN} target="_blank" sx={{textDecoration: "none", mt: 1, display: "flex", alignItems: "center", justifyContent: breakpointXS ? "start" : "center", height: "100%", width: "100%"}}>
                <Typography variant="body1" color="ornament.light" sx={{fontWeight: "700", fontSize: "18px", textTransform: "uppercase"}}>{t("landing_page.footer.follow")}</Typography>
                {/* @ts-ignore */}
                <img src={Linkedin} alt={t("landing_page.alt.linkedin_logo")} style={{marginLeft: "14px"}} />
              </Link>
            </Box>
          </Box>
          <Box sx={{width: breakpointL ? "40%" : breakpointXS ? "30%" : "100%", display: "flex", justifyContent: breakpointL ? "space-between" : "end", height: "fit-content", mt: breakpointXS ? "0px" : 5, alignItems: "end"}}>
            <Box sx={{width: breakpointL ? "100%" : breakpointXS ? "fit-content" : "100%", display: "flex", justifyContent: "space-between", alignItems: "start", flexDirection: breakpointL ? "row" : "column"}}>
              <Box sx={{width: breakpointXS ? "48%" : "100%", textAlign: breakpointXS ? "start" : "center", minWidth: "fit-content", mr: 1, display: "flex", justifyContent: "end", flexDirection: breakpointL ? "row" : "column", alignItems: breakpointXS ? "unset" : "center"}}>
                <Box sx={{width: "fit-content"}}>
                  <Typography variant="body1" color="ornament.light" sx={{fontWeight: "700", fontSize: "18px", mt: 1, textTransform: "uppercase"}}>{t("landing_page.footer.user_space")}</Typography>
                  <Typography variant="body1" color="ornament.light" sx={{fontWeight: "400", fontSize: "18px", mt: breakpointL ? 4 : 1, cursor: "pointer"}} onClick={(): void => handleNav()}>{t("landing_page.footer.admin_text")}</Typography>
                  <Typography variant="body1" color="ornament.light" sx={{fontWeight: "400", fontSize: "18px", mt: 0, cursor: "pointer"}} onClick={openDialog}>{t("landing_page.footer.user_text")}</Typography>
                </Box>
              </Box>
              <Box sx={{width: breakpointXS ? "48%" : "100%", textAlign: breakpointXS ? "start" : "center", minWidth: "fit-content", ml: breakpointL ? 1 : "0px", mt: breakpointXS ? "0px" : 4, display: "flex", justifyContent: breakpointXS ? "end" : "center"}}>
                <Box sx={{width: "fit-content"}}>
                  <Typography variant="body1" color="ornament.light" sx={{fontWeight: "700", fontSize: "18px", mt: 1, textTransform: "uppercase"}}>{t("landing_page.footer.contact_us")}</Typography>
                  <Box sx={{mt: breakpointL ? 4 : 1}}><Link variant="body1" href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} color="ornament.light" sx={{fontWeight: "400", fontSize: "18px", textDecoration: "none"}}>{t("landing_page.footer.email")}</Link></Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{position: breakpointXS ? "absolute" : "relative", bottom: breakpointXS ? "18%" : "unset", right: breakpointXS ? "0px" : "unset", textAlign: breakpointXS ? "right" : "center", mt: breakpointXS ? "unset" : 6, width: breakpointXS ? "100%" : "unset", maxWidth: "1440px", padding: "0px 20px"}}>
            <Link href={PUBLIC_URL.CGVU} target="_blank" sx={{textDecoration: "none"}}>
              <Typography variant="caption" color="ornament.light" sx={{textTransform: "uppercase", lineHeight: "16px", pr: 1, borderRight: "1px solid", borderColor: "ornament.light"}}>
                {t("landing_page.footer.cgvu")}
              </Typography>
            </Link>
            <Link href={PUBLIC_URL.PPDP} target="_blank" sx={{textDecoration: "none"}}>
              <Typography variant="caption" color="ornament.light" sx={{textTransform: "uppercase", lineHeight: "16px", pl: 1}}>
                {t("landing_page.footer.ppdp")}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}