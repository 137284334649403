import React from "react";
import {Box, Typography} from "@mui/material";
import {AlertSeverity, extractAlertProps} from "./alertProps";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface TextAlertProps {
  text: string;
  severity: AlertSeverity;
  sx?: SxProps<Theme>
}

export default function TextAlert(props: TextAlertProps): JSX.Element {
  const {text, severity, sx} = props;
  const {color, Icon} = extractAlertProps(severity);

  return (
    <Box sx={{display: "flex", alignItems: "flex-start", mt: 0, ...sx}}>
      <Icon sx={{color: color, width: 24, height: 24, mr: 1}}/>
      <Typography variant="body2" color={color}>
        {text}
      </Typography>
    </Box>
  )
}
