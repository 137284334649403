import React, {useEffect, useState} from "react";
import {getSelectedCompany, isSuperAdmin} from "../../common/struct/globalVar";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {useNavigate} from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Box, Chip, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import ReactJson from "react-json-view";
import {TLoggingEntry, TLoggingEntryOrigin, TLoggingEntryAction} from "../../interfaces/logging";
import BackLink from "../../patterns/navigation/backLink";
import List from "../../patterns/list/list";
import emptySearch from "../../assets/lists/empty_search.svg";
import {
  actionColumn,
  ChipCellProps,
  columnDefinition,
  ColumnType,
  enumFilter,
  gridAction
} from "../../patterns/list/listUtils";
import {useGridApiRef} from "@mui/x-data-grid-pro";
import {getActionsHistory} from "../../services/AdminService";
import WebIcon from '@mui/icons-material/Web';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/Info";

export default function CompanyHistory(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const selectedCompany = getSelectedCompany();
  const apiRef = useGridApiRef();

  const [log, setLog] = useState<TLoggingEntry|null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (!isSuperAdmin() || selectedCompany == null) {
      navigate(PRIVATE_URL.LANDING_PAGE, {replace: true});
    }
  }, []);

  const showLoggingDialog = (log: TLoggingEntry): void => {
    setLog(log);
    setOpenDialog(true);
  }

  const originProps = (row: TLoggingEntry): ChipCellProps => {
    switch (row.origin) {
    case TLoggingEntryOrigin.BO:
      return {
        icon: <WebIcon sx={{width: 18, height: 18}}/>,
        iconColor: "primaryLight.main",
        color: "primaryLight.light",
        backgroundColor: "primary.main"
      }
    case TLoggingEntryOrigin.FO:
      return {
        icon: <ShoppingBasketIcon sx={{width: 18, height: 18}}/>,
        iconColor: "secondaryLight.main",
        color: "secondaryLight.light",
        backgroundColor: "secondary.main"
      }
    }
  }

  const actionProps = (row: TLoggingEntry): ChipCellProps => {
    switch (row.action) {
    case TLoggingEntryAction.CREATE:
      return {
        icon: <CheckCircleIcon sx={{width: 18, height: 18}}/>,
        iconColor: "success.main",
        color: "success.dark",
        backgroundColor: "success.light"
      }
    case TLoggingEntryAction.UPDATE:
      return {
        icon: <InfoIcon sx={{width: 18, height: 18}}/>,
        iconColor: "info.main",
        color: "info.dark",
        backgroundColor: "infoLight.light"
      }
    case TLoggingEntryAction.DELETE:
      return {
        icon: <ErrorOutlineIcon sx={{width: 18, height: 18}}/>,
        iconColor: "error.main",
        color: "error.dark",
        backgroundColor: "error.light"
      }
    }
  }

  const originColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...nonFilterableColumns("origin", 200),
      type: "singleSelect",
      filterOperators: enumFilter,
      valueOptions: Object.keys(TLoggingEntryOrigin).map(status => ({value: status, label: t(`history.origin_${status.toLowerCase()}`)})),
      renderCell: (item): JSX.Element => {
        const origin = originProps(item.row);
        return <Chip icon={origin.icon}
          sx={{backgroundColor: origin.backgroundColor, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: origin.iconColor}}}
          label={<Typography variant="body2" color={origin.color} sx={{fontWeight: "regular"}}>
            {t("history.origin_" + item.value.toLowerCase())}
          </Typography>}/>
      }
    }
  }

  const actionColumnDefinition = (): GridEnrichedColDef => {
    return {
      ...nonFilterableColumns("action", 200),
      type: "singleSelect",
      filterOperators: enumFilter,
      valueOptions: Object.keys(TLoggingEntryOrigin).map(status => ({value: status, label: t(`history.action_${status.toLowerCase()}`)})),
      renderCell: (item): JSX.Element => {
        const action = actionProps(item.row);
        return <Chip icon={action.icon}
          sx={{backgroundColor: action.backgroundColor, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: action.iconColor}}}
          label={<Typography variant="body2" color={action.color} sx={{fontWeight: "regular"}}>
            {t("history.type_" + item.value.toLowerCase())}
          </Typography>}/>
      }
    }
  }

  const nonFilterableColumns = (field: string, width: number, type: ColumnType = ColumnType.STRING, sortable = true, isNullable = false): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "history", field, width, type, sortable, isNullable),
      filterable: false
    }
  }

  const columns = [
    nonFilterableColumns("userId", 200),
    nonFilterableColumns("user", 200, ColumnType.STRING, false),
    nonFilterableColumns("type", 200),
    actionColumnDefinition(),
    originColumnDefinition(),
    nonFilterableColumns("timestamp", 150, ColumnType.DATE_TIME),
    actionColumn(params => [
      gridAction(params, "details", t("history.actions.details"), VisibilityIcon, showLoggingDialog)
    ])
  ]

  const closeDialog = (): void => {
    setLog(null);
    setOpenDialog(false);
  }
  const showDetailsDialog = <Dialog fullWidth open={openDialog} onClose={closeDialog} sx={{"& .MuiPaper-root": {maxWidth: "50vw"}}}>
    <DialogTitle sx={{pt: 4, px: 4, mb: 3, borderBottom: "1px solid", borderColor: "ornament.dark"}}>
      <Box sx={{display: 'flex', alignItems: "center", justifyContent: 'space-between'}}>
        <Typography variant="h2" sx={{fontWeight: "bold"}}>{t('history.actions.details_title')}</Typography>
        <IconButton onClick={closeDialog} sx={{width: "24px", height: "24x"}}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </Box>
    </DialogTitle>
    <DialogContent sx={{px: 5, maxHeight: "80vh"}}>
      <ReactJson name={log?.type.toLowerCase()} src={JSON.parse(log ? log.value : "{}")}/>
    </DialogContent>
  </Dialog>

  return (
    <Box sx={{minHeight: "100vh", backgroundColor: "ornament.main", display: "flex", flexDirection: "column"}}>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", backgroundColor: "ornament.light",
        borderWidth: "0", borderBottomWidth: "1px", borderStyle: "solid", borderColor: "ornament.dark"}}>
        <BackLink/>
        <Typography variant="h1" color="neutral.dark" sx={{ml: 4, mb: 1, fontWeight: "bold"}}>{t("operation_gifts_history.title")}</Typography>
        <Typography variant="body2" color="neutral.main" sx={{ml: 4, mb: 5, fontWeight: "regular"}}>{t("operation_gifts_history.subtitle")}</Typography>
      </Box>
      <Box sx={{p: 5, display: "flex", justifyContent: "space-around"}}>
        <List
          translationPrefix="operation_gifts_history"
          dialogs={[showDetailsDialog]}
          columns={columns}
          apiRef={apiRef}
          loadRows={getActionsHistory}
          height="75vh"
          emptyListIcon={emptySearch}
          getRowId={(row): any => row.userId}
          defaultOrder={{field: 'timestamp', sort: 'desc'}}
          sx={{width: "100%"}}
        />
      </Box>
    </Box>
  )
}
