import React, {useEffect, useState} from 'react';
import {Card, CardHeader, Checkbox, FormControlLabel, FormGroup, Typography} from "@mui/material";
import UNIVERSES_IMAGES from "./universesImages";
import {useTranslation} from 'react-i18next';
import {translate} from "./operationUniverses";
import {TShopUniverse, TShopUniverseDetailsWithId} from "../../interfaces/shop";

function OperationUniverseSectionFilter(props: {section: TShopUniverseDetailsWithId, selectedUniverses: number[], handleSelectedUniverses: (universes: number[]) => void, loading?: boolean, isLast: boolean}): JSX.Element {
  const {section, selectedUniverses, handleSelectedUniverses, loading, isLast} = props;
  const [isSelected, setSelected] = useState<boolean>(false);

  const manageSection = (e: any): void => {
    if (e.target.checked) {
      const universes: number[] = [];
      universes.push(...selectedUniverses);
      universes.push(section.id);
      handleSelectedUniverses(universes);
      setSelected(true);
    } else {
      const universes: number[] = [];
      universes.push(...selectedUniverses);
      universes.splice(universes.indexOf(section.id), 1);
      handleSelectedUniverses(universes);
      setSelected(false);
    }
  }

  useEffect(() => {
    setSelected(selectedUniverses.find((id: number): boolean => id === section.id) !== undefined);
  }, [selectedUniverses])

  return (
    <Card sx={{backgroundColor: isSelected ? "primaryLight.light" : "ornament.main", borderWidth: 0, pt: 1, pb: 1, px: 2, mb: isLast ? "0px" : 1, boxShadow: 0}}>
      <FormGroup>
        <FormControlLabel sx={{margin: "unset", display: "flex", alignItems: "flex-start"}} control={
          <Checkbox checked={isSelected} disabled={loading} sx={{color: "primary.main", width: "24px", height: "24px", "& .MuiSvgIcon-root": {fontSize: "24px"}}} onClick={manageSection}/>
        } label={<Typography variant="body2" display="inline" sx={{color: isSelected ? "primary.dark" : "neutral.main", mx: 1}}>{section.x_name}</Typography>}/>
      </FormGroup>
    </Card>
  )
}

export default function OperationUniverseCard(props: {universe: TShopUniverse, selectedUniverses: number[], handleSelectedUniverses: (universes: number[]) => void, loading?: boolean}): JSX.Element {
  const {universe, selectedUniverses, handleSelectedUniverses, loading} = props;
  const {t} = useTranslation();
  const [nbGiftsSelected, setNbGiftsSelected] = useState(0);

  // @ts-ignore Toutes les images provenant d'Odoo sont stockées dans l'enum
  const image = UNIVERSES_IMAGES[universe.parent.x_icon];
  const sectionIds = universe.children.map(child => child.id);

  useEffect((): void => {
    let l_nbGifts = 0;
    (universe.children).forEach(l_child => {
      if (selectedUniverses.includes(l_child.id)) l_nbGifts += l_child.gift_count_admin;
    });
    setNbGiftsSelected(l_nbGifts);
  }, [selectedUniverses])

  const selectAllSections = (): void => {
    const ids: number[] = [];
    if (nbGiftsSelected > 0) {
      selectedUniverses.forEach((id: number) => {
        if (!sectionIds.includes(id)) ids.push(id);
      });
      handleSelectedUniverses(ids);
    } else {
      selectedUniverses.forEach((id: number) => ids.push(id));
      sectionIds.forEach((id: number) => ids.push(id));
      handleSelectedUniverses(ids);
    }
    let l_nbGifts = 0;
    (universe.children).forEach(l_child => {
      if (ids.includes(l_child.id)) l_nbGifts += l_child.gift_count_admin;
    });
    setNbGiftsSelected(l_nbGifts);
  }

  const onSelectedUniverses = (universeSelected: number[]): void => {
    let l_nbGifts = 0;
    (universe.children).forEach(l_child => {
      if (universeSelected.includes(l_child.id)) l_nbGifts += l_child.gift_count_admin;
    });
    setNbGiftsSelected(l_nbGifts);
    handleSelectedUniverses(universeSelected);
  };

  return (
    <Card sx={{p: 3, "&:hover": {boxShadow: 3}}}>
      <CardHeader sx={{p: "0px", mb: 3, "& .MuiCardHeader-action": {m: "0px"}}} title={<>
        <Typography variant="body1" sx={{color: "neutral.dark", fontWeight: "bold", mb: 0}}>{universe.parent.x_name}</Typography>
      </>} action={<Checkbox checked={nbGiftsSelected > 0} disabled={loading} sx={{color: "primary.main", width: "24px", height: "24px", "& .MuiSvgIcon-root": {fontSize: "24px"}}} onClick={selectAllSections}/>}/>
      <img src={image} alt={universe.parent.x_name + "-icon"} width="100%" style={{margin: "auto", height: "140px", objectFit: "cover", borderRadius: "4px"}}/>
      <Typography variant="body2" sx={{color: "neutral.main", fontWeight: "bold", mt: 3, mb: 1}}>{translate(t, "filter_sections")}</Typography>
      {universe.children.map((child, index) => <OperationUniverseSectionFilter key={"section-" + child.x_name} section={child} selectedUniverses={selectedUniverses} handleSelectedUniverses={onSelectedUniverses} loading={loading} isLast={index + 1 == universe.children.length}/>)}
    </Card>
  )
}