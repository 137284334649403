import {useTranslation} from "react-i18next";
import React from "react";
import {Link, useLocation} from "react-router-dom";
import {Box, Divider, Typography} from "@mui/material";

type SecondMenuProps = {
  label: string,
  url: string,
  id?: string
}

type SecondLevelMenuProps = {
  menus: SecondMenuProps[]
}

function SecondLevelMenuItem(props: SecondMenuProps): JSX.Element {
  const {t} = useTranslation();
  const location = useLocation();
  const selected = location.pathname + location.search === props.url;
  const id = props.id ? {id: props.id} : {};

  return (
    <Box>
      <Link to={props.url} {...id}>
        <Typography variant="body1" sx={{color: selected ? "primary.main" : "neutral.main", fontWeight: "bold"}}>{t(props.label)}</Typography>
      </Link>
      {selected && <Divider sx={{mr: "auto", mx: "auto", backgroundColor: "primary.main", borderColor: "primary.main", width: "25%", height: "4px"}} flexItem/>}
    </Box>
  )
}

function SecondLevelMenu(props: SecondLevelMenuProps): JSX.Element {
  return (
    <Box sx={{display: "flex"}}>
      {props.menus.map((menu: SecondMenuProps, index: number) => {
        return <Box sx={{display: "flex"}} key={"menu-" + menu.label}>
          <SecondLevelMenuItem {...menu}/>
          {index < props.menus.length - 1 &&
            <Divider sx={{my: 0, mx: 3, backgroundColor: "ornament.dark", borderColor: "ornament.dark", width: "1px", height: "18px"}} orientation="vertical" flexItem />
          }
        </Box>
      })}
    </Box>
  )
}

export default SecondLevelMenu;
