import React, {ElementType, FunctionComponent, MouseEvent} from "react";
import {OverridableStringUnion} from "@mui/types";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export enum IconPosition {
  LEFT,
  MIDDLE,
  RIGHT
}

export enum ButtonType {
  PRIMARY,
  PRIMARY_NEGATIVE,
  SECONDARY,
  GHOST,
  GREY
}

export enum ButtonSize {
  VERY_SMALL,
  SMALL,
  BIG
}

export type MainButtonProps = {
  type: ButtonType,
  size: ButtonSize,
  label?: string,
  id?: string,
  icon?: ElementType,
  image?: FunctionComponent,
  position?: IconPosition,
  disabled?: boolean,
  action?: (event: MouseEvent<HTMLElement>) => void,
  href?: string,
  sx?: SxProps<Theme>,
  backgroundColor?: string,
  selected?: boolean,
  submit?: boolean,
  loading?: boolean,
  download?: boolean,
  openNewWindow?: boolean,
  rightChevron?: boolean
}

export type ButtonProps = {
  label?: string,
  id?: string,
  icon?: ElementType,
  image?: FunctionComponent,
  position?: IconPosition,
  disabled?: boolean,
  action?: (event: MouseEvent<HTMLElement>) => void,
  href?: string,
  sx?: SxProps<Theme>,
  backgroundColor?: string,
  selected?: boolean,
  submit?: boolean,
  loading?: boolean,
  download?: boolean,
  openNewWindow?: boolean,
  rightChevron?: boolean
}

export type SizeProperties = {
  height: string,
  padding: string,
  iconSize: number,
  fontSize: string
}

export type TypeProperties = {
  variant: OverridableStringUnion<'text' | 'outlined' | 'contained'>,
  fontWeight: string,
  color: string,
  borderColor: string,
  backgroundColor: string,
  disabledColor: string,
  disabledBorderColor: string,
  disabledBackgroundColor: string,
  hoverColor: string,
  hoverBorderColor: string,
  hoverBackgroundColor: string,
  focusColor: string,
  focusBorderColor: string,
  focusBackgroundColor: string
}

export function getStartIcon(size: number, position?: IconPosition, icon?: ElementType, image?: FunctionComponent, loading?: boolean): JSX.Element | null {
  if (position !== IconPosition.LEFT) {
    return null;
  }

  if (icon !== undefined) {
    return getIcon(size, icon, loading);
  }
  return getImage(size, icon, loading);
}

export function getEndIcon(size: number, position?: IconPosition, icon?: ElementType, image?: FunctionComponent, loading?: boolean): JSX.Element | null {
  if (position !== IconPosition.RIGHT) {
    return null;
  }

  if (icon !== undefined) {
    return getIcon(size, icon, loading);
  }
  return getImage(size, image, loading);
}

export function getIcon(size: number, icon: ElementType, loading?: boolean): JSX.Element | null {
  const Icon = icon;
  if (loading) {
    return <Icon sx={{width: size, height: size, visibility: "hidden"}}/>;
  }
  return <Icon sx={{width: size, height: size}}/>;
}

export function getImage(size: number, image?: FunctionComponent, loading?: boolean): JSX.Element | null {
  if (image == undefined) {
    return null;
  }

  if (loading) {
    // @ts-ignore
    return <img src={image} alt="" style={{width: 40, height: 40, visibility: "hidden"}}/>;
  }
  // @ts-ignore
  return <img src={image} alt="" style={{width: 40, height: 40}}/>;
}
