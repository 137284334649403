import {API_URL} from "../common/struct/urlManager";
import {multiDel, getPaginated, post, RequestFilter, RequestOrder} from "../common/methods/ApiService";
import {TGiftOrderLine} from "../interfaces/giftOrderLine";
import {TReward} from "../interfaces/reward";

export async function deleteOperationParticipants(operationId: string, search: string, filters: RequestFilter[], walletId?: string): Promise<void> {
  return multiDel(`${API_URL.OPERATION}/${operationId}/operationparticipants`, search, filters, walletId);
}

export async function restoreOperationParticipant(operationId: string, operationParticipantId: string): Promise<void> {
  return post(`${API_URL.OPERATION}/${operationId}/operationparticipants/${operationParticipantId}/restore`);
}

export async function restoreAllOperationParticipants(operationId: string): Promise<void> {
  return post(`${API_URL.OPERATION}/${operationId}/operationparticipants/restore`);
}

export async function getOperationParticipantOrders(operationParticipantId: string, page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TGiftOrderLine[]}> {
  return getPaginated(`${API_URL.OPERATION_PARTICIPANTS}/${operationParticipantId}/orders`, page, itemsPerPage, search, order, filters);
}

export async function getOperationParticipantTransactions(operationParticipantId: string, page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TReward[]}> {
  return getPaginated(`${API_URL.OPERATION_PARTICIPANTS}/${operationParticipantId}/rewards`, page, itemsPerPage, search, order, filters);
}
