import React, {useContext, useState} from 'react';
import Wizard from '../../patterns/wizard/wizard';
import {WizardSize} from "../../patterns/wizard/wizardProps";
import {AppContext} from "../../App";
import {StoreContext, WizardType} from "../../common/struct/store";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import {useNavigate, useParams} from "react-router-dom";
import WizardHelperCard from "../../patterns/wizard/wizardHelperCard";
import UploadFileCard from "./uploadFileCard";
import UpdateImportMappingCard from "./updateImportMappingCard";
import ValidateImportCard from "./validateImportCard";
import DeleteDialog from "../../patterns/dialog/deleteDialog";
import {useTranslation} from "react-i18next";
import {cancelParticipantImport} from "../../services/ImportService";
import {initWizardStore, setHeaderWizardStore} from "../../common/methods/context-setter/wizardStoreReducer";

export default function ImportParticipantsWizard(): JSX.Element {
  const {operationId} = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const [loading, setLoading] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [disableNextStep, setDisableNextStep] = useState(false);
  const [error, setError] = useState<string|null>(null);
  const [saveHeader, setSaveHeader] = useState<boolean>(store.participantWizard.header);

  const handleSaveHeader = (saveHeader: boolean): void => {    
    setSaveHeader(saveHeader);
    setHeaderWizardStore(STORE, WizardType.IMPORT_PARTICIPANT, saveHeader);
  } 

  const params = {
    loading: loading,
    disableNextStep: disableNextStep,
    setLoading: setLoading,
    setError: setError,
    setDisableNextStep: setDisableNextStep,
    operationId: operationId!,
    saveHeader: saveHeader,
    handleSaveHeader: handleSaveHeader
  }
  const steps = [
    {
      content: <UploadFileCard key="upload-file" {...params}/>,
      helper: <>
        <WizardHelperCard name="import_participants.upload.helper.content" sx={{mb: "31px"}}/>
        <WizardHelperCard name="import_participants.upload.helper.encoding"/>
      </>,
      size: WizardSize.FIFTY_FIFTY
    },
    {
      content: <UpdateImportMappingCard key="update-mapping" {...params}/>,
      helper: <>
        <WizardHelperCard name="import_participants.mapping.helper.new_attribute" sx={{mb: 5}}/>
        <WizardHelperCard name="import_participants.mapping.helper.ignore_column"/>
      </>,
      size: WizardSize.SEVENTY_THIRTY
    },
    {
      content: <ValidateImportCard key="validate-import" {...params}/>,
      helper: <></>,
      size: WizardSize.FULL
    }
  ]

  const cancelImport = (): Promise<void> => {
    if (operationId == null) {
      return Promise.resolve();
    }    

    if (store.participantWizard.uploadedFileDatas?.id == null) {
      initWizardStore(STORE, WizardType.IMPORT_PARTICIPANT, t("wizard.import.cancel.notification"));
      navigate(operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANTS, operationId));
      return Promise.resolve();
    }

    return cancelParticipantImport(store.participantWizard.uploadedFileDatas.id)
      .then(() => {
        setOpenCancelDialog(false);
        initWizardStore(STORE, WizardType.IMPORT_PARTICIPANT, t("wizard.operation.cancel.notification"));        
        navigate(operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANTS, operationId));
      })
  }

  const cancelDialog = <DeleteDialog
    open={openCancelDialog}
    close={(): void => setOpenCancelDialog(false)}
    title={t("wizard.import.cancel.title")}
    warning={t("wizard.import.cancel.content")}
    buttonLabel={t("wizard.import.cancel.button_label")}
    cancelLabel={t("wizard.import.cancel.cancel_label")}
    deleteElement={cancelImport}/>

  const updateOpenCancelDialog = (open: boolean): void => {
    if (operationId == null) {
      return;
    }

    if (store.participantWizard.uploadedFileDatas?.id) {
      setOpenCancelDialog(open);
    } else {
      initWizardStore(STORE, WizardType.IMPORT_PARTICIPANT, t("wizard.import.cancel.notification"));
      navigate(operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANTS, operationId));
    }
  }

  return (
    <Wizard steps={steps} wizard={store.participantWizard} loading={loading} error={error}
      cancelInfo={{dialog: cancelDialog, openDialog: openCancelDialog, setOpenDialog: updateOpenCancelDialog}}
      endUrl={operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANTS, operationId!)} operationId={operationId!} disableNextStep={disableNextStep}/>
  )
}
