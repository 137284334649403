import {createTheme} from "@mui/material";
import {Theme} from "@mui/material/styles";

const shadows = [
  "none",
  "0 2px 0 0 rgba(50, 49, 64, 0.15)",
  "0 4px 4px 0 rgba(50, 49, 64, 0.15)",
  "0 6px 14px 0 rgba(50, 49, 64, 0.15)",
  "-8px 0px 8px rgba(50, 49, 64, 0.08)",
  "0px 2px 0px rgba(103, 37, 210, 0.25)"
]
const noneShadows = Array(21).fill("none");

declare module '@mui/material/styles' {
  interface Palette {
    primaryLight: Palette['primary'];
    primaryDark: Palette['primary'];
    secondaryLight: Palette['primary'];
    neutral: Palette['primary'];
    ornament: Palette['primary'];
    infoLight: Palette['primary'];
    blueGreen: Palette['primary'];
    shockingOrange: Palette['primary'];
    raspberry: Palette['primary'];
    hanBlue: Palette['primary'];
    darkOrchid: Palette['primary'];
    zomp: Palette['primary'];
    hookersGreen: Palette['primary'];
    africanViolet: Palette['primary'];
  }
  interface PaletteOptions {
    primaryLight: Palette['primary'];
    primaryDark: Palette['primary'];
    secondaryLight: Palette['primary'];
    neutral: Palette['primary'];
    ornament: Palette['primary'];
    infoLight: Palette['primary'];
    blueGreen: Palette['primary'];
    shockingOrange: Palette['primary'];
    raspberry: Palette['primary'];
    hanBlue: Palette['primary'];
    darkOrchid: Palette['primary'];
    zomp: Palette['primary'];
    hookersGreen: Palette['primary'];
    africanViolet: Palette['primary'];
  }

  interface TypographyVariants {
    bodyLanding1: React.CSSProperties;
    bodyLanding2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    bodyLanding1?: React.CSSProperties;
    bodyLanding2?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyLanding1: true;
    bodyLanding2: true;
  }
}

export const spacing = (index: number): number => {
  const sign = index == 0 ? 1 : Math.sign(index);
  switch (Math.abs(index)) {
  case 0:
    return sign * 4;
  case 1:
    return sign * 8;
  case 2:
    return sign * 12;
  case 3:
    return sign * 16;
  case 4:
    return sign * 20;
  case 5:
    return sign * 24;
  case 6:
    return sign * 32;
  case 7:
    return sign * 40;
  case 8:
    return sign * 48;
  case 9:
    return sign * 56;
  case 10:
    return sign * 64;
  case 11:
    return sign * 72;
  case 12:
    return sign * 96;
  default:
    return sign * 120;
  }
}
export const FOCUS_COLOR = '#6725D2';
export const LIBERTY_THEME = {
  palette: {
    primary: {
      dark: '#4F0DBC',
      main: '#6725D2',
      light: '#8B57DF'
    },
    primaryLight: {
      dark: '#BA8BEA',
      main: '#DAC4F2',
      light: '#EFE2FF'
    },
    primaryDark: {
      dark: '#390788',
      main: '#440AA0',
      light: '#500EBC'
    },
    secondary: {
      dark: '#15144B',
      main: '#2B2A5A',
      light: '#383566'
    },
    secondaryLight: {
      dark: '#494789',
      main: '#9F9ED4',
      light: '#CBCAED'
    },
    neutral: {
      dark: '#323140',
      main: '#565868',
      light: '#A6A8BB'
    },
    ornament: {
      dark: '#CDCEDE',
      main: '#F3F3F7',
      light: '#FFFFFF'
    },
    info: {
      dark: '#1045E7',
      main: '#285AEF',
      light: '#4977FF'
    },
    infoLight: {
      dark: '#6F93FA',
      main: '#AEC0F4',
      light: '#E8EDFE'
    },
    error: {
      dark: '#A9010B',
      main: '#D3000D',
      light: '#FCEBEC'
    },
    warning: {
      dark: '#C13A00',
      main: '#F25700',
      light: '#F6EAE4'
    },
    success: {
      dark: '#006328',
      main: '#008234',
      light: '#DFF3E7'
    },
    blueGreen: {
      dark: '#05829E',
      main: '#098EAC',
      light: '#0F99B9'
    },
    shockingOrange: {
      dark: '#B04E3B',
      main: '#C05742',
      light: '#DF624A'
    },
    raspberry: {
      dark: '#AE0944',
      main: '#C11552',
      light: '#EB105E'
    },
    hanBlue: {
      dark: '#35438A',
      main: '#3D4D9C',
      light: '#4B5FC2'
    },
    darkOrchid: {
      dark: '#8400A9',
      main: '#9706BE',
      light: '#9F2BBE'
    },
    africanViolet: {
      dark: '#8A4598',
      main: '#92599D',
      light: '#B373B8'
    },
    zomp: {
      dark: '#157E75',
      main: '#228E84',
      light: '#2BA095'
    },
    hookersGreen: {
      dark: '#30514E',
      main: '#365B58',
      light: '#42716C'
    },
    landing: {
      dark: '#2D6376',
      main: '#50DBB9'
    },
    landingDark:{
      dark: "#1B1A61"
    }
  },
  spacing: spacing,
  typography: {
    fontFamily: "Mulish",
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightRegular: 300,
    allVariants: {
      letterSpacing: 0
    },
    h1: {
      fontSize: "32px",
      lineHeight: "40px"
    },
    h2: {
      fontSize: "20px",
      lineHeight: "28px"
    },
    body1: {
      fontSize: "16px",
      lineHeight: "24px"
    },
    body2: {
      fontSize: "14px",
      lineHeight: "22px"
    },
    bodyLanding1: {
      fontSize: "18px",
      lineHeight: "26px",
      fontFamily: "Mulish"
    },
    bodyLanding2: {
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "Mulish"
    },
    caption: {
      fontSize: "12px",
      lineHeight: "22px"
    }
  },
  shadows: shadows.concat(noneShadows),
  shape: {
    borderRadius: 4
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        container: {
          height: "unset",
          width: "100%"
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        square: {
          borderRadius: 4
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          borderRadius: 4
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "Mulish !important",
          fontSize: "14px !important"
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          // ornament.dark
          border: '1px solid #E0E3E8'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          minWidth: "unset",
          textTransform: "none"
        },
        text: {
          "&:hover": {
            backgroundColor: "inherit",

          }
        },
        startIcon: {
          fontSize: "unset",
          "& > *:first-of-type": {
            fontSize: "unset"
          }
        },
        endIcon: {
          fontSize: "unset",
          "& > *:first-of-type": {
            fontSize: "unset"
          }
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "unset",
          width: "unset"
        },
        fontSizeSmall: {
          height: "18px"
        },
        fontSizeLarge: {
          height: "24px"
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: '#323140'
        },
        tooltip: {
          backgroundColor: '#323140',
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "22px",
          padding: "4px 16px"
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '.MuiDataGrid-sortIcon': {
            transform: "rotate(180deg)"
          }
        }
      }
    }
  }
};


// @ts-ignore shadows has 4 elements and noneShadows has 21 = 25 elements
export const LibertyTheme: Theme = createTheme(LIBERTY_THEME);

export const getColors = () : string[] => {
  const colorsName: string[] = ['blueGreen', 'shockingOrange', 'raspberry', 'hanBlue', 'darkOrchid', 'zomp'];
  const colorsHex: string[] = [];
  colorsName.forEach((colorName: string) => {
    colorsHex.push(colorName + ".light");
  });
  colorsName.forEach((colorName: string) => {
    colorsHex.push(colorName + ".main");
  });
  colorsName.forEach((colorName: string) => {
    colorsHex.push(colorName + ".dark");
  });
  return colorsHex;
}
