import React from 'react';
import {Box, Button, SxProps, Typography, useMediaQuery} from '@mui/material';
import {PrimaryBigButton} from '../../components/buttons/mainButton';
import {Theme} from "@mui/material/styles";
import Image from "../../assets/landingPage/landingVitrine.jpg";
import ImageResp from "../../assets/landingPage/landingVitrineMobile.jpg";
import Check from "../../assets/landingPage/check.svg";
import {PUBLIC_URL} from '../../common/struct/urlManager';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function ChipLabel(props: {title: string, fontColor?: string, sx?: SxProps<Theme>}): JSX.Element {

  const {t} = useTranslation();

  return (
    <Box sx={{display: "flex", alignItems: "center", maxWidth: "100vw", overflow: "hidden", ...props.sx}}>
      {/* @ts-ignore */}
      <img src={Check} alt={t("landing_page.alt.check")} style={{height: "34px", width: "34px"}} />
      <Typography variant="body1" color={props.fontColor ?? "ornament.light"} sx={{fontWeight: "bold", fontSize: "18px", ml: 3}}>{props.title}</Typography>
    </Box>
  )
}

export function DesktopReward(): JSX.Element {

  const navigate = useNavigate();
  const {t} = useTranslation();

  const img0 = useMediaQuery('(min-width:1760px)');
  const img1 = useMediaQuery('(min-width:1650px)');
  const img2 = useMediaQuery('(min-width:1535px)');
  const img3 = useMediaQuery('(min-width:1435px)');
  const img4 = useMediaQuery('(min-width:1335px)');

  return (
    <Box sx={{height: img0 ? "850px" : img1 ? "780px" : img2 ? "780px" : img3 ? "680px" : "645px", mb: img4 ? 4 : "0px", position: "relative"}}>
      <Box sx={{display: "flex", position: "relative", zIndex: 1, flexDirection: "column", pt: img0 ? "110px" : img1 ? "80px" : img2 ? "70px" : img3 ? "60px" : "50px", pr: 5, boxSizing: "border-box", width: "38%"}}>
        <Typography variant="h1" sx={{fontWeight: "bold", color: "ornament.light"}}>{t("landing_page.body.main_title")}</Typography>
        <Box sx={{display: "flex", flexDirection: "column", mb: img4 ? 7 : 1, mt: img4 ? 7 : 3}}>
          <ChipLabel title={t("landing_page.body.main_chip.free")} sx={{mb: 2}} />
          <ChipLabel title={t("landing_page.body.main_chip.easy")} sx={{mb: 2}}/>
          <ChipLabel title={t("landing_page.body.main_chip.infinite")} sx={{mb: 2}} />
        </Box>
        <Typography variant="body1" color="ornament.light" sx={{fontWeight: "400", fontSize: "16px"}}>
          {t("landing_page.body.main_text_1")}
        </Typography>
        <Typography variant="body1" color="ornament.light" sx={{fontWeight: "400", fontSize: "16px", mt: 4}}>
          {t("landing_page.body.main_text_2")}
        </Typography>
        <Box sx={{display: "flex", flexWrap: "wrap", mt: 6}}>
          <PrimaryBigButton action={(): void => navigate(PUBLIC_URL.REGISTER)} sx={{borderRadius: "50px", height: "44px", color: "ornament.light", border: "none", textTransform: "uppercase", fontWeight: 700, fontSize: "16px", borderWidth: "0px", backgroundColor: "primary.main", mr: 4, mb: 3, "&:hover": {backgroundColor: "primary.main", color: "ornament.light"}}} label={t("landing_page.body.main_btn_1")}/>
          <Button variant="text" href={process.env.REACT_APP_LANDING_PAGE_DEMO_BUTTON_LINK ? process.env.REACT_APP_LANDING_PAGE_DEMO_BUTTON_LINK : "https://calendly.com/nicolas-brevet/15min"} target="_blank" sx={{borderRadius: "50px", height: "44px", color: "ornament.light", border: "none", textTransform: "uppercase", fontWeight: 700, fontSize: "16px", borderWidth: "1.8px", borderColor: "ornament.light", borderStyle: "solid", backgroundColor: "secondary.dark", padding: "6px 12px 6px 12px", "&:hover": {backgroundColor: "secondary.dark", color: "ornament.light"}}}>
            {process.env.REACT_APP_LANDING_PAGE_DEMO_BUTTON_TEXT ? process.env.REACT_APP_LANDING_PAGE_DEMO_BUTTON_TEXT : t("landing_page.body.main_btn_2")}
          </Button>
        </Box>
      </Box>
      <Box sx={{position: "absolute", zIndex: 0, left: img0 ? "36%" : "40%", top: img0 ? "-50%" : img1 ? "-48%" : img2 ? "-46%" : img3 ? "-49%" : img4 ? "-44%" : "-40%"}}>
        <img src={Image} alt={t("landing_page.alt.front")} style={{height: img0 ? "100%" : img1 ? "1130px" : img2 ? "1060px" : img3 ? "1000px" : img4 ? "930px" : "860px", maxHeight: "1563px"}}/>
      </Box>
    </Box>
  )
}

export function MobileReward(): JSX.Element {

  const navigate = useNavigate();
  const {t} = useTranslation();

  const breakpointL = useMediaQuery('(min-width:675px)');
  const breakpointChip = useMediaQuery('(min-width:625px)');
  const breakpointM = useMediaQuery('(min-width:570px)');
  const breakpointS = useMediaQuery('(min-width:510px)');
  const breakpointXS = useMediaQuery('(min-width:460px)');
  const breakpointXXS = useMediaQuery('(min-width:385px)');

  return (
    <Box sx={{height: breakpointL ? "900px" : breakpointChip ? "880px" : breakpointM ? "920px" : breakpointS ? "860px" : breakpointXXS ? "930px" : "960px"}}>
      <Box sx={{display: "flex", flexDirection: "column", width: "100%", maxWidth: "600px", margin: "0 auto", zIndex: 2, boxSizing: "border-box"}}>
        <Typography variant="h1" sx={{fontWeight: "bold", color: "ornament.light"}}>{t("landing_page.body.main_title")}</Typography>
        <Box sx={{display: "flex", flexWrap: "wrap", flexDirection: breakpointXS ? "row" : "column", mt: 7, mb: "28px"}}>
          <ChipLabel title={t("landing_page.body.main_chip.free")} sx={{mr: 4, mb: 2}} />
          <ChipLabel title={t("landing_page.body.main_chip.easy")} sx={{mr: 4, mb: 2}} />
          <ChipLabel title={t("landing_page.body.main_chip.infinite")} sx={{mb: 2}} />
        </Box>
        <Typography variant="body1" color="ornament.light" sx={{fontWeight: "400", fontSize: "16px"}}>
          {t("landing_page.body.main_text_1")}
        </Typography>
        <Typography variant="body1" color="ornament.light" sx={{fontWeight: "400", fontSize: "16px", mt: 4}}>
          {t("landing_page.body.main_text_2")}
        </Typography>
        <Box sx={{display: "flex", flexWrap: "wrap", mt: 5}}>
          <PrimaryBigButton action={(): void => navigate(PUBLIC_URL.REGISTER)} sx={{borderRadius: "50px", height: "44px", color: "ornament.light", border: "none", textTransform: "uppercase", fontWeight: 700, fontSize: "16px", borderWidth: "0px", backgroundColor: "primary.main", mr: 2, mt: 2, "&:hover": {backgroundColor: "primary.main", color: "ornament.light"}}} label={t("landing_page.body.main_btn_1")}/>
          <Button variant="text" href={process.env.REACT_APP_LANDING_PAGE_DEMO_BUTTON_LINK ? process.env.REACT_APP_LANDING_PAGE_DEMO_BUTTON_LINK : "https://calendly.com/nicolas-brevet/15min"} target="_blank" sx={{borderRadius: "50px", height: "44px", color: "ornament.light", border: "none", textTransform: "uppercase", fontWeight: 700, fontSize: "16px", borderWidth: "1.8px", borderColor: "ornament.light", mt: 2, borderStyle: "solid", backgroundColor: "secondary.dark", padding: "6px 12px 6px 12px", "&:hover": {backgroundColor: "secondary.dark", color: "ornament.light"}}}>
            {process.env.REACT_APP_LANDING_PAGE_DEMO_BUTTON_TEXT ? process.env.REACT_APP_LANDING_PAGE_DEMO_BUTTON_TEXT : t("landing_page.body.main_btn_2")}
          </Button>
        </Box>
      </Box>
      <Box sx={{display: "flex", justifyContent: "center", height: breakpointL ? "100%" : breakpointM ? "450px" : breakpointS ? "400px" : "350px", maxHeight: "500px", mt: 4}}>
        <img src={ImageResp} alt={t("landing_page.alt.front")} style={{height: "100%", marginLeft: "282px"}} />
      </Box>
    </Box>
  )
}
