import React, {useContext, useEffect, useState} from "react";
import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js';
import {useNavigate} from "react-router-dom";
import {Alert, Box, Card, Typography} from "@mui/material";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import {useTranslation} from "react-i18next";
import logSVG from "../../assets/logoJoozy.svg";
import stripe from "../../assets/stripe.svg"
import {LibertyTheme} from "../../tokens/libertyTheme";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {ADD_ERROR_NOTIFICATION} from "../../common/methods/context-setter/globals";

type CheckoutFormProps = {
  operationId: string,
  transactionId?: string
  walletId?: string
}

function CheckoutForm(props: CheckoutFormProps): JSX.Element {
  const {operationId, transactionId, walletId} = props;
  const stripe = useStripe();
  const elements = useElements();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<null|string>(null);
  const [loading, setLoading] = useState(false);
  const STORE = useContext<StoreContext>(AppContext);

  const confirmPayment = (): void => {
    if (!elements || !stripe) {
      setErrorMessage(t("payment.loading_error"))
      return;
    }

    setLoading(true);
    setErrorMessage(null);

    stripe
      .confirmPayment({elements, confirmParams: {
        return_url: `${window.location.origin}/transactions/success${transactionId ? ("?transaction_id="+transactionId) : ""}${walletId ? ("?wallet_id="+walletId) : ""}`
      }})
      .then(result => {
        setLoading(false);
        setErrorMessage(result.error.message??t("payment.error"));
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(error.message);
      })
  }

  const submit = (e: any): void => {
    e.preventDefault();
    confirmPayment();
  }

  const returnToPayment = (): void => {
    navigate(operationUrl(PRIVATE_URL.WALLETS_BUY_POINTS, operationId));
  }

  const returnToPaymentOnError = (): void => {
    globalStoreReducer(STORE, {type: ADD_ERROR_NOTIFICATION, notification: t("payment.error")});
    returnToPayment();
  }

  return (
    <form onSubmit={submit}>
      <Box sx={{display: "flex", flexDirection: "column", alignContent: "center"}}>
        <PaymentElement onLoadError={returnToPaymentOnError} />
        <Box sx={{mt: 3, display: "flex", justifyContent: "flex-end"}}>
          <SecondaryBigButton disabled={!stripe || loading} label={t("payment.cancel")} action={returnToPayment} sx={{mr: 3}}/>
          <PrimaryBigButton loading={loading} disabled={!stripe} submit={true} label={t("payment.pay")}/>
        </Box>
        {errorMessage && <Alert severity="error" variant="filled" sx={{mt: 5}}>{errorMessage}</Alert>}
      </Box>
    </form>
  )
}

export default function Payment(): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [store] = useContext<StoreContext>(AppContext);
  const operationId = store.global.currentOperation?.id;
  const transactionId = store.payment?.transactionId;
  const walletId = store.payment?.walletId;

  useEffect(() => {
    if ((!store.payment || !store.payment.secret) && operationId) {
      navigate(operationUrl(PRIVATE_URL.WALLETS_BUY_POINTS, operationId), {replace: true});
    }
  }, [])

  const options: StripeElementsOptions = {
    clientSecret: store.payment?.secret,
    loader: "always",
    fonts: [{
      cssSrc: "https://fonts.googleapis.com/css2?family=Mulish:wght@300;600;700&display=swap"
    }],
    appearance: {
      variables: {
        colorPrimary: LibertyTheme.palette.primary.main,
        fontFamily: LibertyTheme.typography.fontFamily,
        borderRadius: "4px"
      }
    }
  }

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!, {locale: 'fr'});

  return store.payment == null || store.payment.secret == null ? <></> :
    <Card sx={{p: 6, boxShadow: 2, mx: 'auto', width: "80%", boxSizing: "border-box"}}>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2}}>
        {/* @ts-ignore */}
        <img src={logSVG} alt="JoozyLogo" />
        {/* @ts-ignore */}
        <img src={stripe} alt="logo-stripe" style={{width: "20%"}} />
      </Box>
      <Typography variant="h1" color="neutral.dark" sx={{mb: 7, fontWeight: "bold"}}>
        {t("payment.title", {amount: store.payment.amount})}
      </Typography>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm operationId={operationId!} transactionId={transactionId} walletId={walletId}/>
      </Elements>
    </Card>
}
