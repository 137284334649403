import {ADD_PAYMENT_REFERENCE, ADD_TRANSACTION_ID, INIT_PAYMENT} from './globals'
import {PaymentInformation, Store, StoreContext} from "../../struct/store";

export interface PaymentAction {
  type: string;
  transactionId?: string;
  payment?: PaymentInformation;
  reference?: string;
}

export function paymentStoreReducer(STORE: StoreContext, action: PaymentAction): void {
  const [, setStore] = STORE;
  switch (action.type) {
  case INIT_PAYMENT: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      payment: action.payment
    }));
    return;
  }
  case ADD_TRANSACTION_ID: {
    setStore((previousStore: Store) => {
      if (!previousStore.payment) return previousStore
      else return {
        ...previousStore,
        payment: {
          ...previousStore.payment,
          transactionId: action.transactionId
        }
      }
    });
    return;
  }
  case ADD_PAYMENT_REFERENCE: {
    setStore((previousStore: Store) => {
      if (!previousStore.payment) return previousStore
      else return {
        ...previousStore,
        payment: {
          ...previousStore.payment,
          reference: action.reference
        }
      }
    });
    return;
  }
  default:
    return;
  }
}

export default {paymentStoreReducer};
