import {ColorResult, SketchPicker} from "react-color";
import {Box, Popover} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React from "react";
import {LibertyTheme} from "../../tokens/libertyTheme";

interface OperationColorPickerProps {
  anchorId: string;
  color: string;
  changeColor: (color: ColorResult) => void;
  showChangeColor: boolean;
  setShowChangeColor: (show: boolean) => void;
}

export default function OperationColorPicker(props: OperationColorPickerProps): JSX.Element {
  const {color, changeColor, showChangeColor, setShowChangeColor, anchorId} = props;

  const colors = [
    LibertyTheme.palette.primary.main,
    LibertyTheme.palette.darkOrchid.main,
    LibertyTheme.palette.info.main,
    LibertyTheme.palette.blueGreen.light,
    LibertyTheme.palette.success.main,
    LibertyTheme.palette.warning.main,
    LibertyTheme.palette.raspberry.light,
    LibertyTheme.palette.error.main,
  ]
  return (
    <Popover
      anchorEl={document.getElementById(anchorId)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{"& .MuiPaper-root": {backgroundColor: "transparent"}}}
      open={showChangeColor}
      onClose={(): void => setShowChangeColor(false)}
    >
      <Box sx={{mr: 3, p: 1, display: "flex", flexDirection: "column", alignItems: "center", overflow: 'hidden', "& *": {fontFamily: LibertyTheme.typography.fontFamily}}}>
        <SketchPicker color={color} onChangeComplete={changeColor} disableAlpha presetColors={colors}/>
        <ArrowDropDownIcon sx={{color: color, width: 50, height: 50, mt: "-20px", mb: "-25px"}}/>
      </Box>
    </Popover>
  )
}
