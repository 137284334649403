import {ImagePreviewWizardProps} from "../../patterns/preview/imagePreviewWizard";
import {CropSettingsProps} from "./cropImageElement";

export enum FileType {
  IMAGE,
  FAVICON,
  PDF,
  CSV
}

export type DownloadFileProps = {
  open: boolean,
  setOpen: (open: boolean) => void,
  name: string,
  translate: (text: string, params?: any) => string,
  handleVerifyFile: (file: File|null) => Promise<void>,
  handleUploadFile: (file: File|null, settings?: CropSettingsProps) => Promise<void>,
  type: FileType,
  cropType: "logo" | "banner" | "favicon",
  preview: null|ImagePreviewWizardProps,
}

export const IMAGE_FILE_TYPES = ["png", "jpg", "gif", "webp", "jpeg", "svg"];
export const FAVICON_FILE_TYPES = ["PNG", "ICO"];
export const PDF_FILE_TYPES = ["PDF"];
export const CSV_FILE_TYPES = ["CSV", "XLS", "XLSX"];
