import {Alert, AlertTitle, Box, Button, Card, Grid, Typography} from "@mui/material";
import React, {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {formatEuroValues} from "../../common/methods/utils";
import {Link} from "react-router-dom";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {PointsPaymentCardChildren} from "./pointsPaymentCards";
import {TCompany} from "../../interfaces/company";
import stripe from "../../assets/stripe.svg"

export default function WalletsBuyPoints(props: {company: TCompany}): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<StoreContext>(AppContext);
  const missingAddress = store.global.admin.invoiceAddress == null;

  useEffect(() => {
    document.title = t("tab_title.operation.points.balance");
  }, []);

  return <>
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mb: 5}}>
      <Typography variant="body1" color="neutral.main" sx={{pr: 2}} dangerouslySetInnerHTML={{__html: t("operations.points_balance.subtitle_balance")}}/>
      {/* @ts-ignore */}
      <img src={stripe} alt="logo-stripe" style={{width: "10%", paddingLeft: "12px", marginTop: "-2px"}}/>
    </Box>
    <Grid container rowSpacing={5} columnSpacing={5} sx={{mb: 3}}>
      {!missingAddress && props.company.balance < 0 &&
      <Grid item xs={12}>
        <Alert severity="error" variant="filled" sx={{mx: "0px", fontWeight: "regular"}}>
          <AlertTitle>
            <Typography color="ornament.light" sx={{fontWeight: "bold"}} variant="body1">{t("operations.points_balance.balance.alert_start")}</Typography>
          </AlertTitle>
          <Typography color="ornament.light" variant="body2">{t("operations.points_balance.balance.alert_end", {euroValue: formatEuroValues(Math.abs(props.company.balance))})}</Typography>
        </Alert>
      </Grid>}
      {missingAddress &&
      <Grid item xs={12}>
        <Alert variant="filled" severity="error" sx={{alignItems: "center"}} action={
          <Button sx={{color: "ornament.light"}} component={Link} to={PRIVATE_URL.BILLING_ADDRESS}>{t("operations.points_balance.balance.payment.alert_add_address")}</Button>
        }>
          {t("operations.points_balance.balance.payment.alert_missing_address")}
        </Alert>
      </Grid>}
      <Grid item xs={12}>
        <Card>
          <PointsPaymentCardChildren {...props}/>
        </Card>
      </Grid>
    </Grid>
  </>
}
