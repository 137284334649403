import {TooltipRenderProps} from "react-joyride";
import {Box, Card, IconButton, Typography} from "@mui/material";
import JoozBoyIcon from "../../assets/joozBoy.svg";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
import {useTranslation} from "react-i18next";
import {PrimaryInfoButton, SecondaryInfoButton} from "./buttons";

export default function Tooltip(props: TooltipRenderProps): JSX.Element {
  const {t} = useTranslation();

  return (
    <Card sx={{backgroundColor: "info.main", border: "none", boxShadow: 0, p: 3, maxWidth: "319px", boxSizing: "border-box"}} {...props.tooltipProps}>
      <Box sx={{display: "flex", alignItems: "flex-end", justifyContent: "space-between", mb: 1}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          {/** @ts-ignore */}
          <img src={JoozBoyIcon} alt="" style={{width: "38px", height: "38px", marginRight: "10px"}}/>
          <Typography variant="body2" color="ornament.light" sx={{fontWeight: "medium"}}>
            {(props.index + 1) + "/" + props.size}
          </Typography>
        </Box>
        <IconButton onClick={props.skipProps.onClick}><ClearIcon sx={{color: "ornament.light"}}/></IconButton>
      </Box>
      <Typography variant="body1" color="ornament.light" sx={{fontWeight: "bold", my: 3}}>
        {props.step.title}
      </Typography>
      {props.step.content}
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "flex-end", mt: 3}}>
        {props.index > 0 && <SecondaryInfoButton label={t("product_tour.previous")} sx={{mr: "10px"}} action={props.backProps.onClick}/>}
        {props.index < props.size - 1 && <PrimaryInfoButton label={t("product_tour.next")} action={props.primaryProps.onClick}/>}
        {props.index == props.size - 1 && <PrimaryInfoButton label={t("product_tour.finish")} action={props.closeProps.onClick}/>}
      </Box>
    </Card>
  )
}
