import {TAlert} from "../../patterns/list/listSnackbar";
import {Dispatch, SetStateAction} from "react";

export enum CompanyBillingSection {
  ADDRESS = "adresse",
  HISTORY = "historique",
}

export type CompanyBillingAddressProps = {
  setAlerts: Dispatch<SetStateAction<TAlert[]>>;
}
