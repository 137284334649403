import {Box, Card, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import InfoIcon from '@mui/icons-material/Info';
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface WizardHelperCardProps {
  name: string;
  sx?: SxProps<Theme>
}

export default function WizardHelperCard(props: WizardHelperCardProps): JSX.Element {
  const {t} = useTranslation();
  const {name, sx} = props;

  return (
    <Card sx={{border: "1px solid", borderColor: "infoLight.main", backgroundColor: "infoLight.light",
      boxShadow: "inset 6px 0px 0px infoLight.main", p: 5, ...sx}}>
      <Box sx={{display: "flex", alignItems: "center", mb: 2}}>
        <InfoIcon sx={{color: "info.dark", width: 18, height: 18, mr: 1}}/>
        <Typography variant="body1" color="info.dark" sx={{fontWeight: "bold"}}>
          {t(`${name}.title`)}
        </Typography>
      </Box>
      <Typography variant="body2" color="info.dark" sx={{fontWeight: "unset", pl: "26px"}}
        dangerouslySetInnerHTML={{__html: t(`${name}.description`)}}/>
    </Card>
  )
}
