import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {ContrastColor} from "./operationColors";

export function OperationColorSwitch(props: {selected: ContrastColor, setSelected: (color: ContrastColor) => void, disabled: boolean}): JSX.Element {
  const {selected, setSelected, disabled} = props;
  const {t} = useTranslation();

  return (
    <Box sx={{p: "3px", backgroundColor: "ornament.dark", borderRadius: "4px", width: "fit-content"}}>
      <Button disabled={disabled} variant="text" onClick={(): void => setSelected(ContrastColor.LIGHT)}
        sx={{py: "2px", px: 3, mr: 0, boxShadow: selected == ContrastColor.LIGHT ? 2 : 0,
          backgroundColor: selected == ContrastColor.LIGHT ? "ornament.light" : "transparent",
          width: "auto",
          color: selected == ContrastColor.LIGHT ? "neutral.dark" : "neutral.main",
          ":hover": {backgroundColor: selected == ContrastColor.LIGHT ? "ornament.light" : "neutral.light"}}}>
        <Typography variant="caption" sx={{fontWeight: "medium"}}>
          {t("operations.showcase.colors.light")}
        </Typography>
      </Button>
      <Button disabled={disabled} variant="text" onClick={(): void => setSelected(ContrastColor.DARK)}
        sx={{py: "2px", px: 3, boxShadow: selected == ContrastColor.DARK ? 2 : 0,
          backgroundColor: selected == ContrastColor.DARK ? "ornament.light" : "transparent",
          width: "auto",
          color: selected == ContrastColor.DARK ? "neutral.dark" : "neutral.main",
          ":hover": {backgroundColor: selected == ContrastColor.DARK ? "ornament.light" : "neutral.light"}}}>
        <Typography variant="caption" sx={{fontWeight: "medium"}}>
          {t("operations.showcase.colors.dark")}
        </Typography>
      </Button>
    </Box>
  )
}
