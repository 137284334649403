import {participantStepsPaths} from '../../struct/importParticipantAttributeTypes'
import {
  ERROR_IN_FILE,
  INIT_STORE,
  NEXT_STEP,
  PREV_STEP,
  SET_IMPORT_WALLET,
  SET_MAPPING,
  SET_UPLOAD_FILE
} from './globals'
import {initialStore, StoreContext} from "../../struct/store";
import {TParticipantImportPreview} from "../../../interfaces/participantImport";
import {TOperation} from "../../../interfaces/operation";

export interface ParticipantAction {
  type: string;
  step?: number;
  nbError?: number;
  data?: TParticipantImportPreview;
  mapping?: any;
  operation?: TOperation;
  saving?: boolean;
  selected_wallet?: string;
  saveHeader?: boolean;
}

export function participantStoreReducer(STORE: StoreContext, action: ParticipantAction) : void {
  const [store, setStore] = STORE;
  const currentStep = store.participantWizard.currentStep;
  switch (action.type) {
  case NEXT_STEP: {
    setStore({
      ...store,
      participantWizard: {
        ...store.participantWizard,
        currentStep: currentStep + 1,
        currentPath: participantStepsPaths[currentStep],
        saving: action.saving??store.participantWizard.saving
      },
      global: {
        ...store.global,
        currentOperation: action.operation??store.global.currentOperation
      }
    });
    return;
  }
  case PREV_STEP: {
    setStore({
      ...store,
      participantWizard: {
        ...store.participantWizard,
        currentStep: currentStep - 1,
        currentPath: participantStepsPaths[currentStep - 2]
      }
    });
    return;
  }
  case INIT_STORE: {
    setStore({
      ...store,
      participantWizard: initialStore.participantWizard
    });
    return;
  }
  case ERROR_IN_FILE: {
    const nbError = action.nbError;
    if (!nbError) {
      return;
    }

    setStore({
      ...store,
      participantWizard: {
        ...store.participantWizard,
        currentErrorsInFile: nbError
      }
    });
    return;
  }
  case SET_UPLOAD_FILE: {
    setStore({
      ...store,
      participantWizard: {
        ...store.participantWizard,
        uploadedFileDatas: action.data
      }
    });
    return;
  }
  case SET_MAPPING: {
    const mapping = action.mapping;
    const datas = store.participantWizard.uploadedFileDatas;
    if (mapping == null || datas == null) {
      return;
    }

    setStore({
      ...store,
      participantWizard: {
        ...store.participantWizard,
        uploadedFileDatas: {
          ...datas,
          mapping: mapping
        }
      }
    });
    return;
  }
  case SET_IMPORT_WALLET: {
    const wallet = action.selected_wallet;
    if (wallet == null) {
      return;
    }
    setStore({
      ...store,
      participantWizard: {
        ...store.participantWizard,
        selectedWallet: wallet
      }
    });
    return;
  }
  default:
    return;
  }
}

export default {participantStoreReducer};
