import React, {useEffect, useState} from 'react';

// COMPONENTS IMPORT
import {Alert, Box, Pagination} from '@mui/material';

// STRUCT IMPORT
import {getShopGifts, GIFTS_LIMIT} from "../../services/ShopService";
import {useTranslation} from "react-i18next";
import {GiftCardProps} from "./operationShopGifts";
import {TGift} from "../../interfaces/gift";
import OperationGiftCard from './giftCard';
import {Loader} from "../../components/loader/loader";

export default function GiftCardChildren(props: GiftCardProps): JSX.Element {
  const {operation, setError, setSuccess} = props;
  const shopId = operation.shops[0].id;
  const {t} = useTranslation();

  const [pageNum, setPageNum] = useState(1);
  const [totalGifts, setTotalGifts] = useState(0);
  const [gifts, setGifts] = useState<TGift[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadGifts();
  }, [pageNum])

  const loadGifts = (): void => {
    if (!shopId) {
      return;
    }

    setLoading(true);
    getShopGifts(shopId, pageNum, GIFTS_LIMIT)
      .then(shopGifts => {
        const newGifts = shopGifts.items.map(gift => {
          const giftFlag = (operation.shops[0].excludedGiftIds).includes(gift.id);
          return {...gift, isDeleted: giftFlag}
        });
        setTotalGifts(shopGifts.totalItems);
        setGifts(newGifts);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const paginationChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    if (value !== pageNum) {
      setLoading(true);
      setPageNum(value);
    }
  }

  const noGifts = <Alert severity="info" variant="filled">{t("operations.shop.gifts.no_gifts")}</Alert>
  return loading ? <Loader/> : gifts.length == 0 ? noGifts :
    <Box>
      <Box sx={{display: "grid", gridTemplateColumns: "repeat(4, 1fr)", columnGap: 5, rowGap: 5}}>
        {gifts.map((gift, index) => <OperationGiftCard
          key={"gift-" + index}
          gift={gift}
          setSuccess={setSuccess}
          setError={setError}
        />)}
      </Box>
      <Pagination
        page={pageNum}
        count={Math.ceil(totalGifts / GIFTS_LIMIT)}
        onChange={paginationChange}
        sx={{display: "flex", justifyContent: "center", my:5}}
        color="primary" shape="rounded"
      />
    </Box>
}
