import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CloseIcon from "@mui/icons-material/Close";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";
import React from "react";
import {useTranslation} from "react-i18next";

interface RestoreDialogProps {
  open: boolean;
  close: () => void;
  title: string;
  info: string;
  restoreElement: () => void;
}

export default function RestoreDialog(props: RestoreDialogProps): JSX.Element {
  const {open, close, title, info, restoreElement} = props;
  const {t} = useTranslation();

  return (
    <Dialog open={open} onClose={close} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: "info.main", borderColor: "info.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <ReportProblemIcon sx={{color: "info.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {title}
          </Typography>
        </Box>
        <IconButton onClick={close}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1" color="neutral.main">
          {info}
        </Typography>
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <SecondaryBigButton sx={{mr: 0}} label={t("list.restore.cancel")} action={close}/>
        <PrimaryBigButton label={t("list.restore.confirm")} action={restoreElement}/>
      </DialogActions>
    </Dialog>
  )
}
