import React, {useContext, useEffect, useState} from "react";
import {Box, Divider, MenuItem, Select, TextField, Typography} from "@mui/material";
import {LIBERTY_THEME, LibertyTheme} from "../../tokens/libertyTheme";
import {useTranslation} from "react-i18next";
import {SelectChangeEvent} from "@mui/material/Select";
import {Attribute} from "./participantImportWizardSteps";
import {AppContext} from "../../App";
import {participantStoreReducer} from "../../common/methods/context-setter/participantStoreReducer";
import {SET_IMPORT_WALLET, SET_MAPPING} from "../../common/methods/context-setter/globals";
import {StoreContext} from "../../common/struct/store";
import StarsIcon from '@mui/icons-material/Stars';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import TextAlert from "../../components/alerts/textAlert";
import {AlertSeverity} from "../../components/alerts/alertProps";
import {formatAmount} from "../../utils/formatAmount";
import {INPUT_TYPES} from "../../patterns/form/formConstants";

type SelectAttributeTypeProps = {
  value: string,
  index: number,
  setError: (error: string|null) => void,
  types: Attribute[],
  saveHeader: boolean,
  loading?: boolean,
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 460,
      border: "1px solid",
      borderColor: LibertyTheme.palette.ornament.dark,
      boxShadow: LibertyTheme.shadows[2]
    },
  },
};

export default function SelectAttributeType(props: SelectAttributeTypeProps): JSX.Element {
  const {t} = useTranslation();
  const {value, index, setError, types, saveHeader, loading} = props;
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  
  const [selectValue, setSelectValue] = useState<string>(!saveHeader ? store.participantWizard.uploadedFileDatas?.mapping[value] : Object.prototype.hasOwnProperty.call(store.participantWizard.uploadedFileDatas?.mapping[value], 'title') ? store.participantWizard.uploadedFileDatas?.mapping[value].type : store.participantWizard.uploadedFileDatas?.mapping[value]);
  const [selectedWallet, setSelectedWallet] = useState<string|undefined>(store.global.company.defaultWallet ? store.global.company.defaultWallet['@id']: undefined);

  const isDuplicate = selectValue !== "true" && selectValue !== "false" &&
    Object.values(store.participantWizard.uploadedFileDatas?.mapping).filter(value => value === selectValue).length > 1;
  const alreadyExist = selectValue == "true" && !saveHeader && types.find(type => type.label == value) !== undefined;

  const isEmptyTitle = (): boolean => {
    let hasNullTitle = false;
    for (const key in store.participantWizard.uploadedFileDatas?.mapping) {
      if (Object.hasOwn(store.participantWizard.uploadedFileDatas?.mapping[key], 'title')) {              
        if (store.participantWizard.uploadedFileDatas?.mapping[key]?.title == null || store.participantWizard.uploadedFileDatas?.mapping[key]?.title == "") {
          hasNullTitle = true;
          break;
        }
      }
    }
    return hasNullTitle;
  }

  const attributeAlreadyExist = (): boolean => {
    let attributeExist = false;
    for (const key in store.participantWizard.uploadedFileDatas?.mapping) {
      if (Object.hasOwn(store.participantWizard.uploadedFileDatas?.mapping[key], 'title')) {   
        attributeExist = types.some(item => item.label === store.participantWizard.uploadedFileDatas?.mapping[key].title);
        break;
      }
    }
    return attributeExist;
  }

  const displayWalletSelector = selectValue == "points" && (store.global.company.wallets.length > 2);

  const handleChange = (event: SelectChangeEvent): void => {    
    const newValue = event.target.value;
    
    if (store.participantWizard.uploadedFileDatas && saveHeader && newValue == "true") store.participantWizard.uploadedFileDatas.mapping[value] = {"type": newValue, "title": null};
    else if (store.participantWizard.uploadedFileDatas) store.participantWizard.uploadedFileDatas.mapping[value] = newValue;
    participantStoreReducer(STORE, {type: SET_MAPPING, mapping: store.participantWizard.uploadedFileDatas?.mapping});
    
    setSelectValue(newValue);
  }

  const handleWalletChange = (event: SelectChangeEvent): void => {
    const newValue = event.target.value;
    if (store.participantWizard.uploadedFileDatas) store.participantWizard.selectedWallet = newValue;
    participantStoreReducer(STORE, {type: SET_IMPORT_WALLET, selected_wallet: store.participantWizard.selectedWallet});

    setSelectedWallet(newValue);
  }

  const handleTitleChange = (label: string): void => {
    if (store.participantWizard.uploadedFileDatas) store.participantWizard.uploadedFileDatas.mapping[value] = {"type": selectValue, "title": label};
    participantStoreReducer(STORE, {type: SET_MAPPING, mapping: store.participantWizard.uploadedFileDatas?.mapping});
    if (!isEmptyTitle() && !attributeAlreadyExist()) {
      setError(null);
    } else {
      if (attributeAlreadyExist()) {
        setError(t("participants.import_participants.main_analyse_property_global_error"));
      } else {
        setError("");
      }
    }
  }

  useEffect(() => {         
    if (isDuplicate) {
      setError(t("participants.import_participants.main_analyse_property_global_warning"));
    } else if (alreadyExist) {
      setError(t("participants.import_participants.main_analyse_property_global_error"));
    } else if (isEmptyTitle()) {
      setError("");
    } else if (attributeAlreadyExist()) {
      setError("");
    } else {
      setError(null)
    }
  }, [selectValue, saveHeader])

  useEffect(() => {     
    if (saveHeader && store.participantWizard.uploadedFileDatas) {
      for (const key in store.participantWizard.uploadedFileDatas.mapping) {
        if (store.participantWizard.uploadedFileDatas.mapping[key] == "true") {
          store.participantWizard.uploadedFileDatas.mapping[key] = {"type": store.participantWizard.uploadedFileDatas.mapping[key], "title": null};
        }
      }
      participantStoreReducer(STORE, {type: SET_MAPPING, mapping: store.participantWizard.uploadedFileDatas?.mapping});
    } else if (!saveHeader && store.participantWizard.uploadedFileDatas) {
      for (const key in store.participantWizard.uploadedFileDatas.mapping) {
        if (store.participantWizard.uploadedFileDatas.mapping[key].type == "true") {
          store.participantWizard.uploadedFileDatas.mapping[key] = store.participantWizard.uploadedFileDatas.mapping[key].type;
        }
      }
    }    
  }, [saveHeader])

  const menuStyle = {
    py: 1, px: 3,
    fontSize: LIBERTY_THEME.typography.body2.fontSize,
    lineHeight: LIBERTY_THEME.typography.body2.lineHeight,
    overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"
  }
  const divider = (key: string): JSX.Element => <Divider key={key} sx={{height: "1px", backgroundColor: "ornament.dark", borderBottomWidth: 0, my: "4px !important"}}/>

  return (
    <Box>
      <Select
        color="primary"
        displayEmpty
        sx={{width: "100%", "& .MuiSelect-select": {display: "flex", alignItems: "center"}}}
        name={value}
        id={"select-" + index}
        value={selectValue}
        onChange={handleChange}
        error={isDuplicate || alreadyExist}
        disabled={loading}
        MenuProps={MenuProps}>
        {[
          types.map((type: Attribute, i: number): JSX.Element => {
            return (
              <MenuItem key={`item-${index}-${i}`} value={type.label} sx={{color: "neutral.main", ...menuStyle}}>
                {type.trad}
              </MenuItem>
            )
          }),
          divider("basic-types"),
          <MenuItem key="points" value="points" sx={menuStyle}>
            {[
              <StarsIcon key="points-icon" sx={{color: "neutral.main", height: 18, width: 18, mr: 1}}/>,
              <Typography key="points-label" variant="body2" sx={{color: "neutral.main", fontWeight: "bold", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                {t("participants.import_participants.meta_types.add_delete_points")}
              </Typography>
            ]}
          </MenuItem>,
          divider("add-attribute"),
          <MenuItem key="true" value="true" sx={menuStyle}>
            {[
              <AddCircleIcon key="add-icon" sx={{color: "primary.main", height: 18, width: 18, mr: 1}}/>,
              <Typography key="add-label" variant="body2" sx={{color: "primary.main", fontWeight: "bold", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                {t("participants.import_participants.meta_types.true")}
              </Typography>
            ]}
          </MenuItem>,
          divider("ignore-attribute"),
          <MenuItem key="false" value="false" sx={menuStyle}>
            {[
              <ClearIcon key="ignore-icon" sx={{color: "error.main", height: 18, width: 18, mr: 1}}/>,
              <Typography key="ignore-label" variant="body2" sx={{color: "error.main", fontWeight: "bold", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                {t("participants.import_participants.meta_types.false")}
              </Typography>
            ]}
          </MenuItem>
        ]}
      </Select>
      {isDuplicate && <TextAlert severity={AlertSeverity.WARNING} text={t("participants.import_participants.main_analyse_property_warning")}/>}
      {alreadyExist && <TextAlert severity={AlertSeverity.ERROR} text={t("participants.import_participants.main_analyse_property_error")}/>}
      {displayWalletSelector && !isDuplicate &&
        <Box>
          <Typography variant="subtitle2" sx={{mt: 2, mb: 1}}>
            {t("wallets.import.which_wallet")}
          </Typography>
          <Select
            color="primary"
            displayEmpty
            sx={{width: "100%", "& .MuiSelect-select": {display: "flex", alignItems: "center"}}}
            name={'points-wallet'}
            id={"select-wallet-" + index}
            value={selectedWallet}
            onChange={handleWalletChange}
            disabled={loading}
          >
            {store.global.company.wallets.map((wallet, i) => (
              (store.global.company.expiredPointsWallet && wallet.id !== store.global.company.expiredPointsWallet.id) &&
              <MenuItem key={`wallet-${i}`} value={wallet['@id']} sx={{color: "neutral.main", ...menuStyle}}>
                {(wallet.name == "default" ? t("operations.points_balance.wallets.default") : wallet.name) + ` (${formatAmount(wallet.balance)} €)`}
              </MenuItem>
            ))}
          </Select>
        </Box>
      }
      {saveHeader && selectValue == "true" && !isDuplicate &&
        <Box>
          <Typography variant="subtitle2" sx={{mt: 2, mb: 1}}>
            {"Titre de la colonne ? *"}
          </Typography>
          <TextField
            type={INPUT_TYPES.DEFAULT}
            sx={{"& input": {backgroundColor: "ornament.light", p: 3}}}
            defaultValue={Object.prototype.hasOwnProperty.call(store.participantWizard.uploadedFileDatas?.mapping[value], 'title') ? store.participantWizard.uploadedFileDatas?.mapping[value].title : ""}
            onChange={(e: any): void => handleTitleChange(e.target.value)}
            fullWidth
            required
          />
          {attributeAlreadyExist() && <TextAlert severity={AlertSeverity.ERROR} text={t("participants.import_participants.main_analyse_property_error")}/>}
        </Box>
      }
    </Box>
  )
}
