import React, {useState} from "react";
import {Box, Card, Typography} from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {Theme} from '@mui/material/styles';
import {useTranslation} from "react-i18next";
import {SxProps} from "@mui/system";
import {FileUploader} from "react-drag-drop-files";
import {PDF_FILE_TYPES} from "../dialog/downloadFileProps";
import {loaderPrimaryButton} from "../../components/loader/loader";

interface UploadFileProps {
  action: (file: File) => Promise<any>;
  setError: (error: string) => void;
  sx?: SxProps<Theme>;
  isPdf?: boolean;
  types?: string[];
  disabled?: boolean;
}

export default function UploadFile(props: UploadFileProps): JSX.Element {
  const {action, setError, sx, isPdf = true, types = PDF_FILE_TYPES, disabled = false} = props;
  const {t} = useTranslation();
  const maxSize = parseInt(process.env.REACT_APP_MAX_UPLOAD_SIZE!);
  const [loading, setLoading] = useState(false);

  const handleTypeError = (): void => {
    setError(t("operations.rules.pdf.error_type"));
  }
  const handleSizeError = (): void => {
    setError(t("operations.rules.pdf.error_size", {maxSize: maxSize/1000000}));
  }
  const uploadFile = (file: File): Promise<void> => {
    if (disabled) {
      return Promise.resolve();
    }

    setLoading(true);
    return action(file).finally(() => setLoading(false))
  }

  return (
    <Box sx={{width: "100%"}}>
      <FileUploader handleChange={uploadFile} onTypeError={handleTypeError} name="upload-pdf" maxSize={maxSize/1000000} onSizeError={handleSizeError} types={types} style={{width: "100%", boxSizing: "border-box"}}>
        <Card sx={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: loading ? "primaryLight.light" : "ornament.main",
          border: "2px dashed", borderColor: "primaryLight.dark", p: 5, boxShadow: 0, justifyContent: "center", cursor: disabled ? "default" : "pointer", height: isPdf ? "168px" : "auto",
          "&:hover": {backgroundColor: "primaryLight.light"}, ...sx}}>
          {loading && loaderPrimaryButton}
          {!loading && <>
            {isPdf && <InsertDriveFileIcon sx={{width: 42, height: 42, color: "neutral.main"}}/>}
            {!isPdf && <CloudUploadIcon sx={{width: 32, height: 32, color: "neutral.main"}}/>}
            <Typography variant="body1" color="neutral.main" sx={{fontWeight: "bold", mb: 2}}>
              {t("upload.pdf.title")}
            </Typography>
            <Box sx={{display: "flex"}}>
              <Typography variant="body2" color="neutral.main" sx={{mr: 0}}>{t("upload.pdf.subtitle_1")}</Typography>
              <Typography variant="body2" color="primaryDark.main" sx={{fontWeight: "bold", cursor: "pointer"}}>
                {t("upload.pdf.subtitle_2")}
              </Typography>
            </Box>
            {isPdf && <Typography variant="caption" color="neutral.main">{t("upload.pdf.caption")}</Typography>}
          </>}
        </Card>
      </FileUploader>
    </Box>
  )
}
