import {TCompanyAware, TLifecycle} from "./traits";

export enum TAddressType {
  DELIVERY_ADDRESS = 'delivery',
  INVOICE_ADDRESS = 'invoice'
}

export interface TAddress extends TAddressCreate, TLifecycle, TCompanyAware {
}

export interface TAddressCreate {
  type: TAddressType;
  firstName: string;
  lastName: string;
  society?: string;
  email?: string;
  street: string;
  street2?: string;
  street3?: string;
  zip: string;
  city: string;
  vat: string;
  phone: string;
}
