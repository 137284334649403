import React, {useContext, useEffect, useState} from "react";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {CompanyBillingSection} from "./companyBillingProps";
import {useLocation, useNavigate} from 'react-router-dom';
import {TCompany} from "../../interfaces/company";
import {getCompany} from "../../services/CompanyService";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {errorManager} from "../../common/methods/ApiService";
import {Loader} from "../../components/loader/loader";
import CompanyBillingAddress from "./companyBillingAddress";
import ListBillingHistory from "./listBillingHistory/listBillingHistory";
import Page from "../../patterns/page/page";
import ListSnackbar, {TAlert} from "../../patterns/list/listSnackbar";
import {addErrorAlert} from "../../patterns/list/listUtils";
import Footer from "../../patterns/footer/footer";

export default function CompanyBilling(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const extractSelectedMenu = (): CompanyBillingSection|null => {
    switch (location.pathname) {
    case PRIVATE_URL.BILLING_ADDRESS:
      return CompanyBillingSection.ADDRESS;
    case PRIVATE_URL.BILLING_HISTORY:
      return CompanyBillingSection.HISTORY;
    default:
      navigate(PRIVATE_URL.LANDING_PAGE);
      return null;
    }
  }

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const storedCompany = store.global.company;

  const [selectedMenu, setSelectedMenu] = useState(extractSelectedMenu());
  const [company, setCompany] = useState<TCompany|null>(null);
  const [alerts, setAlerts] = useState<TAlert[]>([]);

  useEffect(() => {
    getCompany(storedCompany.id)
      .then(company => setCompany(company))
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') addErrorAlert(errorResult, setAlerts);
      })
  }, []);

  useEffect(() => {
    setSelectedMenu(extractSelectedMenu());
  }, [location]);

  const menus = [
    {
      icon: BusinessOutlinedIcon,
      label: 'company_billing.address',
      url: PRIVATE_URL.BILLING_ADDRESS
    },
    {
      icon: ReceiptLongOutlinedIcon,
      label: 'company_billing.history',
      url: PRIVATE_URL.BILLING_HISTORY
    }
  ];

  return company == null ? <Loader /> :
    <Page title={t("company_billing.title")} menus={menus} noBackLink={true}>
      <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
      <>
        {selectedMenu === CompanyBillingSection.ADDRESS && <CompanyBillingAddress setAlerts={setAlerts}/>}
        {selectedMenu === CompanyBillingSection.HISTORY && <>
          <Box sx={{p: 5, pb: 3}}>
            <ListBillingHistory/>
          </Box>
          <Footer/>
        </>}
      </>
    </Page>
}
