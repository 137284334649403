import React from "react";
import {Box, Typography} from "@mui/material";
import {PrimaryBigButton} from "../../components/buttons/mainButton";

interface EmptyListProps {
  image: any;
  label: string;
  action?: {label: string, fun: () => void}
}

export default function EmptyList(props: EmptyListProps): JSX.Element {
  const {image, label, action} = props;

  return (
    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "absolute", width: "100%", height: "100%", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 5, py: 11}}>
      <img src={image} alt="empty-participants" style={{height: "25vh", minHeight: "190px"}} />
      <Typography variant="body1" color="neutral.main" sx={{my: 3, fontWeight: "bold"}}>
        {label}
      </Typography>
      {action && <PrimaryBigButton label={action.label} action={action.fun}/>}
    </Box>
  )
}
