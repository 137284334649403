import {API_URL, GLOBAL_ADMIN_ID} from "../common/struct/urlManager";
import {
  del,
  get,
  getPaginated,
  post, postWithoutToken,
  put,
  RequestFilter,
  RequestOrder
} from "../common/methods/ApiService";
import {TEmailTemplate, TEmailTemplateCreate} from "../interfaces/email";
import {TOperationParticipantDeleted} from "../interfaces/operationParticipant";
import {TAdmin} from "../interfaces/admin";
import {TAddressCreate} from "../interfaces/address";
import {GridColumnVisibilityModel} from "@mui/x-data-grid";

export async function getEmailTemplate(id: string): Promise<TEmailTemplate|null> {
  return get(`${API_URL.EMAIL_TEMPLATES}/${id}`);
}

export async function createEmailTemplate(emailTemplate: TEmailTemplateCreate): Promise<TEmailTemplate> {
  return post(API_URL.EMAIL_TEMPLATES, emailTemplate);
}

export async function updateEmailTemplate(id: string, emailTemplate: TEmailTemplateCreate): Promise<TEmailTemplate> {
  return put(API_URL.EMAIL_TEMPLATES, id, emailTemplate);
}

export async function deleteEmailTemplate(id: string): Promise<void> {
  return del(`${API_URL.EMAIL_TEMPLATES}`, id);
}

export async function getAllEmailTemplateTypes(): Promise<string[]> {
  return get(`${API_URL.EMAIL_TEMPLATES}/types`);
}

export async function getOperationEmailTemplateByType(operationId: string, type: string): Promise<TEmailTemplate> {
  return get(`${API_URL.OPERATION}/${operationId}/email-templates?type=${type}`);
}

export async function getEmailTemplates(page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TOperationParticipantDeleted[]}> {
  return getPaginated(API_URL.EMAIL_TEMPLATES, page, itemsPerPage, search, order, filters);
}

export async function getActionsHistory(page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TOperationParticipantDeleted[]}> {
  return getPaginated(API_URL.LOGGING_ENTRIES, page, itemsPerPage, search, order, filters);
}

export async function validateEmail(id: string, token: string): Promise<TAdmin> {
  return postWithoutToken(API_URL.VALIDATE_EMAIL.replace(":" + GLOBAL_ADMIN_ID, id), {validationToken: token});
}

export async function sendValidationEmail(id: string): Promise<TAdmin> {
  return post(API_URL.SEND_VALIDATION_EMAIL.replace(":" + GLOBAL_ADMIN_ID, id));
}

export async function updateAdminColumnOrder(id: string, participantColumnsOrder: string[]): Promise<void> {  
  return put(API_URL.ADMIN, id, {participantColumnsOrder: participantColumnsOrder});
}

export async function updateAdminColumnSatus(id: string, participantColumnsStatus: GridColumnVisibilityModel): Promise<void> {
  const jsonStringify = Object.assign({}, participantColumnsStatus);  
  
  return put(API_URL.ADMIN, id, {participantColumnsStatus: jsonStringify});
}

export async function getAdmins(): Promise<TAdmin[]> {
  return get(API_URL.ADMIN);
}

export async function deleteAdmin(adminId: string): Promise<void> {
  return del(API_URL.ADMIN, adminId);
}

export async function updateAdminInvoiceAddress(adminId: string, address: TAddressCreate): Promise<TAdmin> {
  return put(API_URL.ADMIN, adminId, {invoiceAddress: address});
}

export async function updateAdminCompany(adminId: string, companyIri: string): Promise<TAdmin> {
  return put(API_URL.ADMIN, adminId+'/update-company', {company: companyIri});
}
