import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import LogoJoozy from "../../assets/logoJoozyViolet.svg";
import {SecondarySmallButton} from "../../components/buttons/mainButton";
import LogoGRDF from "../../assets/clients/grdf.svg";
import LogoLaPoste from "../../assets/clients/laPosteSolutionsBusiness.png";
import LogoLeBonCoin from "../../assets/clients/leBonCoin.svg";
import LogoSaintGobain from "../../assets/clients/saintGobain.svg";
import LogoTKE from "../../assets/clients/tke.svg";
import Footer from "../footer/footer";
import React from "react";
import {useTranslation} from "react-i18next";
import {PRIVATE_URL, PUBLIC_URL} from "../../common/struct/urlManager";

interface LogoutPageProps {
  children: JSX.Element|JSX.Element[];
  isMobile: boolean;
}

export default function LogoutPage(props: LogoutPageProps): JSX.Element {
  const {children, isMobile} = props;
  const {t} = useTranslation();

  return (
    <Box sx={{display: "flex", flexDirection: "column", minHeight: "100vh", backgroundColor: "ornament.main"}}>
      {isMobile && <Box sx={{width: "90%", mx: "auto", display: "flex", alignItems: "center", justifyContent: "space-between", position: "sticky", backgroundColor: "ornament.main", zIndex: 100, top: 0, py: 5}}>
        <Link to={PRIVATE_URL.LANDING_PAGE}>
          {/* @ts-ignore */}
          <img src={LogoJoozy} alt="logo-joozy-avec-texte"/>
        </Link>
        <SecondarySmallButton label={t("login.register_button")} href={PUBLIC_URL.REGISTER}/>
      </Box>}
      {!isMobile &&
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", position: "sticky", backgroundColor: "ornament.main", zIndex: 100, top: 0, py: 5, px: 7}}>
        <Link to={PRIVATE_URL.LANDING_PAGE}>
          {/* @ts-ignore */}
          <img src={LogoJoozy} alt="logo-joozy-avec-texte"/>
        </Link>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography variant="body2" sx={{fontWeight: "medium", mr: 2}}>
            {t("login.register_label")}
          </Typography>
          <SecondarySmallButton label={t("login.register_button")} href={PUBLIC_URL.REGISTER}/>
        </Box>
      </Box>}
      {children}
      {!isMobile && <>
        <Typography variant="body2" color="neutral.main" sx={{textAlign: "center", mb: 3}}>
          {t("login.trusted_companies")}
        </Typography>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", mb: "50px"}}>
          {/* @ts-ignore */}
          <img src={LogoLeBonCoin} alt="logo-le-bon-coin" style={{marginRight: 32}}/>
          {/* @ts-ignore */}
          <img src={LogoGRDF} alt="logo-grdf" style={{marginRight: 32}}/>
          {/* @ts-ignore */}
          <img src={LogoTKE} alt="logo-tke" style={{marginRight: 32}}/>
          {/* @ts-ignore */}
          <img src={LogoLaPoste} alt="logo-la-poste-solutions-business" style={{height: 48, marginRight: 32}}/>
          {/* @ts-ignore */}
          <img src={LogoSaintGobain} alt="logo-saint-gobain"/>
        </Box>
        <Footer/>
      </>}
    </Box>
  )
}
