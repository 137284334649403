import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTranslation} from "react-i18next";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

type BackLinkProps = {
  to?: string,
  label?: string,
  sx?: SxProps<Theme>,
}

export default function BackLink(props: BackLinkProps) : JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const goBack = (): void => props.to ? navigate(props.to) : navigate(-1);

  return (
    <Button sx={{color: "neutral.main", fontWeight: "bold", mx: 5, mt: 5, mb: 3, ...props.sx}} variant="text" onClick={goBack} startIcon={<ArrowBackIcon/>}>
      {t("navigation." + (props.label??"back"))}
    </Button>
  )
}
