import {operationStepsPaths} from "./createOperationTypes";
import {participantStepsPaths} from "./importParticipantAttributeTypes";
import {OperationWizardSteps} from "../../pages/createOperationWizard/operationWizardSteps";
import {ParticipantImportWizardSteps} from "../../pages/importParticipantsWizard/participantImportWizardSteps";
import {TOperation} from "../../interfaces/operation";
import {TParticipantImportPreview} from "../../interfaces/participantImport";
import {Dispatch, SetStateAction} from "react";
import {TAddress} from "../../interfaces/address";
import {operationUrl, PRIVATE_URL} from "./urlManager";
import {TWalletTransactionOption} from "../../interfaces/wallet";
import {TCompanyWallet} from "../../interfaces/company";

export type AdminInformation = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  job: string,
  invoiceAddress?: TAddress,
  participantColumnsOrder: string[]
  participantColumnsStatus: any
  emailConfirmationDate?: string
}

export type CompanyInformation = {
  id: string,
  name: string,
  wallets: TCompanyWallet[],
  defaultWallet?: TCompanyWallet,
  expiredPointsWallet?: TCompanyWallet,
  euroPerPoint: number
}

export type OperationInformation = {
  id: string,
  name: string
}

export type PaymentInformation = {
  type: TWalletTransactionOption;
  amount: string,
  walletId: string,
  transactionId?: string;
  secret?: string;
  reference?: string;
}

export enum WizardType {
  CREATE_OPERATION = "operation",
  IMPORT_PARTICIPANT = "import"
}

export interface StoreWizard {
  currentStep: number,
  currentPath: string,
  maximumStep: number,
  stepToValidate: number,
  type: WizardType
}

export function wizardUrl(type: WizardType, operationId?: string): string {
  switch (type) {
  case WizardType.CREATE_OPERATION:
    return PRIVATE_URL.CREATE_OPERATION_WIZARD;
  case WizardType.IMPORT_PARTICIPANT:
    return operationUrl(PRIVATE_URL.IMPORT_PARTICIPANTS_WIZARD, operationId??"");
  }
}

export interface OperationWizard extends StoreWizard {
  operation: TOperation|undefined,
  maximumStep: number
}

export interface ParticipantWizard extends StoreWizard {
  currentErrorsInFile: number,
  uploadedFileDatas: TParticipantImportPreview|undefined,
  selectedWallet: string|undefined,
  saving: boolean,
  header: boolean
}

export type Store = {
  participantWizard: ParticipantWizard,
  operationWizard: OperationWizard,
  global: {
    admin: AdminInformation,
    company: CompanyInformation,
    operations: OperationInformation[],
    currentOperation?: TOperation,
    loggedIn: boolean,
    resetDone: boolean,
    superAdmin: boolean,
    stayConnected: boolean,
    notification?: string
    errorNotification?: string
  },
  payment?: PaymentInformation
}

export type StoreContext = [Store, Dispatch<SetStateAction<Store>>];

export const initialStore: Store = {
  participantWizard: {
    currentStep: 1,
    currentPath: participantStepsPaths[0],
    currentErrorsInFile: 0,
    uploadedFileDatas: undefined,
    maximumStep: ParticipantImportWizardSteps.VALIDATION,
    stepToValidate: 0,
    type: WizardType.IMPORT_PARTICIPANT,
    saving: true,
    selectedWallet: undefined,
    header: false
  },
  operationWizard: {
    currentStep: 1,
    currentPath: operationStepsPaths[0],
    operation: undefined,
    maximumStep: OperationWizardSteps.SHOP,
    stepToValidate: 0,
    type: WizardType.CREATE_OPERATION
  },
  global: {
    admin: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      job: "",
      invoiceAddress: undefined,
      participantColumnsOrder: [],
      participantColumnsStatus: {},
      emailConfirmationDate: ""
    },
    company: {
      id: "",
      name: "",
      wallets: [],
      defaultWallet: undefined,
      expiredPointsWallet: undefined,
      euroPerPoint: 1
    },
    operations: [],
    currentOperation: undefined,
    loggedIn: false,
    resetDone: false,
    superAdmin: false,
    stayConnected: false,
    notification: undefined
  }
};
