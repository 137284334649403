import {OperationProps} from "../operationProps";
import {useTranslation} from "react-i18next";
import {Avatar, Box, Typography} from "@mui/material";
import {translate} from "../operationCustomisation";
import PhotoIcon from "@mui/icons-material/Photo";
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import React from "react"

export default function ShowCaseCard(props: OperationProps): JSX.Element {
  const {t} = useTranslation();
  const {operation} = props;
  return (
    <Box sx={{display: "flex", justifyContent: 'space-between'}}>
      {/* LOGO */}
      <Box sx={{display: "flex", flexDirection: "column", height: "100%", pr: 2}}>
        <Typography color="neutral.main" sx={{mb: 1}} variant="body2">{translate(t, "vitrine.logo")}</Typography>
        {operation.logoUrl ?
          <Box sx={{width: "101px", height: "100%", border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px",
            background: `url(${operation.logoUrl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"}}/>
          :
          <Box sx={{display: "flex", border: "1px solid", borderColor: "ornament.dark", height: "100%", p: 0, borderRadius: 1}}>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "ornament.main", width: "100%", p: 3, borderRadius: 1}}>
              <PhotoIcon sx={{color: "neutral.light", height: 48, p: 1}}/>
            </Box>
          </Box>
        }
      </Box>
      {/* BANNIERE */}
      <Box sx={{display: "flex", flexDirection: "column", width: "100%", height: "100%", pr: 2}}>
        <Typography color="neutral.main" sx={{mb: 1}} variant="body2">{translate(t, "vitrine.banner")}</Typography>
        {operation.bannerUrl ?
          <Box sx={{width: "100%", height: "100%", border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px",
            background: `url(${operation.bannerUrl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"}}/>
          :
          <Box sx={{display: "flex", border: "1px solid", borderColor: "ornament.dark", height: "100%", p: 0, borderRadius: 1}}>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "ornament.main", width: "100%", py: 1, borderRadius: 1}}>
              <PhotoSizeSelectActualIcon sx={{color: "neutral.light", height: 48, p: 1}}/>
            </Box>
          </Box>
        }
      </Box>
      {/* COLORS */}
      <Box sx={{display: "flex", flexDirection: "column", minWidth: "255px"}}>
        <Typography color="neutral.main" sx={{mb: 1}} variant="body2">{translate(t, "vitrine.colors")}</Typography>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", py: 2, px: 1, mb: 1, backgroundColor: "ornament.main"}}>
          <Typography variant="caption" color="neutral.main" noWrap sx={{mr: 5}}>{translate(t, "vitrine.primary_color")}</Typography>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography variant="body2" sx={{fontWeight: "bold", mr: 1}}>{operation.primaryColor??translate(t, "vitrine.no_color")}</Typography>
            <Avatar variant="square" sx={{width: 24, height: 24, border: "1px solid", borderColor: "ornament.dark", backgroundColor: operation.primaryColor??'transparent'}}> </Avatar>
          </Box>
        </Box>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", py: 2, px: 1, backgroundColor: "ornament.main"}}>
          <Typography variant="caption" color="neutral.main" noWrap sx={{mr: 5}}>{translate(t, "vitrine.secondary_color")}</Typography>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography variant="body2" sx={{fontWeight: "bold", mr: 1}}>{operation.contrastColor ? translate(t, "colors.dark") : translate(t, "colors.light")}</Typography>
            <Avatar variant="square" sx={{width: 24, height: 24, border: "1px solid", borderColor: "ornament.dark", backgroundColor: !operation.contrastColor?'ornament.light':'ornament.dark'}}> </Avatar>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
