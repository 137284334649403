import {Box, Typography} from "@mui/material";
import MaintenanceMode from "../../assets/maintenance_mode.svg";
import React from "react";
import {useTranslation} from "react-i18next";

export default function Maintenance(): JSX.Element {
  const {t} = useTranslation();

  return (
    <Box sx={{display: "flex", flexDirection: "column", width: "100%", mx: "auto", mr: "auto", mt: 4, mb: 4}}>
      <img src={MaintenanceMode as unknown as string} style={{height:"378px"}} alt="Mode maintenance activé"/>
      <Typography variant="h1" sx={{fontWeight: "bold", color: 'neutral.main', mt: 3, mx: "auto"}}>
        {t('global.maintenance_mode_on')}
      </Typography>
    </Box>
  )
}
