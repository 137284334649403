import bagage from '../../assets/universesPics/Bagagerie.jpg'
import electromenager from '../../assets/universesPics/Electromenager.jpg'
import gastronomie from '../../assets/universesPics/Gastronomie.jpg'
import hightech from '../../assets/universesPics/High_tech.jpg'
import jardin from '../../assets/universesPics/Jardin_et_bricolage.jpg'
import loisirs from '../../assets/universesPics/Loisir_et_detente.jpg'
import maison from '../../assets/universesPics/Maison.jpg'
import tendance from '../../assets/universesPics/Mode_et_tendance.jpg'
import voyages from '../../assets/universesPics/Activite_et_voyage.jpg'


const UNIVERSES_IMAGES = {
  bagage,
  electromenager,
  gastronomie,
  hightech,
  jardin,
  loisirs,
  maison,
  tendance,
  voyages
}

export default UNIVERSES_IMAGES;
