import {
  bodyWithFile,
  del,
  getBlob,
  getCsv,
  getPaginated,
  post,
  postWithFile,
  RequestFilter,
  RequestOrder
} from "../common/methods/ApiService";
import {API_URL, GLOBAL_COMPANY_ID, GLOBAL_IMPORT_ID, GLOBAL_OPERATION_ID} from "../common/struct/urlManager";
import {
  TParticipantImport,
  TParticipantImportInformation,
  TParticipantImportPreview,
  TParticipantImportValidate
} from "../interfaces/participantImport";

export async function importParticipantsFile(file: File, operationId: string): Promise<TParticipantImportPreview> {
  return postWithFile(API_URL.IMPORT_PARTICIPANTS, bodyWithFile(file, {"operation": operationId})).then(data => {
    const correctMapping: any = {};
    for (const element in data.mapping) {
      correctMapping[element] = data.mapping[element].toString();
    }
    return {
      ...data,
      mapping: correctMapping
    }
  });
}

export async function validateParticipantImport(importId: string, mapping: any, walletId: any, saveHeader: boolean): Promise<TParticipantImportValidate> {
  const correctMapping: any = {};
  for (const element in mapping) {
    const value = mapping[element];
    correctMapping[element] = (value === 'true' || value === 'false') ? JSON.parse(value) : value;
  }

  return post(API_URL.VALIDATE_PARTICIPANTS.replace(":" + GLOBAL_IMPORT_ID, importId)+"?noHeaders="+saveHeader, {mapping: correctMapping, wallet: walletId});
}


export async function cancelParticipantImport(importId: string): Promise<any> {
  return post(`${API_URL.CANCEL_PARTICIPANTS.replace(":" + GLOBAL_IMPORT_ID, importId)}`);
}

export async function getImportErrorFile(importId: string): Promise<any> {
  return getBlob(API_URL.ERROR_IMPORT_PARTICIPANTS.replace(":" + GLOBAL_IMPORT_ID, importId));
}

export async function getImportModelFile(): Promise<any> {
  return getCsv(API_URL.MODEL_IMPORT_PARTICIPANTS);
}

export async function downloadImportFile(importId: string): Promise<any> {
  return getBlob(API_URL.IMPORT_DOWNLOAD.replace(":" + GLOBAL_IMPORT_ID, importId));
}

export async function getExportParticipants(operationId: string, search?: string, order?: RequestOrder, filters?: RequestFilter[], visibility?: Record<string, boolean>): Promise<any> {
  return getBlob(API_URL.EXPORT_OPERATION_PARTICIPANTS.replace(":" + GLOBAL_OPERATION_ID, operationId), false, search, order, filters, visibility);
}

export async function getExportCompanyPointsPurchase(companyId: string|undefined): Promise<any> {
  if (companyId)
    return getBlob(API_URL.EXPORT_COMPANY_POINTS_PURCHASE.replace(":" + GLOBAL_COMPANY_ID, companyId));
  else
    return null;
}

export async function getExportCompanyWalletTransactions(companyId: string|undefined): Promise<any> {
  if (companyId)
    return getBlob(API_URL.EXPORT_COMPANY_WALLET_TRANSACTION.replace(":" + GLOBAL_COMPANY_ID, companyId));
  else
    return null;
}

export async function getExportParticipantsRewards(operationId: string): Promise<Blob> {
  return getBlob(API_URL.EXPORT_OPERATION_REWARDS.replace(":" + GLOBAL_OPERATION_ID, operationId));
}

export async function getExportGifts(operationId: string, search?: string, order?: RequestOrder, filters?: RequestFilter[]): Promise<any> {
  return getBlob(API_URL.EXPORT_OPERATION_GIFTS.replace(":" + GLOBAL_OPERATION_ID, operationId), false, search, order, filters);
}

export async function getExportDeletedParticipants(operationId: string): Promise<any> {
  return getBlob(API_URL.EXPORT_OPERATION_DELETED_PARTICIPANTS.replace(":" + GLOBAL_OPERATION_ID, operationId));
}

export async function saveParticipantImport(importId: string, ignoreValidationErrors: boolean, saveHeader: boolean): Promise<TParticipantImport> {
  const url = !ignoreValidationErrors ? (!saveHeader ? API_URL.SAVE_PARTICIPANTS : API_URL.SAVE_PARTICIPANTS + "?noHeaders=true") : (!saveHeader ? API_URL.SAVE_PARTICIPANTS + "?excludeInvalidLines=true" : API_URL.SAVE_PARTICIPANTS + "?excludeInvalidLines=true" + "&noHeaders=true");
  return post(url.replace(":" + GLOBAL_IMPORT_ID, importId), null);
}

export async function deleteParticipantImport(importId: string): Promise<void> {
  return del("participants-imports", importId);
}

export async function getOperationParticipantImports(operationId: string, page: number, itemsPerPage: number): Promise<{totalItems: number, items: TParticipantImportInformation[]}> {
  return getPaginated(`${API_URL.OPERATION}/${operationId}/participantimports`, page, itemsPerPage);
}


