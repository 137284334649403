import React from "react";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {PrimaryBigButton, SecondaryBigButton} from "../../../../components/buttons/mainButton";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {TOperationParticipant} from "../../../../interfaces/operationParticipant";
import {TParticipant} from "../../../../interfaces/participant";

type GiveSavAccessToParticipantProps = {
  openDialog: boolean,
  closeDialog: () => void,
  setSavDate: (date: string) => void,
  operationParticipant: TOperationParticipant|null
}

export default function GiveSavAccessToParticipant(props: GiveSavAccessToParticipantProps): JSX.Element {
  const {openDialog, closeDialog, setSavDate, operationParticipant} = props;
  const {t} = useTranslation();

  const participantName = (participant: TParticipant): string => {
    const names = [participant.firstName??'', participant.lastName??''];
    return names.join(' ');
  }

  const getDateSav = (): Date => {
    const dateSav = new Date();
    dateSav.setDate(dateSav.getDate() + 30);
    dateSav.setHours(23,59,59,0);

    return dateSav;
  }

  const onConfirmSetSavDate = (): void => {
    const dateSav = moment(getDateSav()).format("YYYY-MM-DD HH:mm:ss");
    setSavDate(dateSav);
    closeDialog();
  }

  return operationParticipant == null ? <></> :
    <Dialog open={openDialog} onClose={closeDialog} sx={{display: "flex", justifyContent: "center"}}>
      <DialogTitle sx={{padding: "24px 24px 0 24px", mb: 3}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h2" sx={{fontWeight: "bold"}}>{t("participants.set_sav_date.title")}</Typography>
          <IconButton onClick={closeDialog} sx={{padding: "0 0 0 0", width: "24px", height: "24x", maxHeight: "24px"}}>
            <CloseIcon sx={{color: "neutral.main"}}/>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{t("participants.set_sav_date.text", {name: participantName(operationParticipant.participant), date: moment(getDateSav()).format("DD/MM/YYYY")})}</Typography>
      </DialogContent>
      <DialogActions sx={{display: 'flex', justifyContent: 'flex-end', borderTop: "1px solid", borderColor: "ornament.dark", p: 5}}>
        <SecondaryBigButton sx={{height: '56px'}} label={t("participants.set_sav_date.button.cancel")} action={closeDialog}/>
        <PrimaryBigButton sx={{ml: 2, height: '56px'}} label={t("participants.set_sav_date.button.submit")} action={onConfirmSetSavDate} />
      </DialogActions>
    </Dialog>
}
