export enum COMPONENT_TYPES {
  SELECT = "select",
  INPUT = "input",
  ACTION = "action",
}

export enum INPUT_TYPES {
  DEFAULT = "text",
  NUMBER = "number",
  EMAIL = "email",
  PASSWORD = "password",
  TELEPHONE = "tel",
  DATE = 'date'
}
