import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {manageStringError, RequestFilter, RequestOrder} from "../../common/methods/ApiService";
import {cancelPlannedEmails, getEmailsPlanned, updatePlannedEmails} from "../../services/OperationService";
import empty from "../../assets/lists/empty_search.svg";
import List from "../../patterns/list/list";
import {
  actionColumn,
  addErrorAlert,
  addSuccessAlert,
  ChipCellProps,
  columnDefinition,
  ColumnType,
  enumFilterWithoutNull,
  gridAction
} from "../../patterns/list/listUtils";
import {TOperation} from "../../interfaces/operation";
import {Loader} from "../../components/loader/loader";
import {useGridApiRef} from "@mui/x-data-grid-pro";
import {TEmailTemplateType, TPlannedEmail} from "../../interfaces/email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Chip, Typography} from "@mui/material";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../../patterns/dialog/deleteDialog";
import UpdateEmailDialog from "../../patterns/dialog/updateEmailDialog";
import {TAlert} from "../../patterns/list/listSnackbar";

export default function OperationMailingPlanned(props: {operation: TOperation, setError: (error: string) => void, setSuccess: (success: string) => void, openPlannedEmailDetails: (sendAt: string)=> void}): JSX.Element {
  const {t} = useTranslation();
  const {operation} = props;
  const apiRef = useGridApiRef();
  useEffect(() => {
    document.title = t("tab_title.operation.mailingPlanned");
  }, []);
  const [loading] = useState(false);
  const [emailToDelete, setEmailToDelete] = React.useState<TPlannedEmail | null>(null);
  const [emailToUpdate, setEmailToUpdate] = React.useState<TPlannedEmail | null>(null);
  const [openCancelEmailDialog, setOpenCancelEmailDialog] = React.useState<boolean>(false);
  const [openUpdateEmailDialog, setOpenUpdateEmailDialog] = React.useState<boolean>(false);
  const [reload, setReload] = useState(false);
  const [alerts, setAlerts] = useState<TAlert[]>([]);

  const typeProps = (type: string): ChipCellProps => {
    switch (type) {
    case TEmailTemplateType.PARTICIPANT_INVITATION:
      return {
        icon: <SendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "neutral.main",
        color: "neutral.dark",
        backgroundColor: "ornament.main",
      }
    default:
      return {
        icon: <CancelScheduleSendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "neutral.light",
        color: "neutral.main",
        backgroundColor: "ornament.main"
      }
    }
  }

  const typeColumn = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "operation_mailing_planned", "type", 500, ColumnType.STRING),
      type: "singleSelect",
      filterOperators: enumFilterWithoutNull,
      valueOptions: Object.values(TEmailTemplateType).map(type => ({value: type, label: t(`email_templates.type.${type.toLowerCase()}`)})),
      renderCell: (item): JSX.Element => {
        const type = typeProps(item.row.type);
        return <Chip icon={type.icon}
          sx={{backgroundColor: type.backgroundColor, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: type.iconColor}}}
          label={<Typography variant="body2" color={type.color} sx={{fontWeight: "regular"}}>
            {t(`email_templates.type.${item.row.type.toLowerCase()}`)}
          </Typography>}/>
      }
    }
  }

  const columns = [
    typeColumn(),
    columnDefinition(t, "operation_mailing_planned", "emailCount", 200, ColumnType.NUMBER, false),
    columnDefinition(t, "operation_mailing_planned", "sendAt", 250, ColumnType.DATE_TIME, false),
    actionColumn(params => [
      gridAction(params, "details", t("history.actions.details"), VisibilityIcon, showEmailDetails, false, false, true),
      gridAction(params, "update", t("operation_mailing_details.actions.update"), EditIcon, updateEmail),
      gridAction(params, "cancel", t("operation_mailing_details.actions.cancel"), DeleteIcon, cancelEmail),
    ])
  ]

  const showEmailDetails = async (email: TPlannedEmail): Promise<void> => {
    if (operation) {
      props.openPlannedEmailDetails(email.sendAt);
    }
  }

  const updateEmail = (email: TPlannedEmail): void => {
    setEmailToUpdate(email);
    setOpenUpdateEmailDialog(true);
  }

  const cancelEmail = (email: TPlannedEmail): void => {
    setEmailToDelete(email);
    setOpenCancelEmailDialog(true);
  }

  const closeCancelEmailDialog = (): void => {
    setOpenCancelEmailDialog(false);
    setEmailToDelete(null);
  }

  const closeUpdateEmailDialog = (): void => {
    setOpenUpdateEmailDialog(false);
    setEmailToUpdate(null);
  }

  const cancelEmailAction = (): Promise<void> => {
    if (emailToDelete == null || operation == null) {
      return Promise.resolve();
    }
    return cancelPlannedEmails(operation.id, emailToDelete.sendAt)
      .then(() => {
        setReload(true);
        addSuccessAlert(t("operation_mailing_details.actions.cancel_success"), setAlerts);
      })
      .catch(error => manageStringError(error, error => addErrorAlert(error, setAlerts), t))
      .finally(closeCancelEmailDialog)
  }

  const updateEmailAction = (requestedSendAt: string): Promise<void> => {
    if (emailToUpdate == null || operation == null || emailToUpdate.sendAt == requestedSendAt) {
      return Promise.resolve();
    }
    return updatePlannedEmails(operation.id, emailToUpdate.sendAt, requestedSendAt)
      .then(() => {
        setReload(true);
        addSuccessAlert(t("operation_mailing_details.actions.update_success"), setAlerts);
      })
      .catch(error => manageStringError(error, error => addErrorAlert(error, setAlerts), t))
      .finally(closeUpdateEmailDialog)
  }

  const cancelEmailDialog = <DeleteDialog
    key="cancel-email" open={openCancelEmailDialog}
    close={closeCancelEmailDialog}
    title={t("operation_mailing_details.actions.cancel_title")}
    warning={t("operation_mailing_details.actions.cancel_warning_bulk", {count: emailToDelete?.emailCount})}
    deleteElement={cancelEmailAction}
  />

  const updateEmailDialog = <UpdateEmailDialog key="update-email" openDialog={openUpdateEmailDialog} closeDialog={closeUpdateEmailDialog} action={updateEmailAction}/>

  const loadEmails = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TPlannedEmail[]}> => {
    return getEmailsPlanned(operation.id, page, maxItems, search, order, filters);
  }

  return loading ? <Loader height={"100%"} /> : <>
    <List
      translationPrefix="operation_mailing_planned"
      columns={columns}
      apiRef={apiRef}
      defaultOrder={{field: 'sendAt', sort: 'desc'}}
      loadRows={loadEmails}
      height="75vh"
      emptyListIcon={empty}
      sx={{width: "100%", mb: 3}}
      enableToolbar={false}
      enableSelection={false}
      enablePagination={false}
      reload={reload}
      setReload={setReload}
      alerts={alerts}
      dialogs={[cancelEmailDialog, updateEmailDialog]}
    />
  </>
}
