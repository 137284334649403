import React from "react";
import {OperationProps} from "../operationProps";
import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import ParticipantIcon from '../../../assets/person.svg';
import GiftIcon from '../../../assets/gift_circle.svg';


export default function PointsCard(props: OperationProps): JSX.Element {
  const {operation} = props;
  const {t} = useTranslation();

  return (
    <>
      <Box sx={{display: "flex", height: "100%"}}>
        <Box sx={{width: "50%", height: "100%", margin: "0 6px 0 0", backgroundColor: "ornament.main", padding: 1, boxSizing: "border-box", borderRadius: "10px"}}>
          <Box sx={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            {/** @ts-ignore */}
            <img src={ParticipantIcon} alt="" key={"empty-preview-0"} style={{
              height: "61px", width: "61px",
              zIndex: 0,
              opacity: 1
            }}/>
            <Typography color="primaryDark.light" variant="body1" sx={{fontWeight: 600, mt: 1}}>{operation.participantStats.withReward}</Typography>
            <Typography color="neutral.main" variant="body1" sx={{textAlign: 'center', flex: 1, display: 'flex', alignItems: 'center'}}>{t("operations.customisation.gifts_orders.participants_rewarded")}</Typography>
          </Box>
        </Box>
        <Box sx={{width: "50%", height: "100%", margin: "0 0 0 6px", backgroundColor: "primaryLight.main", padding: 1, boxSizing: "border-box", borderRadius: "10px"}}>
          <Box sx={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            {/** @ts-ignore */}
            <img src={GiftIcon} alt="" key={"empty-preview-0"} style={{
              height: "61px", width: "61px",
              zIndex: 0,
              opacity: 1
            }}/>
            <Typography color="secondary.dark" variant="body1" sx={{fontWeight: 600, mt: 1}}>{operation.orderStats.totalOrderLines}</Typography>
            <Typography color="secondary.dark" variant="body1" sx={{textAlign: 'center', flex: 1, display: 'flex', alignItems: 'center'}}>{t("operations.customisation.gifts_orders.gifts_ordered")}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}
