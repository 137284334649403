import React, {useEffect, useState} from 'react';

// LIBRARY IMPORT
// COMPONENTS IMPORT
import Box from '@mui/material/Box';
import RegisterSideBar from "./registerSideBar";
import RegisterForm from "./registerForm";
import {checkIsMobile, isAuthenticated} from "../../common/struct/globalVar";
import {PRIVATE_URL, PUBLIC_URL} from "../../common/struct/urlManager";
import {Link, useNavigate} from "react-router-dom";
import LogoJoozy from "../../assets/logoJoozyViolet.svg";
import {SecondarySmallButton} from "../../components/buttons/mainButton";
import {Typography, useMediaQuery} from "@mui/material";
import Footer from "../../patterns/footer/footer";
import {useTranslation} from "react-i18next";
import LogoLeBonCoin from "../../assets/clients/leBonCoin.svg";
import LogoGRDF from "../../assets/clients/grdf.svg";
import LogoTKE from "../../assets/clients/tke.svg";
import LogoLaPoste from "../../assets/clients/laPosteSolutionsBusiness.png";
import LogoSaintGobain from "../../assets/clients/saintGobain.svg";

export default function Register() : JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [showImage, setShowImage] = useState(window.innerHeight > 940);
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  useEffect(() => {
    document.title = t("tab_title.register");
  }, []);
  const updateVisualElements = (): void => {
    setShowImage(window.innerHeight > 940);
    setIsMobile(checkIsMobile());
  }

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(PRIVATE_URL.WELCOME, {replace: true});
    }

    // Sur mobile, le scroll est gardé, on remet en haut de la page
    const element = document.getElementById("register");
    element?.scrollIntoView();

    const handleWindowSizeChange = (): void => updateVisualElements();
    window.addEventListener('resize', handleWindowSizeChange);
    return (): any => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])

  const redirectToLogin = (): void => navigate(PUBLIC_URL.LOGIN);

  const breakpoint = useMediaQuery('(min-width:1100px)');

  return (
    <Box id="register" sx={{display: "flex"}}>
      {breakpoint && <RegisterSideBar showImage={showImage}/>}
      <Box sx={{display: "flex", flexDirection: "column", minHeight: "100vh", width: breakpoint ? "calc(100% - 400px)" : "100%", backgroundColor: "ornament.main"}}>
        {isMobile && <Box sx={{width: "90%", mx: "auto", display: "flex", alignItems: "center", justifyContent: "space-between", position: "sticky", backgroundColor: "ornament.main", zIndex: 100, top: 0, py: 5}}>
          <Link to={PRIVATE_URL.LANDING_PAGE}>
            {/* @ts-ignore */}
            <img src={LogoJoozy} alt={t("register.logo.joozy")} />
          </Link>
          <SecondarySmallButton label={t("register.connect_button")} action={redirectToLogin}/>
        </Box>}
        {!isMobile &&
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "flex-end", backgroundColor: "ornament.main", pt: 5, pr: 7}}>
            <Typography variant="body2" sx={{fontWeight: "medium", mr: 2}}>
              {t("register.existing_account")}
            </Typography>
            <SecondarySmallButton label={t("register.connect_button")} action={redirectToLogin}/>
          </Box>}
        <Typography variant="h1" sx={{fontWeight: "bold", textAlign: "center", mb: 4}}>
          {t("register.form.title")}
        </Typography>
        <RegisterForm/>
        <Box sx={{display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", justifyContent: "center", pb: 7, pr: isMobile ? "0px" : 7}}>
          <Typography variant="body2" sx={{fontWeight: "medium", mr: isMobile ? "0px" : 2, mb: isMobile ? 2 : "0px"}}>
            {t("register.existing_account")}
          </Typography>
          <SecondarySmallButton label={t("register.connect_button")} action={redirectToLogin}/>
        </Box>
        {!isMobile && <>
          <Typography variant="body2" color="neutral.main" sx={{textAlign: "center", mb: 3}}>
            {t("login.trusted_companies")}
          </Typography>
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", mb: "50px"}}>
            {/* @ts-ignore */}
            <img src={LogoLeBonCoin} alt={t("register.logo.leboncoin")} style={{marginRight: 32}}/>
            {/* @ts-ignore */}
            <img src={LogoGRDF} alt={t("register.logo.grdf")} style={{marginRight: 32}}/>
            {/* @ts-ignore */}
            <img src={LogoTKE} alt={t("register.logo.tke")} style={{marginRight: 32}}/>
            {/* @ts-ignore */}
            <img src={LogoLaPoste} alt={t("register.logo.laposte")} style={{height: 48, marginRight: 32}}/>
            {/* @ts-ignore */}
            <img src={LogoSaintGobain} alt={t("register.logo.saintgobain")} />
          </Box>
          <Footer/>
        </>}
      </Box>
    </Box>
  )
}
