import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {StoreWizard, wizardUrl} from "../../common/struct/store";
import WizardHeader from "./wizardHeader";
import WizardFooter from "./wizardFooter";
import WizardContent from "./wizardContent";
import WizardHelper from "./wizardHelper";
import {WizardSize, WizardStep} from "./wizardProps";
import {LIBERTY_THEME} from "../../tokens/libertyTheme";
import {useNavigate} from "react-router-dom";

interface WizardCancelProps {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
  dialog: JSX.Element;
}

interface WizardProps {
  wizard: StoreWizard;
  steps: WizardStep[];
  loading: boolean;
  disableNextStep?: boolean;
  error: string|null;
  endUrl: string;
  operationId?: string;
  cancelInfo?: WizardCancelProps;
}

export default function Wizard(props: WizardProps): JSX.Element {
  const {wizard, steps, loading, disableNextStep = false, error, endUrl, operationId, cancelInfo} = props;
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(steps[wizard.currentStep - 1]);

  useEffect(() => {
    if (wizard.currentStep > wizard.maximumStep) {
      navigate(endUrl);
    } else {
      window.history.replaceState(null, '', `${wizardUrl(wizard.type, operationId)}?etape=${wizard.currentStep}`);
      setCurrentStep(steps[wizard.currentStep - 1]);
    }
  }, [wizard.currentStep])

  return (
    <Box sx={{display: "flex", flexDirection: "column", height: "100vh"}}>
      <WizardHeader currentStep={wizard.currentStep} maxSteps={wizard.maximumStep}/>
      <Box sx={{display: "flex", justifyContent: "space-around", overflow: "hidden",
        background: `linear-gradient(90deg, ${LIBERTY_THEME.palette.ornament.light} 50%, ${LIBERTY_THEME.palette.ornament.main} 50%)`}}>
        <WizardContent wizard={wizard} size={currentStep.size} error={error}>
          {currentStep.content}
        </WizardContent>
        {currentStep.size !== WizardSize.FULL && <WizardHelper size={currentStep.size}>
          {currentStep.helper}
        </WizardHelper>}
      </Box>
      <WizardFooter wizard={wizard} loading={loading} error={error} disableNextStep={disableNextStep} setOpenCancelDialog={cancelInfo?.setOpenDialog}/>
      {cancelInfo && cancelInfo.openDialog && cancelInfo.dialog}
    </Box>
  )
}
