import React from 'react';
import {useNavigate} from "react-router-dom";
import {IconButton} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

type BackLinkProps = {
  to?: string,
  sx?: SxProps<Theme>,
}

export default function BackLinkWithoutText(props: BackLinkProps) : JSX.Element {
  const navigate = useNavigate();

  const goBack = (): void => props.to ? navigate(props.to) : navigate(-1);

  return (
    <IconButton sx={{color: "neutral.main", ...props.sx}} onClick={goBack}>
      <ArrowBackIcon sx={{fontSize: 28}}/>
    </IconButton>
  )
}
