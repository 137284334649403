import React, {useContext, useEffect, useState} from 'react';
import {Alert, Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {SecondarySmallButton} from "../../components/buttons/mainButton";
import {FileUploader} from "react-drag-drop-files";
import {
  CSV_FILE_TYPES,
  DownloadFileProps,
  FAVICON_FILE_TYPES,
  FileType,
  IMAGE_FILE_TYPES,
  PDF_FILE_TYPES
} from "./downloadFileProps";
import CropImageElement, {CropSettingsProps} from "./cropImageElement";
import {errorManager} from "../../common/methods/ApiService";
import {useTranslation} from "react-i18next";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {useNavigate} from "react-router-dom";

export default function DownloadFileDialog(props: DownloadFileProps): JSX.Element {
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);

  const {open, setOpen, name, translate, handleVerifyFile, handleUploadFile, cropType} = props;
  const [error, setError] = useState<null|string>(null);
  const [step, setStep] = useState<null|number>(1);
  const [file, setFile] = useState<null|File>(null);
  const [size, setSize] = useState<{width: number, height: number}>({width: 0, height: 0});

  const maxSize = parseInt(process.env.REACT_APP_MAX_UPLOAD_SIZE!);

  const navigate = useNavigate();

  let fileTypes;
  switch (props.type) {
  case FileType.IMAGE:
    fileTypes = IMAGE_FILE_TYPES;
    break;
  case FileType.FAVICON:
    fileTypes = FAVICON_FILE_TYPES;
    break;
  case FileType.PDF:
    fileTypes = PDF_FILE_TYPES;
    break;
  case FileType.CSV:
    fileTypes = CSV_FILE_TYPES;
    break;
  }

  const closeDialog = (): void => {
    setError(null);
    setOpen(false);
  }
  const handleTypeError = (): void => {
    setError(translate("error_type"));
  }
  const handleSizeError = (): void => {
    setError(translate("error_size", {maxSize: maxSize/1000000}));
  }

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setTimeout((): void => setError(null), 8000);
    }
  }, [error]);

  const uploadFile = (file: File|null, settings?: CropSettingsProps): void => {
    setLoading(true);

    handleUploadFile(file, settings && settings)
      .then(() => { closeDialog() })
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
      })
      .finally(() => setLoading(false));
  }

  const checkFile = (file: File|null): void => {
    setLoading(true);
    setError(null);

    if (file && file.size <= 6000000) {

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any): void => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function (): void {
          setSize({width: image.width, height: image.height})
        };
      };

      handleVerifyFile(file)
        .then(() => {
          if (file.name.split('.').pop() === "gif" || file.name.split('.').pop() == "svg") {
            uploadFile(file);
          } else {
            setStep(2), setFile(file)
          }
        })
        .catch(error => {
          const errorResult = errorManager(error, t, STORE, navigate);
          if (typeof errorResult === 'string') setError(errorResult);
        })
        .finally(() => setLoading(false));
    } else {
      setError('La taille de l\'image doit être inférieure à 6Mo');
      setLoading(false)
    }
  }

  return (
    <Dialog sx={{"& .MuiPaper-root": {maxWidth: "908px", width: "100%"}}} open={open} onClose={closeDialog}>
      <DialogTitle>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <Typography variant="h2" sx={{color: "neutral.dark", fontWeight: "bold"}}>{translate("title")}</Typography>
          <IconButton sx={{color: "neutral.main"}} onClick={closeDialog}>
            <CloseIcon/>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{width: "100%", boxSizing: "border-box"}}>
        {step === 1 ?
          <FileUploader handleChange={checkFile} onTypeError={handleTypeError} name={"upload-" + name} maxSize={maxSize/1000000} onSizeError={handleSizeError} types={fileTypes}>
            {error && <Alert variant="filled" severity="error" sx={{mr: 5, mx: 5, mb: 3, fontWeight: "regular", width: "unset !important"}}>{error}</Alert>}
            <Box sx={{width: 808, height: 360, mr: 5, mb: 5, mx: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
              borderWidth: 2, borderColor: "alt.dark", borderStyle: "dashed", borderRadius: 1}}>
              {!loading ?
                <><CloudUploadIcon sx={{color: "neutral.main", width: 42, height: 42}}/>
                  <Typography variant="h2" sx={{color: "neutral.main", fontWeight: "bold", mb: 3}}>{translate("add_file")}</Typography>
                  <SecondarySmallButton label={translate("import_file")} />
                  <Typography variant="body1" sx={{color: "neutral.main", mt: 3}}>{translate("drop_file")}</Typography></>
                :
                <Box style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                  <CircularProgress size={50}/>
                </Box>}
            </Box>
          </FileUploader>
          : step === 2 &&
          <CropImageElement type={cropType} file={file} size={size} setStep={setStep} uploadFile={uploadFile} />
        }
      </DialogContent>
    </Dialog>
  )
}
