import {Attribute, ImportWizardCardProps, ParticipantImportWizardSteps} from "./participantImportWizardSteps";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {Box, Card, Checkbox, Divider, FormControlLabel, FormGroup, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import SelectAttributeType from "./selectAttributeType";
import {getAllParticipantAttributeTypes} from "../../services/ParticipantService";
import {participantStoreReducer} from "../../common/methods/context-setter/participantStoreReducer";
import {NEXT_STEP} from "../../common/methods/context-setter/globals";
import {useNavigate} from "react-router-dom";


export default function UpdateImportMappingCard(props: ImportWizardCardProps): JSX.Element {
  const {setError, loading, handleSaveHeader} = props;
  const {t} = useTranslation();
  useEffect(() => {
    document.title = t("tab_title.operation.import.step2");
  }, []);
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const preview = store.participantWizard.uploadedFileDatas?.preview;

  const [attributeTypes, setAttributeTypes] = useState<Attribute[]>([]);
  const [saveHeader, setSaveHeader] = useState<boolean>(store.participantWizard.header);
  const navigate = useNavigate();

  const safeTranslate = (value: string): string => {
    const prefix = "participants.import_participants.meta_types.";
    const translatedValue = t(prefix + value);
    return (translatedValue.startsWith(prefix)) ? value : translatedValue;
  }

  useEffect(() => {
    getAllParticipantAttributeTypes()
      .then(types => {
        const attributeTypes = Object.keys(types).map(function(key) {
          return {
            label: key,
            trad: safeTranslate(key)
          };
        });

        setAttributeTypes(attributeTypes);
      })
      .catch(async error => manageStringError(errorManager(error, t, STORE, navigate), setError, t))
  }, []);

  useEffect(() => {
    if (store.participantWizard.stepToValidate !== ParticipantImportWizardSteps.MAPPING) {
      return;
    }

    participantStoreReducer(STORE, {type: NEXT_STEP});
  }, [store.participantWizard.stepToValidate])

  useEffect(() => {    
    handleSaveHeader!(saveHeader)
  }, [saveHeader]);

  return (
    <>
      <FormGroup sx={{mb: "8px", width: "fit-content", ".MuiFormControlLabel-root": {margin: "0px"}, ".MuiTypography-root": {pl: "6px"}}}>
        <FormControlLabel control={<Checkbox sx={{color: "primary.main", width: "24px", height: "24px", ".MuiSvgIcon-root": {fontSize: "24px"}}} checked={saveHeader} onClick={(): void => setSaveHeader(!saveHeader)}/>} label={t("import_participants.mapping.header")} />
      </FormGroup>
      <Card sx={{border: "1px solid", borderColor: "ornament.dark", boxShadow: 1}}>
        <Box sx={{display: "flex", alignItems: "center", py: 6, pl: 5, borderBottom: "1px solid", borderColor: "ornament.dark"}}>
          {!saveHeader && <Typography variant="body2" sx={{fontWeight: "bold", width: "33%"}}>{t("import_participants.mapping.column")}</Typography>}
          {!saveHeader && <Divider orientation="vertical" flexItem sx={{width: "1px", height: "15px", mt: 0, backgroundColor: "ornament.dark", borderColor: "ornament.dark"}}/>}
          <Typography variant="body2" sx={{fontWeight: "bold", ml: !saveHeader ? 3 : "0px", width: !saveHeader ? "33%" : "46%"}}>{t("import_participants.mapping.overview")}</Typography>
          <Divider orientation="vertical" flexItem sx={{width: "1px", height: "15px", mt: 0, backgroundColor: "ornament.dark", borderColor: "ornament.dark"}}/>
          <Typography variant="body2" sx={{fontWeight: "bold", ml: 3, width: !saveHeader ? "33%" : "46%"}}>{t("import_participants.mapping.mapping")}</Typography>
        </Box>
        {Object.keys(store.participantWizard.uploadedFileDatas?.mapping??[]).map((key, index) => (
          <Box key={`mapping-${key}-${index}`} sx={{display: "flex", alignItems: "center", p: 5, borderBottom: "1px solid", borderColor: "ornament.dark"}}>
            <Box sx={{width: !saveHeader ? "33%" : "46%"}}>
              <Typography variant="body2" color="primary.main" sx={{fontWeight: "bold", backgroundColor: "primaryLight.main", px: 2, py: 0, borderRadius: "4px", width: "fit-content"}}>
                {key}
              </Typography>
            </Box>
            {!saveHeader && <Typography variant="body2" sx={{width: "33%", pl: 3}}>
              {preview[key]}
            </Typography>}
            <Box sx={{width: !saveHeader ? "33%" : "46%", pl: 3, boxSizing: "border-box"}}>
              <SelectAttributeType
                value={key}
                index={index}
                setError={setError}
                types={attributeTypes}
                saveHeader={saveHeader}
                loading={loading}/>
            </Box>
          </Box>
        ))}
      </Card>
    </>
  )
}
