import React, {useContext, useEffect, useState} from 'react';
import {Loader} from "../../components/loader/loader";
import {useLocation, useNavigate} from "react-router-dom";
import {PRIVATE_URL, PUBLIC_URL} from '../../common/struct/urlManager';
import {validateEmail} from '../../services/AdminService';
import {Box, Card, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {PrimaryBigButton} from '../../components/buttons/mainButton';
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {LOGIN} from "../../common/methods/context-setter/globals";

export default function ValidateEmail() : JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const token = new URLSearchParams(search).get('token');

  const STORE = useContext<StoreContext>(AppContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [tokenExpiration, setTokenExpiration] = useState<boolean>(false);

  useEffect(() => {
    if (token == null || token.length == 0 || id == null || id.length == 0) {
      console.error(`Impossible to validate email with token: ${token} and id: ${id}`);
      returnToLogin();
    }

    if (id && token) {
      validateEmail(id, token)
        .then(admin => {
          localStorage.setItem('auth', JSON.stringify(admin.token));
          localStorage.setItem('id', admin.id);
          globalStoreReducer(STORE, {type: LOGIN, admin: admin});
          setLoading(false);
          navigate(PUBLIC_URL.LOGIN);
        })
        .catch((error: Response) => {
          if (error.status && error.status == 401) {
            setTokenExpiration(true);
            setLoading(false);
          } else{
            returnToLogin();
          }
        });
    }
  }, []);

  const returnToLogin = (): void => {
    navigate(PUBLIC_URL.LOGIN);
  }

  return loading ? <Loader /> :
    <Box sx={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Card sx={{p: 4, width: "60%", textAlign: "center"}}>
        <Typography variant="h1" sx={{color: "primary.main", fontWeight: "bold", mb: 5}}>{!tokenExpiration ? t('validate_email.title') : t('validate_email.expiration.title')}</Typography>
        <Typography variant="body1" sx={{color: "neutral.main", fontWeight: "bold"}}>{!tokenExpiration ? t('validate_email.text') : t('validate_email.expiration.text')}</Typography>
        {!tokenExpiration && <PrimaryBigButton sx={{mb: 2, mt: 5}} label={t("validate_email.button")} href={PRIVATE_URL.LANDING_PAGE}/>}
      </Card>
    </Box>
}
