import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {RequestFilter, RequestOrder} from "../../common/methods/ApiService";
import {getEmailsHistory} from "../../services/OperationService";
import empty from "../../assets/lists/empty_search.svg";
import List from "../../patterns/list/list";
import {ChipCellProps, columnDefinition, ColumnType, enumFilterWithoutNull} from "../../patterns/list/listUtils";
import {TOperation} from "../../interfaces/operation";
import {Loader} from "../../components/loader/loader";
import {useGridApiRef} from "@mui/x-data-grid-pro";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Chip, Typography} from "@mui/material";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SendIcon from "@mui/icons-material/Send";
import DraftsIcon from "@mui/icons-material/Drafts";
import {TEmailForListing, TEMailStatus} from "../../interfaces/email";

export default function OperationMailingHistory(props: {operation: TOperation, setError: (error: string) => void, setSuccess: (success: string) => void}): JSX.Element {
  const {t} = useTranslation();
  const {operation} = props;
  const apiRef = useGridApiRef();
  useEffect(() => {
    document.title = t("tab_title.operation.mailingHistory");
  }, []);
  const [loading] = useState(false);

  const statusProps = (status: string): ChipCellProps => {
    switch (status) {
    case TEMailStatus.JZ_PENDING:
    case TEMailStatus.JZ_CURRENT:
      return {
        icon: <ScheduleSendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "neutral.main",
        color: "neutral.dark",
        backgroundColor: "ornament.main",
      }
    case TEMailStatus.SENT:
      return {
        icon: <SendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "info.main",
        color: "info.dark",
        backgroundColor: "infoLight.light",
      }
    case TEMailStatus.MJ_CLICK:
    case TEMailStatus.MJ_OPEN:
      return {
        icon: <DraftsIcon sx={{width: 18, height: 18}}/>,
        iconColor: "success.main",
        color: "success.dark",
        backgroundColor: "success.light",
      }
    case TEMailStatus.MJ_BOUNCE:
    case TEMailStatus.MJ_SPAM:
    case TEMailStatus.MJ_BLOCKED:
    case TEMailStatus.MJ_UNSUB:
      return {
        icon: <CancelScheduleSendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "error.main",
        color: "error.dark",
        backgroundColor: "error.light",
      }
    case TEMailStatus.UNKNOWN:
    case undefined:
    default:
      return {
        icon: <CancelScheduleSendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "neutral.light",
        color: "neutral.main",
        backgroundColor: "ornament.main"
      }
    }
  }

  const statusColumn = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "operation_mailing_history", "emailStatus", 250, ColumnType.STRING),
      type: "singleSelect",
      filterOperators: enumFilterWithoutNull,
      valueOptions: Object.values(TEMailStatus).map(status => ({value: status, label: t(`operation_mailing_history.columns.status_${status.toLowerCase()}`)})),
      renderCell: (item): JSX.Element => {
        const status = statusProps(item.row.emailStatus);
        return <Chip icon={status.icon}
          sx={{backgroundColor: status.backgroundColor, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: status.iconColor}}}
          label={<Typography variant="body2" color={status.color} sx={{fontWeight: "regular"}}>
            {t(`operation_mailing_history.columns.status_${item.row.emailStatus.toLowerCase()}`)}
          </Typography>}/>
      }
    }
  }

  const columns = [
    statusColumn(),
    columnDefinition(t, "operation_mailing_history", "participantName", 200, ColumnType.STRING),
    columnDefinition(t, "operation_mailing_history", "participantEmail", 200, ColumnType.STRING),
    columnDefinition(t, "operation_mailing_history", "sendAt", 150, ColumnType.DATE_TIME),
  ]

  const loadHistory = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TEmailForListing[]}> => {
    return getEmailsHistory(operation.id, page, maxItems, search, order, filters);
  }

  return loading ? <Loader height={"100%"} /> : <>
    <List
      translationPrefix="operation_mailing_history"
      columns={columns}
      apiRef={apiRef}
      defaultOrder={{field: 'sendAt', sort: 'desc'}}
      loadRows={loadHistory}
      height="100%"
      emptyListIcon={empty}
      sx={{width: "100%", mb: 3}}
      enableToolbarSearch={false}
    />
  </>
}
