import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {trackPromise} from "react-promise-tracker";
import {useTranslation} from "react-i18next";
import {Alert, Box, Select, SelectChangeEvent, TextField, Typography} from "@mui/material";
import {AppContext} from "../../../App";
import {StoreContext} from "../../../common/struct/store";
import {PrimaryBigButton, SecondaryBigButton} from "../../../components/buttons/mainButton";
import {Loader} from "../../../components/loader/loader";
import {errorManager, Violation} from "../../../common/methods/ApiService";
import {PRIVATE_URL} from "../../../common/struct/urlManager";
import {TParticipantAttribute, TParticipantAttributeType} from "../../../interfaces/participantAttribute";
import {INPUT_TYPES} from "../../../patterns/form/formConstants";
import {createParticipantAttribute, updateParticipantAttribute} from "../../../services/ParticipantService";

type ParticipantAttributeFormProps = {
  addSuccess: (success: string) => void;
  setClose: (close: boolean) => void;
  setReload: (reload: boolean) => void;
  attribute?: TParticipantAttribute | null;
};

export default function ParticipantAttributeForm(props: ParticipantAttributeFormProps): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {operationId} = useParams<string>();
  const {addSuccess, setClose, setReload, attribute} = props;
  const STORE = useContext<StoreContext>(AppContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [violations, setViolations] = useState<Violation[]>([]);
  const [error, setError] = useState<string|null>(null);

  const [label, setLabel] = useState<string>('');
  const [description, setDescription] = useState<string|undefined>(undefined);
  const [type, setType] = useState<string>(TParticipantAttributeType.STRING);

  useEffect(() => {
    setLabel(attribute?.label ? attribute.label : '');
    setDescription(attribute?.description ? attribute.description : undefined);
    setType(attribute?.type ? attribute.type : TParticipantAttributeType.STRING);

    setLoading(false);
  }, [])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) : Promise<void> => {
    event.preventDefault();
    setLoading(true);

    if (!operationId) {
      navigate(PRIVATE_URL.LANDING_PAGE, {replace: true});
      return;
    }

    const record = {
      label: label,
      description: description,
      type: type,
      operation: `/bo/operations/${operationId}`
    };

    const call = (): Promise<TParticipantAttribute> => (attribute) ? updateParticipantAttribute(attribute.id, record) : createParticipantAttribute(record);

    trackPromise(
      call()
        .then(() => {
          setReload(true);
          addSuccess(attribute ? t("operation_participants.attributes.actions.update_success") : t("operation_participants.attributes.actions.create_success"));
          setClose(true);
        })
        .catch(async error => {
          const errorResult = errorManager(error, t, STORE, navigate);
          if (typeof errorResult === 'string') setError(errorResult);
          else if (Array.isArray(errorResult)) setViolations(errorResult);
        })
        .finally(() => setLoading(false))
    );
  }

  return loading ? <Loader/> :
    <Box component="form" onSubmit={handleSubmit} sx={{position: "relative", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
      <Box sx={{backgroundColor: "ornament.main", display: "flex", flexDirection: "column", justifyContent: "space-between", mx: 5, mt: 5}}>
        <Box sx={{mb: 3}}>
          <Typography variant="body2" sx={{fontWeight: "bold", mb: "4px", color: "neutral.main"}}>{t('operation_participants.attributes.form.label.label')}</Typography>
          <TextField
            type={INPUT_TYPES.DEFAULT}
            sx={{"& input": {backgroundColor: "ornament.light", p: 3}}}
            error={violations?.find((violation: any) => violation.field === "label")?.error !== undefined}
            helperText={violations?.find((violation: any) => violation.field === "label")?.error}
            placeholder={t('operation_participants.attributes.form.label.placeholder')}
            defaultValue={label}
            onChange={(e: any): void => setLabel(e.target.value)}
            fullWidth
          />
        </Box>
        <Box sx={{mb: 3}}>
          <Typography variant="body2" sx={{fontWeight: "bold", mb: "4px", color: "neutral.main"}}>{t('operation_participants.attributes.form.description.label')}</Typography>
          <Typography variant="caption" sx={{mb: "4px", color: "neutral.main"}}>{t('operation_participants.attributes.form.non_required')}</Typography>
          <TextField
            type={INPUT_TYPES.DEFAULT}
            sx={{"& input": {backgroundColor: "ornament.light", p: 3}}}
            error={violations?.find((violation: any) => violation.field === 'description')?.error !== undefined}
            helperText={violations?.find((violation: any) => violation.field === 'description')?.error}
            placeholder={t('operation_participants.attributes.form.description.placeholder')}
            defaultValue={description}
            onChange={(e: any): void => setDescription(e.target.value)}
            fullWidth
          />
        </Box>
        <Box sx={{mb: 3}}>
          <Typography id="select-label" variant="body2" sx={{fontWeight: "bold", mb: "4px", color: "neutral.main"}}>{t('operation_participants.attributes.form.type.label')}</Typography>
          <Select
            sx={{"& select": {backgroundColor: "ornament.light", p: 3}}}
            value={type}
            native
            onChange={(e: SelectChangeEvent): void => setType(e.target.value.toString())}
            fullWidth
          >
            <option value={TParticipantAttributeType.STRING}>{t(`operation_participants.attributes.type.${TParticipantAttributeType.STRING}`)}</option>
            <option value={TParticipantAttributeType.NUMBER}>{t(`operation_participants.attributes.type.${TParticipantAttributeType.NUMBER}`)}</option>
            <option value={TParticipantAttributeType.DATE}>{t(`operation_participants.attributes.type.${TParticipantAttributeType.DATE}`)}</option>
          </Select>
        </Box>
        {error && <Alert variant="filled" severity="error">{error}</Alert>}
      </Box>
      <Box sx={{display: "flex", flexDirection: "column", zIndex: 1, backgroundColor: "ornament.light"}}>
        <Box sx={{display: "flex", px: 5, py: 3, borderTop: "1px solid", borderColor: "ornament.dark"}}>
          <SecondaryBigButton action={(): void => setClose(true)} sx={{mr: 3}} label={t("navigation.cancel")}/>
          <PrimaryBigButton submit={true} sx={{width: "100%"}} label={attribute ? t("operation_participants.attributes.actions.update") : t("operation_participants.attributes.actions.create")}/>
        </Box>
      </Box>
    </Box>
}
