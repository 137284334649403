import React, {useContext, useState} from "react";
import {Box, Card, Divider, IconButton, Typography} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import {Theme} from '@mui/material/styles';
import PdfLogo from "../../assets/file_pdf.svg";
import {useTranslation} from "react-i18next";
import {SxProps} from "@mui/system";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {FileType} from "../dialog/downloadFileProps";
import CsvLogo from "../../assets/file_csv.svg";
import {loaderPrimaryButton} from "../../components/loader/loader";
import {useNavigate} from "react-router-dom";

export interface FilePreviewCompactProps {
  name: string;
  size: number;
  deleteFile: () => Promise<void>;
  accessFile?: () => Promise<Blob>;
  setError?: (error: string) => void;
  type: FileType
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

export default function FilePreviewCompact(props: FilePreviewCompactProps): JSX.Element {
  const {name, size, deleteFile, accessFile, setError, type, sx, disabled = false} = props;
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const downloadFile = (): void => {
    if (accessFile == null || setError == null || disabled) {
      return;
    }

    accessFile()
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;

        const clickHandler = (): void => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
          }, 150);
        };

        a.addEventListener('click', clickHandler, false);
        a.click();
      })
      .catch((error) => {
        if (error.status === 404) setError(t("operation_transactions.actions.download_no_invoice"))
        else manageStringError(errorManager(error, t, STORE, navigate), setError, t)
      });
  }
  const actionDeleteFile = (): void => {
    if (disabled) {
      return;
    }

    setLoading(true);
    deleteFile().finally(() => setLoading(false))
  }

  const logo = (type === FileType.CSV) ? CsvLogo : PdfLogo;
  return (
    <Card sx={{display: "flex", alignItems: "center", justifyContent: "space-between", p: 5, boxShadow: 0, width: "100%", boxSizing: "border-box", borderColor: "neutral.light", ...sx}}>
      <Box sx={{display: "flex", alignItems: "center", cursor: accessFile !== null && !disabled ? "pointer" : "default"}} onClick={downloadFile}>
        {/* @ts-ignore */}
        <img src={logo} alt={name} style={{width: 21, height: 24}}/>
        <Typography variant="body2" color="neutral.dark" sx={{ml: 1}}>{name}</Typography>
        <Divider sx={{mx: 1, width: "0.5px", height: "12px", borderColor: "ornament.dark"}}/>
        <Typography variant="caption" color="neutral.main">
          {(size > 1000) ? t("preview.ko", {size: Number(size / 1000).toFixed(0)}) : t("preview.octets", {size: size})}
        </Typography>
      </Box>
      <IconButton disabled={disabled} onClick={actionDeleteFile} sx={{width: 24, height: 24}}>
        {loading ? loaderPrimaryButton : <ClearIcon sx={{color: "neutral.main"}}/>}
      </IconButton>
    </Card>
  )
}
