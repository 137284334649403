import React from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {PrimarySmallButton, SecondarySmallButton} from "../../components/buttons/mainButton";

export function PrimaryInfoButton(props: {label: string, action: (e: React.MouseEvent<HTMLElement>) => void, sx?: SxProps<Theme>}): JSX.Element {
  const {label, action, sx} = props;

  return (
    <PrimarySmallButton label={label} action={action} sx={{
      color: "info.main", backgroundColor: "ornament.light",
      ":hover": {color: "info.dark", backgroundColor: "ornament.main", borderColor: "transparent"},
      ...sx
    }}/>
  )
}

export function SecondaryInfoButton(props: {label: string, action: (e: React.MouseEvent<HTMLElement>) => void, sx?: SxProps<Theme>}): JSX.Element {
  const {label, action, sx} = props;

  return (
    <SecondarySmallButton label={label} action={action} sx={{
      color: "ornament.light", backgroundColor: "transparent", borderColor: "ornament.light",
      ":hover": {color: "ornament.light", backgroundColor: "info.light", borderColor: "ornament.light"},
      ...sx
    }}/>
  )
}
