import React from 'react';
import {Box} from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grdf from "../../assets/landingPage/logos/logo-grdf.png";
import Laposte from "../../assets/landingPage/logos/logo-laposte.png";
import Leboncoin from "../../assets/landingPage/logos/logo-leboncoin.png";
import StGobain from "../../assets/landingPage/logos/logo-stgobain.png";
import Tke from "../../assets/landingPage/logos/logo-tke.png";
import {useTranslation} from "react-i18next";

export default function BrandsSlider(): JSX.Element {

  const {t} = useTranslation();

  const logos = [Grdf, Laposte, Leboncoin, StGobain, Tke, Grdf, Laposte, Leboncoin, StGobain, Tke];

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    draggable: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      }
    ],
  };

  return (
    <Slider {...settings}>
      {logos.map((logo, index) => 
        <Box key={index} sx={{outline: "none", "img" : {maxHeight: "180px", margin: "0 auto"}}}><img src={logo} alt={t("landing_page.alt.brand_logo")}/></Box>
      )}
    </Slider>
  )
}