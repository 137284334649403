import {TAddress} from "./address";
import {TUser} from "./user";

export interface TAdmin extends TUser {
  firstName: string;
  lastName: string;
  phone: string;
  job: string;
  invoiceAddress?: TAddress;
  status: TAdminStatus;
  participantColumnsOrder: string[];
  participantColumnsStatus: any;
  lastConnectionDate?: string;
  invitedBy?: TAdmin;
  token?: string;
}

export enum TAdminStatus {
  INVITATION_SENT = 'INVITATION_SENT',
  INVITATION_ACCEPTED = 'INVITATION_ACCEPTED',
  ACCOUNT_UNVALIDATED = 'ACCOUNT_UNVALIDATED',
  ACCOUNT_VALIDATED = 'ACCOUNT_VALIDATED',
}

export interface TAdminRegister {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phone?: string;
  company?: {
    name: string
  }
}
