import {Box, Typography} from "@mui/material";
import BackLink from "../../patterns/navigation/backLink";
import React from "react";
import {useTranslation} from "react-i18next";

export default function Faq(): JSX.Element {
  const {t} = useTranslation();
  return (
    <Box sx={{height: "100vh", backgroundColor: "ornament.main", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
      <Box sx={{maxHeight: "90vh", overflowY: "auto"}}>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", backgroundColor: "ornament.light",
          borderWidth: "0", borderBottomWidth: "1px", borderStyle: "solid", borderColor: "ornament.dark"}}>
          <BackLink/>
          <Typography variant="h1" sx={{mx: 5, mb: 1, fontWeight: "bold", color: "neutral.dark"}}>{t("faq.title")}</Typography>
        </Box>
        <Box sx={{display: "flex", justifyContent: "center", m: 5}}>
          <Typography>A COMPLETER</Typography>
        </Box>
      </Box>
    </Box>
  )
}
