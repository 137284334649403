import React from "react";
import {Box} from "@mui/material";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {useTranslation} from "react-i18next";
import {SideBarProperties} from "../sideBar/sideBarMenu";

interface PageFormProps {
  children: JSX.Element|JSX.Element[];
  submit: () => void;
  loading: boolean;
}

export default function PageForm(props: PageFormProps): JSX.Element {
  const {children, submit, loading} = props;
  const {t} = useTranslation();

  const validate = (e: any): void => {
    e.preventDefault();
    submit();
  }

  return (
    <form onSubmit={validate}>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", mb: "88px"}}>
        {children}
      </Box>
      <Box sx={{display: "flex", justifyContent: "start", width: "100%", height: "88px", ml: SideBarProperties[0].width, position: "fixed", px: 8, bottom: 0, left: 0, backgroundColor: "ornament.light", alignItems: "center", boxShadow: "inset 0px 1px 0px #CDCEDE"}}>
        <PrimaryBigButton label={t("form.validate")} action={validate} loading={loading} submit={true}/>
      </Box>
    </form>
  )
}
