import React, {useContext, useEffect, useState} from 'react'
import {Grid} from '@mui/material'
import GiftCardChildren from './giftCardChildren';
import {TFunction} from 'react-i18next';
import {TOptions} from "i18next";
import {WizardStepProps} from "../../patterns/wizard_old/wizardStepProps";
import {AppContext} from "../../App";
import {TOperation} from "../../interfaces/operation";
import {StoreContext} from "../../common/struct/store";
import Footer from "../../patterns/footer/footer";

export const translate = (t: TFunction, text: string, options?: TOptions<any>): string => t('operations.shop.gifts.' + text, options);

export type GiftCardProps = {
  operation: TOperation,
  setError: (error: string) => void,
  setSuccess: (success: string) => void,
  handleEurosFilter?: (min: number|undefined, max: number|undefined) => void
}

export default function OperationShopGifts(props: WizardStepProps): JSX.Element {
  const {setError, setSuccess} = props;

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const [operation, setOperation] = useState<undefined|TOperation>(undefined);

  useEffect(() => {
    setOperation(store.global.currentOperation);
  }, [store]);

  return (operation == undefined || setError == null || setSuccess == null) ? <></> :
    <>
      {/* TODO: remplacer ces filtres par des semblables au FO
      <Grid item xs={12} sx={{display: "flex", flexDirection: "column", mb: 5, px: 8}}>
        <Card sx={{backgroundColor: "ornament.light", borderColor: "ornament.dark", boxShadow: 0, py: 2}}>
          <FormGroup>
            <Typography variant="caption" color="neutral.main" sx={{mx: 3, fontWeight: "regular", textTransform: "uppercase"}}>{translate(t, "search_bar.title")}</Typography>
            <Box sx={{mx: 3, mt: 3}}>
              <PointsFilter handleEurosFilter={handleEurosFilter} min={operation.shops[0].priceMin} max={operation.shops[0].priceMax} loading={loading} />
            </Box>
          </FormGroup>
        </Card>
      </Grid> */}
      <Grid container rowSpacing={5} columnSpacing={5} sx={{px: 8}}>
        <Grid item xs={12}>
          <GiftCardChildren operation={operation} setError={setError} setSuccess={setSuccess}/>
        </Grid>
      </Grid>
      <Footer/>
    </>
}
