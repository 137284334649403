import {TCompanyAware, TLifecycle} from "./traits";

export enum TRewardCategory {
  UNITARY_CREDIT = 'UNITARY_CREDIT',
  UNITARY_DEBIT = 'UNITARY_DEBIT',
  IMPORT_CREDIT = 'IMPORT_CREDIT',
  IMPORT_DEBIT = 'IMPORT_DEBIT',
  REFUND = 'REFUND',
  ORDER = 'ORDER',
  EXPIRATION = 'EXPIRATION'
}

export interface TReward extends TLifecycle, TCompanyAware {
  reason: string;
  category: TRewardCategory;
  amount: number;
}

export interface TRewardWarningResponse {
  data: TRewardWarning;
}

export interface TRewardWarning {
  countParticipantsCredit: number;
  countParticipantsDebit: number;
  dateSearch: string;
}
