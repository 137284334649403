import React, {useContext, useEffect, useState} from 'react';
import {Box, Card, Divider, Typography} from "@mui/material";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {useNavigate} from "react-router-dom";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import JoozyBoy from "../../assets/joozyBoyFun.svg";
import {useTranslation} from "react-i18next";
import {PrimaryBigButton} from '../../components/buttons/mainButton';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {IconPosition} from "../../components/buttons/buttonProps";

export default function LoggedInLandingPage(): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [store] = useContext<StoreContext>(AppContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = t("tab_title.landingPageOnline");
  }, []);
  
  useEffect(() => {
    if (store.global.currentOperation && !store.global.currentOperation.deletedAt) {
      navigate(operationUrl(PRIVATE_URL.OPERATION_CUSTOMISATION, store.global.currentOperation.id), {replace: true});
      return;
    }
    if (store.global.operations.length > 0) {
      navigate(operationUrl(PRIVATE_URL.OPERATION_CUSTOMISATION, store.global.operations[0].id), {replace: true});
      return;
    }
    setLoading(false);
  }, [])

  return loading ? <></> :
    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "ornament.main", minHeight: "100vh"}}>
      <Card sx={{maxWidth: "750px", width: "100%", padding: "40px 48px 24px", textAlign: "center", mx: "8px", border: "1px solid", borderColor: "ornament.dark", boxShadow: 1}}>
        <Box sx={{display: "flex", justifyContent: "center", mb: "40px"}}>
          {/* @ts-ignore */}
          <img style={{maxWidth: "250px"}} src={JoozyBoy} alt="email validation logo"/>
        </Box>
        <Typography variant="h1" color="neutral.dark" sx={{fontWeight: "bold", mb: "8px"}}>{t("landing_page.online.title")}</Typography>
        <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: "16px"}}>{t("landing_page.online.subtitle")}</Typography>
        <Divider sx={{backgroundColor: "ornament.main", mb: "16px"}} />
        <Typography variant="body1" color="neutral.dark" sx={{mb: "40px"}} 
          dangerouslySetInnerHTML={{__html: t("landing_page.online.text", {"name": store.global.admin.firstName+" "+store.global.admin.lastName})}}
        />
        <PrimaryBigButton label={t("landing_page.online.button")} href={PRIVATE_URL.CREATE_OPERATION_WIZARD}
          icon={KeyboardArrowRightIcon} position={IconPosition.RIGHT}/>
      </Card>
    </Box>
}
