import React, {useContext, useEffect, useState} from 'react';
import {Box} from "@mui/material";
import ElementForm from "../../patterns/form/elementForm";
import {API_URL} from "../../common/struct/urlManager";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {useTranslation} from "react-i18next";
import {COMPONENT_TYPES, INPUT_TYPES} from "../../patterns/form/formConstants";
import LockIcon from '@mui/icons-material/Lock';
import {IconPosition} from "../../components/buttons/buttonProps";
import ConfirmResetPassword from "./confirmResetPassword";
import {AppContext} from "../../App";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {LOGIN} from "../../common/methods/context-setter/globals";
import {TAdmin} from "../../interfaces/admin";
import {StoreContext} from "../../common/struct/store";
import Page from "../../patterns/page/page";
import {addErrorAlert, addSuccessAlert} from "../../patterns/list/listUtils";
import ListSnackbar, {TAlert} from "../../patterns/list/listSnackbar";
import Footer from "../../patterns/footer/footer";

export default function UserSettings(): JSX.Element {
  const {t} = useTranslation();
  useEffect(() => {
    document.title = t("tab_title.userSetting");
  }, []);
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const admin = store.global.admin;

  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [submitForm, setSubmitForm] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const elements = [
    {
      component: COMPONENT_TYPES.INPUT,
      type: INPUT_TYPES.DEFAULT,
      name: 'lastName',
      label: 'user_settings.last_name',
      required: true,
      disabled: loading
    },
    {
      component: COMPONENT_TYPES.INPUT,
      type: INPUT_TYPES.DEFAULT,
      name: 'firstName',
      label: 'user_settings.first_name',
      required: true,
      disabled: loading
    },
    {
      component: COMPONENT_TYPES.INPUT,
      type: INPUT_TYPES.TELEPHONE,
      name: 'phone',
      label: 'user_settings.phone',
      required: false,
      disabled: loading
    },
    {
      component: COMPONENT_TYPES.INPUT,
      type: INPUT_TYPES.DEFAULT,
      name: 'companyName',
      label: 'user_settings.company_name',
      required: true,
      disabled: loading
    },
    {
      component: COMPONENT_TYPES.INPUT,
      type: INPUT_TYPES.DEFAULT,
      name: 'job',
      label: 'user_settings.job',
      required: false,
      disabled: loading
    },
    {
      component: COMPONENT_TYPES.INPUT,
      type: INPUT_TYPES.DEFAULT,
      name: 'email',
      label: 'user_settings.email',
      required: true,
      disabled: loading
    }
  ];

  const submit = (e: any): void => {
    setLoading(true);
    e.preventDefault();
    if (e.target.id === API_URL.ADMIN + "-form") {
      setSubmitForm(API_URL.ADMIN + "-form");
    }
  }

  const manageSuccess = (admin: TAdmin): void => {
    globalStoreReducer(STORE, {type: LOGIN, admin: admin})
    addSuccessAlert(t("user_settings.update_success"), setAlerts);
    setLoading(false);
  }

  const invalidForm = (): void => {
    setSubmitForm('');
    setLoading(false);
  }

  const resetPasswordButton = <PrimaryBigButton label={t('user_settings.password.button_label')} disabled={loading}
    icon={LockIcon} position={IconPosition.LEFT} action={(): void => setOpenDialog(true)}/>

  return (
    <Page title={t("user_settings.title")} buttons={[resetPasswordButton]}>
      <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
      <ConfirmResetPassword openDialog={openDialog} closeDialog={(): void => setOpenDialog(false)} setError={(error): void => addErrorAlert(error, setAlerts)}
        setSuccess={(): void => addSuccessAlert(t("user_settings.password.success"), setAlerts)} email={admin.email}/>
      <form id={API_URL.ADMIN + "-form"} onSubmit={submit} style={{width: "100%"}}>
        <Box sx={{display: "flex", flexDirection: "column", p: 5, alignItems: "center"}}>
          <ElementForm name={API_URL.ADMIN} elements={elements} sx={{width:"100%", m: "0px"}}
            submitForm={submitForm}
            action={manageSuccess}
            invalidateForm={invalidForm}
            initialRecord={admin} />
          <PrimaryBigButton submit={true} label={t("navigation.validate")} sx={{mt: 5}} loading={loading} />
        </Box>
      </form>
      <Footer/>
    </Page>
  )
}
