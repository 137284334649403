import {PUBLIC_URL} from './urlManager';
import VitrineStep1 from "../../assets/landingPage/vitrineSteps/vitrine-step-1.jpg";
import VitrineStep2 from "../../assets/landingPage/vitrineSteps/vitrine-step-2.jpg";
import VitrineStep3 from "../../assets/landingPage/vitrineSteps/vitrine-step-3.jpg";
import VitrineStep4 from "../../assets/landingPage/vitrineSteps/vitrine-step-4.jpg";
import VitrineStep5 from "../../assets/landingPage/vitrineSteps/vitrine-step-5.png";
import {t} from 'i18next';

export const BLOCKS = {
  SOLUTION: "solution",
  FEATURES: "features",
  PRICING: "pricing",
  US: "us"
} 

export const SOLUTION_TEXTS = [
  {
    category: t("landing_page.solution_texts.category_1.category"),
    title: t("landing_page.solution_texts.category_1.title"),
    line1: t("landing_page.solution_texts.category_1.line_1"),
    line2: t("landing_page.solution_texts.category_1.line_2"),
    assets: [t("landing_page.solution_texts.category_1.assets.asset_1"), t("landing_page.solution_texts.category_1.assets.asset_2"), t("landing_page.solution_texts.category_1.assets.asset_3"), t("landing_page.solution_texts.category_1.assets.asset_4"), t("landing_page.solution_texts.category_1.assets.asset_5")],
    link: t("landing_page.solution_texts.category_1.link"),
    linkSrc: PUBLIC_URL.REGISTER
  },{
    category: t("landing_page.solution_texts.category_2.category"),
    title: t("landing_page.solution_texts.category_2.title"),
    line1: t("landing_page.solution_texts.category_2.line_1"),
    line2: t("landing_page.solution_texts.category_2.line_2"),
    assets: [t("landing_page.solution_texts.category_2.assets.asset_1"), t("landing_page.solution_texts.category_2.assets.asset_2"), t("landing_page.solution_texts.category_2.assets.asset_3"), t("landing_page.solution_texts.category_2.assets.asset_4"), t("landing_page.solution_texts.category_2.assets.asset_5")],
    link: t("landing_page.solution_texts.category_2.link"),
    linkSrc: PUBLIC_URL.REGISTER
  },{
    category: t("landing_page.solution_texts.category_3.category"),
    title: t("landing_page.solution_texts.category_3.title"),
    line1: t("landing_page.solution_texts.category_3.line_1"),
    line2: t("landing_page.solution_texts.category_3.line_2"),
    assets: [t("landing_page.solution_texts.category_3.assets.asset_1"), t("landing_page.solution_texts.category_3.assets.asset_2"), t("landing_page.solution_texts.category_3.assets.asset_3"), t("landing_page.solution_texts.category_3.assets.asset_4"), t("landing_page.solution_texts.category_3.assets.asset_5")],
    link: t("landing_page.solution_texts.category_3.link"),
    linkSrc: PUBLIC_URL.REGISTER
  }
];

export const VITRINE_STEPS = [
  {
    step: t("landing_page.vitrine_steps.step_1.step"),
    title: t("landing_page.vitrine_steps.step_1.title"),
    lines: [t("landing_page.vitrine_steps.step_1.lines.line_1"), t("landing_page.vitrine_steps.step_1.lines.line_2"), t("landing_page.vitrine_steps.step_1.lines.line_3")],
    italicLine: t("landing_page.vitrine_steps.step_1.italicLine"),
    image: VitrineStep1,
    buttonLink: PUBLIC_URL.REGISTER
  }, {
    step: t("landing_page.vitrine_steps.step_2.step"),
    title: t("landing_page.vitrine_steps.step_2.title"),
    lines: [t("landing_page.vitrine_steps.step_2.lines.line_1"), t("landing_page.vitrine_steps.step_2.lines.line_2"), t("landing_page.vitrine_steps.step_2.lines.line_3")],
    italicLine: t("landing_page.vitrine_steps.step_2.italicLine"),
    image: VitrineStep2,
    buttonLink: PUBLIC_URL.REGISTER
  }, {
    step: t("landing_page.vitrine_steps.step_3.step"),
    title: t("landing_page.vitrine_steps.step_3.title"),
    lines: [t("landing_page.vitrine_steps.step_3.lines.line_1"), t("landing_page.vitrine_steps.step_3.lines.line_2"), t("landing_page.vitrine_steps.step_3.lines.line_3")],
    italicLine: t("landing_page.vitrine_steps.step_3.italicLine"),
    image: VitrineStep3,
    buttonLink: PUBLIC_URL.REGISTER
  }, {
    step: t("landing_page.vitrine_steps.step_4.step"),
    title: t("landing_page.vitrine_steps.step_4.title"),
    lines: [t("landing_page.vitrine_steps.step_4.lines.line_1"), t("landing_page.vitrine_steps.step_4.lines.line_2")],
    italicLine: t("landing_page.vitrine_steps.step_4.italicLine"),
    image: VitrineStep4,
    buttonLink: PUBLIC_URL.REGISTER
  }, {
    step: t("landing_page.vitrine_steps.step_5.step"),
    title: t("landing_page.vitrine_steps.step_5.title"),
    lines: [t("landing_page.vitrine_steps.step_5.lines.line_1"), t("landing_page.vitrine_steps.step_5.lines.line_2"), t("landing_page.vitrine_steps.step_5.lines.line_3")],
    italicLine: t("landing_page.vitrine_steps.step_5.italicLine"),
    image: VitrineStep5,
    buttonLink: PUBLIC_URL.REGISTER
  }
];

export const PRICING_DRIVING = [
  t("landing_page.pricing_driving.line_1"),
  t("landing_page.pricing_driving.line_2"),
  t("landing_page.pricing_driving.line_3"),
  t("landing_page.pricing_driving.line_4"),
  t("landing_page.pricing_driving.line_5"),
  t("landing_page.pricing_driving.line_6"),
  t("landing_page.pricing_driving.line_7"),
  t("landing_page.pricing_driving.line_8"),
  t("landing_page.pricing_driving.line_9")
];

export const PRICING_REWARD = [
  t("landing_page.pricing_reward.line_1"),
  t("landing_page.pricing_reward.line_2"),
  t("landing_page.pricing_reward.line_3"),
  t("landing_page.pricing_reward.line_4"),
  t("landing_page.pricing_reward.line_5"),
  t("landing_page.pricing_reward.line_6")
];

export const BREAKPOINTS = [1435, 1360, 1275, 1100, 1050, 550];

export interface BreakpointsProps {
    1435?: string,
    1360?: string,
    1275?: string,
    1100?: string,
    1050?: string,
    550?: string,
    default: string
}

export const SOLUTION_TEXT_TOP: BreakpointsProps = {
  1435: "174px",
  1360: "120px",
  550: "76px",
  default: "76px"
}

export const SOLUTION_TEXT_LEFT: BreakpointsProps = {
  1435: "16%",
  1360: "0%",
  1275: "-4%",
  1100: "-10%",
  default: "-10%"
}

export const SOLUTION_IMG_LEFT: BreakpointsProps = {
  1435: "12%",
  1360: "12%",
  1275: "6%",
  1100: "-4%",
  default: "-4%"
}

export const SOLUTION_IMG_HEIGHT: BreakpointsProps = {
  1435: "auto",
  1360: "750px",
  1275: "680px",
  default: "680px"
}

export const SOLUTION_IMG_BOX_HEIGHT: BreakpointsProps = {
  1435: "950px",
  1360: "850px",
  1275: "770px",
  1100: "770px",
  550: "fit-content",
  default: "fit-content"
}