import React, {useContext, useState} from "react";
import {getAllCompanies} from "../../../services/CompanyService";
import List from "../../../patterns/list/list";
import emptySearch from "../../../assets/lists/empty_search.svg";
import {TCompany} from "../../../interfaces/company";
import {errorManager, RequestFilter, RequestOrder} from "../../../common/methods/ApiService";
import {AppContext} from "../../../App";
import {StoreContext} from "../../../common/struct/store";
import {useTranslation} from "react-i18next";
import {GridEnrichedColDef, GridFilterListIcon, GridFilterModel, useGridApiRef} from "@mui/x-data-grid-pro";
import {columnDefinition, ColumnType, connectColumn} from "../../../patterns/list/listUtils";
import {setSelectedCompany} from "../../../common/struct/globalVar";
import {getAllOperations} from "../../../services/OperationService";
import {globalStoreReducer} from "../../../common/methods/context-setter/globalStoreReducer";
import {ADD_OPERATIONS, LOGIN} from "../../../common/methods/context-setter/globals";
import {useNavigate} from "react-router-dom";
import {PRIVATE_URL} from "../../../common/struct/urlManager";
import {Box, Menu} from "@mui/material";
import {PrimarySmallButton} from "../../../components/buttons/mainButton";
import {IconPosition} from "../../../components/buttons/buttonProps";
import ListMenuItem from "../../../patterns/list/listMenuItem";
import {QuickFilterType} from "../../../interfaces/quickFilter";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {updateAdminCompany} from "../../../services/AdminService";
import {refreshToken} from "../../../services/AuthenticationService";

export default function SuperAdminCompanies(): JSX.Element {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const apiRef = useGridApiRef();

  const [quickFilters, setQuickFilters] = useState<GridFilterModel>({items: []});
  const [quickFilterSelected, setQuickFilterSelected] = useState<QuickFilterType|null>(null);
  const [reload, setReload] = useState(false);

  const [actionsAnchorEl, setActionsAnchorEl] = useState<undefined|HTMLElement>(undefined);

  const loadCompanies = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TCompany[]}> => {
    return getAllCompanies(page, maxItems, search, order, filters)
      .catch(error => {
        errorManager(error, t, STORE, navigate);
        return Promise.resolve({totalItems: 0, items: []})
      });
  }

  const nonHideableColumn = (field: string, width: number, type: ColumnType = ColumnType.STRING, isNullable = false): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "super_admin.companies", field, width, type, false, isNullable),
      hideable: false
    }
  }

  const nonFilterableColumns = (field: string, width: number, type: ColumnType = ColumnType.STRING, sortable = true, isNullable = false): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "super_admin.companies", field, width, type, sortable, isNullable),
      filterable: false
    }
  }

  const defaultColumns = (field: string, width: number, type: ColumnType = ColumnType.STRING, sortable = true, isNullable = false): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "super_admin.companies", field, width, type, sortable, isNullable)
    }
  }

  const selectCompany = (company: TCompany): void => {    
    updateAdminCompany(store.global.admin.id, '/bo/companies/'+company.id)
      .then((admin) => {
        refreshToken()
          .then(() => {
            globalStoreReducer(STORE, {type: LOGIN, company: admin.company})
            setSelectedCompany(company);
            getAllOperations()
              .then(operations => {                               
                globalStoreReducer(STORE, {type: ADD_OPERATIONS, operations: operations});
                navigate(PRIVATE_URL.LANDING_PAGE);
              })
              .catch(e => errorManager(e, t, STORE, navigate))
          })
      })
  }

  const columns = [
    nonHideableColumn("name", 350),
    defaultColumns("creatorAdmin", 270, ColumnType.STRING),
    nonFilterableColumns("adminsCount", 90, ColumnType.NUMBER),
    nonFilterableColumns("operationsCount", 80, ColumnType.NUMBER),
    nonFilterableColumns("currentOperationsCount", 140, ColumnType.NUMBER),
    nonFilterableColumns("endedOperationsCount", 140, ColumnType.NUMBER),
    defaultColumns("lastOperationDate", 200, ColumnType.DATE),
    nonFilterableColumns("totalWalletAmount", 110, ColumnType.NUMBER),
    nonFilterableColumns("totalSpentAmount", 110, ColumnType.NUMBER),
    connectColumn(selectCompany)
  ]

  const handleActionsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = (): void => {
    setActionsAnchorEl(undefined);
  };

  const updateFilters = (filterSelected: QuickFilterType): void => {
    if (filterSelected === quickFilterSelected) {
      setQuickFilters({items: []});
      setQuickFilterSelected(null);
    } else {
      setQuickFilters({items: [{columnField: "inactive", operatorValue: "equals", value: 1}]})
      setQuickFilterSelected(filterSelected);
    }
    setReload(true);
  }

  const quickFilterAction = <Box key="quick-filter">
    <PrimarySmallButton label={t("operation_gifts_history.actions.quick_filter")} action={handleActionsClick}
      icon={GridFilterListIcon} position={IconPosition.LEFT} sx={{mr: 1}}/>
    <Menu id="actions-menu" open={actionsAnchorEl != null} onClose={handleActionsClose} anchorEl={actionsAnchorEl}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}} transformOrigin={{vertical: "top", horizontal: "right"}}
      sx={{"& .MuiPaper-root": {mt: 0, border: "1px solid", borderColor: "ornament.dark", borderRadius: "4px", boxShadow: 3}}}>
      <ListMenuItem label={t("operation_participants.actions.quick.inactive_companies")} icon={quickFilterSelected === QuickFilterType.INACTIVECOMPANIES ? RadioButtonCheckedIcon : RadioButtonUncheckedIcon} action={(): void => { updateFilters(QuickFilterType.INACTIVECOMPANIES) }}/>
    </Menu>
  </Box>

  return (
    <List
      translationPrefix="super_admin.companies"
      columns={columns}
      apiRef={apiRef}
      loadRows={loadCompanies}
      height="75vh"
      emptyListIcon={emptySearch}
      enableSelection={false}
      globalActions={[quickFilterAction]}
      reload={reload}
      setReload={setReload}
      quickFilters={quickFilters}
      sx={{m: 7}}
    />
  );
}
