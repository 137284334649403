import React, {useContext, useState} from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import {PrimaryBigButton, SecondaryBigButton} from "../../../../components/buttons/mainButton";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {IconPosition} from "../../../../components/buttons/buttonProps";
import {TOperation} from "../../../../interfaces/operation";
import {InfoPoints, WalletSelector, WarningPoints} from "./pointsUtils";
import {StoreContext} from "../../../../common/struct/store";
import {AppContext} from "../../../../App";


type RemovePointsToSelectedParticipantsProps = {
  openDialog: boolean,
  removeMoney: (amount: number, wallet: string) => void,
  closeDialog: () => void,
  operation?: TOperation,
  selectedElements: number,
  countWarningCredit: number,
  countWarningDebit: number
}

export default function RemovePointsToSelectedParticipants(props: RemovePointsToSelectedParticipantsProps): JSX.Element {
  const {openDialog, removeMoney, closeDialog, operation, selectedElements, countWarningCredit, countWarningDebit} = props;
  const [store] = useContext<StoreContext>(AppContext);
  const [pointValue, setPointValue] = useState<number>(1);
  const [selectedWallet, setSelectedWallet] = useState<string|undefined>(store.global.company.defaultWallet ? store.global.company.defaultWallet['@id']: undefined);
  const displayWalletSelector = store.global.company.wallets.length > 2;
  const {t} = useTranslation();

  const onConfirmMoney = (): void => {
    if (operation == null) {
      return;
    }

    closeDialog();
    removeMoney(-store.global.company.euroPerPoint*pointValue, selectedWallet ? selectedWallet : store.global.company.defaultWallet ? store.global.company.defaultWallet['@id']: 'none');
  }

  return operation == null ? <></> :
    <Dialog open={openDialog} onClose={closeDialog} sx={{display: "flex", justifyContent: "center"}}>
      <DialogTitle>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography color="neutral.dark" sx={{fontWeight: "bold"}} variant="h2">{t('participants.remove_money_to_selected_participants.title')}</Typography>
          <IconButton onClick={closeDialog}><CloseIcon/></IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onConfirmMoney} >
          <Typography color="neutral.dark" sx={{fontWeight: "bold", mb:2}} variant="body1">{t('participants.remove_money_to_selected_participants.sub_title')}</Typography>
          <TextField onChange={(event: any): void => !isNaN(event.target.value)?setPointValue(Math.abs(event.target.value)):setPointValue(0)} type="string" color="info" value={pointValue}
            sx={{width: '100%', mr: 2, "& input": {p: 3}}}
            InputProps={{
              lang: "fr-FR",
              endAdornment: <InputAdornment position="end"><Typography color="neutral.dark" sx={{fontWeight: "regular"}} variant="body2">{t('participants.remove_money_to_selected_participants.unit')}</Typography></InputAdornment>,
              inputProps: {
                min: 1,
              }
            }}
          />
          {displayWalletSelector &&
            <WalletSelector selectedWallet={selectedWallet} setSelectedWallet={setSelectedWallet}/>
          }
        </form>
        <InfoPoints label="remove_money_to_selected_participants" euroValue={store.global.company.euroPerPoint * pointValue} selectedElements={selectedElements}/>
        {countWarningCredit > 0 && countWarningDebit > 0 && <WarningPoints label="warning_participant" count1={countWarningCredit} count2={countWarningDebit}/>}
        {countWarningCredit > 0 && countWarningDebit == 0 && <WarningPoints label="warning_credit_participant" count1={countWarningCredit}/>}
        {countWarningDebit > 0 && countWarningCredit == 0 && <WarningPoints label="warning_debit_participant" count1={countWarningDebit}/>}
      </DialogContent>
      <DialogActions sx={{display: 'flex', justifyContent: 'right', borderTop: "1px solid", borderColor: "ornament.dark", p: 5}}>
        <SecondaryBigButton
          label={t('participants.remove_money_to_selected_participants.cancel_button')}
          action={closeDialog}
        />
        <PrimaryBigButton
          label={t('participants.remove_money_to_selected_participants.confirm_button')}
          icon={AddOutlinedIcon}
          position={IconPosition.LEFT}
          action={onConfirmMoney}
        />
      </DialogActions>
    </Dialog>
}
