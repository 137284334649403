import React, {useContext, useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {TFunction, useTranslation} from "react-i18next";
import {TOptions} from "i18next";
import {ParticipantsCard} from './customisation/participantsCard';
import {EditorialCard} from './customisation/editorialContentCard';
import RulesCard from "./customisation/rulesCard";
import ShowCaseCard from "./customisation/showCaseCard";
import ShopCard from "./customisation/shopCard";
import PointsCard from "./customisation/pointsCard";
import PageCard from "../../patterns/page/pageCard";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import CongratulationsDialog from "./customisation/congratulationsDialog";
import EmailsContentCard from "./customisation/emailsContentCard";
import {OperationMainPageProps} from "./operationProps";
import JoozyMessage, {JoozyMessageProps} from "../../patterns/message/joozyMessage";
import AddParticipantsBackground from "../../assets/message/add_participants.png";
import BuyPointsBackground from "../../assets/message/buy_points.png";
import GivePointsBackground from "../../assets/message/give_points.png";
import ErrorBackground from "../../assets/message/error.png";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningBackground from "../../assets/message/warning.png";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {Loader} from "../../components/loader/loader";
import {getExportGifts} from '../../services/ImportService';
import {getExportDocument} from '../../common/methods/exportDocument';

export function translate(t: TFunction, text: string, options?: TOptions<any>): string {
  return t("operations.customisation." + text, options);
}

export default function OperationCustomisation(props: OperationMainPageProps) : JSX.Element {
  const {t} = useTranslation();
  useEffect(() => {
    document.title = t("tab_title.operation.customisation");
  }, []);
  const {operation, ongoingTransactionsCount, startProductTour} = props;
  const [store] = useContext<StoreContext>(AppContext);
  const [joozyMessage, setJoozyMessage] = useState<JoozyMessageProps|null>(null);
  const [highlight, setHighlight] = useState<string|null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const HIGHLIGHT_TYPES = {PARTICIPANTS: "participants", POINTS: "points"};

  useEffect(() => {    
    if (operation || ongoingTransactionsCount) {
      let expiration;

      if (operation.expirationDate) {
        expiration = new Date(operation.expirationDate);
        expiration.setDate(expiration.getDate() - 30);
      }

      if (operation.totalRewardAmount > operation.walletAmount) {
        setJoozyMessage({
          background: ErrorBackground,
          message: "negative_balance",
          redirect: operationUrl(PRIVATE_URL.WALLETS_BUY_POINTS, operation.id),
          Icon: ErrorOutlineIcon
        }),
        setHighlight(HIGHLIGHT_TYPES.POINTS);
      } else if (operation.expirationDate && expiration && new Date() >= expiration && operation.participantStats.withReward > 0) {
        setJoozyMessage({
          background: WarningBackground,
          message: "expiration_incoming",
          redirect: operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANTS, operation.id),
          Icon: ReportProblemIcon
        })
        setHighlight(HIGHLIGHT_TYPES.PARTICIPANTS);
      } else if (operation.endDate && new Date() > new Date(operation.endDate)) {
        setJoozyMessage({
          background: ErrorBackground,
          message: "operation_date_passed",
          redirect: operationUrl(PRIVATE_URL.OPERATION_CHARACTERISTICS, operation.id),
          Icon: ReportProblemIcon
        })
      } else if (ongoingTransactionsCount && ongoingTransactionsCount > 0) {
        setJoozyMessage({
          background: AddParticipantsBackground,
          message: ongoingTransactionsCount > 1 ? "ongoing_transactions" : "ongoing_transaction",
          redirect: PRIVATE_URL.WALLETS_ONGOING
        })
        setHighlight(HIGHLIGHT_TYPES.POINTS);
      } else if (operation.participantStats.total == 0) {
        setJoozyMessage({
          background: AddParticipantsBackground,
          message: "add_participants",
          redirect: operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANTS, operation.id)
        })
        setHighlight(HIGHLIGHT_TYPES.PARTICIPANTS);
      } else if (operation.walletAmount == 0) {
        setJoozyMessage({
          background: BuyPointsBackground,
          message: "buy_points",
          redirect: operationUrl(PRIVATE_URL.WALLETS_BUY_POINTS, operation.id)
        })
        setHighlight(HIGHLIGHT_TYPES.POINTS);
      } else if (operation.participantStats.withReward == 0) {
        setJoozyMessage({
          background: GivePointsBackground,
          message: "give_points",
          redirect: operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANTS, operation.id)
        })
        setHighlight(HIGHLIGHT_TYPES.PARTICIPANTS);
      } else if (operation.startDate && new Date() < new Date(operation.startDate)) {
        setJoozyMessage({
          background: GivePointsBackground,
          message: "operation_date_in_the_future",
          redirect: operationUrl(PRIVATE_URL.OPERATION_CHARACTERISTICS, operation.id)
        })
      } else {
        setJoozyMessage(null);
        setLoading(false);
        setHighlight(null);
      }
    }
  }, [operation, ongoingTransactionsCount]);

  const exportAllGifts = (): void => {
    if (operation == null) {
      return;
    }

    getExportGifts(operation.id, "", {field: "", sort: ""}, [])
      .then(response => {
        getExportDocument(response)
      });
  }

  return <>
    {joozyMessage ? <JoozyMessage {...joozyMessage}/> : loading && <Loader sx={{height: "64px"}} />}
    {store.operationWizard.currentStep > store.operationWizard.maximumStep && <CongratulationsDialog startProductTour={startProductTour}/>}
    <Box sx={{display: "grid", gridTemplateColumns: "repeat(3, 1fr)", columnGap: 5, rowGap: 5, mt: 5}}>
      {/** PREMIERE LIGNE */}
      <PageCard
        id="operation-participants"
        title={t("operations.customisation.cards.participants")}
        action={t("operations.customisation.participants.button_empty_label")}
        actionUrl={operationUrl(PRIVATE_URL.LIST_OPERATION_PARTICIPANTS, operation.id)}
        highlight={highlight === HIGHLIGHT_TYPES.PARTICIPANTS}>
        <ParticipantsCard operation={operation}/>
      </PageCard>
      <PageCard
        id="operation-shop"
        title={t("operations.customisation.cards.shop")}
        action={t("operations.customisation.shop.button_empty_label")}
        actionUrl={operationUrl(PRIVATE_URL.OPERATION_SHOP_PRICE, operation.id)}>
        <ShopCard operation={operation}/>
      </PageCard>
      <PageCard
        id="operation-points"
        title={t("operations.customisation.cards.gifts_orders")}
        action={t("operations.customisation.gifts_orders.button_empty_label")}
        actionFunction={operation.orderStats.totalOrderLines > 0 ? exportAllGifts : undefined}
        highlight={highlight === HIGHLIGHT_TYPES.POINTS}>
        <PointsCard operation={operation}/>
      </PageCard>
      {/** DEUXIEME LIGNE */}
      <PageCard
        id="operation-vitrine"
        title={t("operations.customisation.cards.vitrine")}
        action={t("operations.customisation.vitrine.button_empty_label")}
        actionUrl={operationUrl(PRIVATE_URL.OPERATION_SHOWCASE, operation.id)}
        sx={{gridColumn: "1/3"}}>
        <ShowCaseCard operation={operation}/>
      </PageCard>
      <PageCard
        id="operation-emails"
        title={t("operations.customisation.cards.emails")}
        action={t("operations.customisation.emails.button_empty_label")}
        actionUrl={operationUrl(PRIVATE_URL.OPERATION_EMAIL_CUSTOMISATION, operation.id)}
        sx={{justifyContent: "unset"}}>
        <EmailsContentCard operation={operation}/>
      </PageCard>
      {/** TROISIEME LIGNE */}
      <PageCard
        id="operation-edito"
        title={t("operations.customisation.cards.editorial_content")}
        action={t("operations.customisation.presentation.button_label" + (operation.presentation == null ? "_empty" : ""))}
        actionUrl={operationUrl(PRIVATE_URL.OPERATION_PRESENTATION, operation.id)}
        sx={{gridColumn: "1/3"}}>
        <EditorialCard operation={operation} />
      </PageCard>
      <PageCard
        id="operation-rules"
        title={t("operations.customisation.cards.rules")}
        action={t("operations.customisation.rules.button_label" + (operation.rules == null ? "_empty" : ""))}
        actionUrl={operationUrl(PRIVATE_URL.OPERATION_RULES, operation.id)}>
        <RulesCard operation={operation} />
      </PageCard>
    </Box>
  </>
}

