import {Box, Card} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App";
import ElementInputWithLabel from "../../patterns/form/elementInputWithLabel";
import {errorManager, manageStringError, Violation} from "../../common/methods/ApiService";
import {INPUT_TYPES} from "../../patterns/form/formConstants";
import {StoreContext} from "../../common/struct/store";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {putOperation} from "../../services/OperationService";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {CURRENT_OPERATION} from "../../common/methods/context-setter/globals";
import {useNavigate} from "react-router-dom";

export default function OperationMailingSettings(props: {setError: (error: string) => void, setSuccess: (success: string) => void}): JSX.Element {
  const {t} = useTranslation();
  const {setError, setSuccess} = props;
  useEffect(() => {
    document.title = t("tab_title.operation.mailingSettings");
  }, []);
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const [violations, setViolations] = useState<Violation[]>([]);
  const [sender, setSender] = useState(store.global.currentOperation?.mailSender??"");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const saveSettings = (): void => {
    if (store.global.currentOperation == null) {
      return;
    }

    setLoading(true);
    putOperation(store.global.currentOperation.id, {mailSender: sender})
      .then(operation => {
        globalStoreReducer(STORE, {type: CURRENT_OPERATION, operation: operation});
        setSuccess(t("operations.mailing.settings.success"))
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), setError, t);
        setViolations(error);
      })
      .finally(() => setLoading(false))
  }

  const submit = (e: any): void => {
    e.preventDefault();
    saveSettings();
  }

  return (
    <>
      <Card sx={{boxShadow: 1, borderColor: "ornament.dark", mb: 3}}>
        <form onSubmit={submit}>
          <ElementInputWithLabel
            sx={{px: 5, pt: 5}}
            label={t("operations.mailing.settings.sender")}
            name="emailSender"
            value={sender}
            placeholder={store.global.company?.name??""}
            type={INPUT_TYPES.DEFAULT}
            violations={violations}
            updateValue={setSender}
            disabled={loading}
          />
          <Box sx={{display: "flex", justifyContent: "start", width: "100%", height: "88px", px: 5, backgroundColor: "ornament.light", alignItems: "center", boxShadow: "inset 0px 1px 0px #CDCEDE"}}>
            <PrimaryBigButton label={t("form.validate")} loading={loading} submit={true}/>
          </Box>
        </form>
      </Card>
    </>
  )
}
