import React, {useEffect, useState} from 'react';
import {Box, SxProps, Typography, useMediaQuery} from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {useNavigate} from 'react-router-dom';
import {Theme} from "@mui/material/styles";
import SimpleCheck from "../../assets/landingPage/simpleCheck.svg";
import {useTranslation} from "react-i18next";

type SolutionTextProps = {
    category: string,
    title: string,
    line1: string,
    line2: string,
    assets: string[],
    link: string,
    linkSrc: string,
    sx?: SxProps<Theme>
}

export default function SolutionText(props: SolutionTextProps): JSX.Element {

  const navigate = useNavigate();
  const {t} = useTranslation();

  const {category, title, line1, line2, assets, link, linkSrc, sx} = props;

  const [animate, setAnimate] = useState<boolean>(false);
  
  useEffect(() => {
    if (!animate) {
      setTimeout(() => (setAnimate(true)), 6000);
    } else {
      setTimeout(() => (setAnimate(false)), 4000);
    }
  }, [animate])

  const handleNav = (): void => {
    navigate(linkSrc);
  }

  const breakpoint = useMediaQuery('(min-width:1275px)');

  return (
    <Box sx={{...sx}}>
      <Typography variant="h1" color="landing.main" sx={{fontWeight: "600", textTransform: "uppercase", fontSize: "20px", mb: 1}}>{category}</Typography>
      <Typography variant="h2" color="ornament.light" sx={{fontWeight: "600", fontSize: "30px", mb: breakpoint ? 6 : 5, lineHeight: "38px"}}>{title}</Typography>
      <Typography variant="body1" color="ornament.light" sx={{fontWeight: "300", fontSize: "18px", mb: breakpoint ? 5 : 4}}>{line1}</Typography>
      {assets.map((asset, index) => 
        <Box key={index} sx={{display: "flex", alignItems: "center", mb: 2}}>
          {/* @ts-ignore */}
          <img src={SimpleCheck} alt={t("landing_page.alt.check")} style={{height: "20px", margin: "0 20px 0 0"}} />
          <Typography variant="body1" color="ornament.light" sx={{fontWeight: "300", fontSize: "18px"}}>{asset}</Typography>
        </Box>
      )}
      <Typography variant="body1" color="ornament.light" sx={{fontWeight: "300", fontSize: "18px", mt: breakpoint ? 5 : 4, lineHeight: "26px"}}>{line2}</Typography>
      <Box sx={{
        display: "flex", 
        alignItems: "center", 
        cursor: "pointer", 
        mt: 4, 
        animation: `${animate ? "bounce 1s linear" : "none"}`,
        "@keyframes bounce": {
          "0%": {
            transform: "translateX(0)"
          },
          "20%": {
            transform: "translateX(0)"
          },
          "40%": {
            transform: "translateX(20px)"
          },
          "50%": {
            transform: "translateX(5px)"
          },
          "60%": {
            transform: "translateX(15px)"
          },
          "80%": {
            transform: "translateX(0)"
          },
          "100%": {
            transform:  "translateX(0)"
          }
        }
      }} onClick={(): void => handleNav()}>
        <Typography variant="body1" color="landing.main" sx={{fontWeight: "600", fontSize: "18px", mr: 3}}>{link}</Typography>
        <ArrowRightAltIcon sx={{color: "landing.main", height: "32px", mb: "-4px"}} />
      </Box>
    </Box>
  )
}