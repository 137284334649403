import React, {useContext, useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {useLocation, useNavigate} from 'react-router-dom';
import {TCompany} from "../../interfaces/company";
import {getCompany} from "../../services/CompanyService";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {errorManager, RequestFilter, RequestFilterOperator} from "../../common/methods/ApiService";
import {Loader} from "../../components/loader/loader";
import Page from "../../patterns/page/page";
import ListSnackbar, {TAlert} from "../../patterns/list/listSnackbar";
import {addErrorAlert, addSuccessAlert} from "../../patterns/list/listUtils";
import Footer from "../../patterns/footer/footer";
import {WalletsSection} from "./walletsProps";
import {ImportExport, ShoppingCart, Wallet} from "@mui/icons-material";
import {getCompanyWalletTransactionsCount} from "../../services/WalletTransactionService";
import {TWalletTransaction, TWalletTransactionStatus} from "../../interfaces/wallet";
import RefreshIcon from "@mui/icons-material/Refresh";
import WalletsBuyPoints from "./walletsBuyPoints";
import OngoingTransactions from "./ongoingTransactions";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {UPDATE_ALL_WALLETS} from "../../common/methods/context-setter/globals";
import WalletsList from "./walletsList";
import WalletsPointsMovements from "./walletsPointsMovements";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {IconPosition} from "../../components/buttons/buttonProps";
import ArticleIcon from '@mui/icons-material/Article';
import InfoDialog from "../../patterns/list/infoDialog";
import BankDetails from "../payment/bankDetails";

export default function Wallets(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const extractSelectedMenu = (): WalletsSection|null => {
    switch (location.pathname) {
    case PRIVATE_URL.WALLETS_BUY_POINTS:
      return WalletsSection.BUY_POINTS;
    case PRIVATE_URL.WALLETS_ONGOING:
      return WalletsSection.ONGOING;
    case PRIVATE_URL.WALLETS_LIST:
      return WalletsSection.WALLETS;
    case PRIVATE_URL.WALLETS_POINTS_MOVEMENTS:
      return WalletsSection.MOVEMENTS;
    default:
      navigate(PRIVATE_URL.LANDING_PAGE);
      return null;
    }
  }

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const storedCompany = store.global.company;

  const [selectedMenu, setSelectedMenu] = useState(extractSelectedMenu());
  const [company, setCompany] = useState<TCompany|null>(null);
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [ongoingTransactions, setOngoingTransactions] = useState<{totalItems: number, items: TWalletTransaction[]}|null>(null);
  const [openBankInfoDialog, setOpenBankInfoDialog] = useState<boolean>(false);

  useEffect(() => {
    getCompany(storedCompany.id)
      .then(company => {
        
        setCompany(company);
        if (company) {
          globalStoreReducer(STORE, {type: UPDATE_ALL_WALLETS, wallets: company.wallets});
        }
      })
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') addErrorAlert(errorResult, setAlerts);
      })
  }, []);

  const filter: RequestFilter[] = [{field: 'status', items: [{value: TWalletTransactionStatus.ON_GOING, operator: RequestFilterOperator.EQUALS, isDate: false, isDatePrecise: false}]}];

  useEffect(() => {
    if (company) {
      setLoading(true);
      getCompanyWalletTransactionsCount(company.id, filter)
        .then((res) => {          
          if (res.totalItems !== 0) {
            setLoading(false)
            setOngoingTransactions(res)
          }
        }).finally(() => setLoading(false));
    }
  }, [company]);

  useEffect(() => {
    if (location.pathname != selectedMenu) {
      setSelectedMenu(extractSelectedMenu());
    }
  }, [location]);

  const initMenu = [
    {
      icon: ShoppingCart,
      label: 'wallets.buy_points',
      url: PRIVATE_URL.WALLETS_BUY_POINTS
    },
    {
      icon: Wallet,
      label: 'wallets.wallets',
      url: PRIVATE_URL.WALLETS_LIST
    },
    {
      icon: ImportExport,
      label: 'wallets.points_movements.title',
      url: PRIVATE_URL.WALLETS_POINTS_MOVEMENTS
    }
  ];

  useEffect(() => {
    if (ongoingTransactions) {
      setMenus([...menus.slice(0, 1), {
        icon: RefreshIcon,
        label: 'operations.points_balance.ongoing_transactions.title',
        url: PRIVATE_URL.WALLETS_ONGOING
      }, ...menus.slice(1, menus.length)])
    } else {
      setMenus(initMenu);
    }
    setLoading(false);
  }, [ongoingTransactions]);

  const [menus, setMenus] = useState<any>(initMenu);

  const handleBankInfoDialog = (): void => {
    setOpenBankInfoDialog(true);
  }

  const mainButton = <PrimaryBigButton key="showcase-button" label={t("operation_transactions.bank_info.bank_details")}
    icon={ArticleIcon} position={IconPosition.LEFT} action={(): void => handleBankInfoDialog()}/>

  const bankInfoDialog = <InfoDialog open={openBankInfoDialog} close={(): void => setOpenBankInfoDialog(false)} title={t("operation_transactions.bank_info.title_without_amount")}
    action={{title: t("operation_transactions.bank_info.download"), href: "/rib.pdf", newWindow: true}}>
    <Typography variant="body1" color="neutral.dark" sx={{mb: 3, fontWeight: "unset"}}
      dangerouslySetInnerHTML={{__html: t("operation_transactions.bank_info.content")}}/>
    <BankDetails />
  </InfoDialog>

  return company == null || loading ? <Loader /> :
    <Page title={t("wallets.title")} menus={menus} buttons={[mainButton]} noBackLink={true}> 
      <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
      <>
        <Box sx={{p: 5, pb: 3, display: "flex", flexDirection: "column", flex: 1}}>
          {bankInfoDialog}
          {selectedMenu === WalletsSection.BUY_POINTS && <WalletsBuyPoints company={company}/>}
          {selectedMenu === WalletsSection.ONGOING && <OngoingTransactions />}
          {selectedMenu === WalletsSection.WALLETS && <WalletsList company={company} addSuccess={(success: string): void => addSuccessAlert(success, setAlerts)} addError={(error: string): void => addErrorAlert(error, setAlerts)}/>}
          {selectedMenu === WalletsSection.MOVEMENTS && <WalletsPointsMovements company={company} />}
        </Box>
        <Footer/>
      </>
    </Page>
}
