import {
  ADD_ERROR_NOTIFICATION,
  ADD_NOTIFICATION,
  ADD_OPERATION,
  ADD_OPERATIONS,
  CURRENT_OPERATION,
  INIT_STORE,
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  UPDATE_ALL_WALLETS,
  UPDATE_COMPANY,
  UPDATE_EXCLUDED_GIFTS,
  UPDATE_WALLETS
} from './globals'
import {
  AdminInformation,
  CompanyInformation,
  initialStore,
  OperationInformation,
  Store,
  StoreContext
} from "../../struct/store";
import {isSuperAdmin, mustStayConnected} from "../../struct/globalVar";
import {logout} from "../../../services/AuthenticationService";
import {TAdmin} from '../../../interfaces/admin';
import {TOperation} from "../../../interfaces/operation";
import {TCompany, TCompanyWallet} from '../../../interfaces/company';

export interface GlobalAction {
  type: string;
  admin?: TAdmin;
  operations?: TOperation[];
  operation?: TOperation;
  operationId?: string;
  company?: TCompany;
  notification?: string;
  excludedGifts?: number[];
  wallet?: TCompanyWallet;
  wallets?: TCompanyWallet[];
  method?: string;
}

export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";

export function globalStoreReducer(STORE: StoreContext, action: GlobalAction) : void {
  const [, setStore] = STORE;
  switch (action.type) {
  case INIT_STORE: {
    setStore(initialStore);
    return;
  }
  case LOGOUT: {
    logout();
    setStore(initialStore);
    return;
  }
  case LOGIN: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        loggedIn: true,
        superAdmin: isSuperAdmin(),
        stayConnected: mustStayConnected(),
        admin: action.admin ? extractAdminInformation(action.admin) : previousStore.global.admin,
        company: action.admin ? extractCompanyInformation(action.admin.company) : previousStore.global.company,
        notification: action.notification??undefined
      }
    }));
    return;
  }
  case RESET_PASSWORD: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        resetDone: true
      }
    }));
    return;
  }
  case ADD_OPERATIONS: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        operations: action.operations ? extractOperationsProps(action.operations) : previousStore.global.operations
      }
    }));
    return;
  }
  case ADD_OPERATION: {
    const operation = action.operation;
    if (!operation) {
      return;
    }

    setStore((previousStore: Store) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        operations: addOperation(previousStore, operation),
        currentOperation: operation
      }
    }));
    return;
  }
  case UPDATE_EXCLUDED_GIFTS: {
    const excludedGifts = action.excludedGifts;
    if (!excludedGifts) {
      return;
    }

    setStore((previousStore: Store) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        currentOperation: previousStore.global.currentOperation ? {
          ...previousStore.global.currentOperation,
          shops: [
            {
              ...previousStore.global.currentOperation.shops[0],
              excludedGiftIds: excludedGifts
            }
          ]
        } : undefined
      }
    }));
    return;
  }
  case CURRENT_OPERATION: {
    const operation = action.operation;

    setStore((previousStore: Store) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        currentOperation: operation??undefined
      }
    }));
    return;
  }
  case ADD_NOTIFICATION: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        notification: action.notification
      }
    }));
    return;
  }
  case ADD_ERROR_NOTIFICATION: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        errorNotification: action.notification
      }
    }));
    return;
  }
  case UPDATE_WALLETS: {
    const wallet = action.wallet;
    const method = action.method;
    if (!wallet || !method) {
      return;
    }

    if (method == CREATE) {
      setStore((previousStore: Store) => ({
        ...previousStore,
        global: {
          ...previousStore.global,
          company: {
            ...previousStore.global.company,
            wallets: [
              ...previousStore.global.company.wallets,
              wallet
            ]
          }
        }
      }));
    } else if (method == DELETE) {
      setStore((previousStore: Store) => ({
        ...previousStore,
        global: {
          ...previousStore.global,
          company: {
            ...previousStore.global.company,
            wallets: [
              ...previousStore.global.company.wallets.filter((item) => {
                return item.id !== wallet.id
              })
            ]
          }
        }
      }));
    } else if (method == UPDATE) {
      setStore((previousStore: Store) => ({
        ...previousStore,
        global: {
          ...previousStore.global,
          company: {
            ...previousStore.global.company,
            wallets: [
              ...previousStore.global.company.wallets.map((item) => {
                if (item.id != wallet.id) {
                  return item;
                } else {
                  return wallet;
                }
              })
            ]
          }
        }
      }));
    }
    return;
  }
  case UPDATE_ALL_WALLETS: {
    const wallets = action.wallets;
    if (!wallets) {
      return;
    }

    setStore((previousStore: Store) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        company: {
          ...previousStore.global.company,
          wallets : wallets
        }
      }
    }));
    return;
  }
  case UPDATE_COMPANY: {
    const company = action.company;
    if (!company) {
      return;
    }

    setStore((previousStore: Store) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        company: company
      }
    }));
    return;
  }
  default:
    return;
  }
}

function extractAdminInformation(admin: TAdmin): AdminInformation {
  return {
    id: admin.id,
    firstName: admin.firstName,
    lastName: admin.lastName,
    email: admin.email,
    phone: admin.phone,
    job: admin.job,
    invoiceAddress: admin.invoiceAddress,
    participantColumnsOrder: admin.participantColumnsOrder,
    participantColumnsStatus: admin.participantColumnsStatus,
    emailConfirmationDate: admin.emailConfirmationDate
  }
}

function extractCompanyInformation(company: TCompany): CompanyInformation {
  return {
    id: company.id,
    name: company.name,
    wallets: company.wallets,
    defaultWallet: company.defaultWallet,
    expiredPointsWallet: company.expiredPointsWallet,
    euroPerPoint: company.euroPerPoint
  }
}

function extractOperationsProps(operations: TOperation[]): OperationInformation[] {
  return operations.map(operation => {
    return {id: operation.id, name: operation.name};
  });
}

function addOperation(store: Store, operation: TOperation): OperationInformation[] {
  const operations = store.global.operations;
  const oldOperation = operations.find((op: OperationInformation) => op.id == operation.id);
  if (oldOperation) {
    operations.splice(operations.indexOf(oldOperation), 1);
  }
  operations.unshift({id: operation.id, name: operation.name});
  return operations;
}

export default {globalStoreReducer};
