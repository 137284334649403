import {TCompanyAware, TLifecycle} from "./traits";
import {TOperation} from "./operation";

export enum TEmailTemplateType {
  PARTICIPANT_INVITATION = 'PARTICIPANT_INVITATION',
  ACCOUNT_VALIDATION = 'ACCOUNT_VALIDATION',
  RESET_ADMIN_PASSWORD = 'RESET_ADMIN_PASSWORD',
  RESET_PARTICIPANT_PASSWORD = 'RESET_PARTICIPANT_PASSWORD'
}

export interface TEmailTemplate extends TLifecycle, TCompanyAware {
  templateType: TEmailTemplateType;
  mailjetId: number;
  operation: TOperation;
  subject?: string;
  extraVariables: any;
}

export interface TEmailTemplateCreate {
  templateType: TEmailTemplateType;
  mailjetId: number;
  operation: string;
  subject?: string | null;
  extraVariables: any;
}

export interface TEmailTemplateUpdate extends TLifecycle, TCompanyAware {
  templateType: TEmailTemplateType;
  mailjetId: number;
  operation: string;
}

export interface TEmailSentResponse {
  sent: TEmailSent;
  errors: any[];
}

export interface TEmailSent {
  count: number;
  participants: string[];
  errors: string[];
}

export interface TEmailForListing extends TLifecycle {
  id: string;
  type: string;
  participant: string;
  status: TEMailStatus;
  sendAt: string;
}

export interface TPlannedEmail extends TLifecycle {
  id: string;
  emailCount: number;
  sendAt: string;
}

export enum TEMailStatus {
  JZ_PENDING = 'jz_0',
  JZ_CURRENT = 'jz_3',
  MJ_OPEN = 'mj_0',
  MJ_CLICK = 'mj_1',
  MJ_BOUNCE = 'mj_2',
  MJ_SPAM = 'mj_3',
  MJ_BLOCKED = 'mj_4',
  MJ_UNSUB = 'mj_5',
  SENT = 'sent',
  UNKNOWN = 'unknown',
}
