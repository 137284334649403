import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import moment from "moment";
import {Avatar, Box, Button, Card, Divider, Typography} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {GhostButton} from "../../components/buttons/mainButton";
import {getColors, LIBERTY_THEME} from "../../tokens/libertyTheme";
import {TOperation} from "../../interfaces/operation";
import TipsButton from "../../components/buttons/tipsButton";
import TipsSmileButton from "../../components/buttons/tipsSmileButton";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";

export default function OperationBand(props: {operation: TOperation, index: number, activateTriggerProductTour?: () => void}): JSX.Element {
  const {t} = useTranslation();
  const {operation, index, activateTriggerProductTour} = props;

  function getDate(date: string|undefined): string {
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    }
    return '-';
  }

  function extractOperationColor(): string {
    return getColors()[index % 18];
  }

  return (
    <Card id="operation-band" sx={{display: "flex", backgroundColor: "ornament.light", boxShadow: 2, p: 3}}>
      <Avatar sx={{height: "82px", width: "82px", fontWeight: "medium", fontSize: "28px", backgroundColor: extractOperationColor()}} variant="rounded">
        {operation.name.substring(0, 1).toUpperCase()}
      </Avatar>
      <Box sx={{display: "flex", flexDirection: "column", width: "100%", ml: 3}}>
        <Box sx={{display: "flex", justifyContent: "space-between", mb: 0}}>
          <Typography variant="h1" sx={{fontWeight: "medium"}}>{operation.name}</Typography>
          <Link to={operationUrl(PRIVATE_URL.OPERATION_CHARACTERISTICS, operation.id)} style={{width: "inherit"}}><GhostButton label={t('operations.main_page.update')}/></Link>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Box sx={{display: "flex", width: "100%", justifyContent: "space-between"}}>
            <Box sx={{display: "flex", mt: 2}}>
              <Box sx={{display: "flex", alignItems: "center"}}>
                <Typography display="inline" variant="body2" color="neutral.main">{t('operations.main_page.period.from')}</Typography>
                <Typography display="inline" variant="body2" sx={{fontWeight: "medium", ml: 0}}>{getDate(operation.startDate)}</Typography>
                <Typography display="inline" variant="body2" sx={{ml: 0}} color="neutral.main">{t('operations.main_page.period.to')}</Typography>
                <Typography display="inline" variant="body2" sx={{fontWeight: "medium", ml: 0}}>{getDate(operation.endDate)}</Typography>
              </Box>
              <Divider sx={{mx: 3, my: "auto", backgroundColor: "ornament.dark", borderColor: "ornament.dark", width: "0.5px", height: 12}} orientation="vertical" />
              <Box sx={{display: "flex", alignItems: "center"}}>
                <Typography display="inline" variant="body2" color="neutral.main">{t('operations.main_page.expiration')}</Typography>
                <Typography display="inline" variant="body2" sx={{fontWeight: "medium", ml: 0}}>{getDate(operation.expirationDate)}</Typography>
              </Box>
              <Divider sx={{mx: 3, my: "auto", backgroundColor: "ornament.dark", borderColor: "ornament.dark", width: "0.5px", height: 12}} orientation="vertical" />
              <Button href={'//' + operation.hostname} target="_blank" sx={{margin: "0 !important", padding: "0 !important", color: "info.dark", textTransform: "none", fontSize: LIBERTY_THEME.typography.body2.fontSize,
                fontWeight: "medium", ":hover": {backgroundColor: "transparent"}}} endIcon={<OpenInNewIcon/>}>
                {operation.hostname}
              </Button>
            </Box>
            {activateTriggerProductTour && <Box sx={{display: "flex", alignItems: "center"}}>
              <TipsButton action={activateTriggerProductTour}/>
              <TipsSmileButton action={activateTriggerProductTour} sx={{ml: 1}}/>
            </Box>}
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
