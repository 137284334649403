import {API_URL, companyUrl} from "../common/struct/urlManager";
import {
  bodyWithBlob,
  bodyWithFile,
  del,
  get,
  getBlob,
  getPaginated,
  post,
  postWithFile,
  put,
  RequestFilter,
  RequestFilterOperator,
  RequestOrder
} from "../common/methods/ApiService";
import {TOperation, TOperationCreate, TOperationLite, TOperationUpdate} from "../interfaces/operation";
import {TOperationParticipant, TOperationParticipantForListing, TOperationParticipantToken} from "../interfaces/operationParticipant";
import {TEmailForListing, TEmailSentResponse, TPlannedEmail} from "../interfaces/email";
import {TPaymentIntent} from "../interfaces/payment";
import {TWalletTransaction, TWalletTransactionOption} from "../interfaces/wallet";
import {CropSettingsProps} from "../patterns/dialog/cropImageElement";
import moment from "moment";
import {TAdminToken} from "../interfaces/adminToken";

export async function getAllOperationsForAdmin(): Promise<TOperationLite[]> {
  return get(API_URL.ALL_OPERATION);
}

export async function getAllOperations(): Promise<TOperation[]> {
  return get(API_URL.OPERATION, {field: "updatedAt", sort: "DESC"});
}

export async function getOperation(operationId: string): Promise<TOperation> {
  return get(`${API_URL.OPERATION}/${operationId}`);
}

export async function deleteOperation(operationId: string): Promise<void> {
  return del(API_URL.OPERATION, operationId);
}

export async function getOperationParticipant(operationId: string, participantId: string): Promise<TOperationParticipant> {
  return get(`${API_URL.OPERATION}/${operationId}/operationparticipants/${participantId}`);
}

export async function getOperationParticipants(operationId: string, page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TOperationParticipant[]}> {
  return getPaginated(`${API_URL.OPERATION}/${operationId}/operationparticipants`, page, itemsPerPage, search, order, filters);
}

export async function getDeletedOperationParticipants(operationId: string, page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TOperationParticipantForListing[]}> {
  return getPaginated(`${API_URL.OPERATION}/${operationId}/operationparticipantsdeleted`, page, itemsPerPage, search, order, filters);
}

export async function getEmailsHistory(operationId: string, page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TEmailForListing[]}> {
  return getPaginated(`${API_URL.OPERATION}/${operationId}/email-history`, page, itemsPerPage, search, order, filters);
}

export async function getEmailsPlanned(operationId: string, page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TPlannedEmail[]}> {
  return getPaginated(`${API_URL.OPERATION}/${operationId}/planned-emails`, page, itemsPerPage, search, order, filters);
}

export async function getEmailsPlannedDetails(operationId: string, sendAt: string, page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TEmailForListing[]}> {
  filters.push({field: "sendAt", items: [{isDate: true, isDatePrecise: true, operator: RequestFilterOperator.IS, value: sendAt}]});
  return getPaginated(`${API_URL.OPERATION}/${operationId}/planned-emails/details`, page, itemsPerPage, search, order, filters);
}

export async function deletePlannedEmail(plannedEmailId: string): Promise<void> {
  return del(`emails`, plannedEmailId);
}

export async function updatePlannedEmails(operationId: string, sendAt: string, requestedSendAt: string): Promise<void> {
  return post(`${API_URL.OPERATION}/${operationId}/planned-emails/${moment.utc(moment(requestedSendAt)).format("YYYY-MM-DDTHH:mm")}`, {sendAt: sendAt});
}

export async function cancelPlannedEmails(operationId: string, sendAt: string): Promise<void> {
  return post(`${API_URL.OPERATION}/${operationId}/planned-emails/cancel`, {sendAt: sendAt});
}

export async function updatePlannedEmail(plannedEmailId: string, sendAt: string): Promise<TEmailForListing> {
  return put(`emails`, plannedEmailId, {sendAt: sendAt});
}

export async function getGiftsHistory(operationId: string, page: number, itemsPerPage: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: any[]}> {
  return getPaginated(`${API_URL.OPERATION}/${operationId}/orderLines`, page, itemsPerPage, search, order, filters);
}

export async function putOperation(operationId: string, entity: TOperationUpdate): Promise<TOperation> {
  return put(API_URL.OPERATION, operationId, entity);
}

export async function createOperation(operation: TOperationCreate): Promise<TOperation> {
  return post(API_URL.OPERATION, operation);
}

export async function uploadImageFile(operationId: string, blob: Blob): Promise<{url: string}> {
  return postWithFile(`${API_URL.OPERATION}/${operationId}/upload-image-file`, bodyWithBlob(blob));
}

export async function uploadImageUrl(operationId: string, url: string): Promise<{url: string}> {
  return post(`${API_URL.OPERATION}/${operationId}/upload-image-url`, {fileUrl: url});
}

export async function uploadFile(operationId: string, file: File, type: string, save?: boolean, settings?: CropSettingsProps): Promise<TOperation> {
  return postWithFile(`${API_URL.OPERATION}/${operationId}/upload-file`, bodyWithFile(file, {"operation": operationId, "type": type, "save": save ?? false, "settings": JSON.stringify(settings) ?? null}));
}

export async function deleteLogo(operationId: string): Promise<TOperation> {
  return put(`${API_URL.OPERATION}/${operationId}`, "delete-logo", null);
}

export async function deleteBanner(operationId: string): Promise<TOperation> {
  return put(`${API_URL.OPERATION}/${operationId}`, "delete-banner", null);
}

export async function deleteFavicon(operationId: string): Promise<TOperation> {
  return put(`${API_URL.OPERATION}/${operationId}`, "delete-favicon", null);
}

export async function sendEmailInvitationToParticipant(operationId: string, participantId: string): Promise<TOperationParticipant> {
  return post(`${API_URL.OPERATION}/${operationId}/participants/${participantId}/invite-by-email`);
}

export async function sendEmailInvitationsToUninvitedParticipants(operationId: string, sendAt: string): Promise<TEmailSentResponse> {
  const filters = [{field: "invitationEmailDate", items: [{isDate: true, isDatePrecise: false, operator: RequestFilterOperator.IS, value: "NULL"}]}];
  return sendEmailInvitationsToParticipants(operationId, "", filters, sendAt);
}

export async function sendTestEmailInvitation(operationId: string): Promise<TEmailSentResponse> {
  return post(`${API_URL.OPERATION}/${operationId}/participants/send-test-email`);
}

export async function sendEmailInvitationsToParticipants(operationId: string, search: string, filters: RequestFilter[], sendAt: string): Promise<TEmailSentResponse> {
  if (sendAt !== "now") {
    sendAt = moment.utc(moment(sendAt)).format("YYYY-MM-DD HH:mm");
  }
  return post(`${API_URL.OPERATION}/${operationId}/participants/invite-by-email/${sendAt}`, null, search, filters);
}

export async function initStripePayment(amount: string, companyId: string): Promise<TPaymentIntent> {
  return get(companyUrl(API_URL.OPERATION_STRIPE_INTENT, companyId) + "?amount=" + amount);
}

export async function paymentTransactionByCb(paymentIntent: string, companyId: string, transactionId?: string, walletId?: string): Promise<TWalletTransaction> {
  if (!transactionId && walletId) {
    return post(companyUrl(API_URL.OPERATION_PAYMENT_CB, companyId) + "?paymentIntent=" + paymentIntent + "&walletId=" + walletId, {});
  } else {
    return post(companyUrl(API_URL.OPERATION_PAYMENT_CB, companyId) + "?paymentIntent=" + paymentIntent + "&transactionId=" + transactionId, {});
  }
}

export async function initWalletTransactionPaymentForTransfer(amount: string, reference: string|null, walletId: string): Promise<TWalletTransaction> {
  return post(API_URL.WALLET_TRANSACTIONS, {amount: amount, reference: reference, option: TWalletTransactionOption.TRANSFER, wallet: '/bo/wallets/'+walletId});
}

export async function updateTransactionReference(id: string, reference: string|null): Promise<void> {
  return put(API_URL.WALLET_TRANSACTIONS, id, {reference: reference});
}

export async function downloadTransactionInvoice(transactionId: string): Promise<any> {
  return getBlob(`wallettransactions/${transactionId}/download-invoice`);
}

export async function downloadTransactionProFormaInvoice(transactionId: string): Promise<any> {
  return getBlob(`wallettransactions/${transactionId}/download-pro-forma-invoice`);
}

export async function downloadTransactionOrderForm(transactionId: string): Promise<any> {
  return getBlob(`wallettransactions/${transactionId}/download-order-form`);
}

export async function cancelTransaction(transactionId: string): Promise<any> {
  return post(`wallettransactions/${transactionId}/cancel`);
}

export async function setSavDateParticipant(operationParticipantId: string, savLimitDate: string): Promise<TOperationParticipant> {
  return put(`${API_URL.OPERATION_PARTICIPANTS}/${operationParticipantId}`, "update-sav", {savLimitDate: savLimitDate});
}

export async function updateBlockOrderToParticipants(operationId: string, blockOrder: boolean, search: string, filters: RequestFilter[]): Promise<TOperationParticipant> {
  return post(`${API_URL.OPERATION}/${operationId}/block-order/${blockOrder}`, null, search, filters);
}

export async function getOperationParticipantToken(operationParticipantId: string): Promise<TOperationParticipantToken> {
  return get(`operationparticipants/${operationParticipantId}/token`);
}

export async function getPreviewToken(operationId: string): Promise<TAdminToken> {
  return get(`${API_URL.OPERATION}/${operationId}/preview-token`);
}
