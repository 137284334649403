import React from "react";
import {Box, Card, Typography} from "@mui/material";
import {GhostButton} from "../../components/buttons/mainButton";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import Bounce from "../bounce/bounce";

interface PageCardProps {
  title: string;
  id: string;
  children: JSX.Element|JSX.Element[];
  action?: string;
  actionUrl?: string;
  actionFunction?: () => void;
  highlight?: boolean;
  sx?: SxProps<Theme>;
}

export default function PageCard(props: PageCardProps): JSX.Element {
  const {title, id, action, actionUrl, actionFunction, children, highlight, sx} = props;

  const cardElement = <Card id={id} sx={{p: 3, display: "flex", height: "auto", flexDirection: "column", justifyContent: "space-between", ":hover": {boxShadow: 3, ".MuiButton-root": {backgroundColor: "primary.main", color: "ornament.light"}}, ...sx}}>
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", pb: 2}}>
      <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", py: 0}}>
        {title}
      </Typography>
      {action && actionUrl && <GhostButton label={action} href={actionUrl}/>}
      {action && actionFunction && <GhostButton label={action} action={actionFunction}/>}
    </Box>
    {children}
  </Card>

  return (
    highlight ? 
      <Bounce sx={{borderColor: "primary.main", borderWidth: "2px", backgroundColor: "primaryLight.light", borderStyle: "solid", borderRadius: "4px", ".MuiCard-root": {backgroundColor: "primaryLight.light", boxShadow: "none"}}}>{cardElement}</Bounce>
      : cardElement
  )
}
