import React from 'react';
import {Box, SxProps, Typography} from '@mui/material';
import {Theme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

type VitrineStepProps = {
    step: string,
    title: string,
    lines: string[],
    italicLine: string,
    buttonLabel: string,
    buttonLink: string,
    image: any,
    reverse?: boolean,
    sx?: SxProps<Theme>
}

export default function VitrineStep(props: VitrineStepProps): JSX.Element {

  const {t} = useTranslation();

  const {step, title, lines, italicLine, image, reverse = false, sx} = props;

  return (
    <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", ...sx}}>
      <Box sx={{width: "45%", display: "flex", justifyContent: "center", flexDirection: "column", order: reverse ? 2 : 1}}>
        <Typography variant="h1" color="landing.main" sx={{fontWeight: "600", textTransform: "uppercase", fontSize: "20px"}}>{step}</Typography>
        <Typography variant="h2" color="ornament.light" sx={{fontWeight: "600", fontSize: "30px", mb: 6, lineHeight: "38px"}}>{title}</Typography>
        <Box sx={{mb: 4}}>
          {lines.map((line, index) => 
            <Typography key={index} variant="body1" color="ornament.light" sx={{fontWeight: "300", fontSize: "18px"}}>{line}</Typography>
          )}
        </Box>
        <Typography variant="body1" color="landing.main" sx={{fontWeight: "300", fontSize: "18px", fontStyle: "italic"}}>{italicLine}</Typography>
      </Box>
      <Box sx={{width: "45%", order: reverse ? 1 : 2}}>
        <img src={image} alt={t("landing_page.alt.solution")} style={{width: "100%"}} />
      </Box>
    </Box>
  )
}