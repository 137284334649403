import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState} from "react";
import InfoIcon from "@mui/icons-material/Info";
import {useTranslation} from "react-i18next";
import {TOperation} from "../../interfaces/operation";
import {PrimaryBigButton, SecondaryBigButton} from "../../components/buttons/mainButton";

type SendTestEmailProps = {
  openDialog: boolean,
  closeDialog: () => void,
  sendMail: () => Promise<void>,
  operation?: TOperation
}

export default function sendTestEmail(props: SendTestEmailProps): JSX.Element {
  const {t} = useTranslation();
  const {openDialog, closeDialog, sendMail} = props;
  const [loading, setLoading] = useState(false);

  const sendTestEmail = (): void => {
    setLoading(true);
    sendMail().finally(() => {
      setLoading(false);
      closeDialog();
    })
  }

  return (
    <Dialog open={openDialog} onClose={closeDialog} sx={{display: "flex", justifyContent: "center"}}>
      <Divider sx={{height: 4, backgroundColor: "info.main", borderColor: "info.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <InfoIcon sx={{color: "info.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" sx={{fontWeight: "bold"}}>{t("participants.invitation.send_all_title")}</Typography>
        </Box>
        <IconButton onClick={closeDialog} sx={{padding: "0 0 0 0", width: "24px", height: "24x", maxHeight: "24px"}}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1" sx={{mb: 3}} dangerouslySetInnerHTML={{__html: t("participants.invitation.send_about_to_send_test")}} />
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <SecondaryBigButton sx={{mr: 0}} label={t("participants.invitation.send_cancel_button")} action={closeDialog} disabled={loading}/>
        <PrimaryBigButton action={sendTestEmail} sx={{ml: 2}} label={t("participants.invitation.send_confirmation_button")} loading={loading} />
      </DialogActions>
    </Dialog>
  );
}
