import React, {useContext} from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import {Avatar, Box, Card, Typography} from "@mui/material";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../App";
import {globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {LOGOUT} from "../../common/methods/context-setter/globals";
import {StoreContext} from "../../common/struct/store";
import {FOCUS_COLOR, LibertyTheme} from "../../tokens/libertyTheme";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const UserProfile = (): JSX.Element => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const admin = store.global.admin;

  const [anchorEl, setAnchorEl] = React.useState<null|HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const goToUserSettings = (): void => {
    handleClose();
    navigate(PRIVATE_URL.USER_SETTINGS);
  }

  return (
    <Box>
      <Card sx={{display: "flex", alignItems: "center", justifyContent: "space-between", boxShadow: 0, p: 1, borderStyle: "solid", borderColor: FOCUS_COLOR,
        cursor: "pointer", ":hover": {backgroundColor: "secondary.main"}, ":focus": {backgroundColor: "secondaryLight.dark", borderWidth: "1px"},
        backgroundColor: anchorEl ? "secondaryLight.dark" : "transparent", borderWidth: anchorEl ? "1px" : 0}} onClick={handleClick}>
        <Box sx={{display: "flex", alignItems: "center", mr: 1}}>
          <Avatar variant="circular" sx={{width: 24, height: 24, fontSize: LibertyTheme.typography.body2.fontSize, fontWeight: "medium",
            backgroundColor: "secondaryLight.main", color: "neutral.dark", mr: 1}}>
            {admin.firstName.charAt(0).toUpperCase()}
          </Avatar>
          <Typography variant="body2" color="secondaryLight.light" sx={{fontWeight: "medium"}}>
            {admin.firstName}
          </Typography>
        </Box>
        <ChevronRightIcon sx={{width: 18, height: 18, color: "secondaryLight.light"}}/>
      </Card>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'center', horizontal: 'left'}}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={goToUserSettings}>
          <SettingsIcon sx={{mr: 1}}/>
          <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium"}}>
            {t("layout_sideBar.personals_parameters")}
          </Typography>
        </MenuItem>
        <MenuItem onClick={(): void => globalStoreReducer(STORE, {type: LOGOUT})}>
          <LogoutIcon sx={{mr: 1}}/>
          <Typography variant="body2" color="neutral.dark" sx={{fontWeight: "medium"}}>
            {t("layout_sideBar.log_out")}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
