import React from 'react';
import {Box, Typography} from "@mui/material";
import BackLinkWithoutText from "../navigation/backLinkWithoutText";
import ThirdLevelMenu, {ThirdMenuProps} from "../menu/thirdLevelMenu";

interface PageProps {
  title: string;
  children: JSX.Element|JSX.Element[];
  backLink?: string;
  buttons?: JSX.Element[];
  menus?: ThirdMenuProps[];
  noBackLink?: boolean;
}

export default function Page(props: PageProps): JSX.Element {
  const {title, children, backLink, buttons, menus, noBackLink = false} = props;

  return (
    <Box sx={{minHeight: "100vh", backgroundColor: "ornament.main", display: "flex", flexDirection: "column", overflowY: "auto"}}>
      <Box sx={{display: "flex", flexDirection: "column", px: 7, backgroundColor: "ornament.light", boxShadow: 1}}>
        <Box sx={{display: "flex", alignItems: "center", pt: 5, pb: 6, justifyContent: buttons ? "space-between" : "start"}}>
          <Box sx={{display: "flex", alignItems: "center", py: 1}}>
            {!noBackLink && <BackLinkWithoutText to={backLink}/>}
            <Typography variant="h1" sx={{ml: !noBackLink ? 3 : "0px", fontWeight: "bold", color: "neutral.dark"}}>
              {title}
            </Typography>
          </Box>
          {buttons && <Box sx={{display: "flex", alignItems: "center", ":not(:first-of-type)": {ml: 2}}}>
            {buttons}
          </Box>}
        </Box>
        {menus && <ThirdLevelMenu sx={{mx: "0px"}} menus={menus}/>}
      </Box>
      {children}
    </Box>
  )
}
