import React, {useContext, useEffect, useState} from 'react';
import {RequestFilter, RequestOrder, manageStringError} from "../../common/methods/ApiService";
import {useTranslation} from "react-i18next";
import List from "../../patterns/list/list";
import {GridEnrichedColDef, GridValueGetterParams, useGridApiRef} from "@mui/x-data-grid-pro";
import {actionColumn, addSuccessAlert, columnDefinition, ColumnType, gridAction} from "../../patterns/list/listUtils";
import emptyTransactions from "../../assets/lists/empty_transactions.svg";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography} from "@mui/material";
import {TAlert} from "../../patterns/list/listSnackbar";
import {deleteWallet, getCompanyWallets, renameWallets, transferWallet} from '../../services/WalletService';
import {TCompany, TCompanyWallet} from '../../interfaces/company';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {PrimaryBigButton, PrimarySmallButton, SecondaryBigButton} from '../../components/buttons/mainButton';
import {IconPosition} from '../../components/buttons/buttonProps';
import Panel, {PanelSize} from '../../patterns/panel/panel';
import WalletForm from './walletForm';
import TransferDialog from './walletTransferDialog';
import DeleteDialog from '../../patterns/dialog/deleteDialog';
import {StoreContext} from '../../common/struct/store';
import {AppContext} from '../../App';
import {CREATE, DELETE, UPDATE, globalStoreReducer} from '../../common/methods/context-setter/globalStoreReducer';
import {UPDATE_WALLETS} from '../../common/methods/context-setter/globals';
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from 'react-router';
import {PRIVATE_URL} from '../../common/struct/urlManager';
import ImportExportIcon from '@mui/icons-material/ImportExport';

export default function WalletsList(props: { company: TCompany, addSuccess: (success: string) => void, addError: (error: string) => void}): JSX.Element {
  const {company, addSuccess, addError} = props;
  const {t} = useTranslation(); 
  const apiRef = useGridApiRef();
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [openCreateWalletPanel, setOpenCreateWalletPanel] = useState<boolean>(false);
  const [wallet, setWallet] = useState<TCompanyWallet|null>(null);
  const [newWallet, setNewWallet] = useState<string|null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);
  const [openTransferDialog, setOpenTransferDialog] = useState<boolean>(false);
  const [transferAmount, setTransferAmount] = useState<number>(0);
  const filters = {items: []};

  useEffect(() => {
    document.title = t("tab_title.operation.wallets.list");
  }, []);

  useEffect(() => {
    if (wallet) {
      setTransferAmount(wallet.balance);
    }
  }, [wallet]);
  
  const closeCreatePanel = (): void => setOpenCreateWalletPanel(false) 

  const openPanel = (): void => {       
    if (!store.global.company.wallets.map((wallet) => wallet.balance >= 0).every(element => element === true)) {
      setOpenWarningDialog(true);
    } else {
      setOpenCreateWalletPanel(true);
    }
  }

  const actionPostWallet = (wallet: TCompanyWallet): void => {
    setReload(true);
    closeCreatePanel();
    globalStoreReducer(STORE, {type: UPDATE_WALLETS, method: CREATE, wallet: wallet})
    addSuccessAlert(t("wallets.actions.create_success", {name: wallet.name}), setAlerts);
    setWallet(null);
  }

  const actionUpdateWallet = (wallet: TCompanyWallet): void => {
    setReload(true);
    closeCreatePanel();
    globalStoreReducer(STORE, {type: UPDATE_WALLETS, method: UPDATE, wallet: wallet})
    addSuccessAlert(t("wallets.actions.update_success", {name: wallet.name}), setAlerts);
    setWallet(null);
  }

  const actionDeleteWallet = (): Promise<void> => {        
    if (wallet == null || newWallet == null) {
      return Promise.resolve();
    }

    return deleteWallet(wallet.id, wallet.balance > 0 ? newWallet!.replace('/bo/wallets/','') : undefined)
      .then(() => {
        setReload(true);
        globalStoreReducer(STORE, {type: UPDATE_WALLETS, method: DELETE, wallet: wallet})
        addSuccess(t("wallets.actions.delete_success", {"name": wallet.name}));
        setWallet(null);
        setNewWallet(null);
      })
      .catch(error => manageStringError(error, addError, t))
      .finally(() => setOpenDeleteDialog(false))
  }

  const actionTransferWallet = (): Promise<void> => {        
    if (wallet == null || newWallet == null || transferAmount <= 0) {
      return Promise.resolve();
    }

    return transferWallet(company.id, wallet.id, newWallet!.replace('/bo/wallets/',''), transferAmount.toString())
      .then(() => {
        setReload(true);
        addSuccess(t("wallets.actions.delete_success", {"name": wallet.name}));
        setWallet(null);
        setNewWallet(null);
      })
      .catch(error => manageStringError(error, addError, t))
      .finally(() => setOpenTransferDialog(false))
  }

  const loadTransactions = (page: number, maxItems: number, search: string, order: RequestOrder, filters: RequestFilter[]): Promise<{totalItems: number, items: TCompanyWallet[]}> => {
    return getCompanyWallets(company.id, page, maxItems, search, order, filters).then((res) => {
      return {totalItems: res.totalItems, items: renameWallets(company, res.items, t)};
    });
  }

  const createdByNameColumn = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "wallets", "createdBy", 250, ColumnType.STRING, false),
      valueGetter: (item: GridValueGetterParams): string => {
        const createdBy = item.row.createdBy;
        return createdBy ? createdBy.name : "Joozy"
      }
    }
  }

  const balanceColumn = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "wallets", "balance", 200, ColumnType.EUROS, false),
      valueGetter: (item: GridValueGetterParams): string => {
        return (Math.round(item.row.balance*100)/100).toString();
      }
    }
  }

  const totalEurosColumn = (): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "wallets", "totalEuros", 250, ColumnType.EUROS, false),
      valueGetter: (item: GridValueGetterParams): string => {
        return (Math.round(item.row.totalEuros*100)/100).toString();
      }
    }
  }

  const createWalletPanelTitle = wallet ? t("wallets.update.title") : t("wallets.create.title");

  const createAction = <PrimarySmallButton key="create-wallet" label={t("wallets.create.title")} icon={AddIcon} sx={{mr: 1}}
    position={IconPosition.LEFT} action={openPanel}/>

  const createWalletPanel = openCreateWalletPanel ? <Panel key="create-wallet-panel-template" title={createWalletPanelTitle} size={PanelSize.SMALL} open={openCreateWalletPanel} close={(): void => { setOpenCreateWalletPanel(false), setWallet(null) }}>
    <WalletForm onSuccess={wallet ? actionUpdateWallet : actionPostWallet} wallet={wallet}/>
  </Panel> : <></>

  const columns = [
    columnDefinition(t, "wallets", "name", 300, ColumnType.STRING, false),
    balanceColumn(),
    totalEurosColumn(),
    columnDefinition(t, "wallets", "description", 380, ColumnType.STRING, false),
    createdByNameColumn(),
    columnDefinition(t, "wallets", "createdAt", 200, ColumnType.DATE_TIME),
    actionColumn(params => [
      gridAction(params, "transfer", t("wallets.actions.transfer"), ImportExportIcon, (wallet: TCompanyWallet): void => {
        setWallet(wallet);
        setOpenTransferDialog(true);
      }, false, false),
      gridAction(params, "update", t("wallets.actions.update"), EditIcon, (wallet: TCompanyWallet): void => {
        setWallet(wallet);
        setOpenCreateWalletPanel(true);
      }, false, (params?.id == store.global.company.expiredPointsWallet?.id) ? true : false),
      gridAction(params, "delete", t("wallets.actions.delete"), DeleteIcon, (wallet: TCompanyWallet): void => {
        setWallet(wallet);
        setNewWallet('/bo/wallets/'+store.global.company.defaultWallet!.id);
        setOpenDeleteDialog(true);
      }, false, (params?.id == store.global.company.expiredPointsWallet?.id || params?.id == store.global.company.defaultWallet?.id) ? true : false),
    ])
  ]

  const handleNav = (): void => {
    navigate(PRIVATE_URL.WALLETS_BUY_POINTS);
  }

  const deleteWalletDialog = <DeleteDialog
    key="delete-wallet"
    title={t("wallets.actions.delete_title", {"name": wallet?.name})}
    open={openDeleteDialog}
    close={(): void => { setOpenDeleteDialog(false), setWallet(null), setNewWallet(null) }}
    warning={t("wallets.actions.delete_warning")}
    deleteElement={actionDeleteWallet}
    walletSelection={wallet && wallet.balance > 0 ? true : false} excludedWallets={wallet ? [wallet.id] : undefined} newWallet={newWallet} setNewWallet={setNewWallet}/>

  const warningWalletDialog = <Dialog open={openWarningDialog} onClose={(): void => { setOpenWarningDialog(false) }} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
    <Divider sx={{height: 4, backgroundColor: "warning.main", borderColor: "warning.main"}} flexItem/>
    <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <ReportProblemIcon sx={{color: "warning.main", width: 24, height: 24, mr: 1}}/>
        <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
          {t("wallets.actions.warning_title", {"name": wallet?.name})}
        </Typography>
      </Box>
      <IconButton onClick={(): void => { setOpenWarningDialog(false) }}>
        <CloseIcon sx={{color: "neutral.main"}}/>
      </IconButton>
    </DialogTitle>
    <DialogContent sx={{pb: 5}}>
      <Typography variant="body1" color="neutral.main" sx={{fontWeight: "unset"}} dangerouslySetInnerHTML={{__html: t("wallets.actions.warning_warning")}}/>
    </DialogContent>
    <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
      <SecondaryBigButton sx={{mr: 0}} label={t("wizard.close")} action={(): void => { setOpenWarningDialog(false) }}/>
      <PrimaryBigButton label={t("wallets.actions.warning_button")} action={(): void => handleNav()}/>
    </DialogActions>
  </Dialog>

  const transferWalletDialog = <TransferDialog
    key="transfer-wallet"
    title={t("wallets.actions.transfer_title", {"name": wallet?.name})}
    open={openTransferDialog}
    close={(): void => { setOpenTransferDialog(false), setWallet(null), setNewWallet(null) }}
    text={t("wallets.actions.transfer_text")}
    transferElement={actionTransferWallet}
    setTransferAmount={setTransferAmount}
    amount={transferAmount}
    walletSelection={wallet && wallet.balance > 0 ? true : false} excludedWallets={wallet ? [wallet.id] : undefined} newWallet={newWallet} setNewWallet={setNewWallet}/>

  return <>
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mb: 5}}>
      <Typography variant="body1" color="neutral.main" dangerouslySetInnerHTML={{__html: t("wallets.subtitle_list")}}/>
    </Box>
    <List
      translationPrefix="wallets"
      columns={columns}
      defaultOrder={{field: 'name', sort: 'asc'}}
      apiRef={apiRef}
      loadRows={loadTransactions}
      height="100%"
      emptyListIcon={emptyTransactions}
      reload={reload}
      setReload={setReload}
      alerts={alerts}
      setAlerts={setAlerts}
      filters={filters}
      sx={{width: "100%", mb: 3}}
      globalActions={[createAction]}
      panels={[createWalletPanel]}
      dialogs={[deleteWalletDialog, warningWalletDialog, transferWalletDialog]}
      enableSelection={false}
    />
  </>
}
