import * as React from "react";
import {useContext, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {errorManager, post, put} from "../../common/methods/ApiService";
import {Alert, Box, Card} from "@mui/material";
import {useTranslation} from "react-i18next";
import {trackPromise} from "react-promise-tracker";
import {useNavigate} from "react-router-dom";
import {COMPONENT_TYPES, INPUT_TYPES} from "./formConstants";
import AutoCompletePart from "./autoCompletePart";
import ElementButton from "./elementButton";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import ElementInputWithLabel from "./elementInputWithLabel";

type ElementFormProps = {
  name: string;
  title?: string;
  redirect?: string;
  action?: (element: any) => void;
  elements: ElementProps[];
  initialRecord?: any;
  updateRecordBeforeShow?: (element: any) => any;
  updateRecordBeforeFetch?: (element: any) => any;
  additionalComponent?: JSX.Element;
  submitForm: string;
  invalidateForm: () => void;
  sx?: SxProps<Theme>;
}

export type ElementProps = {
  component: COMPONENT_TYPES;
  type: INPUT_TYPES;
  name: string;
  subresource?: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  choices?: ElementChoice[];
  button?: JSX.Element
}

type ElementChoice = {
  id: string;
  label: string
}


// TODO: arrêter d'utiliser ce form (':
const ElementForm = (props: ElementFormProps): JSX.Element => {
  const {name, redirect, action, elements, initialRecord, updateRecordBeforeShow, updateRecordBeforeFetch, additionalComponent, submitForm, invalidateForm, sx} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const STORE = useContext<StoreContext>(AppContext);

  const [violations, setViolations] = useState<any[]>([]);
  const computeInitialRecord = (): any => {
    if (initialRecord == null) {
      return {};
    }
    return updateRecordBeforeShow !== undefined ? updateRecordBeforeShow(initialRecord) : initialRecord;
  }
  const [record, setRecord] = useState<any>(computeInitialRecord());
  const [error, setError] = useState<string|null>(null);

  const components = elements.map((child: ElementProps) => {
    const {component, type, name, subresource, label, placeholder, required, disabled, choices, button} = child;
    const computeValue = (): string => (subresource ? (record[subresource] ? record[subresource][name] : null) : record[name])??"";
    const [value, setValue] = useState(computeValue());

    const updateValue = (value: any): void => {
      if (subresource) {
        let subresourceValue = record[subresource];
        if (subresourceValue) {
          subresourceValue[name] = value;
        } else {
          subresourceValue = {[name]: value};
        }
        record[subresource] = subresourceValue;
        setValue(value);
        setRecord(record);
      } else {
        record[name] = value;
        setValue(value);
        setRecord({...record, [name]: value});
      }
    }
    switch (component) {
    case COMPONENT_TYPES.SELECT:
      return <AutoCompletePart
        name={name}
        options={choices!}
        value={value??""}
        onChange={(e: any, newValue: any): any => {
          if (newValue) {
            updateValue(newValue.id);
          } else {
            updateValue(null);
          }
        }}
        renderInput={(params: any): JSX.Element => {
          const [errorMessage, setErrorMessage] = useState(null);

          useEffect(() => {
            setErrorMessage(violations?.find((violation: any) => violation.field === name)?.error);
          }, [violations]);

          return <TextField {...params}
            color="info"
            label={t(label)}
            type={type}
            error={errorMessage !== undefined}
            helperText={errorMessage}
            required={required??false}
            disabled={disabled??false}/>
        }}
      />
    case COMPONENT_TYPES.ACTION:
      return <ElementButton key={"button-" + name} button={button!} />
    default:
      return <ElementInputWithLabel
        key={name}
        name={name}
        label={t(label)}
        placeholder={t(placeholder??label)}
        type={type}
        required={required}
        disabled={disabled??false}
        value={value??""}
        updateValue={updateValue}
        violations={violations}/>
    }
  });

  useEffect(() => {
    if (submitForm === name + "-form") {
      const updatedRecord = (updateRecordBeforeFetch) ? updateRecordBeforeFetch(record) : record;
      const call = (): Promise<any> => (initialRecord) ? put(name, initialRecord.id, updatedRecord) : post(name, updatedRecord);

      setViolations([]);
      setError(null);
      trackPromise(
        call()
          .then((data: any) => {
            if (redirect === "-1") navigate(-1)
            if (redirect) navigate(redirect)
            if (action) action(data)
            invalidateForm();
          })
          .catch((error: any) => {
            const errorResult = errorManager(error, t, STORE, navigate);
            invalidateForm();
            if (typeof errorResult === 'string') setError(errorResult);
            else if (Array.isArray(errorResult)) setViolations(errorResult);
          })
      );
    }
  }, [submitForm]);

  useEffect(() => {
    if (error) {
      setTimeout((): void => setError(null), 8000);
    }
  }, [error])

  return (
    <Box sx={{width: "566px", margin: "0 auto"}}>
      {error && <Alert variant="filled" severity="error" sx={{mb: 3}}>{error}</Alert>}
      <Card sx={{display: "flex", flexDirection: "column", backgroundColor: "ornament.light", m: 5, pt: 1, pb: 1, pr: 3, px: 3, boxShadow: 1, ...sx}}>
        {components}
        {additionalComponent}
      </Card>
    </Box>
  )
}
export default ElementForm;
