import React, {useState} from 'react';
import {Box, Dialog, DialogContent, IconButton, Typography, Slide, SlideProps, useMediaQuery} from '@mui/material';
import LogoJoozy from "../../assets/logoJoozy.svg";
import LogoJoozyViolet from "../../assets/logoJoozyViolet.svg";
import {PrimaryBigButton, SecondaryBigButton} from '../../components/buttons/mainButton';
import {IconPosition} from '../../components/buttons/buttonProps';
import PersonIcon from '@mui/icons-material/Person';
import {useNavigate} from "react-router-dom";
import {PUBLIC_URL} from '../../common/struct/urlManager';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import {BLOCKS} from '../../common/struct/landingVar';
import {useTranslation} from "react-i18next";

type HeaderProps = {
    scrollTo: (block: string) => void;
}

export function DesktopHeader(props: HeaderProps) : JSX.Element {

  const {scrollTo} = props;
  const {t} = useTranslation();
  
  const navigate = useNavigate();

  const scrollTop = (): void => {
    window.scrollTo(0, 0);
  }

  const breakpointM = useMediaQuery('(min-width:1360px)');
  const breakpointS = useMediaQuery('(min-width:1278px)');
  const breakpointXS = useMediaQuery('(min-width:1110px)');

  return (
    <Box sx={{maxWidth: "1440px", width: "100%", height: "fit-content", px: 4, boxSizing: "border-box", display: "flex", alignItems: "center", my: 6, justifyContent: "space-between", flexWrap: "wrap", position: "initial", zIndex: 2}}>
      <Box sx={{mr: 2}}>
        {/* @ts-ignore */}
        <img src={LogoJoozy} alt={t("landing_page.alt.joozy_logo")} style={{height: breakpointXS ? "60px" : "45px", cursor: "pointer"}} onClick={(): void => scrollTop()} />
      </Box>
      <Box sx={{display: "flex", width: "fit-content", justifyContent: "center", py: 4}}>
        <Typography variant="body1" color="ornament.light" sx={{fontWeight: "unset", mr: breakpointM ? 8 : 4, textTransform: "uppercase", cursor: "pointer"}} onClick={(): void => scrollTo(BLOCKS.SOLUTION)}>{t("landing_page.header.menu.solution")}</Typography>
        <Typography variant="body1" color="ornament.light" sx={{fontWeight: "unset", mr: breakpointM ? 8 : 4, textTransform: "uppercase", cursor: "pointer"}} onClick={(): void => scrollTo(BLOCKS.FEATURES)}>{t("landing_page.header.menu.features")}</Typography>
        <Typography variant="body1" color="ornament.light" sx={{fontWeight: "unset", mr: breakpointM ? 8 : 4, textTransform: "uppercase", cursor: "pointer"}} onClick={(): void => scrollTo(BLOCKS.PRICING)}>{t("landing_page.header.menu.pricing")}</Typography>
        <Typography variant="body1" color="ornament.light" sx={{fontWeight: "unset", textTransform: "uppercase", cursor: "pointer"}} onClick={(): void => scrollTo(BLOCKS.US)}>{t("landing_page.header.menu.us")}</Typography>
      </Box>
      <Box sx={{display: "flex", alignItems: "center", ml: 2}}>
        {breakpointS ? 
          <PrimaryBigButton action={(): void => navigate(PUBLIC_URL.LOGIN)} icon={PersonIcon} position={IconPosition.LEFT} sx={{borderRadius: "50px", height: "44px", color: "ornament.light", border: "none", textTransform: "uppercase", fontWeight: 700, fontSize: "16px", borderWidth: "1.8px", borderColor: "ornament.light", borderStyle: "solid", backgroundColor: "secondary.dark", "&:hover": {backgroundColor: "secondary.dark", color: "ornament.light"}}} label={t("login.connect_button")}/>
          :
          <PersonIcon onClick={(): void => navigate(PUBLIC_URL.LOGIN)} sx={{height: "30px", color: "ornament.light", cursor: "pointer", borderStyle: "solid", borderColor: "ornament.light", borderWidth: "1.8px", borderRadius: "100%", padding: "3px"}} />
        }
        <SecondaryBigButton action={(): void => navigate(PUBLIC_URL.REGISTER)} sx={{borderRadius: "50px", height: "44px", ml: breakpointS ? 4 : 2, color: "secondary.dark", border: "none", textTransform: "uppercase", fontWeight: 700, fontSize: breakpointXS ? "16px" : "14px", "&:hover": {border: "none", color: "secondary.dark"}}} label={t("landing_page.header.menu.begin")}/>
      </Box>
    </Box>
  )
}

export const MobileTransition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})  

export function MobileHeader(props: HeaderProps): JSX.Element {

  const {scrollTo} = props;

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  
  const navigate = useNavigate();
  const {t} = useTranslation();

  const scrollTop = (): void => {
    window.scrollTo(0, 0);
  }

  return (
    <Box sx={{maxWidth: "1440px", width: "100%", height: "fit-content", px: 4, boxSizing: "border-box", display: "flex", alignItems: "center", my: 6, justifyContent: "space-between", flexWrap: "wrap", position: "initial", zIndex: 2}}>
      <Box sx={{mr: 2}}>
        {/* @ts-ignore */}
        <img src={LogoJoozy} alt={t("landing_page.alt.joozy_logo")} style={{height: "60px", cursor: "pointer"}} onClick={(): void => scrollTop()} />
      </Box>
      <Box sx={{display: "flex"}}>
        <Box onClick={(): void => setOpenMenu(true)}><MenuIcon sx={{color: "landing.main", height: "52px", width: "52px", cursor: 'pointer'}} /></Box>
        <Dialog open={openMenu} fullScreen TransitionComponent={MobileTransition} sx={{mx: 8, height: "100%", mr: "0px", ".MuiDialog-container": {height: "100%"}, "& .MuiBackdrop-root": {backgroundColor: "#363739"}}}>
          <IconButton sx={{color: "ornament.light", position: "fixed", top: 0, left: 0, mt: 2, mx: 1, p: "0px"}} onClick={(): void => setOpenMenu(false)}>
            <ClearIcon sx={{color: "landing.main", stroke: "#50DBB9", strokeWidth: 1.2, height: "32px"}} />
          </IconButton>
          <DialogContent sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", p: "0px", backgroundColor: "#EEEDF2"}}>
            <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", py: 7, px: 8, height: "100%"}}>
              {/* @ts-ignore */}
              <Box onClick={(): void => setOpenMenu(false)}><img src={LogoJoozyViolet} alt={t("landing_page.alt.joozy_logo")} style={{height: "52px", width: "fit-content"}} /></Box>
              <Box>
                <Typography variant="h2" color="secondary.dark" onClick={(): void => { setOpenMenu(false), scrollTo(BLOCKS.SOLUTION) }} sx={{fontSize: "26px", fontWeight: "600", textTransform: "uppercase", mt: 7, cursor: "pointer", borderTopWidth: "2px", borderTopStyle: "solid", borderTopColor: "landing.main", pt: 4}}>{t("landing_page.header.menu.solution")}</Typography>
                <Typography variant="h2" color="secondary.dark" onClick={(): void => { setOpenMenu(false), scrollTo(BLOCKS.FEATURES) }} sx={{fontSize: "26px", fontWeight: "600", textTransform: "uppercase", mt: 7, cursor: "pointer"}}>{t("landing_page.header.menu.features")}</Typography>
                <Typography variant="h2" color="secondary.dark" onClick={(): void => { setOpenMenu(false), scrollTo(BLOCKS.PRICING) }} sx={{fontSize: "26px", fontWeight: "600", textTransform: "uppercase", mt: 7, cursor: "pointer"}}>{t("landing_page.header.menu.pricing")}</Typography>
                <Typography variant="h2" color="secondary.dark" onClick={(): void => { setOpenMenu(false), scrollTo(BLOCKS.US) }} sx={{fontSize: "26px", fontWeight: "600", textTransform: "uppercase", mt: 7, cursor: "pointer", borderBottomWidth: "2px", borderBottomStyle: "solid", borderBottomColor: "landing.main", pb: 7}}>{t("landing_page.header.menu.us")}</Typography>
                <PrimaryBigButton action={(): void => { setOpenMenu(false), navigate(PUBLIC_URL.REGISTER) }} sx={{borderRadius: "50px", width: "fit-content", height: "fit-content", color: "ornament.light", border: "none", textTransform: "uppercase", fontWeight: 700, fontSize: "20px", borderWidth: "1.8px", borderColor: "ornament.light", borderStyle: "solid", backgroundColor: "primary.main", mt: 6, "&:hover": {backgroundColor: "primary.main", color: "ornament.light"}}} label={t("landing_page.header.menu.begin")}/>
              </Box>
              <Box sx={{display: "flex", alignItems: "center", height: "fit-content"}} onClick={(): void => { setOpenMenu(false), navigate(PUBLIC_URL.LOGIN) }}>
                <PersonIcon sx={{height: "38px", color: "secondary.dark"}} />
                <Typography variant="h2" color="secondary.dark" sx={{fontSize: "20px", fontWeight: 700, textTransform: "uppercase", ml: 3, cursor: "pointer"}}>{t("login.connect_button")}</Typography>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  )
}