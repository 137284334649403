export const DEFAULT_PRIMARY_COLOR = "#6725D2";
export const FO_LIGHT_CONTRAST_COLOR = '#FFFFFF';
export const FO_DARK_CONTRAST_COLOR = '#3A3A3A';

export enum ContrastColor {
  LIGHT = '#FFFFFF',
  DARK = '#3A3A3A'
}

export const extractContrastColor = (color: boolean): ContrastColor => {
  return color ? ContrastColor.DARK : ContrastColor.LIGHT;
}
