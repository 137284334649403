import {Alert, Box, Card, Grid, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import WarningIcon from '@mui/icons-material/Warning';
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import shopSettingsImage from "../../assets/balancePoints/visuel_settings.svg";
import OperationShopBlockDialog from "./operationShopBlockDialog";
import {AppContext} from "../../App";
import {TOperation} from "../../interfaces/operation";
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {IconPosition} from "../../components/buttons/buttonProps";
import Footer from "../../patterns/footer/footer";

export default function OperationShopSettings(props: {setError: (error: string) => void, setSuccess: (success: string) => void}): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);
  const {setError, setSuccess} = props;

  const [blocked, setBlocked] = useState(false);
  const [blockedDate, setBlockedDate] = useState<null|string>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [operation, setOperation] = useState<null|TOperation>(null);

  useEffect(() => {
    setOperation(store.global.currentOperation);
  }, [store]);

  useEffect(() => {
    if (operation) {
      setBlocked(operation.shops[0].blockOrder);
      if (operation.shops[0].blockOrderDate) {
        setBlockedDate(operation.shops[0].blockOrderDate);
      }
    }
  }, [operation])

  return operation == null ? <></> : <>
    <Grid container columns={12} columnSpacing={5} sx={{px: 8, pb: 3}}>
      <Grid item xs={8}>
        <OperationShopBlockDialog
          operation={operation}
          open={openDialog} setOpen={setOpenDialog}
          setSuccess={setSuccess} setError={setError}
        />
        {/** @ts-ignore */}
        <Card sx={{display: "flex", flexDirection: "column", boxShadow: 1, border: "1px solid", borderColor: "ornament.dark", p: 3, pl: "205px",
          backgroundImage: `url(${shopSettingsImage})`, backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
          <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {t("operations.shop.settings.title")}
          </Typography>
          <Typography variant="body2" color="neutral.dark" sx={{mt: 3, fontWeight: "unset"}}>
            {t("operations.shop.settings.subtitle_1")}
          </Typography>
          <Typography variant="body2" color="neutral.dark" sx={{mt: 1, fontWeight: "unset"}}>
            {t("operations.shop.settings.subtitle_2")}
          </Typography>
          <Box sx={{display: "flex", alignItems: "center", mt: 5}}>
            <WarningIcon sx={{color: "warning.main", width: 18, height: 18}}/>
            <Typography variant="body2" color="warning.main" sx={{ml: 1, fontWeight: "medium"}}>{t("operations.shop.settings.warning")}</Typography>
          </Box>
          <PrimaryBigButton sx={{width: "fit-content", mt: 5, backgroundColor: `${blocked ? "success.main" : "error.main"}`, "&:hover": {backgroundColor: `${blocked ? "success.dark" : "error.dark"}`}}} action={(): void => setOpenDialog(true)}
            label={blocked ? t("operations.shop.settings.unblock.title") : t("operations.shop.settings.block.title")} icon={blocked ? BlockIcon : CheckCircleOutlineIcon} position={IconPosition.LEFT}/>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{display: "flex", flexDirection: "column", boxShadow: 1, p: 3,
          border: "1px solid", borderColor: "ornament.dark"}}>
          <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mb: 3}}>
            {t("operations.shop.settings.state")}
          </Typography>
          <Alert severity={blocked ? "warning" : "info"} variant="filled">
            {t("operations.shop.settings." + (blocked ? "closed" : "open"), {date: new Date(blockedDate??operation.shops[0].createdAt)})}
          </Alert>
        </Card>
      </Grid>
    </Grid>
    <Footer/>
  </>
}
