import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import LogoJoozy from "../../assets/logoJoozy.svg";
import {Avatar, SxProps, Typography} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import GiftIcon from "../../assets/gift.svg";
import StarsIcon from "@mui/icons-material/Stars";
import {LIBERTY_THEME} from "../../tokens/libertyTheme";
import Rainbow from "../../assets/rainbow.svg";
import JoozBoys from "../../assets/joozBoys.svg";
import React, {ElementType, FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Theme} from "@mui/material/styles";

function RegisterInfo(props: {icon?: ElementType, image?: FunctionComponent, info: string}): JSX.Element {
  const {t} = useTranslation();
  const Icon = props.icon;

  return (
    <Box sx={{display: "flex", flexDirection: "column", mb: 6}}>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Avatar sx={{backgroundColor: "secondaryLight.dark", width: 56, height: 56, mb: 1}}>
          {Icon && <Icon sx={{width: 32, height: 32, color: "primaryLight.dark"}}/>}
          {/* @ts-ignore */}
          {props.image && <img src={props.image} alt="" style={{width: 32, height: 32}}/>}
        </Avatar>
        <Typography variant="body1" color="secondaryLight.light" sx={{fontWeight: "bold", textTransform: "uppercase", ml: 3, mb: 1}}>
          {t(`register.${props.info}.title`)}
        </Typography>
      </Box>
      <Typography variant="body1" color="secondaryLight.main" sx={{fontSize: "unset"}}
        dangerouslySetInnerHTML={{__html: t(`register.${props.info}.description`)}}/>
    </Box>
  )
}

export default function RegisterSideBar(props: {showImage: boolean, sx?: SxProps<Theme>}): JSX.Element {
  const {t} = useTranslation();
  const {showImage, sx} = props;

  return (
    <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", width: "400px", backgroundColor: "secondary.dark", zIndex: 101, ...sx}}>
      <Box sx={{display: "flex", flexDirection: "column", height: showImage ? "auto" : "100%"}}>
        <Box sx={{px: 7, py: 5}}>
          <Link to={PRIVATE_URL.LANDING_PAGE}>
            {/* @ts-ignore */}
            <img src={LogoJoozy} alt="logo-joozy-avec-texte"/>
          </Link>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", pt: 5, px: 7}}>
          <Typography variant="h2" color="secondaryLight.main" sx={{mb: 6}}>
            {t("register.introduction")}
          </Typography>
          <RegisterInfo icon={GroupsIcon} info="free"/>
          <RegisterInfo image={GiftIcon} info="quick"/>
          <RegisterInfo icon={StarsIcon} info="unlimited"/>
        </Box>
      </Box>
      {showImage && <Box sx={{height: "inherit", background: `linear-gradient(180deg, ${LIBERTY_THEME.palette.secondary.dark} 0%, ${LIBERTY_THEME.palette.secondary.main} 100%)`}}>
        {/* @ts-ignore */}
        <img src={Rainbow} alt="" style={{position: "absolute", bottom: "0"}}/>
        {/* @ts-ignore */}
        <img src={JoozBoys} alt="" style={{position: "absolute", bottom: "35px", left: "93px"}}/>
      </Box>}
    </Box>
  )
}
