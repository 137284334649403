import {TCompanyAware, TLifecycle} from "./traits";

export enum CivilityType {
  MME = 'MME',
  MR = 'MR',
  OTHER = 'OTHER',

}

export interface TUser extends TLifecycle, TCompanyAware {
  firstName?: string;
  lastName?: string;
  email: string;
  password?: string;
  passwordInitDate?: string;
  phone?: string;
  job?: string;
  emailConfirmationDate?: string;
  numId?: number;
}

export const userName = (user: TUser): string => {
  return [user.firstName??"", user.lastName??""].join(" ");
}
