import React from "react";
import {Box} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

type AutoCompletePartProps = {
  name: string,
  options: any[],
  value?: any,
  onChange: (event: any, newValue: any) => void,
  renderInput: (params: any) => JSX.Element
}

const AutoCompletePart = (props: AutoCompletePartProps): JSX.Element => {
  const {name, options, value, onChange, renderInput} = props;
  const defaultValue = options.find((option: any) => option.default == true);

  return (
    <Box sx={{pt: 1, pb: 1}}>
      <Autocomplete
        key={name + "-select-label"}
        id={name + "-select-label"}
        options={options}
        fullWidth={false}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        renderInput={renderInput}
      />
    </Box>
  )
}
export default AutoCompletePart;
