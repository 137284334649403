import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Box, Card, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import SuccessLogo from "../../assets/success.svg";
import ErrorLogo from "../../assets/error.svg";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {LibertyTheme} from "../../tokens/libertyTheme";
import {Loader} from "../../components/loader/loader";
import {paymentTransactionByCb} from "../../services/OperationService";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import {getCompany} from "../../services/CompanyService";
import {UPDATE, globalStoreReducer} from "../../common/methods/context-setter/globalStoreReducer";
import {UPDATE_COMPANY} from "../../common/methods/context-setter/globals";
import {errorManager} from "../../common/methods/ApiService";

export default function PaymentSuccess(): JSX.Element {
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const navigate = useNavigate();
  const {t} = useTranslation();
  const {search} = useLocation();
  const searchParams = new URLSearchParams(search);
  const transactionId = searchParams.get('transaction_id');
  const walletId = searchParams.get('wallet_id');
  const paymentIntent = searchParams.get('payment_intent');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string|null>(null);
  const [message, setMessage] = useState<string|null>(null);

  useEffect(() => {
    setLoading(true);
    extractMessage();
  }, [])

  useEffect(() => {        
    if (paymentIntent && store.global.company.id) {
      if (transactionId) {
        paymentTransactionByCb(paymentIntent, store.global.company.id, transactionId)
          .then(() => {
            getCompany(store.global.company.id)
              .then(company => {
                if (company) {
                  globalStoreReducer(STORE, {type: UPDATE_COMPANY, method: UPDATE, company: company});
                }
              })
              .catch(error => {
                errorManager(error, t, STORE, navigate);
              })
            setLoading(false)
          });
      } else if (walletId) {
        paymentTransactionByCb(paymentIntent, store.global.company.id, undefined, walletId)
          .then(() => {
            getCompany(store.global.company.id)
              .then(company => {
                if (company) {
                  globalStoreReducer(STORE, {type: UPDATE_COMPANY, method: UPDATE, company: company});
                }
              })
              .catch(error => {
                errorManager(error, t, STORE, navigate);
              })
            setLoading(false)
          });
      }
    }
  }, [paymentIntent, store.global.company.id])

  const extractMessage = (): void => {
    switch (searchParams.get('redirect_status')) {
    case 'succeeded':
      setMessage(t("payment.success"));
      break;
    case 'processing':
      setMessage(t("payment.ongoing"));
      break;
    default:
      setError(t("payment.error"));
      break;
    }
  }

  const redirectToCustomisation = (): void => {
    navigate(PRIVATE_URL.WALLETS_BUY_POINTS);
  }
  
  const backgroundColor = error ? `linear-gradient(to right, #f1c6c6, ${LibertyTheme.palette.error.light})` : `linear-gradient(to right, #C6F1E8, ${LibertyTheme.palette.success.light})`;
  return loading ? <Loader /> :
    <Card sx={{boxShadow: 2, mt: "10vh", width: "40%", mx: "auto"}}>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Box sx={{p: 6, width: "100%", boxSizing: "border-box", display: "flex", alignItems: "center", background: backgroundColor}}>
          {/* @ts-ignore */}
          <img src={error ? ErrorLogo : SuccessLogo} alt="payment-success"/>
          <Box sx={{display: "flex", flexDirection: "column", ml: 2}}>
            <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 1}}>
              {error??message}
            </Typography>
            <Typography variant="body2" color="neutral.main">
              {error ? t("payment.error_explanation") : t("payment.success_explanation")}
            </Typography>
          </Box>
        </Box>
        <PrimaryBigButton label={t("navigation.back_to_customisation")} action={redirectToCustomisation} sx={{my: 5}}/>
      </Box>
    </Card>
}