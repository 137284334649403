import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Page from "../../patterns/page/page";
import {TAlert} from "../../patterns/list/listSnackbar";
import emptyParticipants from "../../assets/lists/empty_participants.svg";
import List from "../../patterns/list/list";
import {GridValueGetterParams, useGridApiRef} from "@mui/x-data-grid-pro";
import {deleteAdmin, getAdmins, sendValidationEmail} from "../../services/AdminService";
import {
  actionColumn,
  addErrorAlert,
  addSuccessAlert,
  ChipCellProps,
  columnDefinition,
  ColumnType,
  enumFilter,
  gridAction
} from "../../patterns/list/listUtils";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import {IconPosition} from "../../components/buttons/buttonProps";
import {GridEnrichedColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import SendIcon from "@mui/icons-material/Send";
import {TAdmin, TAdminStatus} from "../../interfaces/admin";
import DeleteIcon from "@mui/icons-material/Delete";
import {getAdminId} from "../../common/struct/globalVar";
import {Chip, Typography} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MailIcon from "@mui/icons-material/Mail";
import DeleteDialog from "../../patterns/dialog/deleteDialog";
import {userName} from "../../interfaces/user";
import Panel, {PanelSize} from "../../patterns/panel/panel";
import AdminForm from "./adminForm";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {errorManager} from "../../common/methods/ApiService";
import Footer from "../../patterns/footer/footer";
import {useNavigate} from "react-router-dom";

export default function CompanyBilling(): JSX.Element {
  const {t} = useTranslation();
  const apiRef = useGridApiRef();
  useEffect(() => {
    document.title = t("tab_title.companyAdministrators");
  }, []);
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [admin, setAdmin] = useState<TAdmin|null>(null);
  const [reload, setReload] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openInvitePanel, setOpenInvitePanel] = useState(false);

  const closeDeleteDialog = (): void => setOpenDeleteDialog(false)
  const closeInvitePanel = (): void => setOpenInvitePanel(false)

  const navigate = useNavigate();

  const loadAdmins = (): Promise<{totalItems: number, items: TAdmin[]}> => {
    return getAdmins()
      .then(admins => ({totalItems: admins.length, items: admins}))
      .catch(error => {
        errorManager(error, t, STORE, navigate);
        return Promise.resolve({totalItems: 0, items: []})
      });
  }
  const inviteAdministrator = (): void => setOpenInvitePanel(true)
  const actionPostInvite = (admin: TAdmin): void => {
    setReload(true);
    closeInvitePanel();
    addSuccessAlert(t("company_administrators.actions.invite_success", {name: userName(admin)}), setAlerts);
  }

  const reInviteAdministrator = (admin: TAdmin): void => {
    sendValidationEmail(admin.id)
      .then(() => {
        addSuccessAlert(t("company_administrators.actions.invite_sent_success", {name: userName(admin)}), setAlerts);
        setAdmin(null);
      })
      .catch(error => addErrorAlert(error, setAlerts))
  }
  const reConfirmAdministrator = (admin: TAdmin): void => {
    sendValidationEmail(admin.id)
      .then(() => {
        addSuccessAlert(t("company_administrators.actions.confirm_success", {name: userName(admin)}), setAlerts);
        setAdmin(null);
      })
      .catch(error => addErrorAlert(error, setAlerts))
  }
  const openDeleteAdministratorDialog = (admin: TAdmin): void => {
    setAdmin(admin);
    setOpenDeleteDialog(true);
  }

  const deleteAdministrator = (): Promise<void> => {
    if (admin == null) {
      return Promise.resolve();
    }

    return deleteAdmin(admin.id)
      .then(() => {
        addSuccessAlert(t("company_administrators.actions.delete_success", {name: userName(admin)}), setAlerts);
        setReload(true);
        setAdmin(null);
      })
      .catch(error => addErrorAlert(error, setAlerts))
      .finally(closeDeleteDialog)
  }

  const nonHideableColumn = (field: string, width: number, type: ColumnType = ColumnType.STRING, isNullable = false): GridEnrichedColDef => {
    return {
      ...columnDefinition(t, "company_administrators", field, width, type, false, isNullable),
      hideable: false
    }
  }

  const nameColumn = (): GridEnrichedColDef => {
    return {
      ...nonHideableColumn("name", 150),
      valueGetter: (item: GridValueGetterParams): string => item.row.firstName + ' ' + item.row.lastName
    }
  }

  const invitedByNameColumn = (): GridEnrichedColDef => {
    return {
      ...nonHideableColumn("invitedBy", 150, ColumnType.STRING, true),
      valueGetter: (item: GridValueGetterParams): string => {
        const invitedBy = item.row.invitedBy;
        return invitedBy ? invitedBy.firstName + ' ' + invitedBy.lastName : "-"
      }
    }
  }

  const statusProps = (row: TAdmin): ChipCellProps => {
    switch (row.status) {
    case TAdminStatus.INVITATION_SENT:
      return {
        icon: <SendIcon sx={{width: 18, height: 18}}/>,
        iconColor: "info.main",
        color: "info.dark",
        backgroundColor: "infoLight.light",
        date: row.updatedAt ? new Date(row.updatedAt) : "-"
      }
    case TAdminStatus.INVITATION_ACCEPTED:
      return {
        icon: <MarkEmailReadIcon sx={{width: 18, height: 18}}/>,
        iconColor: "success.main",
        color: "success.dark",
        backgroundColor: "success.light",
        date: row.emailConfirmationDate ? new Date(row.emailConfirmationDate) : "-"
      }
    case TAdminStatus.ACCOUNT_UNVALIDATED:
      return {
        icon: <ErrorOutlineIcon sx={{width: 18, height: 18}}/>,
        iconColor: "warning.main",
        color: "warning.dark",
        backgroundColor: "warning.light"
      }
    case TAdminStatus.ACCOUNT_VALIDATED:
      return {
        icon: <CheckCircleOutlineIcon sx={{width: 18, height: 18}}/>,
        iconColor: "success.main",
        color: "success.dark",
        backgroundColor: "success.light",
        date: row.emailConfirmationDate ? new Date(row.emailConfirmationDate) : "-"
      }
    }
  }

  const statusColumn = (): GridEnrichedColDef => {
    return {
      ...nonHideableColumn("status", 300),
      type: "singleSelect",
      filterOperators: enumFilter,
      valueOptions: Object.keys(TAdminStatus).map(status => ({value: status, label: t(`company_administrators.columns.status_${status.toLowerCase()}`)})),
      renderCell: (item): JSX.Element => {
        const status = statusProps(item.row);
        return <Chip icon={status.icon}
          sx={{backgroundColor: status.backgroundColor, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: status.iconColor}}}
          label={<Typography variant="body2" color={status.color} sx={{fontWeight: "regular"}}>
            {t(`company_administrators.columns.status_${item.row.status.toLowerCase()}`, {date: status.date})}
          </Typography>}/>
      }
    }
  }

  const columns = [
    nameColumn(),
    nonHideableColumn("email", 200),
    statusColumn(),
    invitedByNameColumn(),
    nonHideableColumn("createdAt", 150, ColumnType.DATE_TIME),
    nonHideableColumn("lastConnectionDate", 150, ColumnType.DATE_TIME, true),
    actionColumn(params => [
      params.row.invitedBy !== null ? gridAction(params, "invite", t("company_administrators.actions.invite_one"), SendIcon, reInviteAdministrator)
        : params.row.status == TAdminStatus.INVITATION_SENT || params.row.status == TAdminStatus.ACCOUNT_UNVALIDATED ? gridAction(params, "invite", t("company_administrators.actions.confirm"), MailIcon, reConfirmAdministrator): <></>,
      ...(params.row.id !== getAdminId() ? [gridAction(params, "invite", t("company_administrators.actions.delete"), DeleteIcon, openDeleteAdministratorDialog)] : [])
    ])
  ]

  const mainButton = <PrimaryBigButton label={t("company_administrators.actions.invite")} action={inviteAdministrator}
    icon={SupervisorAccountIcon} position={IconPosition.LEFT}/>

  return (
    <Page title={t("company_administrators.title")} buttons={[mainButton]} noBackLink={true}>
      <DeleteDialog key="delete-dialog" open={openDeleteDialog} close={closeDeleteDialog} title={t("company_administrators.actions.delete")}
        warning={t("company_administrators.actions.delete_warning", {name: admin ? userName(admin) : ""})} deleteElement={deleteAdministrator}/>
      {openInvitePanel ? <Panel key="invite-panel" size={PanelSize.BIG} title={t("company_administrators.actions.invite_title", {company: store.global.company.name})} open={openInvitePanel} close={closeInvitePanel}>
        <AdminForm onSuccess={actionPostInvite}/>
      </Panel> : <></>}
      <List
        key="admin-list"
        sx={{m: 7}}
        translationPrefix="company_administrators"
        columns={columns}
        apiRef={apiRef}
        loadRows={loadAdmins}
        alerts={alerts}
        setAlerts={setAlerts}
        reload={reload}
        setReload={setReload}
        height="75vh"
        emptyListIcon={emptyParticipants}
        enableSelection={false}
        enableToolbar={false}
      />
      <Footer/>
    </Page>
  )
}
