import React, {useEffect, useRef, useState} from 'react';
import {Box, Divider, Typography, useMediaQuery} from '@mui/material';
import {DesktopHeader, MobileHeader} from "./landingHeaders";
import {DesktopPricing, MobilePricing} from "./landingPricing";
import {ChipLabel, DesktopReward, MobileReward} from "./landingRewards";
import SolutionText from "./solutionText";
import VitrineStep from "./vitrineStep";
import BrandsSlider from "./brandsSlider";
import {LandingTitle, LandingTitleText} from "./landingTitle";
import ParticipantInfoDialog from "./participantInfoDialog";
import LandingFooter from "./landingFooter";
import CompanyFull from "../../assets/landingPage/visuelEntrepriseBulle.png";
import Company from "../../assets/landingPage/visuelEntreprise.png";
import CompanyQuote from "../../assets/landingPage/entrepriseBulle.png";
import ManagerFull from "../../assets/landingPage/visuelManagerBulle.png";
import Manager from "../../assets/landingPage/visuelManager.png";
import ManagerQuote from "../../assets/landingPage/managerBulle.png";
import UserFull from "../../assets/landingPage/visuelBeneficiaireBulle.png";
import User from "../../assets/landingPage/visuelBeneficiaire.png";
import UserQuote from "../../assets/landingPage/beneficiaireBulle.png";
import Personnages from "../../assets/landingPage/personnages.png";
import Nous from "../../assets/landingPage/us.jpg";
import CircularFigure2 from "../../assets/landingPage/circular-arc.png";
import {SOLUTION_TEXTS, VITRINE_STEPS, BREAKPOINTS, SOLUTION_TEXT_TOP, BreakpointsProps, SOLUTION_TEXT_LEFT, SOLUTION_IMG_LEFT, SOLUTION_IMG_HEIGHT, SOLUTION_IMG_BOX_HEIGHT, BLOCKS} from '../../common/struct/landingVar';
import {PrimaryBigButton} from '../../components/buttons/mainButton';
import {useNavigate} from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {PRIVATE_URL, PUBLIC_URL} from '../../common/struct/urlManager';
import CircularFigure1 from "../../assets/landingPage/circular_arc.svg";
import {isAuthenticated} from "../../common/struct/globalVar";
import {useTranslation} from "react-i18next";

export default function LandingPage(): JSX.Element {

  const {t} = useTranslation();

  const solutionRef = useRef<null | HTMLDivElement>(null);
  const featuresRef = useRef<null | HTMLDivElement>(null);
  const pricingRef = useRef<null | HTMLDivElement>(null);
  const usRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050 ? true : false);

  const [openDialog, setOpenDialog] = useState(false);

  const [selectedMenu, setSelectedMenu] = useState<number>(0); 
  const [selectedStep, setSelectedStep] = useState<number>(0); 
  const [showStep1, setShowStep1] = useState<boolean>(false); 
  const [showStep2, setShowStep2] = useState<boolean>(false); 
  const [showStep3, setShowStep3] = useState<boolean>(false); 

  const [solutionTextTop, setSolutionTextTop] = useState<string>(window.innerWidth >= 1435 ? "174px" : window.innerWidth >= 1360 ? "120px" : "76px"); 
  const [solutionTextLeft, setSolutionTextLeft] = useState<string>(window.innerWidth >= 1435 ? "16%" : window.innerWidth >= 1360 ? "0%" : window.innerWidth >= 1275 ? "-4%" : "-10%"); 
  const [imgHeight, setImgHeight] = useState<string>(window.innerWidth >= 1435 ? "auto" : window.innerWidth >= 1360 ? "750px" : "680px"); 
  const [imgBoxHeight, setImgBoxHeight] = useState<string>(window.innerWidth >= 1435 ? "950px" : window.innerWidth >= 1360 ? "850px" : window.innerWidth >= 1100 ? "770px" : "fit-content"); 
  const [imgLeft, setImgLeft] = useState<string>(window.innerWidth >= 1360 ? "12%" : window.innerWidth >= 1275 ? "6%" : "-4%"); 

  useEffect(() => {     
    if (isAuthenticated()) {
      navigate(PRIVATE_URL.WELCOME, {replace: true});
    }

    document.title = t("tab_title.landingPage");   
    window.addEventListener('resize', () => setIsMobile(window.innerWidth <= 1050 ? true : false));
    return (): any => {
      window.removeEventListener('resize', () => setIsMobile(window.innerWidth <= 1050 ? true : false));
    }
  }, []);

  useEffect(() => {    
    window.addEventListener('resize', () => {
      const BreakException = {};
      
      try {
        BREAKPOINTS.forEach(element => {
          if (window.innerWidth >= element) {            
            setSolutionTextTop(SOLUTION_TEXT_TOP[element as keyof BreakpointsProps] ?? SOLUTION_TEXT_TOP["default"]);
            setSolutionTextLeft(SOLUTION_TEXT_LEFT[element as keyof BreakpointsProps] ?? SOLUTION_TEXT_LEFT["default"]);
            setImgLeft(SOLUTION_IMG_LEFT[element as keyof BreakpointsProps] ?? SOLUTION_IMG_LEFT["default"]);
            setImgHeight(SOLUTION_IMG_HEIGHT[element as keyof BreakpointsProps] ?? SOLUTION_IMG_HEIGHT["default"]);
            setImgBoxHeight(SOLUTION_IMG_BOX_HEIGHT[element as keyof BreakpointsProps] ?? SOLUTION_IMG_BOX_HEIGHT["default"]);
            throw BreakException;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    });
  }, []);

  const handleNav = (): void => {
    navigate(PUBLIC_URL.REGISTER);
  }

  const scrollTo = (block: string): void => {
    switch(block) { 
    case BLOCKS.SOLUTION: { 
      solutionRef && solutionRef.current && solutionRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
      break; 
    } 
    case BLOCKS.FEATURES: { 
      featuresRef && featuresRef.current && featuresRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
      break; 
    }
    case BLOCKS.PRICING: { 
      pricingRef && pricingRef.current && pricingRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
      break; 
    }
    case BLOCKS.US: { 
      usRef && usRef.current && usRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
      break; 
    } 
    default: {
      break; 
    } 
    }
  }

  const breakpointReward = useMediaQuery('(min-width:1235px)');
  const breakpointXXL = useMediaQuery('(min-width:1360px)');
  const breakpointXL = useMediaQuery('(min-width:1100px)');
  const breakpointL = useMediaQuery('(min-width:1000px)');
  const breakpointM = useMediaQuery('(min-width:830px)');
  const breakpointS = useMediaQuery('(min-width:750px)');
  const breakpointXS = useMediaQuery('(min-width:550px)');
  const breakpointXXS = useMediaQuery('(min-width:475px)');

  return (
    <Box sx={{backgroundColor: "secondary.dark", display: "flex", alignItems: "center", flexDirection: "column", maxWidth: "100vw", overflow: "hidden"}}>
      {!isMobile ? <DesktopHeader scrollTo={scrollTo} /> : <MobileHeader scrollTo={scrollTo} />}
      {openDialog && <ParticipantInfoDialog open={openDialog} close={(): void => setOpenDialog(false)} />}
      <Box sx={{maxWidth: "1440px", px: 4, width: "100%", boxSizing: "border-box", position: "relative"}}>
        {breakpointReward ? <DesktopReward /> : <MobileReward />}
      </Box>
      <Box sx={{maxWidth: "1440px", px: 4, mt: 4, width: "100%", boxSizing: "border-box"}}>
        <Box sx={{width: "100%", position: "relative", zIndex: 2}}>
          <Box sx={{display: "flex", width: "100%", justifyContent: "center"}}>
            <Typography variant="h1" color="ornament.light" sx={{fontWeight: "700", fontSize: "28px", mt: 4, textAlign: "center", "span": {color: "landing.main"}}} dangerouslySetInnerHTML={{__html: t("landing_page.body.brand_text")}} />
          </Box>
          <Box sx={{py: 4}}>
            <BrandsSlider />
          </Box>
        </Box>
      </Box>
      <Box ref={solutionRef} sx={{maxWidth: "1440px", width: "100%", boxSizing: "border-box"}}>
        <Box sx={{height: imgBoxHeight, mb: 8}}>
          <Box sx={{display: "flex", width: "100%", maxWidth: "700px", justifyContent: "space-between", mt: breakpointXL ? 4 : "0px", position: "relative", zIndex: 2, mx: "auto", px: 4, boxSizing: "border-box", flexDirection: breakpointXXS ? "row" : "column", alignItems: "center"}}>
            <Box>
              <Typography variant="body1" color="ornament.light" sx={{fontWeight: "600", textTransform: "uppercase", cursor: "pointer", fontSize: breakpointXS ? "20px" : "16px", width: "fit-content", padding: "8px 10px 8px 10px"}} onClick={(): void => setSelectedMenu(0)}>{t("landing_page.body.solution_company")}</Typography>
              <Divider sx={{backgroundColor: selectedMenu == 0 ? "landing.main" : "landing.dark", borderRadius: "5px", height: "2.2px"}} />
            </Box>
            <Box>
              <Typography variant="body1" color="ornament.light" sx={{fontWeight: "600", textTransform: "uppercase", cursor: "pointer", fontSize: breakpointXS ? "20px" : "16px", width: "fit-content", padding: "8px 10px 8px 10px", mx: 2}} onClick={(): void => setSelectedMenu(1)}>{t("landing_page.body.solution_admin")}</Typography>
              <Divider sx={{backgroundColor: selectedMenu == 1 ? "landing.main" : "landing.dark", borderRadius: "5px", height: "2.2px"}} />
            </Box>
            <Box>
              <Typography variant="body1" color="ornament.light" sx={{fontWeight: "600", textTransform: "uppercase", cursor: "pointer", fontSize: breakpointXS ? "20px" : "16px", width: "fit-content", padding: "8px 10px 8px 10px"}} onClick={(): void => setSelectedMenu(2)}>{t("landing_page.body.solution_user")}</Typography>
              <Divider sx={{backgroundColor: selectedMenu == 2 ? "landing.main" : "landing.dark", borderRadius: "5px", height: "2.2px"}} />
            </Box>
          </Box>
          <Box sx={{width: "100%", display: "flex"}}>
            {breakpointXL && <Box sx={{width: "60%", position: "relative"}}>
              <img src={selectedMenu == 0 ? (breakpointXXL ? CompanyFull : Company) : selectedMenu == 1 ? (breakpointXXL ? ManagerFull : Manager) : (breakpointXXL ? UserFull : User)} alt={t("landing_page.alt.company_picture")} style={{position: "absolute", left: imgLeft, top: "-72px", height: imgHeight, transform: "translateX(-50%)"}} />
              {!breakpointXXL && <img src={selectedMenu == 0 ? CompanyQuote : selectedMenu == 1 ? ManagerQuote : UserQuote} alt={t("landing_page.alt.company_quote")} style={{position: "absolute", right: selectedMenu%2 === 0 ? "30%" : "60%", top: "25px"}} />}
            </Box>}
            <Box sx={{width: breakpointXL ? "40%" : !isMobile ? "60%" : "90%", position: "relative", margin: breakpointXL ? "0 0 0 0" : "50px auto 0"}}>
              <SolutionText sx={{position: breakpointXL ? "absolute" : "initial", top: solutionTextTop, left: solutionTextLeft, pr: breakpointXL ? 4 : "0px"}} category={SOLUTION_TEXTS[selectedMenu].category} title={SOLUTION_TEXTS[selectedMenu].title} line1={SOLUTION_TEXTS[selectedMenu].line1} line2={SOLUTION_TEXTS[selectedMenu].line2} assets={SOLUTION_TEXTS[selectedMenu].assets} link={SOLUTION_TEXTS[selectedMenu].link} linkSrc={SOLUTION_TEXTS[selectedMenu].linkSrc} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box ref={featuresRef} sx={{maxWidth: "1440px", width: "100%", boxSizing: "border-box", px: 4, position: "relative", zIndex: 2}}>
        <LandingTitle title={t("landing_page.body.feature_title")} undertitles={[t("landing_page.body.feature_undertitle")]} />
        {useMediaQuery('(min-width:850px)') ? 
          <Box sx={{width: "100%", mt: "80px"}}>
            {VITRINE_STEPS.map((vitrineStep: any, index) => 
              <VitrineStep key={index} step={vitrineStep.step} title={vitrineStep.title} lines={vitrineStep.lines} italicLine={vitrineStep.italicLine} buttonLabel={vitrineStep.buttonLabel} buttonLink={vitrineStep.buttonLink} image={vitrineStep.image} sx={{mb: 7}} reverse={(index%2) == 0 ? false : true} />
            )}
            <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
              <PrimaryBigButton action={(): void => handleNav()} sx={{borderRadius: "50px", height: "44px", width: "fit-content", color: "ornament.light", border: "none", textTransform: "uppercase", fontWeight: 700, fontSize: "16px", borderWidth: "0px", backgroundColor: "primary.main", mt: "60px", "&:hover": {backgroundColor: "primary.main", color: "ornament.light"}}} label={t("landing_page.vitrine_steps.btn")}/>
            </Box>
          </Box> :
          <Box>
            <Box sx={{mt: 6}}>
              <Box sx={{width: "100%", textAlign: "center"}}>
                <img src={VITRINE_STEPS[selectedStep].image} alt={t("landing_page.alt.steps_picture")} style={{maxHeight: "380px", maxWidth: "100%"}} />
              </Box>
              <Box sx={{mt: 6}}>
                <Box sx={{mb: 6, display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between"}}>
                  <Box sx={{height: "100%", pr: 3, width: "fit-content", cursor: "pointer"}} onClick={(): any => selectedStep > 0 && setSelectedStep(selectedStep-1)}>
                    <ArrowBackIosIcon sx={{color: "ornament.light", height: "28px", width: "20px"}} />
                  </Box>
                  <Box sx={{width: "100%"}}> 
                    <Typography variant="h2" color="landing.main" sx={{fontWeight: "600", textTransform: "uppercase", fontSize: "20px", width: "fit-content"}}>{VITRINE_STEPS[selectedStep].step}</Typography>
                    <Typography variant="h2" color="ornament.light" sx={{fontWeight: "600", fontSize: "26px", lineHeight: "38px", width: "fit-content"}}>{VITRINE_STEPS[selectedStep].title}</Typography>
                  </Box>
                  <Box sx={{height: "100%", pl: 3, width: "fit-content", cursor: "pointer"}} onClick={(): any => selectedStep < 4 && setSelectedStep(selectedStep+1)}>
                    <ArrowForwardIosIcon sx={{color: "ornament.light", height: "28px", width: "20px"}} />
                  </Box>
                </Box>
                <Box sx={{mb: 4}}>
                  {VITRINE_STEPS[selectedStep].lines.map((line: any, index) => 
                    <Typography key={index} variant="body1" color="ornament.light" sx={{fontWeight: "300", fontSize: "18px"}}>{line}</Typography>
                  )}
                </Box>
                <Typography variant="body1" color="landing.main" sx={{fontWeight: "300", fontSize: "18px", fontStyle: "italic"}}>{VITRINE_STEPS[selectedStep].italicLine}</Typography>
                <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
                  <PrimaryBigButton action={(): void => handleNav()} sx={{borderRadius: "50px", height: "44px", width: "fit-content", color: "ornament.light", border: "none", textTransform: "uppercase", fontWeight: 700, fontSize: "16px", borderWidth: "0px", backgroundColor: "primary.main", mt: 6, "&:hover": {backgroundColor: "primary.main", color: "ornament.light"}}} label={t("landing_page.vitrine_steps.btn")}/>
                </Box>
              </Box>  
            </Box>
            {/* <Box sx={{mt: 5, display: "flex", justifyContent: "center", alignItems: "center", borderWidth: "1px", borderStyle: "solid", borderColor: "ornament.light", borderRadius: "30px", width: "fit-content", padding: "4px 4px 4px 8px", mx: "auto"}}>
                <Box sx={{cursor: selectedStep > 0 ? "pointer" : "default", display: "flex", justifyContent: "center", alignItems: "center"}} onClick={(): any => selectedStep > 0 && setSelectedStep(selectedStep-1)}>
                  <ArrowBackIosIcon sx={{color: "ornament.light", height: "28px", width: "20px"}} />
                  <Typography variant="body1" color="ornament.light" sx={{fontWeight: "400", fontSize: "22px", pr: 2}}>{"0"+(selectedStep+1)}</Typography>
                </Box>
                <Typography variant="body1" color="ornament.light" sx={{fontWeight: "400", fontSize: "22px"}}>{"|"}</Typography>
                <Box sx={{cursor: selectedStep < 4 ? "pointer" : "default", display: "flex", justifyContent: "center", alignItems: "center"}} onClick={(): any => selectedStep < 4 && setSelectedStep(selectedStep+1)}>
                  <Typography variant="body1" color="ornament.light" sx={{fontWeight: "400", fontSize: "22px", pl: 2}}>{"05"}</Typography>
                  <ArrowForwardIosIcon sx={{color: "ornament.light", height: "28px", width: "20px", pl: 1}} />
                </Box>
              </Box>*/}
          </Box>
        }
      </Box>
      <Box sx={{maxWidth: "1440px", width: "100%", height: "fit-content", boxSizing: "border-box", px: 4, mt: 10}}>
        <Box sx={{width: "100%", position: "relative"}}>
          {/* @ts-ignore */}
          <img src={CircularFigure1} alt={t("landing_page.alt.circle")} style={{position: "absolute", left: "0%", top: breakpointL ? "-270px" : breakpointS ? "-170px" : "-100px", zIndex: 1, maxWidth: "1400px", width: "100%"}} />
        </Box>
        <Box sx={{backgroundColor: "ornament.light", height: "fit-content", borderRadius: "15px", display: "flex", px: 5, py: 5, justifyContent: "center", position: "relative", zIndex: 2, flexDirection: breakpointL ? "row" : "column"}}>
          <Box sx={{width: breakpointL ? "40%" : "100%", textAlign: "center"}}>
            <img src={Personnages} alt={t("landing_page.alt.characters")} style={{maxHeight: breakpointL ? "350px" : "250px", height: "100%", maxWidth: "100%"}} />
          </Box>
          <Box sx={{width: breakpointL ? "60%" : "100%", display: "flex", alignItems: "center", mt : breakpointL ? "0px" : 4}}>
            <Box sx={{width: breakpointS ? "80%" : "100%", margin: "0 auto"}}>
              <Typography variant="h1" color="secondary.dark" sx={{fontWeight: "700", fontSize: "28px", pl: 2, textAlign: "center"}}>
                {t("landing_page.body.card_text")}
              </Typography>
              <Divider sx={{width: "30%", height: "2px", mt: 4, mx: "auto", backgroundColor: "landing.main"}} />
              <Box sx={{display: "flex", my: 6, mx: "auto", width: "fit-content", flexDirection: breakpointXXL ? "row" : breakpointL ? "column" : breakpointM ? "row" : "column"}}>
                <ChipLabel title={t("landing_page.body.chip.free")} fontColor={"secondary.dark"} />
                <ChipLabel title={t("landing_page.body.chip.easy")} fontColor={"secondary.dark"} sx={{ml: breakpointXXL ? 4 : breakpointL ? "0px" : breakpointM ? 4 : "0px", mt: breakpointXXL ? "0px" : breakpointL ? 4 : breakpointM ? "0px" : 4}} />
                <ChipLabel title={t("landing_page.body.chip.infinite")} fontColor={"secondary.dark"} sx={{ml: breakpointXXL ? 4 : breakpointL ? "0px" : breakpointM ? 4 : "0px", mt: breakpointXXL ? "0px" : breakpointL ? 4 : breakpointM ? "0px" : 4}} />
              </Box>
              <Box sx={{width: "100%", textAlign: "center"}}>
                <PrimaryBigButton action={(): void => handleNav()} sx={{borderRadius: "50px", height: "44px", width: "fit-content", color: "ornament.light", border: "none", textTransform: "uppercase", fontWeight: 700, fontSize: "16px", borderWidth: "0px", backgroundColor: "primary.main", "&:hover": {backgroundColor: "primary.main", color: "ornament.light"}}} label={t("landing_page.body.card_btn")}/>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box ref={pricingRef} sx={{maxWidth: "1440px", width: "100%", height: "fit-content", boxSizing: "border-box", px: 4, mt: 10, mb: useMediaQuery('(min-width:900px)') ? 6 : 0}}>
        {useMediaQuery('(min-width:900px)') ? <DesktopPricing /> : <MobilePricing />}
      </Box>
      <Box ref={usRef} sx={{maxWidth: "1440px", width: "100%", height: "fit-content", boxSizing: "border-box", px: 4, mt: 10}}>
        <LandingTitle title={t("landing_page.body.us_title")} undertitles={breakpointS ? [t("landing_page.body.us_undertitle_1"), t("landing_page.body.us_undertitle_2")] : [t("landing_page.body.us_undertitle_mobile")]} />
        {breakpointL ? 
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mt: 8}}>
            <Box sx={{width: "56%", pr: 4}}>
              <LandingTitleText sx={{mb: 6}} title={t("landing_page.body.us_subtitle_1")} titleColored={t("landing_page.body.us_subtitle_1_colored")} text={t("landing_page.body.us_text_1")} />
              <LandingTitleText sx={{mb: 6}} title={t("landing_page.body.us_subtitle_2")} titleColored={t("landing_page.body.us_subtitle_2_colored")} text={t("landing_page.body.us_text_2")} />
              <LandingTitleText title={t("landing_page.body.us_subtitle_3")} titleColored={t("landing_page.body.us_subtitle_3_colored")} text={t("landing_page.body.us_text_3")} />
            </Box>
            <Box sx={{width: "36%", display: "flex", justifyContent: "center"}}>
              <Box sx={{height: "fit-content", width: "fit-content", position: "relative"}}>
                <img src={Nous} alt={t("landing_page.alt.team")} style={{maxWidth: "100%", maxHeight: "400px", height: "auto", borderWidth: "14px", borderStyle: "solid", borderColor: "#50DBB9", borderRadius: "100%", boxSizing: "border-box", position: "relative", zIndex: 2}}/>
                <img src={CircularFigure2} alt={t("landing_page.alt.smile")} style={{position: "absolute", height: "300px", left: "35%", top: "42%"}} />
              </Box>
            </Box>
          </Box>
          : <Box>
            <Box sx={{mt: 7, textAlign: "center"}}>
              <img src={Nous} alt={t("landing_page.alt.team")} style={{maxWidth: "100%", maxHeight: "350px", height: "auto", borderWidth: "14px", borderStyle: "solid", borderColor: "#50DBB9", borderRadius: "100%", boxSizing: "border-box", position: "relative", zIndex: 2}}/>
            </Box>
            <Box sx={{mt: 4, backgroundColor: "landingDark.dark", padding: "10px 20px 10px 20px", borderRadius: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer"}} onClick={(): any => setShowStep1(!showStep1)}>
              <Typography variant="h3" color="ornament.light" sx={{fontWeight: "700", fontSize: "29px", "span": {color: "landing.main"}}}>
                {t("landing_page.body.us_subtitle_1")} <span>{t("landing_page.body.us_subtitle_1_colored")}</span>
              </Typography>
              <ArrowForwardIosIcon sx={{transform: showStep1 ? "rotate(-90deg)" : "rotate(-270deg)", color: "landing.main", height: "26px", transition: "all 0.3s ease-in-out"}} />
            </Box>
            <Box sx={{maxHeight: "fit-content", overflow: "hidden"}}>
              <Typography variant="body1" color="ornament.light" sx={{fontWeight: "300", fontSize: "18px", padding: "0 20px 0 20px", mt: showStep1 ? "10px" : "-300%", mb: "10px", transition: "all 0.2s ease-in-out"}}>
                {t("landing_page.body.us_text_1")}
              </Typography>
            </Box>
            <Box sx={{mt: 4, backgroundColor: "landingDark.dark", padding: "10px 20px 10px 20px", borderRadius: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer"}} onClick={(): any => setShowStep2(!showStep2)}>
              <Typography variant="h3" color="ornament.light" sx={{fontWeight: "700", fontSize: "29px", "span": {color: "landing.main"}}}>
                {t("landing_page.body.us_subtitle_2")} <span>{t("landing_page.body.us_subtitle_2_colored")}</span>
              </Typography>
              <ArrowForwardIosIcon sx={{transform: showStep2 ? "rotate(-90deg)" : "rotate(-270deg)", color: "landing.main", height: "26px", transition: "all 0.3s ease-in-out"}} />
            </Box>
            <Box sx={{maxHeight: "fit-content", overflow: "hidden"}}>
              <Typography variant="body1" color="ornament.light" sx={{fontWeight: "300", fontSize: "18px", padding: "0 20px 0 20px", mt: showStep2 ? "10px" : "-300%", mb: "10px", transition: "all 0.2s ease-in-out"}}>
                {t("landing_page.body.us_text_2")}
              </Typography>
            </Box>
            <Box sx={{mt: 4, backgroundColor: "landingDark.dark", padding: "10px 20px 10px 20px", borderRadius: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer"}} onClick={(): any => setShowStep3(!showStep3)}>
              <Typography variant="h3" color="ornament.light" sx={{fontWeight: "700", fontSize: "29px", "span": {color: "landing.main"}}}>
                {t("landing_page.body.us_subtitle_3")} <span>{t("landing_page.body.us_subtitle_3_colored")}</span>
              </Typography>
              <ArrowForwardIosIcon sx={{transform: showStep3 ? "rotate(-90deg)" : "rotate(-270deg)", color: "landing.main", height: "26px", transition: "all 0.3s ease-in-out"}} />
            </Box>
            <Box sx={{maxHeight: "fit-content", overflow: "hidden"}}>
              <Typography variant="body1" color="ornament.light" sx={{fontWeight: "300", fontSize: "18px", padding: "0 20px 0 20px", mt: showStep3 ? "10px" : "-300%", mb: "10px", transition: "all 0.2s ease-in-out"}}>
                {t("landing_page.body.us_text_3")}
              </Typography>
            </Box>
          </Box>
        }
      </Box>
      <LandingFooter openDialog={(): void => setOpenDialog(true)} />
    </Box>
  )
}
