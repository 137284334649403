import React from 'react';
import {Box, SxProps, Typography} from '@mui/material';
import {Theme} from "@mui/material/styles";

export function LandingTitle(props: {title: string, undertitles: string[]}): JSX.Element {

  const {title, undertitles} = props;

  return (
    <>
      <Box sx={{width: "fit-content", margin: "0 auto"}}>
        <Typography variant="h1" color="landing.main" sx={{fontWeight: "700", textAlign: "center", fontSize: "24px", textTransform: "uppercase"}}>
          {title}
        </Typography>
      </Box>
      <Box sx={{width: "fit-content", margin: "0 auto"}}>
        {undertitles.map((undertitle, index) => 
          <Typography key={index} variant="h2" color="ornament.light" sx={{fontWeight: "700", textAlign: "center", fontSize: "32px", lineHeight: "unset"}}>
            {undertitle}
          </Typography>
        )}
      </Box>
    </>
  )
}

export function LandingTitleText(props: {title: string, titleColored: string, text: string, sx?: SxProps<Theme>}): JSX.Element {

  const {title, titleColored, text, sx} = props;

  return (
    <Box sx={{...sx}}>
      <Typography variant="h3" color="ornament.light" sx={{fontWeight: "700", fontSize: "29px", mb: 2, ml: "30px", "span": {color: "landing.main"}}}>
        {title} <span>{titleColored}</span>
      </Typography>
      <Typography variant="body1" color="ornament.light" sx={{fontWeight: "300", fontSize: "18px", borderLeftWidth: "1px", borderLeftStyle: "solid", borderLeftColor: "landing.main", pl: "29px"}}>
        {text}
      </Typography>
    </Box>
  )
}