import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CloseIcon from "@mui/icons-material/Close";
import {PrimaryBigButton, SecondaryBigButton} from "../../../../components/buttons/mainButton";
import React from "react";
import {useTranslation} from "react-i18next";


interface BlockOrderToSelectedParticipantsProps {
  open: boolean;
  close: () => void;
  content1: string;
  content2: string;
  button: string;
  title: string;
  setBlockOrder: (blockOrder: boolean) => void;
  block: boolean;

}

export default function BlockOrderToSelectedParticipants(props: BlockOrderToSelectedParticipantsProps): JSX.Element {
  const {open, close, content1, content2, button, title, setBlockOrder, block} = props;
  const {t} = useTranslation();

  const onConfirmBlock = (): void => {
    setBlockOrder(block)
    close();
  }

  return (
    <Dialog open={open} onClose={close} sx={{display: "flex", justifyContent: "center"}} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: "warning.main", borderColor: "warning.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <ReportProblemIcon sx={{color: "warning.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {title}
          </Typography>
        </Box>
        <IconButton onClick={close}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1" color="neutral.main" sx={{fontWeight: "unset"}} dangerouslySetInnerHTML={{__html: content1}}/>
        <Typography variant="body1" color="neutral.main" sx={{fontWeight: "unset"}} dangerouslySetInnerHTML={{__html: content2}}/>
      </DialogContent>
      <DialogActions sx={{p: 5, borderTop: "1px solid", borderColor: "ornament.dark"}}>
        <SecondaryBigButton sx={{mr: 0}} label={t("list.delete.cancel")} action={close}/>
        <PrimaryBigButton label={button} action={onConfirmBlock}/>
      </DialogActions>
    </Dialog>
  )
}
