import React, {useContext, useEffect, useState} from "react";

import {
  Alert,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import pointsBalanceImage from "../../assets/balancePoints/visuel_points.svg"
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {handleInitPaymentChoice} from "../operationTransactions/operationTransactionsPayment";
import {AppContext} from "../../App";
import {StoreContext} from "../../common/struct/store";
import {TCompany, TCompanyWallet} from "../../interfaces/company";
import {getCompany} from "../../services/CompanyService";
import {Loader} from "../../components/loader/loader";
import {TWalletTransactionOption} from "../../interfaces/wallet";
import {manageStringError} from "../../common/methods/ApiService";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import StarsIcon from '@mui/icons-material/Stars';
import {PRIVATE_URL} from "../../common/struct/urlManager";
import {formatAmount} from "../../utils/formatAmount";

export const MIN_POINTS_PURCHASE = 100;

export const PointsPaymentCardChildren = (props: { company: TCompany}): JSX.Element => {

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const missingAddress = store.global.admin.invoiceAddress == null;

  const {t} = useTranslation();
  const navigate = useNavigate();

  const [pointsPurchase, setPointsPurchase] = useState<string>(props.company.balance < 0 ? Math.abs(props.company.balance).toString() : MIN_POINTS_PURCHASE.toString());
  const [selectedWallet, setSelectedWallet] = useState<string|null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [wallets, setWallets] = useState<TCompanyWallet[]>([]);
  const [paymentType, setPaymentType] = useState<TWalletTransactionOption>(TWalletTransactionOption.CB);
  const [error, setError] = useState<string|null>(null);
  const [euroPerPoint, setEuroPerPoint] = useState(store.global.company.euroPerPoint);

  const handleLoadWallets = (): void => {
    if (store.global.company.id) {
      setLoading(true);
      getCompany(store.global.company.id).then((res) => {
        if (res) {
          res.wallets.map((wallet) => {
            if (wallet.name == res.defaultWallet.name) {
              setSelectedWallet(res.defaultWallet.id);
            }
            if (wallet.name != res.expiredPointsWallet.name) {
              setWallets(oldArray => [...oldArray, wallet]);
            }
          })
          setLoading(false);
        }
      })
    }
  };

  const MIN_POINTS_PRICE = 0.0001;

  const handlePointsPurchase = (event: any): void => {
    if (!isNaN(event.target.value)) {
      setPointsPurchase(event.target.value);
    }
  }

  const handleChange = (event: SelectChangeEvent): void => {
    setSelectedWallet(event.target.value as string);
  };

  useEffect(() => {
    handleLoadWallets()
  }, [store])

  const handleConfirm = (): void => {
    if (selectedWallet) {
      setError(null);
      setLoading(true);
      handleInitPaymentChoice(paymentType, pointsPurchase, store.global.company.id, euroPerPoint.toString(), selectedWallet, navigate, STORE)
        .catch(error => manageStringError(error, setError, t))
        .finally(() => setLoading(false));
    }
  }

  const handleEuroPerPointChange = (event: any): void => {
    if (!isNaN(event.target.value)) {
      setEuroPerPoint(event.target.value);
    }
  };

  return (
    <Box sx={{display: 'flex'}}>
      {/** @ts-ignore */}
      <Box component="img" sx={{backgroundColor: "primary.dark"}} src={pointsBalanceImage}/>
      <Box sx={{display: 'flex', flexDirection: 'column', px: 4, py: 3, width: '100%'}}>
        {error && <Alert variant="filled" severity="error" sx={{mt: 3}}>{error}</Alert>}
        {loading || selectedWallet == null ? <Loader sx={{height: "100%"}}/> : <>
          {!props.company.euroPerPointBlocked ?
            <>
              <Typography color="neutral.dark" sx={{fontWeight: "bold"}} variant="body1">{t("operations.points_balance.balance.payment.title")}</Typography>
              <Typography color="neutral.main" sx={{fontWeight: "regular", mt: 1}} variant="body2">{t("operations.points_balance.balance.payment.sub_title_start")}</Typography>
              <FormControl sx={{width: "49%", mr: 12, mt: 6, mb: 6}}>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1}}>
                  <Typography color="neutral.main" sx={{fontWeight: "regular"}} variant="body1">{t("operations.points_balance.balance.payment.euroPerPoint_label")}</Typography>
                  <Chip icon={<ErrorOutlineIcon/>}
                    sx={{backgroundColor: "error.light", py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: "error.dark", mt: "2px"}}}
                    label={<Typography variant="body2" color={"error.dark"} sx={{fontWeight: "regular"}}>
                      {t("operations.points_balance.balance.payment.warning_point_price")}
                    </Typography>}/>
                </Box>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", position: "relative"}}>
                  <TextField onChange={handleEuroPerPointChange} value={euroPerPoint} disabled={loading}
                    sx={{width: "100%", "& input": {p: 3}}}
                    InputProps={{
                      endAdornment: <InputAdornment position="end"><EuroSymbolIcon fontSize="small"/></InputAdornment>,
                      inputProps: {
                        min: MIN_POINTS_PRICE,
                        step: MIN_POINTS_PRICE
                      }
                    }}
                  />
                  <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "100px", position: "absolute", left: "100%", top: "50%", transform: "translateY(-50%)"}}><Typography color="neutral.main" sx={{fontWeight: 600, pl: 3, pr: 1}} variant="body1">{t("operations.points_balance.balance.payment.equal")}</Typography><StarsIcon sx={{color: "primary.main", mt: "2px"}}/></Box>
                </Box>
                <Typography color="neutral.main" sx={{fontWeight: "regular", mt: 0}} variant="body2">{t("operations.points_balance.balance.payment.point_price_between")}</Typography>
              </FormControl>
            </> :
            <Chip icon={<StarsIcon/>}
              sx={{backgroundColor: "ornament.main", borderRadius: "5px", justifyContent: "left", mb: 3, py: 0, pl: "7px", pr: "0px", "& .MuiChip-icon": {color: "neutral.main", mt: "2px"}}}
              label={<Typography variant="body2" color={"neutral.main"} sx={{fontWeight: "regular"}}>
                {t("operations.points_balance.balance.payment.point_price_reminder", {euroValue: euroPerPoint})}
              </Typography>}/>
          }
          <Typography color="neutral.dark" sx={{fontWeight: "bold"}} variant="body1">{t("operations.points_balance.balance.payment.buy_points")}</Typography>
          <Box sx={{mt: 1, mb: 3}}>
            <Box sx={{display: 'flex', width: "100%", alignItems: 'center', justifyContent: "space-between", mb: 1}}>
              <Box sx={{width: "49%"}}>
                <Typography color="neutral.main" sx={{fontWeight: "regular", mb: 1}} variant="body1">{"Montant"}</Typography>
                <Box sx={{width: "100%", "label": {pr: "6px", backgroundColor: "ornament.light"}}}>
                  <TextField onChange={handlePointsPurchase} color="primary" value={pointsPurchase}
                    sx={{width: '100%', mr: 2, "& input": {p: 3}}}
                    InputProps={{
                      lang: "fr-FR",
                      endAdornment: <InputAdornment position="end"><EuroSymbolIcon fontSize="small"/></InputAdornment>,
                      inputProps: {
                        min: MIN_POINTS_PURCHASE,
                      }
                    }}
                  />
                </Box>
              </Box>
              {wallets.length > 1 ?
                <FormControl sx={{width: "49%"}}>
                  <Typography color="neutral.main" sx={{fontWeight: "regular", mb: 1}} variant="body1">{t("operations.points_balance.wallets.title")}</Typography>
                  <Select
                    labelId="wallet-select-label"
                    value={selectedWallet}
                    onChange={handleChange}
                  >
                    {wallets.map((wallet, index) => {
                      return <MenuItem value={wallet.id} key={index}>{(wallet.name == "default" ? t("operations.points_balance.wallets.default") : wallet.name) + ` (${formatAmount(wallet.balance)} €)`}</MenuItem>
                    })}
                  </Select>
                </FormControl> :
                <Box sx={{width: "49%"}}>
                  <Typography color="neutral.main" sx={{fontWeight: "regular", opacity: 0}} variant="body1">{t("operations.points_balance.wallets.title")}</Typography>
                  <Typography color="neutral.main" onClick={(): void => navigate(PRIVATE_URL.WALLETS_LIST)} sx={{fontWeight: "regular", "span": {color: "primary.main", fontWeight: 700, cursor: "pointer"}}} variant="body2" dangerouslySetInnerHTML={{__html: t("operations.points_balance.balance.payment.new_wallet")}} />
                </Box>
              }
            </Box>
            <Typography color="neutral.main" sx={{fontWeight: "regular", mt: 0, "span": {color: "primary.main", fontWeight: 700}}} variant="body2" dangerouslySetInnerHTML={{__html: t("operations.points_balance.balance.payment.point_amount_reminder", {pointsValue: (Math.round(((parseInt(pointsPurchase)/euroPerPoint))* 100) / 100)})}} />
          </Box>
          <Box sx={{display: "flex", width: "100%", justifyContent: "space-between"}}>
            <FormControl>
              <FormLabel id="row-radio-buttons-group-label">{t("operations.points_balance.balance.payment.payment_type")}</FormLabel>
              <RadioGroup
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="CB" control={<Radio />} label="Carte bancaire" checked={TWalletTransactionOption.CB === paymentType} onClick={(): void => setPaymentType(TWalletTransactionOption.CB)}/>
                <FormControlLabel value="Transfer" control={<Radio />} label="Virement" checked={TWalletTransactionOption.TRANSFER === paymentType} onClick={(): void => setPaymentType(TWalletTransactionOption.TRANSFER)}/>
              </RadioGroup>
            </FormControl>
            <PrimaryBigButton label={t("operations.points_balance.balance.payment.label_button")} action={handleConfirm} disabled={missingAddress} sx={{px: "100px !important"}}/>
          </Box>
        </>}
      </Box>
    </Box>
  )
}
