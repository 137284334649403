import {initialStore, Store, StoreContext, WizardType} from "../../struct/store";

export function initWizardStore(STORE: StoreContext, wizard: WizardType, notification?: string): void {
  const [, setStore] = STORE;
  switch (wizard) {
  case WizardType.CREATE_OPERATION: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        notification: notification
      },
      operationWizard: initialStore.operationWizard
    }));
    return;
  }
  case WizardType.IMPORT_PARTICIPANT: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      global: {
        ...previousStore.global,
        notification: notification
      },
      participantWizard: initialStore.participantWizard
    }));
    return;
  }
  }
}

export function goToStepWizardStore(STORE: StoreContext, wizard: WizardType, step: number): void {
  const [, setStore] = STORE;
  switch (wizard) {
  case WizardType.CREATE_OPERATION: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      operationWizard: {
        ...previousStore.operationWizard,
        currentStep: step
      }
    }));
    return;
  }
  case WizardType.IMPORT_PARTICIPANT: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      participantWizard: {
        ...previousStore.participantWizard,
        currentStep: step
      }
    }));
    return;
  }
  }
}

export function setStepToValidateWizardStore(STORE: StoreContext, wizard: WizardType, step: number): void {
  const [, setStore] = STORE;
  switch (wizard) {
  case WizardType.CREATE_OPERATION: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      operationWizard: {
        ...previousStore.operationWizard,
        stepToValidate: step
      }
    }));
    return;
  }
  case WizardType.IMPORT_PARTICIPANT: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      participantWizard: {
        ...previousStore.participantWizard,
        stepToValidate: step
      }
    }));
    return;
  }
  }
}

export function setHeaderWizardStore(STORE: StoreContext, wizard: WizardType, header: boolean): void {
  const [, setStore] = STORE;
  switch (wizard) {
  case WizardType.CREATE_OPERATION: {
    return;
  }
  case WizardType.IMPORT_PARTICIPANT: {
    setStore((previousStore: Store) => ({
      ...previousStore,
      participantWizard: {
        ...previousStore.participantWizard,
        header: header
      }
    }));
    return;
  }
  }
}