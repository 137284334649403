import {TGift} from "../../interfaces/gift";
import {Box, Dialog, DialogContent, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import React from "react";
import {useTranslation} from "react-i18next";
import defaultLogo from '../../assets/default_gift.svg';
import {formatEuroValues} from "../../common/methods/utils";
import {LIBERTY_THEME} from "../../tokens/libertyTheme";
import {isSuperAdmin} from "../../common/struct/globalVar";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {IconPosition} from "../../components/buttons/buttonProps";

interface GiftCardDetailProps {
  open: boolean;
  close: () => void;
  gift: TGift;
  isDeleted: boolean;
  excludeOrIncludeGift: () => void;
}

export default function GiftCardDetail(props: GiftCardDetailProps): JSX.Element {
  const {open, close, gift, isDeleted, excludeOrIncludeGift} = props;
  const {t} = useTranslation();

  const imageToDisplay = (gift.image_urls[0]?.small)??defaultLogo;
  const description = gift.x_website_description.replaceAll("<p><br></p>", "");
  const characteristics = gift.x_website_caracteristics.replaceAll("<p><br></p>", "");

  return (
    <Dialog open={open} onClose={close} sx={{display: "flex", justifyContent: "center", "& .MuiDialog-container": {minWidth: "50%"}}} disableEscapeKeyDown fullWidth maxWidth="md">
      <DialogContent sx={{px: 5}}>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "medium"}}>
            {t("operations.shop.gifts.details.title")}
          </Typography>
          <IconButton onClick={close}>
            <CloseIcon sx={{color: "neutral.main"}}/>
          </IconButton>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Box sx={{display: "flex", justifyContent: "center", mb: 3}}>
            <img style={{minWidth: "238px"}} src={imageToDisplay} alt={gift.name}/>
          </Box>
          <Box sx={{display: "flex", justifyContent: "space-between", mb: 3}}>
            <Box sx={{display: "flex", flexDirection: "column", mr: 3, mb: 3}}>
              <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "medium", mb: 2}}>
                {gift.name}
              </Typography>
              {gift.brand && <Box sx={{display: "flex", alignItems: "center", mb: 0}}>
                <img style={{maxHeight: "24px"}} src={gift.brand.image_url} alt={gift.brand.name}/>
                <Typography variant="body2" color="neutral.dark" sx={{ml: 1}}>
                  {gift.brand.name}
                </Typography>
              </Box>}
              <Typography variant="body2" color="neutral.main">
                {t("operations.shop.gifts.giftCard_subTitle", {points: gift.sellingPointPrice, euroValue: formatEuroValues(gift.sellingEuroNetPrice)})}
              </Typography>
            </Box>
            <PrimaryBigButton label={t("operations.shop.gifts.details." + (isDeleted ? "add" : "remove"))}
              icon={isDeleted ? AddIcon : RemoveIcon} position={IconPosition.LEFT} action={excludeOrIncludeGift}/>
          </Box>
          {isSuperAdmin() && <>
            <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "medium", mb: 1}}>
              {t("operations.shop.gifts.details.technical_information")}
            </Typography>
            <Box sx={{display: "flex", mb: 0}}>
              <Typography variant="body2" color="neutral.dark" sx={{mr: 1}}>
                {t("operations.shop.gifts.details.reference")}
              </Typography>
              <Typography variant="body2" color="neutral.main">{gift.default_code}</Typography>
            </Box>
            <Box sx={{display: "flex", mb: 3}}>
              <Typography variant="body2" color="neutral.dark" sx={{mr: 1}}>
                {t("operations.shop.gifts.details.supplier")}
              </Typography>
              <Typography variant="body2" color="neutral.main">{gift.supplier?.name??"-"}</Typography>
            </Box>
          </>}
          <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "medium"}}>
            {t("operations.shop.gifts.details.description")}
          </Typography>
          {description.length == 0 ? "-" : <Typography variant="body2" sx={{"& *": {width: "unset !important", fontFamily: "unset !important",
            fontSize: LIBERTY_THEME.typography.body2.fontSize,
            lineHeight: LIBERTY_THEME.typography.body2.lineHeight,
          }}} dangerouslySetInnerHTML={{__html: description}}/>}
          <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "medium", mt: 3}}>
            {t("operations.shop.gifts.details.characteristics")}
          </Typography>
          {characteristics.length == 0 ? "-" : <Typography variant="body2" sx={{"& *": {width: "unset !important", fontFamily: "unset !important",
            fontSize: LIBERTY_THEME.typography.body2.fontSize,
            lineHeight: LIBERTY_THEME.typography.body2.lineHeight,
          }}} dangerouslySetInnerHTML={{__html: characteristics}}/>}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
