import React, {useContext, useEffect, useState} from "react";
import {ImportWizardCardProps, ParticipantCount, ParticipantImportWizardSteps} from "./participantImportWizardSteps";
import {useTranslation} from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {AppContext} from "../../App";
import {StoreContext, WizardType} from "../../common/struct/store";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import {getImportErrorFile, saveParticipantImport, validateParticipantImport} from "../../services/ImportService";
import {participantStoreReducer} from "../../common/methods/context-setter/participantStoreReducer";
import {errorManager, manageStringError} from "../../common/methods/ApiService";
import {TImportViolation, TImportWarning, TParticipantImportStatus} from "../../interfaces/participantImport";
import {useNavigate} from "react-router-dom";
import TextAlert from "../../components/alerts/textAlert";
import {AlertSeverity} from "../../components/alerts/alertProps";
import {Loader} from "../../components/loader/loader";
import {NEXT_STEP} from "../../common/methods/context-setter/globals";
import {setStepToValidateWizardStore} from "../../common/methods/context-setter/wizardStoreReducer";
import {getOperation} from "../../services/OperationService";
import DeleteDialog from "../../patterns/dialog/deleteDialog";
import {getExportDocument} from "../../common/methods/exportDocument";

export default function ValidateImportCard(props: ImportWizardCardProps): JSX.Element {
  const {loading, disableNextStep, setLoading, setDisableNextStep, saveHeader} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = t("tab_title.operation.import.step3");
  }, []);
  const [error, setError] = useState<string|null>(null);
  const [users, setUsers] = useState<any[]>([]);
  const [violations, setViolations] = useState<TImportViolation[]>([]);
  const [warnings, setWarnings] = useState<TImportWarning[]>([]);
  const [warningLines, setWarningLines] = useState<number[]>([]);
  const [warningsCreation, setWarningsCreation] = useState<number>(0);
  const [warningsUpdate, setWarningsUpdate] = useState<number>(0);
  const [currentErrorLines, setCurrentErrorLines] = useState(0);
  const [currentWarningLines, setCurrentWarningLines] = useState(0);
  const [validParticipantCounts, setValidParticipantCounts] = useState<ParticipantCount>({created: 0, updated: 0});
  const [ignoreValidationErrors, setIgnoreValidationErrors] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [mapping, setMapping] = useState<any|null>(null);

  const isAttribute = (field: string): boolean => {
    const fields = ['id', 'firstName', 'lastName', 'email', 'password', 'phone', 'job', 'civility', 'points'];    
    return fields.includes(field);
  }

  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const mappingToSend = store.participantWizard.uploadedFileDatas?.mapping;
  const importId = store.participantWizard.uploadedFileDatas?.id;
  const walletId = store.participantWizard.selectedWallet;

  const nbWarningCreation = 0;
  const nbWarningUpdate = 0;

  const downloadErrorFile = (): void => {
    if (importId == null) {
      return;
    }

    setDisableNextStep(true);
    getImportErrorFile(importId)
      .then(getExportDocument)
      .finally(() => setDisableNextStep(false));
  }

  const ignoreErrorsInFile = (): Promise<void> => {
    setIgnoreValidationErrors(true);
    setStepToValidateWizardStore(STORE, WizardType.IMPORT_PARTICIPANT, ParticipantImportWizardSteps.VALIDATION);
    return Promise.resolve();
  }

  useEffect(() => {    
    if (importId == null || mappingToSend == null) {
      navigate(operationUrl(PRIVATE_URL.OPERATION_CUSTOMISATION, store.global.currentOperation!.id));
      return;
    }

    setLoading(true);
    validateParticipantImport(importId, mappingToSend, walletId, saveHeader)
      .then(data => {
        const violations = data.violations;
        const warnings = data.warnings;

        let nbError = 0;
        if (violations) {
          violations.map(line => {
            if (line.violations) nbError = nbError + line.violations.length
          });
        }

        let nbWarning = 0;
        if (warnings) {
          warnings.map(line => {
            if (line.warnings) nbWarning = nbWarning + line.warnings.length
            if (line.type === "CREATION") setWarningsCreation(warningsCreation + 1)
            if (line.type === "UPDATE") setWarningsUpdate(warningsUpdate + 1)
          });
        }

        if (nbError !== 0) setViolations(violations);
        if (nbWarning !== 0) setWarnings(warnings);
        if (nbWarningCreation !== 0) setWarningsCreation(nbWarningCreation);
        if (nbWarningUpdate !== 0) setWarningsUpdate(nbWarningUpdate);

        setUsers(data.created.users.concat(data.updated.users));
        setValidParticipantCounts({updated: data.updated.count, created: data.created.count});

        setCurrentErrorLines(nbError);
        setCurrentWarningLines(nbWarning);
        if (nbError > 0) setError(t("import_participants.validation.error", {total: nbError}))

        // Store "nbError" in CONTEXT cause it's useFull in FILE SAVE FETCH
        participantStoreReducer(STORE, {type: "ERROR_IN_FILE", nbError: nbError});
      })
      .catch(error => {
        manageStringError(errorManager(error, t, STORE, navigate), setError, t);
        setCurrentErrorLines(0);
        // Store "nbError" in CONTEXT cause it's useFull in FILE SAVE FETCH
        participantStoreReducer(STORE, {type: "ERROR_IN_FILE", nbError: 0});
      })
      .finally(() => setLoading(false))
  }, []);

  useEffect(() => {
    if (store.participantWizard.stepToValidate !== ParticipantImportWizardSteps.VALIDATION || importId == null) {
      return;
    }

    let saving = false;
    if ((currentErrorLines > 0 || currentWarningLines > 0) && !ignoreValidationErrors) {
      setOpenWarningDialog(true);
      setStepToValidateWizardStore(STORE, WizardType.IMPORT_PARTICIPANT, 0);
    } else {
      setLoading(true);
      saveParticipantImport(importId, ignoreValidationErrors, saveHeader)
        .then(participantImport => {
          saving = participantImport.status == TParticipantImportStatus.SAVING;
          return getOperation(store.global.currentOperation!.id)
        })
        .then((operation) => participantStoreReducer(STORE, {type: NEXT_STEP, operation: operation, saving}))
        .catch(async error => {
          setIgnoreValidationErrors(false);
          const errorResult = errorManager(error, t, STORE, navigate);
          if (typeof errorResult === 'string') setError(errorResult);
        })
        .finally(() => {
          setStepToValidateWizardStore(STORE, WizardType.IMPORT_PARTICIPANT, 0);
          setLoading(false);
        });
    }
  }, [store.participantWizard.stepToValidate])

  useEffect(() => {    
    warnings.forEach(warning => {
      if (users.find(user => user.email === warning.value.email)) {
        setWarningLines(prevState => [...prevState, warning.value.email]);
      }
    })

    if (!saveHeader) {
      setMapping(mappingToSend);           
    } else {
      if (users.length > 0) { 
        const mapping: any = {};
        Object.getOwnPropertyNames(users[0]).map((value) => {
          mapping[value] = value;
        });

        setMapping(mapping);
      }
    }
  }, [users])

  return loading ? <Loader/> :
    <>
      {openWarningDialog && <DeleteDialog open={openWarningDialog} close={(): void => setOpenWarningDialog(false)} deleteElement={ignoreErrorsInFile}
        buttonLabel={t("import_participants.validation.warning_button")}
        title={t("import_participants.validation.warning_title")} warning={t("import_participants.validation.warning_content", {errors: currentErrorLines, warnings: currentWarningLines})}/>}
      {error && <Alert severity="error" variant="filled" sx={{mb: 3, fontWeight: "regular", alignItems: "center"}}
        action={currentErrorLines > 0 && <Button disabled={disableNextStep || loading} variant="text" sx={{color: "ornament.light"}} onClick={downloadErrorFile}>
          {t("import_participants.validation.download_error")}
        </Button>}>
        {error}
      </Alert>}
      <Card sx={{display: "flex", border: "1px solid", borderColor: "ornament.dark", boxShadow: 1, p: 2, mb: 3}}>
        <Typography variant="body2" sx={{mr: 7, fontWeight: "bold"}}>
          {t("import_participants.validation.overview.title")}
        </Typography>
        <Box sx={{display: "flex", flexDirection: "column", px: 5,
          borderRight: "1px solid", borderLeft: "1px solid", borderColor: "ornament.dark"}}>
          <Typography variant="body2" color="neutral.main">
            {t("import_participants.validation.overview.created")}
          </Typography>
          <Typography variant="body1" sx={{fontWeight: "bold"}}>
            {validParticipantCounts.created}
          </Typography>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", px: 5}}>
          <Typography variant="body2" color="neutral.main">
            {t("import_participants.validation.overview.updated")}
          </Typography>
          <Typography variant="body1" sx={{fontWeight: "bold"}}>
            {validParticipantCounts.updated}
          </Typography>
        </Box>
      </Card>
      {users.length > 0 && <TableContainer component={Paper} sx={{border: "1px solid", borderRadius: "4px", borderColor: "ornament.dark", mt: 3, width: "100%"}}>
        <Table sx={{boxShadow: 1, width: "100%"}}>
          <TableHead>
            <TableRow sx={{overflowX: "auto", backgroundColor: "ornament.main", borderBottom: "1px solid", borderColor: "ornament.dark"}}>
              {mapping && Object.keys(mapping).map((key: any, index: any) => (
                mapping[key] !== "false" && 
                <>
                  <TableCell key={`header-${index}`} sx={{maxWidth: "250px"}}>
                    <Typography variant="body2" color="primary.main" sx={{py: 0, px: 2, width: "fit-content",
                      fontWeight: "bold", backgroundColor: "primaryLight.main", borderRadius: "4px"}}>
                      {isAttribute(mapping[key]) ? t("participants.import_participants.meta_types."+mapping[key]) : key}
                    </Typography>
                  </TableCell>
                  {key == 'id' && !mapping.prototype.hasOwnProperty.call('email') && 
                    <TableCell key={`header-email`} sx={{maxWidth: "250px"}}>
                      <Typography variant="body2" color="primary.main" sx={{py: 0, px: 2, width: "fit-content",
                        fontWeight: "bold", backgroundColor: "primaryLight.main", borderRadius: "4px"}}>
                        {"email"}
                      </Typography>
                    </TableCell>
                  }
                </>   
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentErrorLines > 0 && violations.map((error, index) =>
            { return <TableRow key={`error-${index}`}>
              {Object.keys(error.value).map((key, i) => {
                const errorMessages = error.violations
                  .filter(violation => key === violation.mappedPropertyPath)
                  .map(violation => violation.message);

                const warningCells = warnings.find((warning) => warning.line == error.line);

                let warningMessages: string[] = [];

                if (warningCells) {
                  warningMessages = warningCells.warnings
                    .filter(warning => key === warning.mappedPropertyPath)
                    .map(warning => warning.message);
                }

                return <TableCell key={`error-${index}-element-${i}`} sx={{maxWidth: "250px", backgroundColor: errorMessages.length > 0 ? "error.light" : warningMessages.length > 0 ? "warning.light" : "initial"}}>
                  <Typography variant="body2" sx={{py: 0, px: 2}}>
                    {key === "points" && parseInt(error.value[key]) > 0 ? "+" + error.value[key] : error.value[key]}
                  </Typography>
                  {errorMessages.length > 0 && <TextAlert sx={{pl: 2}} text={errorMessages.join("\n")} severity={AlertSeverity.ERROR}/>}
                  {errorMessages.length <= 0 && warningMessages.length > 0 && <TextAlert sx={{pl: 2}} text={warningMessages.join("\n")} severity={AlertSeverity.WARNING}/>}
                </TableCell>
              })}
            </TableRow>
            })}
            {currentWarningLines > 0 && warnings.map((warning, index) => {
              const errorWarning = violations.find((error) => error.line == warning.line);

              if (!errorWarning) {
                return <TableRow key={`warning-${index}`}>
                  {Object.keys(warning.value).map((key, i) => {
                    const warningMessages = warning.warnings
                      .filter(violation => key === violation.mappedPropertyPath)
                      .map(violation => violation.message);

                    return <TableCell key={`warning-${index}-element-${i}`} sx={{maxWidth: "250px", backgroundColor: warningMessages.length > 0 ? "warning.light" : "initial"}}>
                      <Typography variant="body2" sx={{py: 0, px: 2}}>
                        {key === "points" && parseInt(warning.value[key]) > 0 ? "+" + warning.value[key] : warning.value[key]}
                      </Typography>
                      {warningMessages.length > 0 && <TextAlert sx={{pl: 2}} text={warningMessages.join("\n")} severity={AlertSeverity.WARNING}/>}
                    </TableCell>
                  })}
                </TableRow>
              } else {
                return;
              }
            })}
            {users.map((user, index) =>
            { if (!warningLines.includes(user.email)) {
              return <TableRow key={`user-${index}`}>
                {Object.keys(user).map((key, i) => (
                  <TableCell key={`user-${index}-element-${i}`} sx={{maxWidth: "250px"}}>
                    <Typography variant="body2" sx={{py: 0, px: 2}}>
                      {key === "points" && parseInt(user[key]) > 0 ? "+" + user[key] : user[key]}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            } }
            )}
          </TableBody>
        </Table>
      </TableContainer>}
    </>
}

