import {getSelectedCompanyName} from "../../common/struct/globalVar";
import {OperationsList} from "./operationsList";
import {Parameters} from "./parameters";
import {Box, Typography} from "@mui/material";
import {ParameterItem, SelectCompanyItem} from "../../components/layoutItems/mainItem";
import BusinessIcon from "@mui/icons-material/Business";
import {UserProfile} from "./userProfile";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App";
import {ParameterType} from "../../components/layoutItems/itemProps";
import EmailIcon from '@mui/icons-material/Email';
import {LibertyTheme} from '../../tokens/libertyTheme';

export default function GlobalSideBar(): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);

  return (
    <Box sx={{
      height: "90vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      pr: 3,
      pl: 3,
      overflowY: "auto",
      "&::-webkit-scrollbar": {width: "6px"}, "&::-webkit-scrollbar-thumb": {background: LibertyTheme.palette.secondaryLight.main, borderRadius: "8px"}
    }}>
      <Box sx={{display: "flex", flexDirection: "column", pb: 5, boxSizing: "border-box"}}>
        {!(store.global.superAdmin && getSelectedCompanyName() == null) && <OperationsList/>}
        <Parameters/>
      </Box>
      <Box sx={{width: "100%", height: "fit-content", pb: 5, boxSizing: "border-box"}}>
        {store.global.superAdmin && 
          <Box>
            <Typography color="secondaryLight.main" sx={{fontWeight: "regular", mb: 1}} variant="body2">{t("layout_sideBar.liberty_title").toUpperCase()}</Typography>
            {getSelectedCompanyName() && <>
              <Typography color="secondaryLight.main" variant="body2">
                {t('layout_sideBar.selected_company')}
                <br/>
              </Typography>
              <Typography color="secondaryLight.main" variant="body2" fontWeight="bold">
                {getSelectedCompanyName()}
              </Typography>
              <SelectCompanyItem label={t("menu.select_company")} icon={BusinessIcon}/>
            </>}
            {!getSelectedCompanyName() && <SelectCompanyItem label={t("menu.select_new_company")} icon={BusinessIcon}/>}
          </Box>
        }
        <ParameterItem parameterType={ParameterType.CONTACT} label={t("layout_sideBar.parameters_mailto")} icon={EmailIcon}/>
        <UserProfile/>
      </Box>
    </Box>
  )
}
