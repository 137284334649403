import React, {useEffect} from 'react'
import {Box, Card, Typography} from "@mui/material";
import BackLink from "../../patterns/navigation/backLink";
import {LibertyTheme} from "../../tokens/libertyTheme";
import {PRIVATE_URL, PUBLIC_URL} from "../../common/struct/urlManager";
import {useNavigate} from "react-router-dom";
import {isAuthenticated} from "../../common/struct/globalVar";

export default function Error404() : JSX.Element {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate(PUBLIC_URL.REGISTER, {replace: true});
    }
  }, [])

  return (
    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100%"}}>
      <Card sx={{boxShadow: 2, p: 6, backgroundColor: "error.main", color: "error.light", width: "50%"}}>
        <Typography variant="h1" sx={{fontWeight: "bold", mb: 5}}>404</Typography>
        <Typography variant="body1" sx={{fontWeight: "medium", mb: 5}}>La page que vous cherchez n'existe plus</Typography>
        <BackLink sx={{mx: 0, color: "error.light", fontSize: LibertyTheme.typography.h2.fontSize}} to={PRIVATE_URL.LANDING_PAGE}/>
      </Card>
    </Box>
  )
}
