import React, {useContext, useEffect, useState} from "react";
import {ImportWizardCardProps, ParticipantImportWizardSteps} from "./participantImportWizardSteps";
import {Alert, Box, Card, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import UploadFile from "../../patterns/upload/uploadFile";
import {SecondarySmallButton} from "../../components/buttons/mainButton";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {IconPosition} from "../../components/buttons/buttonProps";
import {deleteParticipantImport, getImportModelFile, importParticipantsFile} from "../../services/ImportService";
import {TParticipantImportPreview} from "../../interfaces/participantImport";
import {errorManager} from "../../common/methods/ApiService";
import {AppContext} from "../../App";
import {StoreContext, WizardType} from "../../common/struct/store";
import FilePreviewCompact, {FilePreviewCompactProps} from "../../patterns/preview/filePreviewCompact";
import {CSV_FILE_TYPES, FileType} from "../../patterns/dialog/downloadFileProps";
import {participantStoreReducer} from "../../common/methods/context-setter/participantStoreReducer";
import {NEXT_STEP, SET_UPLOAD_FILE} from "../../common/methods/context-setter/globals";
import {setStepToValidateWizardStore} from "../../common/methods/context-setter/wizardStoreReducer";
import {getExportDocument} from "../../common/methods/exportDocument";
import {useNavigate} from "react-router-dom";

export default function UploadFileCard(props: ImportWizardCardProps): JSX.Element {
  const {operationId, loading, setDisableNextStep} = props;
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;
  const {t} = useTranslation();
  useEffect(() => {
    document.title = t("tab_title.operation.import.step1");
  }, []);
  const [error, setError] = useState<string|null>(null);
  const [filePreview, setFilePreview] = useState<null|FilePreviewCompactProps>(null);
  const [participantImport, setParticipantImport] = useState<TParticipantImportPreview|null>(null);
  const navigate = useNavigate();

  const uploadFile = (file: File): Promise<any> => {
    setError(null);
    setDisableNextStep(true);
    return importParticipantsFile(file, operationId)
      .then(participantImport => setParticipantImport(participantImport))
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
      })
      .finally(() => setDisableNextStep(false))
  }

  const downloadExampleFile = (): void => {
    getImportModelFile().then(getExportDocument)
  }

  const removeImport = (): Promise<void> => {
    if (participantImport == null) {
      return Promise.resolve();
    }

    setError(null);
    setDisableNextStep(true);
    return deleteParticipantImport(participantImport.id)
      .then(() => setParticipantImport(null))
      .catch(error => {
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') setError(errorResult);
      })
      .finally(() => setDisableNextStep(false))
  }

  useEffect(() => {
    if (participantImport) {
      setFilePreview({
        name: participantImport.originalName,
        size: participantImport.size,
        deleteFile: removeImport,
        type: FileType.CSV,
      });
      participantStoreReducer(STORE, {type: SET_UPLOAD_FILE, data: participantImport});
    } else {
      setFilePreview(null);
    }
  }, [participantImport]);

  useEffect(() => {
    if (store.participantWizard.stepToValidate !== ParticipantImportWizardSteps.UPLOAD) {
      return;
    }

    if (filePreview == null) {
      setError(t("import_participants.upload.missing_file"));
      setStepToValidateWizardStore(STORE, WizardType.IMPORT_PARTICIPANT, 0);
    } else {
      setError(null);
      participantStoreReducer(STORE, {type: NEXT_STEP});
    }
  }, [store.participantWizard.stepToValidate])

  return (
    <Card sx={{border: "1px solid", borderColor: "ornament.dark"}}>
      <Box sx={{display: "flex", flexDirection: "column", p: 6}}>
        <Typography variant="body1" sx={{fontWeight: "bold", mb: 1}}>
          {t("import_participants.upload.title")}
        </Typography>
        <Typography variant="body2" color="neutral.main" sx={{mb: 3}}>
          {t("import_participants.upload.subtitle")}
        </Typography>
        {error && <Alert severity="error" variant="filled" sx={{mb: 3}}>{error}</Alert>}
        {filePreview == null && <UploadFile disabled={loading} action={uploadFile} setError={setError} isPdf={false} types={CSV_FILE_TYPES}/>}
        {filePreview && <FilePreviewCompact disabled={loading} {...filePreview}/>}
      </Box>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "ornament.main", p: 3}}>
        <Typography variant="body2" color="neutral.main" dangerouslySetInnerHTML={{__html: t("import_participants.upload.no_file")}}/>
        <SecondarySmallButton disabled={loading} label={t("import_participants.upload.download_example")} icon={SaveAltIcon} position={IconPosition.LEFT} action={downloadExampleFile}
          sx={{color: "neutral.main", borderColor: "neutral.main", ":hover": {borderColor: "neutral.dark", color: "neutral.dark", backgroundColor: "ornament.light"}}}/>
      </Box>
    </Card>
  )
}
