import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Box, FormControl, RadioGroup, Typography} from "@mui/material";
import {operationUrl, PRIVATE_URL} from "../../common/struct/urlManager";
import OperationRulesPdf from "./operationRulesPdf";
import OperationRulesText from "./operationRulesText";
import OperationRulesDialog from "./operationRulesDialog";
import {AppContext} from "../../App";
import {TOperationRules, TOperationRulesFormat} from '../../interfaces/operation';
import {StoreContext} from "../../common/struct/store";
import Page from "../../patterns/page/page";
import ListSnackbar, {TAlert} from "../../patterns/list/listSnackbar";
import {addErrorAlert, addSuccessAlert} from "../../patterns/list/listUtils";
import Footer from "../../patterns/footer/footer";
import {AccessToShopButton} from '../operationMainPage/customisation/accessToShopButton';

export interface OperationRulesProps {
  rules: TOperationRules|null,
  selectedFormat: TOperationRulesFormat,
  askForSelectedFormatChange: (format: TOperationRulesFormat) => void,
  askForRuleDeletion: TOperationRulesFormat|null,
  setAskForRuleDeletion: (deleteRules: TOperationRulesFormat|null) => void,
  operationId: string,
  setRules: (rules: TOperationRules|null) => void,
  setSuccess: (success: string) => void,
  setError: (success: string) => void,
}

export default function OperationRules() : JSX.Element {
  const {t} = useTranslation();
  const {operationId} = useParams<string>();
  useEffect(() => {
    document.title = t("tab_title.operation.rule");
  }, []);
  const STORE = useContext<StoreContext>(AppContext);
  const [store] = STORE;

  const [alerts, setAlerts] = useState<TAlert[]>([]);
  const [rules, setRules] = useState<TOperationRules|null>(null);
  const [selectedFormat, setSelectedFormat] = useState(TOperationRulesFormat.PDF);
  const [openRulesDialog, setOpenRulesDialog] = useState(false);
  const [askForRuleDeletion, setAskForRuleDeletion] = useState<TOperationRulesFormat|null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (store.global.currentOperation?.id === operationId) {
      const currentRules = store.global.currentOperation?.rules;
      setRules(currentRules??null);
      if (currentRules) setSelectedFormat(currentRules?.format??TOperationRulesFormat.PDF);
      setLoading(false);
    }
  }, [store]);

  const changeSelectedFormat = (): void => {
    setAskForRuleDeletion(selectedFormat);
    setSelectedFormat(selectedFormat === TOperationRulesFormat.PDF ? TOperationRulesFormat.TEXT : TOperationRulesFormat.PDF);
  }

  const askForSelectedFormatChange = (format: TOperationRulesFormat): void => {
    if (rules && rules.format !== format) {
      setOpenRulesDialog(true);
    } else {
      setSelectedFormat(format);
    }
  }

  const setError = (error: string): void => addErrorAlert(error, setAlerts)
  const setSuccess = (success: string): void => addSuccessAlert(success, setAlerts)

  return (
    <>
      <Page title={t("operations.rules.title")} backLink={operationUrl(PRIVATE_URL.OPERATION_CUSTOMISATION, operationId!)} buttons={store.global.currentOperation && [<AccessToShopButton key={store.global.currentOperation.id} operation={store.global.currentOperation} />]}>
        <ListSnackbar alerts={alerts} setAlerts={setAlerts}/>
        <Box sx={{px: 8, display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mt: 6, mb: 5}}>
            <Typography color="neutral.main" sx={{fontWeight: "regular"}} variant="body2" dangerouslySetInnerHTML={{__html: t("operations.rules.subtitle")}}/>
          </Box>
          <OperationRulesDialog open={openRulesDialog} setOpen={setOpenRulesDialog} operationId={operationId!} changeSelectedFormat={changeSelectedFormat}/>
          {loading == null ? <></> :
            <FormControl sx={{width: "100%"}}>
              <RadioGroup>
                <OperationRulesPdf rules={rules}
                  selectedFormat={selectedFormat}
                  askForSelectedFormatChange={askForSelectedFormatChange}
                  askForRuleDeletion={askForRuleDeletion}
                  setAskForRuleDeletion={setAskForRuleDeletion}
                  operationId={operationId!}
                  setRules={setRules}
                  setError={setError}
                  setSuccess={setSuccess}/>
                <OperationRulesText rules={rules}
                  selectedFormat={selectedFormat}
                  askForSelectedFormatChange={askForSelectedFormatChange}
                  askForRuleDeletion={askForRuleDeletion}
                  setAskForRuleDeletion={setAskForRuleDeletion}
                  operationId={operationId!}
                  setRules={setRules}
                  setError={setError}
                  setSuccess={setSuccess}/>
              </RadioGroup>
            </FormControl>}
        </Box>
        <Footer/>
      </Page>
    </>
  )
}
