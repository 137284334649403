import React, {useState} from "react";
import {Box, Card, CircularProgress, IconButton, Typography} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";

export type ImagePreviewWizardProps = {
  url: string,
  name: string,
  size: number,
  fullWidth: boolean,
  smallImage?: boolean,
  deleteImage: () => void,
  sx?: SxProps<Theme>,
}

export default function ImagePreviewWizard(props: ImagePreviewWizardProps): JSX.Element {
  const {url, name, size, fullWidth, smallImage = false, deleteImage, sx} = props;

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Card sx={{display: "flex", flexDirection: fullWidth ? "column" : "row", border: "1px solid", borderColor: "ornament.dark", width: "100%", ...sx}}>
      <Box sx={{display: "flex", height: "auto", borderRight: fullWidth ? "none" : "1px solid", borderBottom: fullWidth ? "1px solid" : "none", borderColor: "ornament.dark", p: smallImage ? 2 : "0px"}}>
        <img src={url} alt="Uploaded image" className="uploaded-image" style={{maxHeight: fullWidth ? "100%" : "204px", height: "auto", width: fullWidth ? "100%" : "auto"}}/>
      </Box>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", width: fullWidth ? "auto" : "100%", boxSizing: "border-box", px: 5, py: 3}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography variant="body2" sx={{pr: 1, borderRight: "1px solid", borderColor: "ornament.dark"}}>
            {name}
          </Typography>
          <Typography variant="body2" color="neutral.main" sx={{pl: 1}}>
            {Number(size / 1000).toFixed(0)}Ko
          </Typography>
        </Box>
        <IconButton onClick={():void => { setLoading(true), deleteImage() }} sx={{p: "0px", "&:hover": {backgroundColor: `${loading && "unset"}`, cursor: `${loading && "default"}`}}}>
          {loading ? <CircularProgress size={24} /> : <ClearIcon sx={{width: 24, height: 24, color: "neutral.main"}}/>}
        </IconButton>
      </Box>
    </Card>
  )
}
