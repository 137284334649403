import React from 'react';
import {Avatar} from "@mui/material";
import smile from '../../assets/tips/smile.svg';
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export default function TipsSmileButton(props: {action: () => void, sx?: SxProps<Theme>}): JSX.Element {
  return (
    /** @ts-ignore */
    <Avatar src={smile} onClick={props.action} sx={{width: "unset", height: "unset", p: "3px", boxShadow: 1, cursor: "pointer",
      border: "1px solid", borderColor: "infoLight.main", backgroundColor: "infoLight.light",
      ":hover": {borderColor: "infoLight.dark", backgroundColor: "infoLight.light"}, ...props.sx}}/>
  )
}
