import React, {useState} from 'react';
import {Box, Typography, Divider} from '@mui/material';
import {LandingTitle} from "./landingTitle";
import {PRICING_DRIVING, PRICING_REWARD} from '../../common/struct/landingVar';
import CircleIcon from '@mui/icons-material/Circle';
import Stripe from "../../assets/stripeWhite.svg";
import Driving from "../../assets/landingPage/driving.svg";
import Reward from "../../assets/landingPage/reward.svg";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useTranslation} from "react-i18next";

export function DesktopPricing() : JSX.Element {

  const {t} = useTranslation();

  return (
    <>
      <LandingTitle title={t("landing_page.body.pricing_title")} undertitles={[t("landing_page.body.pricing_undertitle")]} />
      <Typography variant="body1" color="landing.main" sx={{fontWeight: "300", fontSize: "18px", margin: "32px auto 0px", width: "450px", textAlign: "center"}}>
        {t("landing_page.body.pricing_text")}
      </Typography>
      <Box sx={{width: "100%", height: "fit-content", display: "flex", justifyContent: "space-between", mt: 8}}>
        <Box sx={{width: "45%", textAlign: "center"}}>
          {/* @ts-ignore */}
          <Box sx={{width: "110px", height: "110px", margin: "0 auto", backgroundColor: "#EEEDF2", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}><img src={Driving} alt={t("landing_page.alt.drive")} style={{height: "65px", marginTop: "12px"}} /></Box>
          <Typography variant="h1" color="ornament.light" sx={{fontWeight: "700", textAlign: "center", fontSize: "32px", mb: 2, mt: 4}}>
            {t("landing_page.body.pricing_title_1")}
          </Typography>
        </Box>
        <Divider orientation="vertical" sx={{opacity: 0, width: "2px"}} flexItem />
        <Box sx={{width: "45%", textAlign: "center"}}>
          {/* @ts-ignore */}
          <Box sx={{width: "110px", height: "110px", margin: "0 auto", backgroundColor: "#EEEDF2", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}><img src={Reward} alt={t("landing_page.alt.reward")} style={{height: "75px"}} /></Box>
          <Typography variant="h1" color="ornament.light" sx={{fontWeight: "700", textAlign: "center", fontSize: "32px", mb: 2, mt: 4}}>
            {t("landing_page.body.pricing_title_2")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{width: "100%", height: "fit-content", display: "flex", justifyContent: "space-between", mt: 6}}>
        <Box sx={{width: "45%"}}>
          <Box sx={{width: "fit-content", height: "100%", mx: "auto", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            {PRICING_DRIVING.map((line, index) => 
              <Box key={index} sx={{display: "flex", alignItems: "center", mt: index == 0 ? "0px" : 4, width: "fit-content"}}>
                <CircleIcon sx={{color: "landing.main", mr: 3, height: "11px"}} />
                <Typography variant="body1" color="ornament.light" sx={{fontWeight: "600", fontSize: "18px"}}>
                  {line}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Divider orientation="vertical" sx={{backgroundColor: "landing.main", width: "2px"}} flexItem />
        <Box sx={{width: "45%"}}>
          <Box sx={{width: "fit-content", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", mx: "auto"}}>
            {PRICING_REWARD.map((line, index) => 
              <Box key={index} sx={{mt: index == 0 ? "0px" : 4}}>
                <Box sx={{display: "flex", alignItems: "center", mt: index == 0 ? "0px" : 4, width: "fit-content"}}>
                  <CircleIcon sx={{color: "landing.main", mr: 3, height: "11px"}} />
                  <Typography variant="body1" color="ornament.light" sx={{fontWeight: "600", fontSize: "18px"}}>
                    {line}
                  </Typography>
                </Box>
                {/* @ts-ignore */}
                {index == 0 && <img src={Stripe} alt={t("landing_page.alt.stripe")} style={{height: "28px", margin: "12px 0 0 27px"}} />}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export function MobilePricing() : JSX.Element {

  const {t} = useTranslation();

  const [showStep1, setShowStep1] = useState<boolean>(false);
  const [showStep2, setShowStep2] = useState<boolean>(false);

  return (
    <>
      <LandingTitle title={t("landing_page.body.pricing_title")} undertitles={[t("landing_page.body.pricing_undertitle")]} />
      <Box sx={{width: "100%", mt: 6}}>
        <Box sx={{width: "100%", height: "fit-content", display: "flex", justifyContent: "space-between", padding: "10px 20px 10px 20px", boxSizing: "border-box"}}>
          <Typography variant="h2" color="ornament.light" sx={{fontWeight: "700", textAlign: "center", fontSize: "32px"}}>
            {t("landing_page.body.pricing_title_1")}
          </Typography>
          <ArrowForwardIosIcon sx={{transform: showStep1 ? "rotate(-90deg)" : "rotate(-270deg)", cursor: "pointer", color: "landing.main", height: "26px", transition: "all 0.3s ease-in-out"}} onClick={(): any => setShowStep1(!showStep1)} />
        </Box>
        <Box sx={{maxHeight: "fit-content", overflow: "hidden", mt: 2}}>
          <Box sx={{width: "fit-content", height: "fit-content", display: "flex", flexDirection: "column", padding: "10px 20px 10px 20px", mt: showStep1 ? "0px" : "-300%", transition: "all 0.2s ease-in-out"}}>
            {PRICING_DRIVING.map((line, index) => 
              <Box key={index} sx={{display: "flex", alignItems: "center", mt: index == 0 ? "0px" : 4, width: "fit-content"}}>
                <CircleIcon sx={{color: "landing.main", mr: 3, height: "11px"}} />
                <Typography variant="body1" color="ornament.light" sx={{fontWeight: "600", fontSize: "18px"}}>
                  {line}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{width: "100%", mt: 1}}>
        <Box sx={{width: "100%", height: "fit-content", display: "flex", justifyContent: "space-between", padding: "10px 20px 10px 20px", boxSizing: "border-box"}}>
          <Typography variant="h2" color="ornament.light" sx={{fontWeight: "700", textAlign: "center", fontSize: "32px"}}>
            {t("landing_page.body.pricing_title_2")}
          </Typography>
          <ArrowForwardIosIcon sx={{transform: showStep2 ? "rotate(-90deg)" : "rotate(-270deg)", cursor: "pointer", color: "landing.main", height: "26px", transition: "all 0.3s ease-in-out"}} onClick={(): any => setShowStep2(!showStep2)} />
        </Box>
        <Box sx={{maxHeight: "fit-content", overflow: "hidden", mt: 2}}>
          <Box sx={{width: "fit-content", height: "fit-content", display: "flex", flexDirection: "column", padding: "10px 20px 10px 20px", mt: showStep2 ? "0px" : "-300%", transition: "all 0.2s ease-in-out"}}>
            {PRICING_REWARD.map((line, index) => 
              <Box key={index} sx={{display: "flex", alignItems: "center", mt: index == 0 ? "0px" : 4, width: "fit-content"}}>
                <CircleIcon sx={{color: "landing.main", mr: 3, height: "11px"}} />
                <Typography variant="body1" color="ornament.light" sx={{fontWeight: "600", fontSize: "18px"}}>
                  {line}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}