import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import {resetPassword} from "../../services/AuthenticationService";
import {errorManager} from "../../common/methods/ApiService";
import {StoreContext} from "../../common/struct/store";
import {AppContext} from "../../App";
import InfoIcon from "@mui/icons-material/Info";
import {useNavigate} from "react-router-dom";


type ConfirmResetPasswordProps = {
  openDialog: boolean,
  closeDialog: () => void,
  setError: (error: string) => void
  setSuccess: () => void
  email: string
}

export default function ConfirmResetPassword(props: ConfirmResetPasswordProps): JSX.Element {
  const {t} = useTranslation();
  const STORE = useContext<StoreContext>(AppContext);

  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const confirmResetPassword = (): void => {
    setLoading(true);
    resetPassword(props.email)
      .then(() => {
        props.closeDialog();
        props.setSuccess();
      })
      .catch(error => {
        props.closeDialog();
        const errorResult = errorManager(error, t, STORE, navigate);
        if (typeof errorResult === 'string') props.setError(errorResult);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Dialog open={props.openDialog} onClose={props.closeDialog} disableEscapeKeyDown>
      <Divider sx={{height: 4, backgroundColor: "info.main", borderColor: "info.main"}} flexItem/>
      <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <InfoIcon sx={{color: "info.main", width: 24, height: 24, mr: 1}}/>
          <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {t("user_settings.password.button_label")}
          </Typography>
        </Box>
        <IconButton onClick={props.closeDialog}>
          <CloseIcon sx={{color: "neutral.main"}}/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{pb: 5}}>
        <Typography variant="body1">{t("user_settings.password.warning_message")}</Typography>
        <Typography variant="body1">{t("user_settings.password.email_info")}</Typography>
      </DialogContent>
      <DialogActions sx={{display: 'flex', justifyContent: 'flex-end', borderTop: "1px solid", borderColor: "ornament.dark", p: 5}}>
        <PrimaryBigButton action={confirmResetPassword} label={t('user_settings.password.confirm_reset')} sx={{height: "56px"}} loading={loading}/>
      </DialogActions>
    </Dialog>
  );
}
